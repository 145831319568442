import { PublicLayout } from '@/components/Layout/PublicLayout';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/public')({
    component: Public,
});

function Public() {
    return (
        <PublicLayout>
            <Outlet />
        </PublicLayout>
    );
}
