import { PageHeader } from '@/components/Common/PageHeader';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { EmailConfig } from './EmailConfig';
import { LogoConfig } from './LogoConfig';
import { PhoneNumber } from './PhoneNumber';
import { Reporting } from './Reporting';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { Tabs } from 'antd';
import { navRoutes } from 'shared/navigation/navRoutes';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/system')({
    staticData: {
        title: 'System',
    },
    component: ConfigurationSystem,
});

function ConfigurationSystem() {
    const orgId = useOrgId();

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    toBreadCrumb(navRoutes.configuration_system, { orgId }),
                ]}
            />

            <PageHeader title="System configuration" />

            <Tabs
                type="line"
                defaultActiveKey="email"
                items={[
                    {
                        key: 'email',
                        label: 'E-mail',
                        children: <EmailConfig />,
                    },
                    {
                        key: 'logo',
                        label: 'Logo',
                        children: <LogoConfig />,
                    },
                    {
                        key: 'reporting',
                        label: 'Reporting',
                        children: <Reporting />,
                    },
                    {
                        key: 'phone',
                        label: 'Phone number',
                        destroyInactiveTabPane: true,
                        children: <PhoneNumber />,
                    },
                ]}
            />
        </>
    );
}
