import { PageHeader } from '@/components/Common/PageHeader';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { RouterInputs, trpc } from '@/trpc';
import { Card, Input, Space, Table } from 'antd';
import { format } from 'date-fns';
import { useState } from 'react';
import { AiPromptAction, AiPromptEdit } from './AiPromptEdit';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/ai-prompts')({
    staticData: {
        title: 'AI Prompts',
    },
    component: AiPrompts,
});

function AiPrompts() {
    const [aiPromptAction, setAiPromptAction] = useState<AiPromptAction>(['none']);
    const [queryVariables, setQueryVariables] = useState<RouterInputs['aiPrompt']['listAiPrompts']>(
        {
            limit: 10,
            offset: 0,
            orderBy: { id: 'desc' },
        },
    );

    const {
        data: aiPrompts,
        isLoading,
        refetch,
    } = trpc.aiPrompt.listAiPrompts.useQuery(queryVariables);

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: aiPrompts?.rows || [],
            total: aiPrompts?.total || 0,
            loading: isLoading,
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                render: (value) => BigInt(value).toString(),
            },
            {
                title: 'Title',
                dataIndex: 'title',
            },
            {
                title: 'Name',
                dataIndex: 'name',
            },
            {
                title: 'Description',
                dataIndex: 'description',
            },
            {
                title: 'Content',
                dataIndex: 'content',
                render: (text: string) => <div className="max-w-60 truncate">{text}</div>,
            },
            {
                title: 'Model',
                dataIndex: 'model',
                render: (text: string) => <div className="max-w-60 truncate">{text}</div>,
            },
            {
                title: 'Pricing Model',
                dataIndex: 'pricingModel',
                render: (text: string) => <div className="max-w-60 truncate">{text}</div>,
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                render: (date: string) =>
                    date ? format(new Date(date), 'yyyy-MM-dd HH:mm:ss') : '-',
            },
            {
                title: 'Updated At',
                dataIndex: 'updatedAt',
                render: (date: string) =>
                    date ? format(new Date(date), 'yyyy-MM-dd HH:mm:ss') : '-',
            },
        ],
        onQueryVariableChange(options) {
            setQueryVariables((prev) => ({
                ...prev,
                ...options,
            }));
        },
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
    });

    return (
        <>
            <PageHeader title="AI Prompts" subTitle="List of AI prompts." />

            <Space className="mb-4 flex justify-between">
                <Input.Search
                    placeholder="Search prompts"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={(value) => {
                        setQueryVariables({
                            ...queryVariables,
                            where: {
                                ...queryVariables.where,
                                OR: [{ name: { contains: value } }, { title: { contains: value } }],
                            },
                        });
                    }}
                />
            </Space>

            <Card>
                <Table
                    {...tableProps}
                    size="small"
                    scroll={{ x: true }}
                    onRow={(record) => ({
                        onClick: () => {
                            setAiPromptAction(['edit', record.id]);
                        },
                    })}
                />
            </Card>

            <AiPromptEdit
                action={aiPromptAction}
                onClose={() => {
                    setAiPromptAction(['none']);
                    void refetch();
                }}
            />
        </>
    );
}
