import { ComplaintFormWrapper } from '@/components/Complaint/ComplaintFormWrapper';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/compliance/complaints/$complaintId/edit')({
    staticData: {
        title: 'Edit complaint',
    },
    component() {
        return (
            <ComplaintFormWrapper
                action={['edit', { complaintId: Route.useParams().complaintId }]}
            />
        );
    },
});
