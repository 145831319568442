import { navRoutes } from 'shared/navigation/navRoutes';
import { ComplaintForm } from '@/components/Complaint/ComplaintForm';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useNavigate } from '@/hooks/useNavigate';
import { toBreadCrumb } from '@/utils/navigation';
import { Card, Col, Row } from 'antd';

type ComplaintFormWrapperProps = {
    action: ['add'] | ['edit', { complaintId: string }];
    emailClassificationId?: bigint;
};

export function ComplaintFormWrapper({ action, emailClassificationId }: ComplaintFormWrapperProps) {
    const orgId = useOrgId();
    const navigate = useNavigate();

    const complaintId = action[1]?.complaintId;
    const actionType = action[0];

    const onCancel = () => {
        if (actionType === 'add') {
            navigate({ moveInHistory: 'back' });
        } else {
            navigate({
                to: '/console/$orgId/compliance/complaints/details/$id/manage',
                params: {
                    orgId,
                    id: BigInt(complaintId!),
                },
            });
        }
    };

    const onAfterSaveCancel = (complaintId: string) => {
        navigate({
            to: '/console/$orgId/compliance/complaints/details/$id/manage',
            params: {
                orgId,
                id: BigInt(complaintId!),
            },
        });
    };

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumb(navRoutes.compliance_complaints_search, { orgId }),
                    actionType === 'add'
                        ? toBreadCrumb(navRoutes.compliance_complaints_add, { orgId })
                        : toBreadCrumb(navRoutes.compliance_complaints_edit, {
                              complaintId: complaintId!,
                              orgId,
                          }),
                ]}
            />
            {actionType && (
                <Row gutter={16} justify="center">
                    <Col sm={24} lg={14}>
                        <Card bordered>
                            <ComplaintForm
                                emailClassificationId={emailClassificationId}
                                onCancel={onCancel}
                                onAfterSaveCancel={onAfterSaveCancel}
                            />
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
}
