import { createRouter, RouterProvider } from '@tanstack/react-router';
import { routeTree } from '@/routeTree.gen';
import { NotFound } from '@/components/NotFound';
import { UserFlag, UserRole } from 'shared/constants/user';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AppModule } from 'shared/types/app';
// This is a workaround to force the AuthCallback route to be loaded
import {
    Route as AuthCallbackRoute,
    AuthCallback,
} from '@/routes/~(authentication)/~callback/~route';
import {
    Route as AuthAdminCallbackRoute,
    AdminAuthCallback,
} from '@/routes/~(authentication)/~admin-callback/~route';

AuthCallbackRoute.update({
    component: AuthCallback,
});

AuthAdminCallbackRoute.update({
    component: AdminAuthCallback,
});

export const router = createRouter({
    routeTree,
    defaultNotFoundComponent: NotFound,
    defaultPreload: 'intent',
});

declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router;
    }

    interface StaticDataRouteOption {
        title?: string;
        roles?: UserRole[];
        modules?: AppModule[];
        userFlags?: UserFlag[];
        featureFlag?: keyof ReturnType<typeof useFlags>;
        restrictToEmployee?: boolean;
        superAdminOnly?: boolean;
    }
}

export function TanstackRouterProvider() {
    return <RouterProvider router={router} />;
}
