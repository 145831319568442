import { logInDev } from '@/utils/general';
import { useEffect } from 'react';
import { useAppStateStore } from '@/stores/AppStateStore';

export function useOfflineDetection() {
    const appStateStore = useAppStateStore();

    useEffect(() => {
        const updateOnlineStatus = () => {
            logInDev('Online status changed to:', navigator.onLine);
            appStateStore.setIsOnline(navigator.onLine);
        };

        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, [appStateStore]);
}
