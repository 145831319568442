import { useState } from 'react';
import { uuid as _uuid } from 'shared/utils/general';

/**
 * Returns a unique identifier for the component. This ID is stable across re-renders.
 * @returns
 */
export const useUuid = () => {
    const [uuid] = useState<string>(() => _uuid());

    return uuid;
};
