import { FlagEdit } from '@/components/Claim/FlagEdit';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useNavigate } from '@/hooks/useNavigate';
import { useApolloClient } from '@apollo/client';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { FlagsByComplaintId } from '../ManageFlags';

export const Route = createFileRoute(
    '/console/$orgId/compliance/complaints/details/$id/manage/flag/add',
)({
    component: FlagAdd,
});

function FlagAdd() {
    const { id } = Route.useParams();
    const orgId = useOrgId();
    const apolloClient = useApolloClient();
    const navigate = useNavigate();

    const [open, setOpen] = useState(true);

    return (
        <FlagEdit
            complaintId={Number(id)}
            open={open}
            onClose={() => {
                setOpen(false);

                void apolloClient.refetchQueries({
                    include: [FlagsByComplaintId],
                });

                navigate({
                    to: '/console/$orgId/compliance/complaints/details/$id/manage',
                    params: {
                        orgId,
                        id,
                    },
                    fromComponent: 'drawer',
                });
            }}
        />
    );
}
