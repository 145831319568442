import { SpecifiedItemByIdQuery, SpecifiedItemByIdQueryVariables } from '@/__generated__/graphql';
import { FileLink } from '@/components/Common/FileLink';
//import { SpecifiedItemById } from '@/graphql/queries/specifiedItem';
import { Formatter } from '@/hooks/useFormatter';
import { FormField } from '@/model/FormField';
import { ApolloClient, QueryResult } from '@apollo/client';
import { makeAutoObservable, runInAction } from 'mobx';
import { useRef } from 'react';
import { JsonValue } from 'type-fest';
import {
    Card,
    CardBase,
    CardFactory,
    CardForm,
    CardMultiSelect,
    CardSingleSelect,
} from 'shared/model/Card';
import {
    CardChildBase,
    CardChildDate,
    CardChildDateTime,
    CardChildSpecifiedItem,
    CardChildUpload,
} from 'shared/model/CardChild';
import { CardDef, FormValues } from 'shared/types/questionnaire/definition';
import {
    SpecifiedItem,
    // SpecifiedItemAttribute,
    // SpecifiedItemAttributeType,
} from 'shared/types/specifiedItem';

export type SpecifiedItemByIdQueryResult = QueryResult<
    SpecifiedItemByIdQuery,
    SpecifiedItemByIdQueryVariables
>;

export function useQuestionnaireService(client: ApolloClient<object>): QuestionnaireService {
    const refFormItemTree = useRef(new QuestionnaireService(client));

    return refFormItemTree.current;
}

export class QuestionnaireService {
    client: ApolloClient<object>;
    isLoading: boolean = false;
    currentPathSeqNo: number = -1;
    root: Card;
    direction: 'backward' | 'forward' = 'forward';
    questionnaireDef: CardDef[] = [];

    constructor(client: ApolloClient<object>) {
        this.client = client;

        makeAutoObservable(this);
    }

    /**
     * Init form service with data from DB
     */
    init(root: CardBase, questionnaireDef: CardDef[], resetSeqNo: boolean = true) {
        this.questionnaireDef = questionnaireDef;

        if (root) {
            this.root = CardFactory.create(root);
            if (resetSeqNo) {
                this.answeredCards.forEach((q) => {
                    q.seqNo = -1;
                });
                this.root.seqNo = 0;
                this.currentPathSeqNo = 0;
            }
        }
    }

    initAndContinue(root: CardBase, questionnaireDef: CardDef[]) {
        this.questionnaireDef = questionnaireDef;

        if (root) {
            this.root = CardFactory.create(root);
            let lastAnswered = -1;
            this.answeredCards.forEach((q) => {
                if (lastAnswered < q.seqNo) {
                    lastAnswered = q.seqNo;
                }
            });
            this.root.seqNo = 0;
            this.currentPathSeqNo = lastAnswered;
        }
    }

    /**
     * Loads next form question
     * @returns {boolean} - is for telling whether next question was found
     */
    async loadNext(): Promise<boolean> {
        try {
            this.isLoading = true;

            if (this.currentPathSeqNo === -1) {
                if (this.questionnaireDef.length === 0) {
                    throw new Error('Questionnaire is missing.');
                }
                const rootCard = this.questionnaireDef[0];
                this.root = CardFactory.create(rootCard);
                this.root.seqNo = 0;
                this.currentPathSeqNo = 0;
                return true;
            } else {
                const nextPathSeqNo = this.currentPathSeqNo + 1;
                // Update currentPathSeqNo only if next question is found
                let updateCurrentPathSeqNo = false;

                const card = this.currentCard;
                card.answered = true;

                let nextCard: CardBase | null = null;
                let cardToProcess = card;
                // Get the next card for the current card or, if there is none, for cards up in the tree
                let proceed = false;
                do {
                    nextCard = this._getNextCard(cardToProcess);
                    if (nextCard) {
                        proceed = true;
                    } else {
                        if (cardToProcess.parentCard) {
                            cardToProcess = cardToProcess.parentCard;
                        } else {
                            proceed = true;
                        }
                    }
                } while (!proceed);

                if (nextCard) {
                    nextCard!.seqNo = nextPathSeqNo;
                    updateCurrentPathSeqNo = true;
                    this.direction = 'forward';
                }

                if (updateCurrentPathSeqNo) {
                    this.currentPathSeqNo++;
                    return true;
                } else {
                    return false;
                }
            }
        } finally {
            this.isLoading = false;
        }
    }

    get allCards(): CardBase[] {
        const getChildCards = (card: CardBase): CardBase[] => {
            const ary: CardBase[] = [card];
            if (card.nextCard) {
                ary.push(...getChildCards(card.nextCard));
            }
            if ('children' in card && Array.isArray(card.children)) {
                card.children?.forEach((q) => {
                    if (q.nextCard) {
                        ary.push(...getChildCards(q.nextCard));
                    }
                    if (q.additionalCard) {
                        ary.push(...getChildCards(q.additionalCard));
                    }
                });
            }
            return ary;
        };
        if (!this.root) {
            return [];
        }
        return getChildCards(this.root);
    }

    get answeredCards(): CardBase[] {
        const getChildCards = (card: CardBase): CardBase[] => {
            const ary: CardBase[] = [card];
            if (card.nextCard && card.nextCard.seqNo > -1) {
                ary.push(...getChildCards(card.nextCard));
            }

            if ('children' in card && Array.isArray(card.children)) {
                card.children?.forEach((q) => {
                    if (q.nextCard && q.nextCard.seqNo > -1) {
                        ary.push(...getChildCards(q.nextCard));
                    }
                    if (q.additionalCard && q.additionalCard.seqNo > -1) {
                        ary.push(...getChildCards(q.additionalCard));
                    }
                });
            }
            return ary;
        };
        if (!this.root) {
            return [];
        }
        return getChildCards(this.root);
    }

    /**
     * Get all selected children (selected or inputs on FORM type Cards).
     */
    get answers(): CardChildBase[] {
        const answers: CardChildBase[] = [];
        this.answeredCards.forEach((c) => {
            if ('children' in c && Array.isArray(c.children)) {
                c.children?.forEach((child) => {
                    if (child.isSelected || c.type === 'FORM' || c.type === 'SPECIFIED_ITEMS') {
                        answers.push(child);
                    }
                });
            }
        });
        return answers;
    }

    goToPreviousCard(): boolean {
        if (this.currentPathSeqNo < 0) {
            return false;
        }
        runInAction(() => {
            const currentCard = this.currentCard;
            currentCard.seqNo = -1;
            currentCard.answered = false;
        });
        this.currentPathSeqNo--;
        this.direction = 'backward';
        return true;
    }

    get currentCard(): CardBase {
        return this.allCards.find((q) => q.seqNo === this.currentPathSeqNo)!;
    }

    validateCurrentCard() {
        const values: FormValues = {};
        this.answers.forEach((c) => {
            values[c.id] = c.value;
        });

        this.currentCard.validate(values);
    }

    get isCurrentCardValid(): boolean {
        return this.currentCard.validationResults.flat(1).every((r) => !r.error);
    }

    _fetchCard(cardId: string, parentCard?: CardBase) {
        const card = this.questionnaireDef.find((q) => q.id === cardId);

        if (!card) {
            return undefined;
        }
        return CardFactory.create(card, parentCard);
    }

    _getNextCard(card: CardBase): CardBase | null {
        if (card instanceof CardMultiSelect) {
            if (card.nextCardId && !card.nextCard) {
                card.nextCard = this._fetchCard(card.nextCardId, card);
            }

            card.children?.map(async (o) => {
                if (o.isSelected && o.nextCardId && !o.nextCard) {
                    o.nextCard = this._fetchCard(o.nextCardId, card);
                }
                if (o.isSelected && o.additionalCardId && !o.additionalCard) {
                    o.additionalCard = this._fetchCard(o.additionalCardId, card);
                }
            });
            // Get first unanswered card -> child additional card -> child next card -> card next card
            let child = card.children?.find(
                (o) => o.isSelected && o.additionalCard && o.additionalCard.seqNo < 0,
            );
            if (child) return child.additionalCard!;
            child = card.children?.find((o) => o.isSelected && o.nextCard && o.nextCard.seqNo < 0);
            if (child) return child.nextCard!;
            if (card.nextCard && card.nextCard.seqNo < 0) {
                return card.nextCard;
            }
            return null;
        } else if (card instanceof CardSingleSelect) {
            const selectedChild = card.children?.find((o) => o.isSelected);
            if (selectedChild) {
                if (selectedChild.nextCardId && !selectedChild.nextCard) {
                    selectedChild.nextCard = this._fetchCard(selectedChild.nextCardId, card);
                }
                if (selectedChild.additionalCardId && !selectedChild.additionalCard) {
                    selectedChild.additionalCard = this._fetchCard(
                        selectedChild.additionalCardId,
                        card,
                    );
                }
            }
            if (card.nextCardId && !card.nextCard) {
                card.nextCard = this._fetchCard(card.nextCardId, card);
            }

            // Get first unanswered question -> Option Additional Q -> Option Next Question -> Question Next Question
            if (selectedChild) {
                if (selectedChild.additionalCard && selectedChild.additionalCard.seqNo < 0)
                    return selectedChild.additionalCard!;
                if (selectedChild.nextCard && selectedChild.nextCard.seqNo < 0)
                    return selectedChild.nextCard!;
            }
            if (card.nextCard && card.nextCard.seqNo < 0) {
                return card.nextCard;
            }
            return null;
        } else if (
            card.type === 'FORM' ||
            card.type === 'SPECIFIED_ITEMS' ||
            card.type === 'TEXT'
        ) {
            if (card.nextCardId && !card.nextCard) {
                card.nextCard = this._fetchCard(card.nextCardId, card);
            }
            if (card.nextCard && card.nextCard.seqNo < 0) {
                return card.nextCard;
            }
        }

        return null;
    }

    // async fetchSpecifiedItemAttributes(specifiedItemId: number): Promise<SpecifiedItemAttribute[]> {
    //     const { data } = await this.client.query<
    //         SpecifiedItemByIdQueryResult,
    //         SpecifiedItemByIdQueryVariables
    //     >({
    //         query: SpecifiedItemById,
    //         variables: { id: specifiedItemId },
    //     });

    //     const results: SpecifiedItemAttribute[] = [];

    //     const item = data?.data?.SpecifiedItemByPk;

    //     if (!item) {
    //         return [];
    //     }

    //     if (item.attributeName1) {
    //         results.push({
    //             name: item.attributeName1,
    //             type: item.attributeType1! as SpecifiedItemAttributeType,
    //             unit: item.attributeUnit1!,
    //             value: '',
    //         });
    //     }
    //     if (item.attributeName2) {
    //         results.push({
    //             name: item.attributeName2,
    //             type: item.attributeType2! as SpecifiedItemAttributeType,
    //             unit: item.attributeUnit2!,
    //             value: '',
    //         });
    //     }
    //     if (item.attributeName3) {
    //         results.push({
    //             name: item.attributeName3,
    //             type: item.attributeType3! as SpecifiedItemAttributeType,
    //             unit: item.attributeUnit3!,
    //             value: '',
    //         });
    //     }
    //     if (item.attributeName4) {
    //         results.push({
    //             name: item.attributeName4,
    //             type: item.attributeType4! as SpecifiedItemAttributeType,
    //             unit: item.attributeUnit4!,
    //             value: '',
    //         });
    //     }
    //     return results;
    // }

    private getSelectCardValue(cardInternalName: string): string {
        return this.answeredCards
            .filter((c) => c.internalName === cardInternalName)
            .map((c) => {
                if (c instanceof CardSingleSelect || c instanceof CardMultiSelect) {
                    const child = c.children?.find((o) => o.isSelected);
                    if (child) {
                        return child.title;
                    }
                }
                return '';
            })
            .join(';');
    }

    getFieldValue(cardOrFieldInternalName: string): JsonValue {
        const value = this.getSelectCardValue(cardOrFieldInternalName);
        if (value) {
            return value;
        }
        const field = this.answers.find((child) => child.internalName === cardOrFieldInternalName);
        if (!field) {
            return null;
        }
        if ('value' in field) {
            return field.value as JsonValue;
        } else {
            return null;
        }
    }

    getLogicalGroup2Labels(): string[] {
        return [
            ...new Set(
                this.answeredCards.map((q) => q.logicalGroup2).filter((g): g is string => !!g),
            ),
        ];
    }

    getLogicalGroup2Fields(
        logicalGroupLabel: string,
        labelType: 'title' | 'titleSystem' | 'titleInsured' = 'title',
        formatter: Formatter,
    ): FormField[] {
        return this.answeredCards
            .filter((c) => c.logicalGroup2 === logicalGroupLabel)
            .map((c) => {
                if (c instanceof CardSingleSelect) {
                    const value = c.children?.filter((o) => o.isSelected).find(() => true)?.title;
                    return [new FormField(c[labelType] || c.title, value)];
                } else if (c instanceof CardForm) {
                    if (c.children && c.children.length > 0) {
                        return c.children.map((child) => {
                            if (child instanceof CardChildUpload) {
                                const value = child.value
                                    ? (child.value || []).map((el) => {
                                          return (
                                              <FileLink
                                                  key={el.key}
                                                  fileKey={el.key}
                                                  fileName={el.name}
                                                  target="_blank"
                                              />
                                          );
                                      })
                                    : '';
                                return new FormField(child.title, value);
                            } else if (child instanceof CardChildDate) {
                                return new FormField(
                                    child.title,
                                    child.value ? formatter.formatISODate(child.value) : '',
                                );
                            } else if (child instanceof CardChildDateTime) {
                                return new FormField(
                                    child.title,
                                    child.value ? formatter.formatISODateTime(child.value) : '',
                                );
                            } else {
                                return new FormField(child.title, child.value);
                            }
                        });
                    } else {
                        return [];
                    }
                }
                return [];
            })
            .flat(1);
    }

    // Get all PDS IDs from CardChildBase pdsId
    get childrenPdsIds(): number[] {
        return this.answers
            .filter((c): c is CardChildSpecifiedItem => c.type === 'SPECIFIED_ITEM')
            .map((c) => c.pdsConfigItemId!)
            .filter((id) => !!id);
    }

    // Get all PDS IDs for selected SpecifiedItems
    get specifiedItemsPdsIds(): number[] {
        return this.answers
            .filter((c): c is CardChildSpecifiedItem => c.type === 'SPECIFIED_ITEM')
            .map((c) => {
                const item: SpecifiedItem[] = c.value || [];
                return item.filter((item) => !!item).map((item) => item.pdsIds!);
            })
            .flat(2)
            .filter((id) => !!id);
    }

    get files() {
        return this.answers
            .filter((c): c is CardChildUpload => c instanceof CardChildUpload)
            .filter((c) => !!c.value && Array.isArray(c.value) && c.value.length > 0)
            .map((c) => c.value || [])
            .flat(1);
    }

    setQuestionnaireDef(questionnaireDef: CardDef[]) {
        this.questionnaireDef = questionnaireDef;
    }
}
