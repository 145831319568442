import { useLocalObservable } from 'mobx-react-lite';
import { mobxToJson } from '@/global/mobx';
import { extendObservable, intercept, makeAutoObservable, runInAction } from 'mobx';
import { ViewModel } from '@/types/common';
import { createViewModel } from 'mobx-utils';
import { useRef } from 'react';

type Store<T extends Record<string, any>> = T & {
    $isSet: boolean;
};

/**
 * useLocalStore hook. Use it instead of useLocalObservable as it comes with some useful methods.
 * It returns array with following objects:
 *  1) store - observable object
 *  2) initStore - method for initializing store data or setting multiple properties
 *  4) storeVM - MobX ViewModel of the store
 * @param initializer
 * @return [store, initData, vmStore]
 */
export function useLocalStore<T extends Record<string, any>>(initializer?: () => T) {
    const isInitializing = useRef(false);
    const init = initializer
        ? () => {
              return { data: { ...initializer(), $isSet: true } };
          }
        : function () {
              return { data: { $isSet: false } } as { data: Store<T> };
          };
    const store = useLocalObservable<{ data: Store<T> }>(init as () => { data: Store<T> });
    const vmStore = useLocalObservable<{ data: ViewModel<T> }>(() => {
        if (Array.isArray(store.data)) {
            return { data: createViewModel(makeAutoObservable({}) as T) };
        }
        return {
            data: createViewModel(store.data),
        };
    });

    intercept(store.data, (change) => {
        if (isInitializing.current) {
            return change;
        }
        if (change.name === '$isSet') {
            return change;
        }
        if (store.data.$isSet) {
            return change;
        } else {
            throw new Error("Store wasn't initialized");
        }
    });

    const initData = (initData: T) => {
        try {
            runInAction(() => {
                isInitializing.current = true;
                try {
                    Object.keys(store.data).forEach((k) => {
                        delete store.data[k as keyof T];
                    });
                    extendObservable(store.data, { ...mobxToJson(initData), ...{ $isSet: true } });
                    vmStore.data = createViewModel(store.data);
                } catch (e) {
                    console.log(e);
                } finally {
                    isInitializing.current = false;
                }
            });
        } catch (e) {}
    };
    return [store.data as Store<T>, initData, vmStore.data] as const;
}
