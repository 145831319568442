import {
    OrderBy,
    UserInOrgBoolExp,
    UsersInOrgDsQuery,
    UsersInOrgDsQueryVariables,
} from '@/__generated__/graphql';
import { Link } from '@/components/Common/Link';
import { NewTabLink } from '@/components/Common/NewTabLink';
import { User } from '@/components/Common/User';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { CipStatistic } from '@/components/Training/UserTrainingStatus/CipStatistic';
import { HoursStatistic } from '@/components/Training/UserTrainingStatus/HoursStatistic';
import { UsersInOrgDs } from '@/graphql/queries/user';
import { useConfigServiceLoader } from '@/hooks/Configuration/useConfigServiceLoader';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useAntdTableOld } from '@/hooks/Table/useAntdTableOld';
import { useCurrentUser } from '@/hooks/User/useCurrentUser';
import { useCurrentTrainingPeriod } from '@/hooks/useCurrentTrainingPeriod';
import { useIsOffline } from '@/hooks/useIsOffline';
import { useNavigate } from '@/hooks/useNavigate';
import { getUserFullName } from '@/utils/general';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { commonActionColumn } from '@/utils/table';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Card, Table } from 'antd';
import { useState } from 'react';
import { navRoutes } from 'shared/navigation/navRoutes';

export const Route = createFileRoute('/console/$orgId/compliance/training/search')({
    staticData: {
        title: 'Staff training record',
    },
    component: TrainingRecordSearch,
});

type UserInOrg = UsersInOrgDsQuery['UserInOrg'][number];

function TrainingRecordSearch() {
    const orgId = useOrgId();
    const user = useCurrentUser();
    const isOffline = useIsOffline();
    const { currentTrainingPeriod } = useCurrentTrainingPeriod();
    const navigate = useNavigate();

    const [variables, setVariables] = useState<UsersInOrgDsQueryVariables>({
        where: { isEmployee: { _eq: true } },
        orderBy: [{ userLastName: OrderBy.desc }],
        limit: 10,
        offset: 0,
    });

    const config = useConfigServiceLoader((configService) =>
        configService.fetchStaffTrainingRecord(),
    );
    const { data, loading } = useQuery(UsersInOrgDs, {
        fetchPolicy: 'network-only',
        variables,
        pollInterval: 10000,
        skip: isOffline,
    });

    const { tableProps } = useAntdTableOld<UserInOrg>({
        data: {
            rows: data?.UserInOrg,
            loading: loading,
            total: data?.UserInOrgAggregate?.aggregate?.count,
        },
        onQueryVariableChange: (options) => {
            const where: UserInOrgBoolExp = user.roles.includes('org_admin')
                ? { _and: [{ isEmployee: { _eq: true } }] }
                : {
                      _and: [{ isEmployee: { _eq: true } }, { userId: { _eq: user.id } }],
                  };
            setVariables({
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length === 0 ? variables.orderBy : options?.orderBy,
                where: where,
            });
        },
        rowKey: 'userId',
        columns: [
            {
                title: 'Employee ID',
                dataIndex: 'userId',
                sorter: true,
            },
            {
                title: 'Name',
                dataIndex: 'fullName',
                sorter: true,
                render: (_, item) => (
                    <User
                        userId={item.userId}
                        name={getUserFullName(item)}
                        photoUrl={item.photoUrl}
                    />
                ),
            },
            {
                title: 'Position',
                dataIndex: 'position',
                sorter: true,
            },
            {
                title: '# of hours this period',
                dataIndex: 'userId',
                align: 'center',
                render: (value) => (
                    <HoursStatistic
                        userId={BigInt(value)}
                        trainingPeriodId={currentTrainingPeriod?.id || 0}
                        wrapInTag
                    />
                ),
            },
            {
                title: `${config.data?.pointsName} this period`,
                dataIndex: 'userId',
                align: 'center',
                render: (value) => (
                    <CipStatistic
                        userId={BigInt(value)}
                        trainingPeriodId={currentTrainingPeriod?.id || 0}
                        wrapInTag
                    />
                ),
            },
            {
                ...commonActionColumn,
                dataIndex: 'userId',
                render: (value) => (
                    <NewTabLink
                        to="/console/$orgId/compliance/training/user/$userId"
                        params={{ orgId, userId: value }}
                    />
                ),
            },
        ],
    });

    return (
        <>
            <Outlet />

            <BreadCrumbs
                breadCrumbs={[toBreadCrumbWithoutLink(navRoutes.compliance_training_search)]}
            />

            <div className="mb-4 flex justify-end">
                <Link
                    to="/console/$orgId/compliance/training/search/add-training"
                    params={{ orgId }}
                    type="primary"
                    icon={<PlusOutlined />}
                >
                    New Training Record
                </Link>
                {/* <Button icon={<PlusOutlined />} onClick={() => setOpen(true)}>
                    New Training Record
                </Button> */}
            </div>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ userId }) => ({
                        className: 'cursor-pointer',
                        onClick: (e) =>
                            navigate(
                                {
                                    to: '/console/$orgId/compliance/training/user/$userId',
                                    params: {
                                        orgId,
                                        userId,
                                    },
                                },
                                e,
                            ),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
