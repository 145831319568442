import { ComplaintFormWrapper } from '@/components/Complaint/ComplaintFormWrapper';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';
import { zodValidator } from '@tanstack/zod-adapter';

const ComplaintEditSearchSchema = z.object({
    emailClassificationId: z.coerce.bigint().optional(),
});

export const Route = createFileRoute('/console/$orgId/compliance/complaints/add')({
    staticData: {
        title: 'New complaint',
    },
    validateSearch: zodValidator(ComplaintEditSearchSchema),
    component() {
        const emailClassificationId = Route.useSearch().emailClassificationId;

        return (
            <ComplaintFormWrapper
                action={['add']}
                emailClassificationId={emailClassificationId ? emailClassificationId : undefined}
            />
        );
    },
});
