import { forwardRef, Ref } from 'react';
import { useCurrentUser } from '@/hooks/User/useCurrentUser';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { Select, GetRef } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { errorMessage } from '../errorMessage';
import { uuid } from 'shared/utils/general';
import { SYSTEM_ORG_ID } from 'shared/constants/org';
import { AsyncSelect, AsyncSelectProps } from '../Select/Async';
import { RouterInputs } from '@/trpc';

type HandlingPartySelectProps = Pick<
    AsyncSelectProps<bigint[], { key: string; value: bigint; label: string }>,
    'onChange' | 'value' | 'placeholder' | 'defaultOpen' | 'className'
> & {
    onLoaded?: () => void;
    forAdminShowAllOrgs?: boolean;
    showUnallocated?: boolean;
    showAllOrgs?: boolean;
    orgId?: bigint;
    containerId?: string;
};

type SelectRef = Ref<GetRef<typeof Select>>;

export const HandlingPartySelect = forwardRef(
    (
        {
            onLoaded,
            forAdminShowAllOrgs,
            showAllOrgs: showAllOrgsForUsers,
            showUnallocated,
            orgId,
            placeholder = 'All handling parties',
            containerId,
            ...props
        }: HandlingPartySelectProps,
        ref: SelectRef,
    ) => {
        const trpcClient = useTrpcClient();
        const user = useCurrentUser();

        const [id] = useState(containerId || uuid());

        const showAllOrgs = useMemo(
            () =>
                (user.roles.includes('org_admin') && forAdminShowAllOrgs === true) ||
                showAllOrgsForUsers === true ||
                user.isSuperAdmin,
            [user.roles, forAdminShowAllOrgs, showAllOrgsForUsers],
        );

        const fetchOptions = useCallback(async () => {
            try {
                const data = await trpcClient.handlingParty.listHandlingParties.query({
                    orgId,
                    where: {
                        isDisabled: false,
                        ...(showAllOrgs
                            ? {}
                            : ({
                                  UsersInHandlingParty: {
                                      some: {
                                          userId: user.id,
                                      },
                                  },
                              } satisfies RouterInputs['handlingParty']['listHandlingParties']['where'])),
                    },
                });

                onLoaded?.();

                return [
                    ...data.rows.map((item) => ({
                        key: item.id.toString(),
                        value: item.id,
                        label: item.name,
                    })),
                    ...(showUnallocated && user.isOrgAdmin
                        ? [
                              {
                                  key: String(SYSTEM_ORG_ID.UNALLOCATED),
                                  value: SYSTEM_ORG_ID.UNALLOCATED,
                                  label: 'Unallocated',
                              },
                          ]
                        : []),
                ];
            } catch (e) {
                errorMessage.show(e);
                return [];
            }
        }, [orgId, showAllOrgs, trpcClient, user.id, user.isOrgAdmin]);

        return (
            <AsyncSelect
                allowClear
                ref={ref}
                fetchOptions={fetchOptions}
                fetchOnInit
                optionFilterProp="label"
                placeholder={placeholder}
                getPopupContainer={() => document.getElementById(id)!}
                mode="multiple"
                {...props}
            />
        );
    },
);
