import { Form, Input } from 'antd';
import { FormInstance, Rule } from 'antd/es/form';
import { useEffect, useMemo } from 'react';
import PhoneNumberInput from '@/components/Common/PhoneNumberInput';
import { validatePhoneNumber } from '@/utils/phone';
import { Paths } from '@/types/common';
import { RouterInputs } from '@/trpc';

type Complaint = RouterInputs['complaint']['createComplaint'];

type Props = {
    form: FormInstance;
    name: Extract<Paths<Complaint>, 'preferredChannelValue'>;
};

export const PreferredChannelValueFormItem = ({ form, name }: Props) => {
    const preferredChannel = Form.useWatch<Complaint, Complaint['preferredChannel'] | undefined>(
        (values) => values.preferredChannel,
        form,
    );

    useEffect(() => {
        form.setFields([
            {
                name,
                errors: undefined,
            },
        ]);
    }, [preferredChannel]);

    const rules: Rule[] = useMemo(() => {
        const defaultRules = [
            {
                required: true,
                message: 'Field is required.',
            },
        ];

        if (preferredChannel?.toLowerCase() === 'email') {
            return [...defaultRules, { type: 'email', message: 'Invalid email address' }];
        }

        if (preferredChannel?.toLowerCase() === 'phone') {
            return [
                {
                    validator: async (_, value) => {
                        const isValid = validatePhoneNumber(value);
                        if (!isValid) {
                            throw new Error('Invalid phone number');
                        }
                    },
                },
            ];
        }

        return defaultRules;
    }, [preferredChannel]);

    const input = useMemo(() => {
        if (preferredChannel?.toLowerCase() === 'phone') {
            return <PhoneNumberInput />;
        }

        return <Input />;
    }, [preferredChannel]);

    return (
        <Form.Item
            label={`Preferred channel - ${preferredChannel || 'contact'}`}
            name={name}
            rules={rules}
        >
            {input}
        </Form.Item>
    );
};
