import { Button, Drawer, Form, Input, message } from 'antd';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { useEffect, useId, useState } from 'react';
import { errorMessage } from '@/components/Common/errorMessage';
import { useNavigate } from '@/hooks/useNavigate';
import { trpc } from '@/trpc';
import Joi from 'joi';

type EmailDomainFormProps = {
    action: ['add'] | ['edit', { id: number }];
};

type FormValues = {
    domain: string;
};

export function EmailDomainForm({ action }: EmailDomainFormProps) {
    const [form] = Form.useForm<FormValues>();
    const navigate = useNavigate();
    const trpcClient = useTrpcClient();
    const formId = useId();
    const [open, setOpen] = useState(true);
    const trpcUtils = trpc.useUtils();

    const isEdit = action[0] === 'edit';
    const title = isEdit ? 'Edit Email Domain' : 'Add Email Domain';

    useEffect(() => {
        if (isEdit) {
            const id = action[1].id;
            trpcClient.admin.emailDomain.getEmailDomain.query({ id }).then((data) => {
                form.setFieldsValue(data);
            });
        }
    }, [action, form, trpcClient]);

    const handleSubmit = async (values: FormValues) => {
        try {
            if (isEdit) {
                await trpcClient.admin.emailDomain.updateEmailDomain.mutate({
                    id: action[1].id,
                    set: {
                        domain: values.domain,
                    },
                });
                message.success('Email domain updated successfully');
            } else {
                await trpcClient.admin.emailDomain.createEmailDomain.mutate({
                    domain: values.domain,
                });
                message.success('Email domain added successfully');
            }
            await trpcUtils.admin.emailDomain.invalidate();
            setOpen(false);
        } catch (error) {
            errorMessage.show(error);
        }
    };

    return (
        <Drawer
            title={title}
            open={open}
            onClose={() => setOpen(false)}
            afterOpenChange={(open) => {
                if (!open) {
                    navigate({ to: '/admin-console/advanced/email-domains' });
                }
            }}
            width={400}
            footer={
                <div className="flex justify-end gap-2">
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button type="primary" htmlType="submit" form={formId}>
                        {isEdit ? 'Update' : 'Add'}
                    </Button>
                </div>
            }
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                id={formId}
                initialValues={{ domain: '' }}
            >
                <Form.Item
                    name="domain"
                    label="Email Domain"
                    rules={[
                        { required: true, message: 'Please input the email domain' },
                        {
                            validator: async (_, value) => {
                                if (!value) return;

                                const schema = Joi.string().domain({
                                    tlds: { allow: false },
                                });

                                const { error } = schema.validate(value);
                                if (error) {
                                    throw new Error('Please enter a valid domain');
                                }
                            },
                        },
                    ]}
                >
                    <Input placeholder="contoso.com" />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
