import { SupplierByPkQuery, SupplierSetInput } from '@/__generated__/graphql';
import { errorMessage } from '@/components/Common/errorMessage';
import { modal } from '@/components/Common/Modal';
import { PageHeader } from '@/components/Common/PageHeader';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { UpdateSupplier } from '@/graphql/mutations/supplier';
import { SupplierByPk } from '@/graphql/queries/supplier';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useLocalStore } from '@/hooks/useLocalStore';
import { toJSON } from '@/utils/hasura';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { LeftOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, message, Modal, Row, Skeleton, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { navRoutes } from 'shared/navigation/navRoutes';
import { FinancialDetails } from './FinancialDetails';
import { SupplierContacts } from './SupplierContacts';
import { SupplierInfo } from './SupplierInfo';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { useNavigate } from '@/hooks/useNavigate';

type Supplier = NonNullable<SupplierByPkQuery['SupplierByPk']>;

const SupplierDetails = observer(() => {
    const [supplier, initSupplier, supplierVm] = useLocalStore<Supplier>();
    const [getSupplierByPk] = useLazyQuery(SupplierByPk);
    const [updateSupplier] = useMutation(UpdateSupplier);
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    const orgId = useOrgId();
    const { supplierId } = useParams({
        from: '/console/$orgId/configuration/suppliers/details/$supplierId',
    });

    const load = async () => {
        try {
            const { data } = await getSupplierByPk({
                variables: {
                    id: supplierId,
                    orgId,
                },
                fetchPolicy: 'network-only',
            });
            if (!data?.SupplierByPk?.id) {
                Modal.error({
                    title: 'Error',
                    content:
                        "The supplier you are trying to access either doesn't exist or you don't have permissions to access it.",
                    afterClose() {
                        navigate({
                            to: '/console/$orgId/configuration/suppliers',
                            params: {
                                orgId,
                            },
                        });
                    },
                });
                return;
            }
            initSupplier(data.SupplierByPk!);
        } catch (e) {
            errorMessage.show(e);
        }
    };

    const save = async () => {
        try {
            message.info('Saving...');
            supplierVm.submit();

            await updateSupplier({
                variables: {
                    supplier: {
                        ...toJSON<SupplierSetInput>()(supplier, 'ExternalOrgs', 'orgId', 'id'),
                    },
                    supplierId: supplierId,
                    orgId: orgId,
                    externalOrgs: supplier.ExternalOrgs.map((el) => ({
                        externalOrgId: el.id,
                        supplierId: supplier.id,
                    })),
                },
            });
            message.destroy();
            message.success('Saved.');
        } catch (e) {
            supplierVm.reset();
            errorMessage.show(e);
        }
    };

    useEffect(() => {
        load();
    }, []);

    const confirmAndSave = () => {
        modal.confirm({
            title: 'Save?',
            content: 'Do you want to save changes?',
            onOk: () => {
                save();
                setIsEditing(false);
            },
            onCancel: () => {
                supplierVm.reset();
                setIsEditing(false);
            },
        });
    };

    const cancelEditing = () => {
        supplierVm.reset();
        setIsEditing(false);
    };

    const editFieldProps = {
        allowEdit: true,
        onSave: confirmAndSave,
        onCancel: cancelEditing,
        onStartEditing: () => {
            setIsEditing(true);
        },
        disableEditing: isEditing,
    };

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    toBreadCrumb(navRoutes.configuration_suppliers, { orgId }),
                    toBreadCrumbWithoutLink(navRoutes.configuration_suppliers_details),
                ]}
            />

            <div className="flex justify-between">
                <PageHeader title={supplier.name} />
                <Button
                    icon={<LeftOutlined />}
                    onClick={() =>
                        navigate({
                            to: '/console/$orgId/configuration/suppliers',
                            params: {
                                orgId,
                            },
                        })
                    }
                >
                    Return to suppliers list
                </Button>
            </div>

            <Space direction="vertical" size={16} className="w-full">
                <Row gutter={[16, 16]}>
                    {supplier.id ? (
                        <>
                            <Col span={16}>
                                <SupplierInfo
                                    editFieldProps={editFieldProps}
                                    supplier={supplierVm}
                                />
                            </Col>
                            <Col span={8}>
                                <FinancialDetails
                                    editFieldProps={editFieldProps}
                                    supplier={supplierVm}
                                />
                            </Col>
                        </>
                    ) : (
                        <Skeleton active />
                    )}
                    <Col span={24}>
                        {supplier.id && orgId ? <SupplierContacts /> : <Skeleton active />}
                    </Col>
                </Row>
            </Space>
        </>
    );
});

export const Route = createFileRoute('/console/$orgId/configuration/suppliers/details/$supplierId')(
    {
        staticData: {
            title: 'Supplier details',
        },
        component: SupplierDetails,
    },
);
