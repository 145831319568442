import { errorMessage } from '@/components/Common/errorMessage';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { useNavigate } from '@/hooks/useNavigate';
import { useSafePath } from '@/hooks/useSafePath';
import { useAuthStore } from '@/stores/AuthStore';
import { trpc } from '@/trpc';
import { requiredFieldRule } from '@/utils/form';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { MailOutlined, TeamOutlined } from '@ant-design/icons';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Form, Input, message, Typography } from 'antd';
import { navRoutes } from 'shared/navigation/navRoutes';

export const Route = createFileRoute('/admin-console/new-organization')({
    staticData: {
        title: 'Onboard new organization',
    },
    component: NewOrganization,
});

type State = {
    orgName: string;
    orgAdminEmail: string;
};

function NewOrganization() {
    const reloadUser = useAuthStore((state) => state.reloadUser);
    const { mutateAsync, isPending } = trpc.admin.org.onboardOrg.useMutation({
        async onSuccess() {
            void reloadUser();

            message.destroy();
            message.success('A new organization was created.');
            navigate({ to: '/admin-console' });
        },
        onError(error) {
            message.destroy();
            errorMessage.show(error);
        },
        onMutate() {
            message.loading('Creating new organization...');
        },
    });
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const safePath = useSafePath<State>();

    const onFinish = async (values: State) => {
        await mutateAsync({
            email: values.orgAdminEmail,
            name: values.orgName,
        });
    };

    return (
        <div className="mx-auto max-w-2xl">
            <BreadCrumbs
                breadCrumbs={[toBreadCrumbWithoutLink(navRoutes.adminPortal.newOrganization)]}
            />

            <Card className="mt-4">
                <Typography.Title level={4} className="mb-6 text-center">
                    Create New Organization
                </Typography.Title>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    scrollToFirstError
                    className="mx-auto max-w-lg"
                >
                    <Form.Item
                        label="Organization Name"
                        name={safePath('orgName')}
                        rules={requiredFieldRule}
                    >
                        <Input
                            prefix={<TeamOutlined className="text-gray-400" />}
                            autoFocus
                            placeholder="Enter organization name"
                            className="py-2"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Organization Admin Email"
                        name={safePath('orgAdminEmail')}
                        rules={[...requiredFieldRule, { type: 'email' }]}
                        extra="This email will be used to send admin credentials"
                    >
                        <Input
                            prefix={<MailOutlined className="text-gray-400" />}
                            placeholder="Enter admin email address"
                            className="py-2"
                        />
                    </Form.Item>

                    <Form.Item className="mb-0 mt-6">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isPending}
                            className="w-full"
                            size="large"
                        >
                            Create Organization
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}
