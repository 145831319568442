import { Card, Col, Row } from 'antd';
import { useFormatter } from '@/hooks/useFormatter';
import invariant from 'tiny-invariant';
import { claimFormStore } from '../store';
import { VTextInput } from '@/components/Common/ViewForm/VTextInput';
import { VTextArea } from '@/components/Common/ViewForm/VTextArea';
import { VDatePickerOld } from '@/components/Common/ViewForm/VDatePickerOld';
import { VDropdown } from '@/components/Common/ViewForm/VDropdown';
import { InsurersByProductTypeId } from '@/graphql/queries/insurer';
import { useLazyQuery } from '@apollo/client';
import { VReadOnly } from '@/components/Common/ViewForm/VReadOnly';
import dayjs from 'dayjs';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/policy-info')({
    component: PolicyInfo,
});

function PolicyInfo() {
    const claim = claimFormStore.use.formData?.();
    invariant(claim, 'Claim is required.');
    const { formatISODateTime, formatISODate, fDateTimeDayJs, fDateShortDayJs } = useFormatter();
    const [getInsurersByProductTypeId] = useLazyQuery(InsurersByProductTypeId, {
        variables: {
            productTypeId: claim?.productTypeId,
        },
        fetchPolicy: 'network-only',
    });

    return (
        <Row>
            <Col span={16}>
                <Card title="Policy" size="small">
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <VDropdown
                                field="insurerId"
                                label="Insurer"
                                formStore={claimFormStore}
                                formatValueFn={(_, { Insurer }) => Insurer?.name}
                                loaderFn={async () => {
                                    const { data } = await getInsurersByProductTypeId();

                                    return (
                                        data?.Insurer.map(({ id, name }) => ({
                                            value: id,
                                            label: name,
                                        })) || []
                                    );
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <VTextInput
                                field="policyName"
                                label="Policy Name"
                                formStore={claimFormStore}
                            />
                        </Col>

                        <Col span={12}>
                            <VTextInput
                                field="policyNo"
                                label="Policy Number"
                                formStore={claimFormStore}
                            />
                        </Col>
                        <Col span={12}>
                            <VReadOnly
                                field="policyStatus"
                                label="Status"
                                formStore={claimFormStore}
                            />
                        </Col>

                        <Col span={12}>
                            <VDatePickerOld
                                field="policyTermFrom"
                                label="Term From"
                                formStore={claimFormStore}
                                formatValueFn={(value) => formatISODateTime(value)}
                                format={fDateTimeDayJs}
                                showTime
                                maxDate={claim.policyTermTo && dayjs(claim.policyTermTo)}
                                rules={[
                                    {
                                        async validator(_, value: string) {
                                            if (!value) {
                                                throw new Error('Field is required');
                                            }

                                            if (
                                                claim.policyTermTo &&
                                                new Date(claim.policyTermTo) <= new Date(value)
                                            ) {
                                                throw new Error(
                                                    'Can not be later than “Term to” date',
                                                );
                                            }
                                        },
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <VDatePickerOld
                                field="policyTermTo"
                                label="Term To"
                                formStore={claimFormStore}
                                formatValueFn={(value) => formatISODateTime(value)}
                                format={fDateTimeDayJs}
                                showTime
                                minDate={claim.policyTermFrom && dayjs(claim.policyTermFrom)}
                                rules={[
                                    {
                                        async validator(_, value: string) {
                                            if (!value) {
                                                throw new Error('Field is required');
                                            }

                                            if (
                                                claim.policyTermFrom &&
                                                new Date(value) <= new Date(claim.policyTermFrom)
                                            ) {
                                                throw new Error(
                                                    'Can not be earlier than “Term From” date',
                                                );
                                            }
                                        },
                                    },
                                ]}
                                onSave={async (value, { save, getFieldValue }) => {
                                    void save({
                                        policyTermTo: value,
                                        ...(new Date(getFieldValue('policyIssuedOn')) >=
                                            new Date(value) && {
                                            policyIssuedOn: null,
                                        }),
                                    });
                                }}
                            />
                        </Col>

                        <Col span={12}>
                            <VDatePickerOld
                                field="policyIssuedOn"
                                label="Policy issue date"
                                formStore={claimFormStore}
                                formatValueFn={(value) => formatISODate(value)}
                                format={fDateShortDayJs}
                                maxDate={claim.policyTermTo && dayjs(claim.policyTermTo)}
                                rules={[
                                    {
                                        async validator(_, value: string) {
                                            if (!value) {
                                                throw new Error('Field is required');
                                            }

                                            if (
                                                claim.policyTermTo &&
                                                new Date(claim.policyTermTo) <= new Date(value)
                                            ) {
                                                throw new Error(
                                                    'Can not be later than “Term to” date',
                                                );
                                            }
                                        },
                                    },
                                ]}
                            />
                        </Col>

                        <Col span={24}>
                            <VTextArea
                                field="policyOtherInfo"
                                label="Other info"
                                formStore={claimFormStore}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}
