import { Link } from '@/components/Common/Link';
import { PageHeader } from '@/components/Common/PageHeader';
import { ConfigurationItemEditor } from '@/components/Configuration/ConfigurationItemEditor';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { ComplaintBrands } from './ComplaintBrands';
import { IncidentIndicators } from './IncidentIndicators';
import { StaffTrainingRecord } from './StaffTrainingRecord';
import { TimelineItem } from '@/types/common';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { Space, Tabs } from 'antd';
import { useMemo } from 'react';
import { COMPLAINT_MILESTONES } from 'shared/constants/complaint';
import { navRoutes } from 'shared/navigation/navRoutes';
import { ComplaintIndicators } from './ComplaintIndicators';
import { IncidentTypes } from './IncidentTypes';
import { LegislationItems } from './LegislationItems';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/compliance')({
    staticData: {
        title: 'Compliance',
        modules: ['compliance'],
    },
    component: ConfigurationCompliance,
});

function ConfigurationCompliance() {
    const orgId = useOrgId();

    const tabItems = useMemo(
        () => [
            {
                label: 'Complaints',
                key: 'complaints',
                children: (
                    <Space direction="vertical" size={[16, 16]} className="w-full">
                        <Link
                            to="/public/$orgId/complaint/new"
                            params={{ orgId: String(orgId) }}
                            target="_blank"
                            type="dashed"
                        >
                            Public complaint link
                        </Link>
                        <ConfigurationItemEditor
                            onValidate={(value) => {
                                const items: TimelineItem[] = JSON.parse(value);
                                const existingInternalNames = new Set();

                                for (const item of items) {
                                    if (existingInternalNames.has(item.internalName)) {
                                        return new Error(
                                            'Internal name must be unique. Duplicate found: ' +
                                                item.internalName,
                                        );
                                    }

                                    const blackListedInternalNames = [
                                        COMPLAINT_MILESTONES.registered.internalName,
                                        COMPLAINT_MILESTONES.received.internalName,
                                        COMPLAINT_MILESTONES.closed.internalName,
                                    ];

                                    if (blackListedInternalNames.includes(item.internalName)) {
                                        return new Error(
                                            'Internal name is reserved for system use: ' +
                                                item.internalName,
                                        );
                                    }

                                    existingInternalNames.add(item.internalName);
                                }

                                return undefined;
                            }}
                            title="Complaint Timeline Items"
                            settingToEdit="COMPLAINT_TIMELINE_ITEMS"
                            description="Rename, add or remove a complaint timeline item."
                            editor={{
                                height: '300px',
                            }}
                            refreshCache
                        />
                        <ConfigurationItemEditor
                            title="Complaint Flag Types"
                            settingToEdit="COMPLAINT_FLAG_TYPES"
                            description="Rename, add or remove a complaint flag type."
                            editor={{
                                height: '300px',
                            }}
                        />
                        <ConfigurationItemEditor
                            title="Complaint Vulnerabilities"
                            settingToEdit="COMPLAINT_VULNERABILITIES"
                            description="Rename, add or remove a complaint vulnerability."
                            editor={{
                                height: '300px',
                            }}
                        />
                    </Space>
                ),
            },
            {
                label: 'Complaints rules',
                key: 'complaints_rules',
                children: <ComplaintIndicators />,
            },
            {
                label: 'Complaint brands',
                key: 'complaints_brands',
                children: <ComplaintBrands />,
            },
            {
                label: 'Incidents',
                key: 'incidents',
                children: (
                    <Space direction="vertical" size={[16, 16]} className="w-full">
                        <ConfigurationItemEditor
                            title="Part of the business impacted"
                            settingToEdit="INCIDENT_PRODUCTS_IMPACTED_ITEMS"
                            description="A list of impacted products for incidents."
                            editor={{
                                height: '150px',
                            }}
                        />
                        <ConfigurationItemEditor
                            title="Risks"
                            settingToEdit="INCIDENT_RISKS"
                            description="A list of risks for incidents."
                            editor={{
                                height: '150px',
                            }}
                        />
                        <IncidentTypes />
                        <ConfigurationItemEditor
                            title="Root causes"
                            settingToEdit="INCIDENT_ROOT_CAUSES"
                            description="A list of root causes for incidents."
                            editor={{
                                height: '150px',
                            }}
                        />
                        <ConfigurationItemEditor
                            title="Incident Timeline Items"
                            settingToEdit="INCIDENT_TIMELINE_ITEMS"
                            description="Rename, add or remove an incident timeline item."
                            editor={{
                                height: '300px',
                            }}
                            refreshCache
                        />
                    </Space>
                ),
            },
            {
                label: 'Incident rules',
                key: 'incident_rules',
                children: <IncidentIndicators />,
            },
            {
                label: 'Staff training record',
                key: 'staff_training_record',
                children: <StaffTrainingRecord />,
            },
            {
                label: 'Obligations',
                key: 'obligations',
                children: <LegislationItems />,
            },
        ],
        [],
    );

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    toBreadCrumb(navRoutes.configuration_compliance, { orgId }),
                ]}
            />

            <PageHeader title="Compliance configuration" />

            <Tabs type="line" defaultActiveKey="complaints" items={tabItems} />
        </>
    );
}
