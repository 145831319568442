import { ClaimByPkQuery } from '@/__generated__/graphql';
import { errorMessage } from '@/components/Common/errorMessage';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { useCreateSignalStore } from '@/context/signal';
import { UpdateClaimByPk } from '@/graphql/mutations/claim';
import { ClaimByPk } from '@/graphql/queries/claim';
import { ProductTypeByPk } from '@/graphql/queries/productType';
import { MessagesNotificationSub } from '@/graphql/subscription/notification';
import { useCustomDocumentTitle } from '@/hooks/useCustomDocumentTitle';
import { useIsOffline } from '@/hooks/useIsOffline';
import { ClaimDetailsActions } from './Actions';
import { toBreadCrumb } from '@/utils/navigation';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { App, Badge, Skeleton, Tabs } from 'antd';
import { useState } from 'react';
import { OverrideProperties } from 'type-fest';
import { ClaimStatusEnum, ThirdPartyContactConfig } from 'shared/types/claim';
import { ContactCollection } from 'shared/types/contact';
import { ProductTypeDef } from 'shared/types/productType';
import { CLAIM_DETAILS_SECTIONS, navRoutes } from 'shared/navigation/navRoutes';
import { ClaimContext } from './ClaimContext';
import { ClaimSignalContext, ClaimSignals } from './ClaimSignalContext';
import { Header } from './Header';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useCurrentUser } from '@/hooks/User/useCurrentUser';
import { claimFormStore } from './store';
import { RecordNotFoundError } from '@/utils/error';
import { useNavigate } from '@/hooks/useNavigate';
import { useCurrentOrg } from '@/hooks/Org/useCurrentOrg';
import { createFileRoute, Outlet, useLocation } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/claims/details/$id')({
    staticData: {
        title: 'Claim Details',
    },
    params: {
        parse({ id }) {
            return {
                id: +id,
            };
        },
    },
    component: ClaimDetails,
});

export type Claim = OverrideProperties<
    NonNullable<ClaimByPkQuery['ClaimByPk']>,
    { contacts?: ContactCollection }
>;

function ClaimDetails() {
    const { message } = App.useApp();
    const currentOrg = useCurrentOrg();
    const orgId = useOrgId();
    const user = useCurrentUser();
    const { id } = Route.useParams();
    const signalsCtx = useCreateSignalStore<ClaimSignals>();
    const isOffline = useIsOffline();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [claimByPk] = useLazyQuery(ClaimByPk);
    const [updateClaimByPk] = useMutation(UpdateClaimByPk);
    const [getProductType] = useLazyQuery(ProductTypeByPk);

    const [productTypeDataTabName, setProductTypeDataTabName] = useState<string | undefined>();
    const [thirdPartyContacts, setThirdPartyContacts] = useState<ThirdPartyContactConfig>([]);

    const { formData: claim } = claimFormStore.useInit({
        loadDependencies: [id],

        async onSave(currentValue, newValue) {
            try {
                message.info('Saving...');

                await updateClaimByPk({
                    variables: { id: currentValue.id, orgId, data: newValue },
                });

                message.destroy();
                message.success('Saved.');

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad({ setReadonly }) {
            try {
                const { data, error } = await claimByPk({
                    variables: {
                        id,
                        orgId,
                    },
                    fetchPolicy: 'network-only',
                });

                if (!data?.ClaimByPk || error) {
                    throw error || new RecordNotFoundError();
                }

                const status = data?.ClaimByPk?.status;
                setReadonly(
                    [ClaimStatusEnum.closed, ClaimStatusEnum.closedInRecoveryOnly].includes(
                        status as ClaimStatusEnum,
                    ),
                );

                if (data?.ClaimByPk?.productTypeId) {
                    const productType = await getProductType({
                        variables: {
                            id: data?.ClaimByPk?.productTypeId,
                            orgId,
                        },
                        fetchPolicy: 'network-only',
                    });

                    if (productType?.data?.ProductTypeByPk?.definition) {
                        setProductTypeDataTabName(
                            (productType.data.ProductTypeByPk.definition as ProductTypeDef)
                                ?.claimTabLabel,
                        );
                    }

                    setThirdPartyContacts(
                        productType?.data?.ProductTypeByPk?.thirdPartyContactTypes || [],
                    );
                }
                return { success: true, data: data?.ClaimByPk };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onError(error) {
            message.destroy();

            if (error instanceof RecordNotFoundError) {
                await errorMessage.showAsync(
                    "The claim you are trying to access either doesn't exist or you don't have permissions to access it.",
                );
                navigate({
                    to: '/console/$orgId/claims/search',
                    params: { orgId },
                });
            } else {
                errorMessage.show(error);
            }
        },
    });

    useCustomDocumentTitle(`Claim - ${claim?.generatedId || id} - Curium`);

    const { data: messagesNotifications } = useSubscription(MessagesNotificationSub, {
        variables: {
            where: {
                isActive: { _eq: true },
                commLogId: { _isNull: false },
                userId: { _eq: user.id },
                orgId: { _eq: orgId },
                CommLog: { claimId: { _eq: id } },
            },
        },
        fetchPolicy: 'network-only',
        shouldResubscribe: true,
        skip: isOffline,
    });

    const isProductTypeDataExists = Object.values(claim?.productTypeData || {}).length;

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumb(navRoutes.claims_search, { orgId }),
                    { title: 'Claim Details' },
                ]}
            />
            {claim && BigInt(id) === BigInt(claim.id) ? (
                <ClaimContext.Provider
                    value={{
                        thirdPartyContactConfig: thirdPartyContacts,
                    }}
                >
                    <ClaimSignalContext.Provider value={signalsCtx}>
                        <Header />
                        <ClaimDetailsActions />
                        <Tabs
                            destroyInactiveTabPane
                            type="line"
                            activeKey={pathname.split('/')[6]}
                            onTabClick={(key) =>
                                navigate({
                                    to: `/console/$orgId/claims/details/$id/${key}`,
                                    params: {
                                        orgId,
                                        id,
                                    },
                                })
                            }
                            items={[
                                {
                                    key: CLAIM_DETAILS_SECTIONS.MANAGE,
                                    label: 'Manage claim',
                                    children: <Outlet />,
                                },
                                {
                                    key: CLAIM_DETAILS_SECTIONS.POLICY_INFO,
                                    label: 'Policy info',
                                    children: <Outlet />,
                                },
                                ...(isProductTypeDataExists
                                    ? [
                                          {
                                              key: CLAIM_DETAILS_SECTIONS.PRODUCT_TYPE_DATA,
                                              label: productTypeDataTabName || 'Product Type Data',
                                              children: <Outlet />,
                                          },
                                      ]
                                    : []),
                                {
                                    key: CLAIM_DETAILS_SECTIONS.COMMS,
                                    label: (
                                        <Badge
                                            count={
                                                messagesNotifications?.NotificationAggregate
                                                    .aggregate?.count || 0
                                            }
                                            size="small"
                                            offset={[5, -5]}
                                        >
                                            Communication
                                        </Badge>
                                    ),
                                    children: <Outlet />,
                                },
                                {
                                    key: CLAIM_DETAILS_SECTIONS.CONTACTS,
                                    label: 'Contacts',
                                    children: <Outlet />,
                                },
                                ...(currentOrg.enabledModules.includes('billing')
                                    ? [
                                          {
                                              key: CLAIM_DETAILS_SECTIONS.EXPENSES,
                                              label: 'Expenses & Billing',
                                              children: <Outlet />,
                                          },
                                      ]
                                    : []),
                                {
                                    key: CLAIM_DETAILS_SECTIONS.FINANCIALS,
                                    label: 'Financials',
                                    children: <Outlet />,
                                },
                                {
                                    key: CLAIM_DETAILS_SECTIONS.LIBRARY,
                                    label: 'Library',
                                    children: <Outlet />,
                                },
                                {
                                    key: CLAIM_DETAILS_SECTIONS.HISTORY,
                                    label: 'History',
                                    children: <Outlet />,
                                },
                            ]}
                        />
                    </ClaimSignalContext.Provider>
                </ClaimContext.Provider>
            ) : (
                <Skeleton active />
            )}
        </>
    );
}
