import { useNavigate } from '@/hooks/useNavigate';
import { Button, Result } from 'antd';

export function NotFound() {
    const navigate = useNavigate();

    return (
        <div className="w-full">
            <div className="flex h-screen items-center justify-center">
                <Result
                    status="warning"
                    title="404 - Page not found"
                    extra={
                        <Button type="primary" onClick={() => navigate({ moveInHistory: 'back' })}>
                            Go back
                        </Button>
                    }
                />
            </div>
        </div>
    );
}
