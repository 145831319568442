import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { navRoutes } from 'shared/navigation/navRoutes';
import { useState } from 'react';
import { Button, Card, Popconfirm, Space, Table } from 'antd';
import { PageHeader } from '@/components/Common/PageHeader';
import { OrderBy, ProductTypeDsQuery, ProductTypeDsQueryVariables } from '@/__generated__/graphql';
import { useAntdTableOld } from '@/hooks/Table/useAntdTableOld';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { grey } from '@ant-design/colors';
import { ProductTypeEdit } from './ProductTypeEdit';
import { errorMessage } from '@/components/Common/errorMessage';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { CostTypes } from './CostTypes';
import { generatePath } from 'shared/navigation/utils';
import { gql } from '@/__generated__';
import { useMutation, useQuery } from '@apollo/client';
import { commonActionColumn } from '@/utils/table';
import { Link } from '@/components/Common/Link';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/product-types/')({
    component: ProductTypeList,
});

type ProductType = ProductTypeDsQuery['ProductType'][number];

function ProductTypeList() {
    const orgId = useOrgId();
    const [deleteProductTypeByPk, { loading: deleting }] = useMutation(DeleteProductTypeByPk);

    const [idToEdit, setIdToEdit] = useState<number | undefined>(undefined);
    const [idToEditForCostTypes, setIdToEditForCostTypes] = useState<number | undefined>(undefined);
    const [variables, setVariables] = useState<ProductTypeDsQueryVariables>({
        where: undefined,
        orderBy: [{ id: OrderBy.desc }],
        limit: 10,
        offset: 0,
    });

    const { data, loading, refetch } = useQuery(ProductTypeDs, {
        fetchPolicy: 'network-only',
        variables: variables,
    });

    const { tableProps } = useAntdTableOld<ProductType>({
        rowKey: 'id',
        data: {
            rows: data?.ProductType,
            loading: loading || deleting,
            total: data?.ProductTypeAggregate?.aggregate?.count,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Space>
                        <Popconfirm
                            title="Are you sure?"
                            onPopupClick={(e) => e.stopPropagation()}
                            onConfirm={async () => {
                                try {
                                    await deleteProductTypeByPk({
                                        variables: {
                                            id: value,
                                            orgId,
                                        },
                                    });

                                    void refetch();
                                } catch (e) {
                                    errorMessage.show(e);
                                }
                            }}
                        >
                            <Button
                                type="text"
                                size="small"
                                icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </Popconfirm>

                        <Button
                            type="link"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIdToEditForCostTypes(value);
                            }}
                        >
                            Manage Cost Types
                        </Button>
                        <Link
                            to="/console/$orgId/configuration/product-types/$productTypeId"
                            params={{ orgId, productTypeId: value }}
                            onClick={(e) => e.stopPropagation()}
                            type="link"
                        >
                            Manage PDS
                        </Link>
                    </Space>
                ),
            },
        ],
    });

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    {
                        title: 'Product Types',
                        path: generatePath(navRoutes.configuration_productTypes, { orgId }),
                    },
                ]}
            />

            <PageHeader title="Product types configuration" />

            <ProductTypeEdit
                idToEdit={idToEdit}
                open={!!idToEdit}
                onClose={() => {
                    setIdToEdit(undefined);
                    void refetch();
                }}
            />

            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setIdToEdit(-1);
                    }}
                >
                    Add new
                </Button>
            </div>

            {idToEditForCostTypes && (
                <CostTypes
                    productTypeId={idToEditForCostTypes}
                    onClose={() => setIdToEditForCostTypes(undefined)}
                />
            )}

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: () => setIdToEdit(id),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}

const DeleteProductTypeByPk = gql(/* GraphQL */ `
    mutation DeleteProductTypeByPk($id: bigint!, $orgId: bigint!) {
        deleteProductTypeByPk(id: $id, orgId: $orgId) {
            id
        }
    }
`);

const ProductTypeDs = gql(/* GraphQL */ `
    query ProductTypeDs(
        $limit: Int = 10
        $offset: Int = 0
        $orderBy: [ProductTypeOrderBy!] = {}
        $where: ProductTypeBoolExp = {}
    ) {
        ProductType(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
            id
            orgId
            name
        }
        ProductTypeAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`);
