import { Tabs } from 'antd';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { ConfigurationExpenses } from './Expenses';
import { ConfigurationBilling } from './Billing';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { navRoutes } from 'shared/navigation/navRoutes';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/expenses-and-billing')({
    staticData: {
        title: 'Expenses & billing',
        modules: ['billing'],
    },
    component: ConfigurationExpensesAndBilling,
});

function ConfigurationExpensesAndBilling() {
    const orgId = useOrgId();

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    toBreadCrumb(navRoutes.configuration_expenses_and_billing, { orgId }),
                ]}
            />

            <Tabs type="line" defaultActiveKey="expenses">
                <Tabs.TabPane tab="Expenses" key="expenses">
                    <ConfigurationExpenses />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Billing" key="billing">
                    <ConfigurationBilling />
                </Tabs.TabPane>
            </Tabs>
        </>
    );
}
