import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { navRoutes } from 'shared/navigation/navRoutes';
import { useState } from 'react';
import { Button, Card, Table } from 'antd';
import { OrderBy, PdsVersionDsQuery, PdsVersionDsQueryVariables } from '@/__generated__/graphql';
import { useAntdTableOld } from '@/hooks/Table/useAntdTableOld';
import { PlusOutlined } from '@ant-design/icons';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useFormatter } from '@/hooks/useFormatter';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { PdsVersionByPdsEdit } from './PdsVersionByPdsEdit';
import { PageHeader } from '@ant-design/pro-layout';
import { generatePath } from 'shared/navigation/utils';
import { useQuery } from '@apollo/client';
import { ProductTypeByPk } from '@/graphql/queries/productType';
import { PdsById } from '@/graphql/queries/pds';
import { gql } from '@/__generated__/gql';
import { commonActionColumn } from '@/utils/table';
import { Link } from '@/components/Common/Link';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
    '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId',
)({
    staticData: {
        title: 'PDS version',
    },
    component: ConfigurationPdsVersionsByPds,
});

type PdsVersion = PdsVersionDsQuery['PdsVersion'][number];

function ConfigurationPdsVersionsByPds() {
    const orgId = useOrgId();
    const { pdsId, productTypeId } = Route.useParams();
    const { formatISODate } = useFormatter();

    const [idToEdit, setIdToEdit] = useState<number | undefined>(undefined);
    const [variables, setVariables] = useState<PdsVersionDsQueryVariables>({
        where: { pdsId: { _eq: +pdsId } },
        orderBy: [{ id: OrderBy.asc }],
        limit: 10,
        offset: 0,
    });

    const { data, loading, refetch } = useQuery(PdsVersionDs, {
        fetchPolicy: 'network-only',
        variables: variables,
    });
    const { data: productType } = useQuery(ProductTypeByPk, {
        variables: {
            id: productTypeId,
            orgId: orgId,
        },
    });
    const { data: pds, loading: pdsLoading } = useQuery(PdsById, {
        variables: {
            id: pdsId,
            orgId: orgId,
        },
    });

    const { tableProps } = useAntdTableOld<PdsVersion>({
        rowKey: 'id',
        data: {
            rows: data?.PdsVersion,
            loading,
            total: data?.PdsVersionAggregate?.aggregate?.count,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
            },
            {
                title: 'Start date',
                dataIndex: 'startDate',
                render: (value) => formatISODate(value),
            },
            {
                title: 'End date',
                dataIndex: 'endDate',
                render: (value) => formatISODate(value),
            },
            {
                title: 'Questionnaire',
                dataIndex: 'Questionnaire.name',
            },
            {
                title: 'Workflow',
                dataIndex: 'Workflow.name',
            },
            {
                ...commonActionColumn,
                render: (_, { id, questionnaireId }) =>
                    !!questionnaireId && (
                        <Link
                            to="/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId"
                            params={{
                                orgId: String(orgId),
                                pdsVersionId: id,
                                questionnaireId,
                                productTypeId,
                            }}
                            search={{
                                data: window.btoa(
                                    JSON.stringify({
                                        insurerName: pds?.PdsByPk?.Insurer?.name,
                                    }),
                                ),
                            }}
                            target="_blank"
                            type="dashed"
                            onClick={(e) => e.stopPropagation()}
                        >
                            Public lodgement
                        </Link>
                    ),
            },
        ],
    });

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    {
                        title: (
                            <>
                                Product Types:
                                <strong> {productType?.ProductTypeByPk?.name || ''}</strong>
                            </>
                        ),
                        path: generatePath(navRoutes.configuration_productTypes, { orgId }),
                    },
                    {
                        title: (
                            <>
                                PDS: <strong>{pds?.PdsByPk?.name}</strong>
                            </>
                        ),
                        path: generatePath(navRoutes.configuration_productTypes_pds, {
                            productTypeId: productTypeId!,
                            orgId,
                        }),
                    },
                    {
                        title: 'PDS versions',
                        path: generatePath(navRoutes.configuration_productTypes_pds_pdsVersion, {
                            orgId,
                            productTypeId,
                            pdsId,
                        }),
                    },
                ]}
            />

            <PageHeader
                title={`PDS version configuration for ${pdsLoading ? '...' : pds?.PdsByPk?.name}`}
            />

            <PdsVersionByPdsEdit
                idToEdit={idToEdit}
                pdsId={+pdsId}
                open={!!idToEdit}
                onClose={() => {
                    setIdToEdit(undefined);
                    void refetch();
                }}
            />

            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setIdToEdit(-1);
                    }}
                >
                    Add new
                </Button>
            </div>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: () => setIdToEdit(id),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}

const PdsVersionDs = gql(/* GraphQL */ `
    query PdsVersionDs(
        $limit: Int = 10
        $offset: Int = 0
        $orderBy: [PdsVersionOrderBy!] = {}
        $where: PdsVersionBoolExp = {}
    ) {
        PdsVersion(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
            id
            name
            questionnaireId
            Questionnaire {
                id
                name
            }
            Workflow {
                id
                name
            }
            endDate
            pdsId
            startDate
        }
        PdsVersionAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`);
