import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { navRoutes } from 'shared/navigation/navRoutes';
import { Space } from 'antd';
import { ConfigurationItemEditor } from '@/components/Configuration/ConfigurationItemEditor';
import { PageHeader } from '@/components/Common/PageHeader';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/tasks')({
    staticData: {
        title: 'Tasks',
        modules: ['claim'],
    },
    component: ConfigurationTasks,
});

function ConfigurationTasks() {
    const orgId = useOrgId();

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    toBreadCrumb(navRoutes.configuration_tasks, { orgId }),
                ]}
            />

            <PageHeader title="Tasks configuration" />

            <Space direction="vertical" size={[16, 16]} className="w-full">
                <ConfigurationItemEditor
                    title="Task types"
                    settingToEdit={'TASK_TYPES'}
                    description="Rename, add or remove a task type."
                    editor={{ height: '300px' }}
                />
            </Space>
        </>
    );
}
