import { Auth0Provider as Auth0ProviderOriginal } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PropsWithChildren, useEffect } from 'react';
import { navRoutes } from 'shared/navigation/navRoutes';

export function Auth0Provider({ children }: PropsWithChildren) {
    const { auth0UseRefreshToken, enableDebugInfo } = useFlags();

    useEffect(() => {
        if (enableDebugInfo) {
            console.log('🚀 ~ AppSetup ~ auth0UseRefreshToken:', auth0UseRefreshToken);
        }
    }, [auth0UseRefreshToken]);

    return (
        <Auth0ProviderOriginal
            domain={import.meta.env.VITE_AUTH0_DOMAIN}
            clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
            authorizationParams={{
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                redirect_uri: `${window.location.origin}${navRoutes.public_authCallback.path}`,
            }}
            useRefreshTokens={auth0UseRefreshToken}
            cacheLocation="localstorage"
        >
            {children}
        </Auth0ProviderOriginal>
    );
}
