import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { useState } from 'react';
import { Button, Card, Popconfirm, Table } from 'antd';
import { PageHeader } from '@/components/Common/PageHeader';
import {
    OrderBy,
    QuestionnaireDsQuery,
    QuestionnaireDsQueryVariables,
} from '@/__generated__/graphql';
import { useAntdTableOld } from '@/hooks/Table/useAntdTableOld';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { QuestionnaireEdit } from './QuestionnaireEdit';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { navRoutes } from 'shared/navigation/navRoutes';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useCurrentUser } from '@/hooks/User/useCurrentUser';
import { errorMessage } from '@/components/Common/errorMessage';
import { gql } from '@/__generated__';
import { useMutation, useQuery } from '@apollo/client';
import { commonActionColumn } from '@/utils/table';
import { grey } from '@ant-design/colors';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/questionnaire')({
    staticData: {
        title: 'Questionnaire',
        modules: ['claim'],
    },
    component: ConfigurationQuestionnaire,
});

type Questionnaire = QuestionnaireDsQuery['Questionnaire'][number];

type QueryVariables = QuestionnaireDsQueryVariables;

function ConfigurationQuestionnaire() {
    const orgId = useOrgId();
    const user = useCurrentUser();
    const [deleteQuestionnaireByPk] = useMutation(DeleteObligationByPkMutation);

    const [idToEdit, setIdToEdit] = useState<number | undefined>(undefined);
    const [variables, setVariables] = useState<QueryVariables>({
        where: undefined,
        orderBy: [{ id: OrderBy.desc }],
        limit: 10,
        offset: 0,
    });

    const { data, loading, refetch } = useQuery(QuestionnaireDs, {
        fetchPolicy: 'network-only',
        variables: variables,
    });

    const { tableProps } = useAntdTableOld<Questionnaire>({
        rowKey: 'id',
        data: {
            rows: data?.Questionnaire,
            loading: loading,
            total: data?.QuestionnaireAggregate?.aggregate?.count,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
            },
            {
                ...commonActionColumn,
                render: (value) =>
                    user.isOrgAdmin && (
                        <Popconfirm
                            title="Are you sure?"
                            onPopupClick={(e) => e.stopPropagation()}
                            onConfirm={async () => {
                                try {
                                    await deleteQuestionnaireByPk({
                                        variables: {
                                            id: value,
                                            orgId,
                                        },
                                    });

                                    void refetch();
                                } catch (e) {
                                    errorMessage.show(e);
                                }
                            }}
                        >
                            <Button
                                type="text"
                                size="small"
                                icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </Popconfirm>
                    ),
            },
        ],
    });

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    toBreadCrumb(navRoutes.configuration_questionnaire, { orgId }),
                ]}
            />

            <PageHeader title="Questionnaire configuration" />

            <QuestionnaireEdit
                idToEdit={idToEdit}
                open={!!idToEdit}
                onClose={() => {
                    setIdToEdit(undefined);
                    void refetch();
                }}
            />

            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setIdToEdit(-1);
                    }}
                >
                    Add new
                </Button>
            </div>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: () => setIdToEdit(id),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}

const DeleteObligationByPkMutation = gql(/* GraphQL */ `
    mutation DeleteObligationByPkMutation($id: bigint!, $orgId: bigint!) {
        updateQuestionnaireByPk(pkColumns: { id: $id, orgId: $orgId }, _set: { isDeleted: true }) {
            id
        }
    }
`);

const QuestionnaireDs = gql(/* GraphQL */ `
    query QuestionnaireDs(
        $limit: Int = 10
        $offset: Int = 0
        $orderBy: [QuestionnaireOrderBy!] = {}
        $where: QuestionnaireBoolExp = {}
    ) {
        Questionnaire(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
            id
            orgId
            name
            definition
        }
        QuestionnaireAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`);
