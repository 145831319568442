import { useAuth0 } from '@auth0/auth0-react';
import { createFileRoute } from '@tanstack/react-router';
import qs from 'query-string';
import { useEffect } from 'react';
import { navRoutes } from 'shared/navigation/navRoutes';

export const Route = createFileRoute('/(authentication)/admin-login')({
    component: AdminLogin,
});

function AdminLogin() {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        const urlParams = qs.parse(window.location.search);

        void loginWithRedirect({
            authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                scope: 'openid profile email offline_access',
                isAdminLogin: true,
                redirect_uri: `${window.location.origin}${navRoutes.public_adminAuthCallback.path}`,
            },
            appState: {
                redirectTo: (urlParams.redirectTo as string) ?? '',
            },
        });
    }, []);

    return null;
}
