import { useTrpcClient } from '@/hooks/useTrpcClient';
import { useAuthStore } from '@/stores/AuthStore';
import { useOrgSettingsStore } from '@/stores/OrgSettingStore';
import { trpc } from '@/trpc';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink, unstable_httpBatchStreamLink } from '@trpc/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import superjson from 'superjson';

const queryClient = new QueryClient();

export function TrpcProvider({ children }: PropsWithChildren) {
    const { enableTrpcBatchStreamLink } = useFlags();
    const getAuthToken = useAuthStore(({ getAuthToken }) => getAuthToken);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const refExecuteRecaptcha = useRef(executeRecaptcha);

    refExecuteRecaptcha.current = executeRecaptcha;

    const trpcClient = useMemo(
        () =>
            trpc.createClient({
                links: [
                    enableTrpcBatchStreamLink
                        ? unstable_httpBatchStreamLink({
                              url: (import.meta.env.VITE_API_URL || location.origin) + '/trpc',
                              transformer: superjson,
                              async headers(opts) {
                                  const headers: Record<string, string> = {
                                      'x-page-url': window.location.href,
                                  };
                                  if (opts.opList.some((op) => op.context?.reCaptcha === true)) {
                                      if (!refExecuteRecaptcha.current) {
                                          throw new Error('executeRecaptcha is not defined');
                                      }
                                      headers['x-recaptcha-token'] =
                                          await refExecuteRecaptcha.current();
                                  }
                                  const token = getAuthToken();
                                  if (token) {
                                      headers['authorization'] = `Bearer ${token}`;
                                  }
                                  return headers;
                              },
                          })
                        : httpBatchLink({
                              url: (import.meta.env.VITE_API_URL || location.origin) + '/trpc',
                              transformer: superjson,
                              async headers(opts) {
                                  const headers: Record<string, string> = {
                                      'x-page-url': window.location.href,
                                  };
                                  if (opts.opList.some((op) => op.context?.reCaptcha === true)) {
                                      if (!refExecuteRecaptcha.current) {
                                          throw new Error('executeRecaptcha is not defined');
                                      }
                                      headers['x-recaptcha-token'] =
                                          await refExecuteRecaptcha.current();
                                  }
                                  const token = getAuthToken();
                                  if (token) {
                                      headers['authorization'] = `Bearer ${token}`;
                                  }
                                  return headers;
                              },
                          }),
                ],
            }),
        [enableTrpcBatchStreamLink, getAuthToken, refExecuteRecaptcha],
    );

    return (
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
                <TrpcClientSetup>{children}</TrpcClientSetup>
            </QueryClientProvider>
        </trpc.Provider>
    );
}

function TrpcClientSetup({ children }: PropsWithChildren) {
    const authStoreSetTrpcClient = useAuthStore(({ setTrpcClient }) => setTrpcClient);
    const orgSettingsStoreSetTrpcClient = useOrgSettingsStore(({ setTrpcClient }) => setTrpcClient);
    const trpcClient = useTrpcClient();

    useEffect(() => {
        if (trpcClient) {
            Sentry.addBreadcrumb({
                category: 'trpc',
                message: 'trpcClient set in TrpcClientSetup',
            });
            authStoreSetTrpcClient(trpcClient);
            orgSettingsStoreSetTrpcClient(trpcClient);
        }
    }, [trpcClient]);

    return trpcClient ? children : null;
}
