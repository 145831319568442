import { ClaimBoolExp, ClaimDsQuery, OrderBy } from '@/__generated__/graphql';
import { CollapsiblePanel } from '@/components/Common/CollapsiblePanel';
import { errorMessage } from '@/components/Common/errorMessage';
import { HandlingPartySelect_Depr } from '@/components/Common/HandlingPartySelect_Depr';
import { User } from '@/components/Common/User';
import { UserSelectorMultiple } from '@/components/Common/UserSelectorMultiple';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { ClaimDs } from '@/graphql/queries/claim';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useTableDataSource } from '@/hooks/Table/useTableDataSource';
import { useFormatter } from '@/hooks/useFormatter';
import { useSafePath } from '@/hooks/useSafePath';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { FilterOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space, Table, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';
import { OverrideProperties } from 'type-fest';
import { SYSTEM_ACCOUNT_ID } from 'shared/constants/user';
import { CLAIM_STATUSES } from 'shared/types/claim';
import { ContactCollection } from 'shared/types/contact';
import { getContactFullName, getFirstContactForType, getPrimaryContactForType } from 'shared/utils/contact';
import { navRoutes } from 'shared/navigation/navRoutes';
import { useNavigate } from '@/hooks/useNavigate';
import { NewTabLink } from '@/components/Common/NewTabLink';
import { commonActionColumn } from '@/utils/table';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/claims/search')({
    staticData: {
        title: 'View claims',
    },
    component: ClaimSearch,
});

type Claim = OverrideProperties<ClaimDsQuery['Claims'][number], { contacts?: ContactCollection }>;

type Filters = {
    generatedId: string;
    causeOfLoss: string;
    policyNo: string;
    phName: string;
    phCompany: string;
    insurerName: string;
    brokerName: string;
    dateOfLoss: dayjs.Dayjs | null;
    ownerIds: bigint[] | undefined;
    statuses: string[] | undefined;
    externalOrgIds: number[] | undefined;
    externalId: string;
};

function ClaimSearch() {
    const orgId = useOrgId();
    const [claimDs] = useLazyQuery(ClaimDs);
    const navigate = useNavigate();
    const { formatISODate, fDateShortDayJs } = useFormatter();
    const [form] = Form.useForm<Filters>();

    const [showFilter, setShowFilter] = useState(false);
    const safePath = useSafePath<Filters>();
    const ownerIds = Form.useWatch<Filters['ownerIds']>(safePath('ownerIds'), form);
    const externalOrgIds = Form.useWatch<Filters['externalOrgIds']>(safePath('externalOrgIds'), form);

    const { tableProps, error, setFilterAndRun } = useTableDataSource<Claim, ClaimBoolExp>({
        rowKey: 'id',
        columns: [
            {
                dataIndex: 'generatedId',
                title: 'Claim#',
                sorter: true,
                className: 'font-bold',
                render: (_value, row) => row.generatedId || row.id,
            },
            {
                title: 'Owner',
                dataIndex: 'Owner.name',
                sorter: true,
                render: (_value, row) => <User userId={row.Owner?.userId ? BigInt(row.Owner.userId) : undefined} />,
            },
            {
                dataIndex: 'status',
                title: 'Claim status',
                sorter: true,
                render: (value) => <Tag>{value}</Tag>,
            },
            {
                dataIndex: 'policyNo',
                title: 'Policy#',
                sorter: true,
                className: 'font-bold',
            },
            {
                dataIndex: 'decision',
                title: 'Decision',
                sorter: true,
                render: (value) => <Tag>{value}</Tag>,
            },
            {
                dataIndex: 'contacts',
                title: 'First name',
                sorter: true,
                render: (value) =>
                    getPrimaryContactForType(value, 'policy_holder')?.firstName ||
                    getFirstContactForType(value, 'policy_holder')?.firstName ||
                    '',
            },
            {
                dataIndex: 'contacts',
                title: 'Surname',
                sorter: true,
                render: (value) =>
                    getPrimaryContactForType(value, 'policy_holder')?.lastName ||
                    getFirstContactForType(value, 'policy_holder')?.lastName ||
                    '',
            },
            {
                dataIndex: 'causeOfLoss',
                title: 'Cause of loss',
                sorter: true,
            },
            {
                dataIndex: 'claimOccurredAt',
                title: 'Date of loss',
                sorter: true,
                render: (value) => formatISODate(value),
            },
            {
                dataIndex: 'contacts',
                title: 'Broker',
                sorter: true,
                render: (value) =>
                    getContactFullName(getPrimaryContactForType(value, 'broker')) ||
                    getContactFullName(getFirstContactForType(value, 'broker')),
            },
            {
                dataIndex: 'createdAt',
                title: 'Claim submitted',
                sorter: true,
                render: (value) => formatISODate(value),
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <NewTabLink
                        to="/console/$orgId/claims/details/$id/manage"
                        params={{
                            orgId,
                            id: value,
                        }}
                    />
                ),
            },
        ],
        defaultOrderBy: [{ createdAt: OrderBy.desc }],
        getResults: async (options) => {
            const { data } = await claimDs({
                variables: {
                    limit: options!.limit! || 10,
                    offset: options!.offset! || 0,
                    ...(options?.orderBy && { orderBy: options.orderBy }),
                    ...(options?.where && { where: options.where }),
                },
                fetchPolicy: 'network-only',
            });
            return {
                rows: data?.Claims,
                total: data?.ClaimAggregate.aggregate?.count || 0,
            };
        },
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
    });

    useEffect(() => {
        if (error) {
            errorMessage.show(error);
        }
    }, [error]);

    const onSearch = () => {
        const where: { _and: ClaimBoolExp[] } = { _and: [] };
        const state = form.getFieldsValue();

        if (state.generatedId) {
            where._and.push({ generatedId: { _ilike: `%${state.generatedId}%` } });
        }
        if (state.causeOfLoss) {
            where._and.push({ causeOfLoss: { _ilike: `%${state.causeOfLoss}%` } });
        }
        if (state.phName) {
            where._and.push({
                searchContent: {
                    _ilike: `%policy_holder_start!%${state.phName}%!policy_holder_end%`,
                },
            });
        }
        if (state.phCompany) {
            where._and.push({
                phCompany: {
                    _ilike: `%${state.phCompany}%`,
                },
            });
        }
        if (state.insurerName) {
            where._and.push({
                searchContent: {
                    _ilike: `%insurer_start!%${state.insurerName}%!insurer_end%`,
                },
            });
        }
        if (state.brokerName) {
            where._and.push({
                searchContent: {
                    _ilike: `%broker_start!%${state.brokerName}%!broker_end%`,
                },
            });
        }
        if (state.dateOfLoss) {
            where._and.push({
                _and: [
                    { claimOccurredAt: { _gte: state.dateOfLoss } },
                    {
                        claimOccurredAt: {
                            _lt: state.dateOfLoss.add(1, 'day'),
                        },
                    },
                ],
            });
        }
        if (state.policyNo) {
            where._and.push({ policyNo: { _ilike: `%${state.policyNo}%` } });
        }
        if (state.ownerIds?.length) {
            const ownerIdFilter: ClaimBoolExp = { _or: [] };
            invariant(ownerIdFilter._or, 'ownerIdFilter._or is not an array');
            for (const ownerId of state.ownerIds) {
                if (BigInt(ownerId) === SYSTEM_ACCOUNT_ID.UNALLOCATED) {
                    ownerIdFilter._or.push({ ownerId: { _isNull: true } });
                } else {
                    ownerIdFilter._or.push({ ownerId: { _eq: ownerId } });
                }
            }
            where._and.push(ownerIdFilter);
        }
        if (state.statuses?.length) {
            where._and.push({ status: { _in: state.statuses } });
        }
        if (state.externalOrgIds?.length) {
            where._and.push({
                ExternalOrgs: { externalOrgId: { _in: state.externalOrgIds } },
            });
        }
        if (state.externalId) {
            where._and.push({ externalId: { _ilike: `%${state.externalId}%` } });
        }

        setFilterAndRun(where._and.length ? where : undefined);
    };

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.claims),
                    toBreadCrumbWithoutLink(navRoutes.claims_search),
                ]}
            />

            <CollapsiblePanel open={showFilter}>
                <Card title="Filters" size="small" className="mb-4">
                    <Form layout="vertical" form={form}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Claim#" name={safePath('generatedId')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Policy Holder name" name={safePath('phName')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Cause of loss" name={safePath('causeOfLoss')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Policy Holder company" name={safePath('phCompany')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Date of loss" name={safePath('dateOfLoss')}>
                                    <DatePicker className="w-full" format={fDateShortDayJs} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Insurer contact" name={safePath('insurerName')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Policy Number" name={safePath('policyNo')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Broker contact" name={safePath('brokerName')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Status" name={safePath('statuses')}>
                                    <Select
                                        options={CLAIM_STATUSES.map((value) => ({
                                            value,
                                        }))}
                                        mode="multiple"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Claim owner" name={safePath('ownerIds')}>
                                    <UserSelectorMultiple value={ownerIds || []} showUnallocated />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Handling parties" name={safePath('externalOrgIds')}>
                                    <HandlingPartySelect_Depr
                                        value={externalOrgIds}
                                        onChange={(_, options) => {
                                            if (Array.isArray(options)) {
                                                form.setFieldsValue({
                                                    externalOrgIds: options.map(({ value }) => Number(value)),
                                                });
                                            }
                                        }}
                                        mode="multiple"
                                        forAdminShowAllOrgs={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="External#" name={safePath('externalId')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Space className="flex justify-end">
                                    <Button
                                        onClick={() => {
                                            form.resetFields();
                                            onSearch();
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button type="primary" htmlType="submit" onClick={onSearch}>
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </CollapsiblePanel>

            <div className="mb-4 flex justify-end">
                <Tooltip title="Filters">
                    <Button
                        type="text"
                        size="large"
                        title="Filter"
                        onClick={() => {
                            setShowFilter((prev) => !prev);
                        }}
                    >
                        <FilterOutlined />
                    </Button>
                </Tooltip>
            </div>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    tableLayout="fixed"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: (e) =>
                            navigate(
                                {
                                    to: '/console/$orgId/claims/details/$id/manage',
                                    params: {
                                        orgId,
                                        id,
                                    },
                                },
                                e,
                            ),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
