export const SYSTEM_ORG_ID = {
    SYSTEM_CONSOLE: -1n,
    NOT_APPLICABLE: -999n,
    /*
        Unallocated org id is store in DB as null.
    */
    UNALLOCATED: -9999n,

    FIRST_VALID_ORG_ID: 1n,
} as const;
