import invariant from 'tiny-invariant';
import { claimFormStore } from '../store';
import { createFileRoute } from '@tanstack/react-router';
import { DocumentLibrary } from '@/components/Common/Documents/DocumentLibrary';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/library')({
    component: Library,
});

function Library() {
    const claim = claimFormStore.use.formData?.();
    invariant(claim, 'Claim is required.');

    return (
        <DocumentLibrary
            entityType="claim"
            entityId={claim.id}
            showDownloadAllButton
            fileNamePrefix={`${claim.generatedId || claim.id}`}
        />
    );
}
