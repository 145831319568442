import { gql } from '@/__generated__';

export const TrainingPeriodDs = gql(/* GraphQL */ `
    query TrainingPeriodDs(
        $where: TrainingPeriodBoolExp = {}
        $orderBy: [TrainingPeriodOrderBy!] = {}
        $limit: Int
        $offset: Int
    ) {
        TrainingPeriod(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
            pointsTarget
            orgId
            name
            id
            dateTo
            dateFrom
        }
        TrainingPeriodAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`);
