import { CollapsiblePanel } from '@/components/Common/CollapsiblePanel';
import { DatePicker } from '@/components/Common/DatePicker';
import { errorMessage } from '@/components/Common/errorMessage';
import { HandlingPartySelect } from '@/components/Common/HandlingPartySelect';
import { modal } from '@/components/Common/Modal';
import { SelectWithOther } from '@/components/Common/SelectWithOther';
import { UserSelector } from '@/components/Common/UserSelector';
import { ComplaintBrandSelector } from '@/components/Complaint/ComplaintBrandSelector';
import { ComplaintIssueSelect } from '@/components/Complaint/ComplaintIssueSelect';
import { OutcomeSelector } from '@/components/Complaint/OutcomeSelector';
import { ProductSelector } from '@/components/Complaint/ProductSelector';
import {
    EmailDetailsAction,
    EmailDetailsDrawer,
} from '@/components/EmailClassification/EmailDetailsDrawer';
import { useConfigServiceLoader } from '@/hooks/Configuration/useConfigServiceLoader';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useFormatter } from '@/hooks/useFormatter';
import { useNavigate } from '@/hooks/useNavigate';
import { RouterInputs, trpc } from '@/trpc';
import { requiredFieldRule } from '@/utils/form';
import { debounce } from '@/utils/general';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { skipToken } from '@tanstack/react-query';
import {
    Button,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Radio,
    Select,
    Skeleton,
    Space,
    Switch,
} from 'antd';
import { FormInstance } from 'antd/lib';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import {
    AGES,
    CHANNELS,
    COMPLAINANT_SOLUTIONS,
    COMPLAINANT_TYPES,
    GENDERS,
    RELATED_TO_AFS_LICENSEE,
    RELATIONSHIPS,
} from 'shared/constants/complaint';
import { COMPLAINANT_TYPE, COMPLAINT_STATUS } from 'shared/types/complaint';
import invariant from 'tiny-invariant';
import { PreferredChannelValueFormItem } from './PreferredChannelValueFormItem';

type Complaint = RouterInputs['complaint']['createComplaint'];

type Props = {
    claimId?: bigint;
    onCancel: () => void;
    onAfterSaveCancel: (complaintId: string) => void;
    formOverride?: FormInstance<Complaint>;
    emailClassificationId?: bigint;
};

const FormItem = Form.Item<Complaint>;

export function ComplaintForm({
    claimId,
    onCancel,
    onAfterSaveCancel,
    formOverride,
    emailClassificationId,
}: Props) {
    const [emailDetailsAction, setEmailDetailsAction] = useState<EmailDetailsAction>(['hide']);
    const [form] = Form.useForm<Complaint>(formOverride);
    const complainantType = Form.useWatch('complainantType', form);

    const { data, isPending: getClaimQueryIsPending } = trpc.claim.getClaim.useQuery(
        claimId
            ? {
                  id: claimId,
              }
            : skipToken,
        {
            throwOnError({ message }) {
                errorMessage.show(message);
                return false;
            },
        },
    );
    const { mutateAsync, isPending: createComplaintMutationIsPending } =
        trpc.complaint.createComplaint.useMutation();

    const brandConfigLoader = useConfigServiceLoader((configService) =>
        configService.fetchComplaintBrandConfiguration(),
    );

    const { data: emailClassification } = trpc.emailClassification.getEmailClassification.useQuery(
        emailClassificationId ? { id: BigInt(emailClassificationId) } : skipToken,
        {
            select(data) {
                if (data) {
                    const extractedData = data.extractedData || ({} as any);
                    invariant(
                        extractedData.type === 'complaint',
                        'Email classification is not a complaint',
                    );
                    form.setFieldsValue({
                        complainantFirstName: extractedData.firstName,
                        complainantLastName: extractedData.lastName,
                        policyNo: extractedData.policyNumber,
                        externalClaimId: extractedData.claimNumber,
                        complainantPostcode: extractedData.postalCode,
                        complainantGender: extractedData.gender,
                        complainantAge: extractedData.age,
                        incidentDescription: data.summary || extractedData.description || '',
                    });
                }
                return data;
            },
            throwOnError(error) {
                errorMessage.show(error);
                return false;
            },
        },
    );

    const orgId = useOrgId();
    const [isResolved, setIsResolved] = useState(false);
    const navigate = useNavigate();
    const { fDateShortDayJs } = useFormatter();

    const referredByAfca = Form.useWatch<Complaint, Complaint['referredByAfca']>(
        (values) => values.referredByAfca,
        form,
    );
    const handlingPartyIds = Form.useWatch<Complaint, Complaint['handlingPartyIds']>(
        (values) => values.handlingPartyIds,
        form,
    );

    const loading = useMemo(
        () => (claimId && getClaimQueryIsPending) || createComplaintMutationIsPending,
        [claimId, getClaimQueryIsPending, createComplaintMutationIsPending],
    );

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                claimId: data.id,
                policyNo: data.policyNo || undefined,
            });
        }
    }, [data]);

    const onFinish = debounce(async (values: Complaint) => {
        try {
            if (loading) {
                return;
            }

            message.loading('Saving...');

            const { id } = await mutateAsync({
                ...values,
                ...(isResolved && { status: COMPLAINT_STATUS.CLOSED }),
                claimId,
            });

            message.destroy();
            message.success('Saved.');

            if (id) {
                modal.confirm({
                    icon: <ExclamationCircleOutlined />,
                    okText: 'Yes',
                    cancelText: 'No',
                    content: 'Do you think the customer might experience a vulnerability?',
                    onOk() {
                        navigate({
                            to: '/console/$orgId/compliance/complaints/details/$id/manage/flag/add',
                            params: {
                                orgId,
                                id,
                            },
                        });
                    },
                    onCancel: () => {
                        onAfterSaveCancel(id.toString());
                    },
                });
            }
        } catch (e) {
            message.destroy();
            errorMessage.show(e);
        }
    });

    return (
        <>
            <Form
                form={form}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                onFinish={onFinish}
                scrollToFirstError
                labelWrap
            >
                {loading ? (
                    <Skeleton active />
                ) : (
                    <>
                        {emailClassification && (
                            <div className="mb-4 flex justify-end">
                                <Button
                                    onClick={() =>
                                        setEmailDetailsAction(['view', emailClassification.id])
                                    }
                                >
                                    View Complaint Details
                                </Button>
                            </div>
                        )}
                        <Divider orientation="left">Complainant</Divider>
                        <FormItem
                            label="Name"
                            name="complainantFirstName"
                            rules={requiredFieldRule}
                        >
                            <Input autoFocus />
                        </FormItem>
                        <FormItem
                            label="Surname"
                            name="complainantLastName"
                            rules={requiredFieldRule}
                        >
                            <Input />
                        </FormItem>
                        <FormItem
                            label="Relationship"
                            name="relationship"
                            rules={requiredFieldRule}
                            validateTrigger="onValidate"
                        >
                            <SelectWithOther options={RELATIONSHIPS.map((value) => ({ value }))} />
                        </FormItem>
                        <FormItem label="Type" name="complainantType" rules={requiredFieldRule}>
                            <Select
                                options={COMPLAINANT_TYPES.map(({ label }) => ({
                                    label,
                                    value: label,
                                }))}
                            />
                        </FormItem>
                        <FormItem label="Referred by AFCA" name="referredByAfca">
                            <Switch />
                        </FormItem>
                        <CollapsiblePanel open={!!referredByAfca}>
                            <FormItem label="Date referred (AFCA)" name="referredByAfcaDate">
                                <DatePicker className="w-full" format={fDateShortDayJs} />
                            </FormItem>
                            <FormItem label="Reference# (AFCA)" name="afcaReferenceNo">
                                <Input />
                            </FormItem>
                        </CollapsiblePanel>
                        <FormItem
                            label="Gender"
                            name="complainantGender"
                            rules={
                                complainantType === COMPLAINANT_TYPE.INDIVIDUAL
                                    ? requiredFieldRule
                                    : []
                            }
                            required={complainantType === COMPLAINANT_TYPE.INDIVIDUAL}
                        >
                            <Select
                                options={GENDERS.map(({ label }) => ({ label, value: label }))}
                            />
                        </FormItem>
                        <FormItem label="Age" name="complainantAge">
                            <Select options={AGES.map(({ label }) => ({ label, value: label }))} />
                        </FormItem>
                        <FormItem label="Postcode" name="complainantPostcode">
                            <Input />
                        </FormItem>
                        <FormItem label="Channel" name="channel" rules={requiredFieldRule}>
                            <Select
                                options={CHANNELS.map(({ label }) => ({ label, value: label }))}
                            />
                        </FormItem>
                        <FormItem
                            label="Preferred channel"
                            name="preferredChannel"
                            rules={requiredFieldRule}
                        >
                            <Select
                                options={CHANNELS.map(({ label }) => ({ label, value: label }))}
                            />
                        </FormItem>
                        <PreferredChannelValueFormItem form={form} name="preferredChannelValue" />
                        <FormItem
                            label="Is the complaint about the authorized representative of an AFS licensee?"
                            name="afsLicenseeRelated"
                            rules={requiredFieldRule}
                        >
                            <Select
                                options={RELATED_TO_AFS_LICENSEE.map(({ label }) => ({
                                    label,
                                    value: label,
                                }))}
                            />
                        </FormItem>
                        <Divider orientation="left" className="mt-8">
                            Nature of complaint
                        </Divider>
                        <FormItem
                            label="Issues"
                            name="issues"
                            rules={requiredFieldRule}
                            validateTrigger={['onChange']}
                            help={<div className="pb-2 pt-1">Select up to 3 issues</div>}
                        >
                            <ComplaintIssueSelect />
                        </FormItem>
                        <FormItem label="Incident description" name="incidentDescription">
                            <Input.TextArea rows={4} />
                        </FormItem>
                        <FormItem
                            label="Solution the complainant is seeking"
                            name="complainantSolution"
                        >
                            <SelectWithOther
                                options={COMPLAINANT_SOLUTIONS.map((value) => ({
                                    value,
                                }))}
                            />
                        </FormItem>
                        <Divider orientation="left" className="mt-8">
                            Handling info
                        </Divider>
                        <FormItem label="Date received" name="dtReceived" rules={requiredFieldRule}>
                            <DatePicker
                                className="w-full"
                                format={fDateShortDayJs}
                                maxDate={dayjs()}
                            />
                        </FormItem>
                        <FormItem
                            label="Handling parties"
                            name="handlingPartyIds"
                            rules={requiredFieldRule}
                            getValueProps={(value) => value}
                        >
                            <HandlingPartySelect
                                showAllOrgs
                                onChange={() => form.setFieldsValue({ ownerId: undefined })}
                                placeholder=""
                            />
                        </FormItem>
                        <FormItem
                            label="Owner"
                            name="ownerId"
                            rules={requiredFieldRule}
                            validateTrigger={['onSelect']}
                        >
                            <UserSelector handlingPartyIds={handlingPartyIds} valueType="bigint" />
                        </FormItem>
                        <FormItem label="Products" name="products" rules={requiredFieldRule}>
                            <ProductSelector />
                        </FormItem>
                        <FormItem label="Policy#" name="policyNo">
                            <Input />
                        </FormItem>
                        <FormItem label="Claim#" name="externalClaimId">
                            <Input />
                        </FormItem>
                        {!brandConfigLoader.loading &&
                            brandConfigLoader?.data?.isDisplayedOnInternalForm && (
                                <FormItem label="Brand" name="brand">
                                    <ComplaintBrandSelector />
                                </FormItem>
                            )}

                        <FormItem label="Has this complaint been resolved">
                            <Radio.Group
                                onChange={(e) => setIsResolved(e.target.value)}
                                value={isResolved}
                            >
                                <Radio value={false}>No</Radio>
                                <Radio value={true}>Yes</Radio>
                            </Radio.Group>
                        </FormItem>
                        {isResolved && (
                            <>
                                <FormItem
                                    label="Outcomes"
                                    name="outcomes"
                                    rules={requiredFieldRule}
                                >
                                    <OutcomeSelector />
                                </FormItem>
                                <FormItem label="Monetary remedy amount" name="monetaryRemedy">
                                    <InputNumber className="w-full" prefix="$" />
                                </FormItem>
                                <FormItem label="Outcome details" name="outcomeDetails">
                                    <Input.TextArea rows={4} />
                                </FormItem>
                            </>
                        )}
                    </>
                )}
                <Space className="flex justify-end">
                    <Button onClick={onCancel} loading={loading}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                    </Button>
                </Space>
            </Form>
            <EmailDetailsDrawer
                action={emailDetailsAction}
                onClose={() => setEmailDetailsAction(['hide'])}
                title="Complaint Details - Email"
            />
        </>
    );
}
