import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Table, Tag, Tooltip } from 'antd';
import { BarChartOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { navRoutes } from 'shared/navigation/navRoutes';
import { useTableDataSource } from '@/hooks/Table/useTableDataSource';
import { errorMessage } from '@/components/Common/errorMessage';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import clsx from 'clsx';
import { ComplaintDsQuery, ComplaintBoolExp, OrderBy } from '@/__generated__/graphql';
import { FlagAutoComplete } from '@/components/Flag/FlagAutoComplete';
import { FormField } from '@/components/Common/FormField';
import { CollapsiblePanel } from '@/components/Common/CollapsiblePanel';
import { HandlingPartySelect_Depr } from '@/components/Common/HandlingPartySelect_Depr';
import { User } from '@/components/Common/User';
import { getUserFullName } from '@/utils/general';
import { useFormatter } from '@/hooks/useFormatter';
import { UserSelectorMultiple } from '@/components/Common/UserSelectorMultiple';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { ComplaintSources } from 'shared/types/complaint';
import { STATUSES } from 'shared/constants/complaint';
import { ComplaintIssueSelect } from '@/components/Complaint/ComplaintIssueSelect';
import { ProductSelector } from '@/components/Complaint/ProductSelector';
import { OutcomeSelector } from '@/components/Complaint/OutcomeSelector';
import { ComplaintDs } from '@/graphql/queries/complaint';
import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useNavigate } from '@/hooks/useNavigate';
import { useState } from 'react';
import { useSafePath } from '@/hooks/useSafePath';
import { NewTabLink } from '@/components/Common/NewTabLink';
import { Link } from '@/components/Common/Link';
import { commonActionColumn } from '@/utils/table';
import { createFileRoute } from '@tanstack/react-router';
import { useFilterNavigationItem } from '@/hooks/useFilterNavigationItem';

export const Route = createFileRoute('/console/$orgId/compliance/complaints/search')({
    staticData: {
        title: 'Complaints',
    },
    component: ComplaintsSearch,
});

type Complaint = ComplaintDsQuery['Complaint'][number];

type Filters = {
    id: string | undefined;
    complainantName: string | undefined;
    issues: string[] | undefined;
    products: string[] | undefined;
    status: string[] | undefined;
    outcomes: string[] | undefined;
    dtReceived: dayjs.Dayjs | null;
    flag: string | undefined;
    externalOrgIds: number[] | undefined;
    ownerIds: bigint[] | undefined;
    source: string | undefined;
    externalClaimId: string | undefined;
};

function ComplaintsSearch() {
    const orgId = useOrgId();
    const { formatISODate, fDateShortDayJs } = useFormatter();
    const filterNavigationItem = useFilterNavigationItem();
    const navigate = useNavigate();
    const [complaintDsQuery] = useLazyQuery(ComplaintDs);

    const [showFilter, setShowFilter] = useState(false);

    const [form] = Form.useForm<Filters>();
    const safePath = useSafePath<Filters>();
    const ownerIds = Form.useWatch<Filters['ownerIds']>(safePath('ownerIds'), form);
    const externalOrgIds = Form.useWatch<Filters['externalOrgIds']>(safePath('externalOrgIds'), form);

    const { tableProps, setFilterAndRun } = useTableDataSource<Complaint, ComplaintBoolExp>({
        rowKey: 'id',
        columns: [
            {
                key: 'indicator',
                render: (_, item) =>
                    item.Indicators.length ? (
                        <Tooltip title={item.Indicators[0].Definition?.title}>
                            <div
                                style={{
                                    width: 15,
                                    height: 15,
                                    backgroundColor: item.indicatorColor!,
                                    borderRadius: '100%',
                                }}
                            />
                        </Tooltip>
                    ) : (
                        <div
                            style={{
                                width: 15,
                                height: 15,
                                backgroundColor: 'green',
                                borderRadius: '100%',
                            }}
                        />
                    ),
            },
            {
                title: 'Complaint#',
                dataIndex: 'id',
                sorter: true,
            },
            {
                title: 'Owner',
                dataIndex: 'Owner.name',
                sorter: true,
                render: (_, item) => (
                    <User
                        userId={item.Owner?.userId}
                        name={getUserFullName(item.Owner)}
                        photoUrl={item.Owner?.photoUrl}
                    />
                ),
            },
            {
                title: 'Complainant',
                dataIndex: 'complainantLastName',
                sorter: true,
                render: (_, item) => `${item.complainantFirstName} ${item.complainantLastName}`,
            },
            {
                title: 'Policy#',
                dataIndex: 'policyNo',
                sorter: true,
            },
            {
                title: 'Issues',
                dataIndex: 'issues',
                sorter: true,
                render: (value?: string[]) =>
                    value?.map?.((item, i) => (
                        <Tag color="volcano" key={i}>
                            {item}
                        </Tag>
                    )),
            },
            {
                title: 'Products',
                dataIndex: 'products',
                sorter: true,
                render: (value?: string[]) =>
                    value?.map?.((item) => (
                        <Tag color="blue" key={item}>
                            {item}
                        </Tag>
                    )),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: true,
                render: (value) => value && <Tag>{value}</Tag>,
            },
            {
                title: 'Outcomes',
                dataIndex: 'outcomes',
                sorter: true,
                render: (value?: string[]) =>
                    value?.map((item) => (
                        <Tag color={clsx(item === 'Rejected' && 'red', item === 'Accepted' && 'green')} key={item}>
                            {item}
                        </Tag>
                    )),
            },
            {
                title: 'Date registered',
                dataIndex: 'createdAt',
                sorter: true,
                render: (value) => formatISODate(value),
            },
            {
                title: 'Received on',
                dataIndex: 'dtReceived',
                sorter: true,
                render: (value) => formatISODate(value),
            },
            {
                title: 'Claim#',
                render: (_, item) => item.claimId || item.externalClaimId,
            },
            {
                title: 'Source',
                dataIndex: 'source',
                sorter: true,
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <NewTabLink
                        to="/console/$orgId/compliance/complaints/details/$id/manage"
                        params={{
                            orgId,
                            id: value,
                        }}
                    />
                ),
            },
        ],
        getResults: async (options) => {
            try {
                const { data } = await complaintDsQuery({
                    variables: {
                        limit: options!.limit! || 10,
                        offset: options!.offset! || 0,
                        where: options?.where,
                        ...(options?.orderBy && { orderBy: options.orderBy }),
                    },
                    fetchPolicy: options?.fetchPolicy || 'network-only',
                });
                return {
                    rows: data?.Complaint,
                    total: data?.ComplaintAggregate.aggregate?.count || 0,
                };
            } catch (e) {
                return { error: e };
            }
        },
        onError: (error) => errorMessage.show(error),
        defaultOrderBy: [{ id: OrderBy.desc }],
        paginationConfig: {
            defaultPageSize: 10,
            hideOnSinglePage: true,
            showSizeChanger: true,
        },
    });

    const onSearch = () => {
        const where: { _and: ComplaintBoolExp[] } = { _and: [] };
        const values = form.getFieldsValue();

        if (values.id) {
            where._and?.push({ id: { _eq: values.id } });
        }

        if (values.complainantName) {
            const complainantNames = values.complainantName.split(' ');

            where._and?.push({
                _or: [
                    {
                        _or: complainantNames.map((item) => ({
                            complainantFirstName: { _ilike: `%${item}%` },
                        })),
                    },
                    {
                        _or: complainantNames.map((item) => ({
                            complainantLastName: { _ilike: `%${item}%` },
                        })),
                    },
                ],
            });
        }

        if (values.issues?.length) {
            where._and?.push({
                _or: values.issues.map((item) => ({
                    issues: {
                        _contains: item,
                    },
                })),
            });
        }

        if (values.products?.length) {
            where._and?.push({
                _or: values.products.map((item) => ({
                    products: {
                        _contains: item,
                    },
                })),
            });
        }

        if (values.status) {
            where._and?.push({ status: { _in: values.status } });
        }

        if (values.outcomes?.length) {
            where._and?.push({
                _or: values.outcomes.map((item) => ({
                    outcomes: {
                        _contains: item,
                    },
                })),
            });
        }

        if (values.dtReceived) {
            where._and.push({
                _and: [
                    {
                        dtReceived: {
                            _gte: values.dtReceived.startOf('day').toISOString(),
                        },
                    },
                    {
                        dtReceived: {
                            _lte: values.dtReceived.endOf('day').toISOString(),
                        },
                    },
                ],
            });
        }

        if (values.flag) {
            where._and?.push({
                Flags: { labelActive: { _ilike: `%${values.flag}%` } },
            });
        }

        if (values.externalOrgIds?.length) {
            where._and?.push({
                ExternalOrgs: { externalOrgId: { _in: values.externalOrgIds } },
            });
        }

        if (values.ownerIds?.length) {
            where._and?.push({ Owner: { userId: { _in: values.ownerIds } } });
        }

        if (values.source) {
            where._and?.push({ source: { _eq: values.source } });
        }

        if (values.externalClaimId) {
            where._and?.push({
                externalClaimId: { _ilike: `%${values.externalClaimId}%` },
            });
        }

        setFilterAndRun(where._and!.length > 0 ? where : undefined);
    };

    return (
        <>
            <BreadCrumbs breadCrumbs={[toBreadCrumbWithoutLink(navRoutes.compliance_complaints_search)]} />

            <CollapsiblePanel open={showFilter}>
                <Card title="Filters" size="small" className="mb-4">
                    <Form form={form} layout="vertical">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Complaint#" name={safePath('id')}>
                                    <InputNumber className="w-full" min="0" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Complainant name" name={safePath('complainantName')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Issue" name={safePath('issues')}>
                                    <ComplaintIssueSelect />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Product" name={safePath('products')}>
                                    <ProductSelector />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Status" name={safePath('status')}>
                                    <Select
                                        options={STATUSES.map(({ label }) => ({
                                            value: label,
                                        }))}
                                        mode="multiple"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Outcome" name={safePath('outcomes')}>
                                    <OutcomeSelector />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Date received" name={safePath('dtReceived')}>
                                    <DatePicker
                                        placeholder=""
                                        format={fDateShortDayJs}
                                        className="w-full"
                                        maxDate={dayjs()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Flag" name={safePath('flag')}>
                                    <FlagAutoComplete filterOption allowClear />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Handling parties" name={safePath('externalOrgIds')}>
                                    <HandlingPartySelect_Depr mode="multiple" forAdminShowAllOrgs />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Owner" name={safePath('ownerIds')}>
                                    <UserSelectorMultiple value={ownerIds || []} handlingPartyIds={externalOrgIds} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Source" name={safePath('source')}>
                                    <Select
                                        options={[
                                            { label: '-', value: '' },
                                            ...Object.values(ComplaintSources).map((item) => ({
                                                value: item,
                                            })),
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Claim#" name={safePath('externalClaimId')}>
                                    <Input className="w-full" min="0" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Space className="flex justify-end">
                                    <Button
                                        onClick={() => {
                                            form.resetFields();
                                            onSearch();
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button type="primary" onClick={onSearch}>
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </CollapsiblePanel>

            <Space className="mb-4 flex justify-end">
                <Tooltip title="Filters">
                    <Button type="text" size="large" title="Filter" onClick={() => setShowFilter((prev) => !prev)}>
                        <FilterOutlined />
                    </Button>
                </Tooltip>
                {filterNavigationItem({ to: '/console/$orgId/dashboard/$section' }) && (
                    <Tooltip title="Dashboard">
                        <Link
                            to="/console/$orgId/dashboard/$section"
                            params={{ orgId, section: 'complaints' }}
                            type="text"
                            size="large"
                            title="Filter"
                        >
                            <BarChartOutlined />
                        </Link>
                    </Tooltip>
                )}
                <Link to="/console/$orgId/compliance/complaints/add" params={{ orgId }} icon={<PlusOutlined />}>
                    New complaint
                </Link>
            </Space>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    expandable={{
                        expandedRowRender: (item) => (
                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <FormField
                                        label="Flags"
                                        disableFormatting
                                        value={
                                            <ul>
                                                {item.Flags.map(({ id, labelActive }) => (
                                                    <li key={id}>{labelActive}</li>
                                                ))}
                                            </ul>
                                        }
                                    />
                                </Col>
                                <Col span={8}>
                                    <FormField
                                        label="Handling parties"
                                        disableFormatting
                                        value={
                                            <ul>
                                                {item.ExternalOrgs.map((el) => (
                                                    <li key={el.externalOrgId}>{el.ExternalOrg?.name}</li>
                                                ))}
                                            </ul>
                                        }
                                    />
                                </Col>
                            </Row>
                        ),
                        rowExpandable: (item) => !!item.Flags.length || !!item.ExternalOrgs.length,
                    }}
                    {...tableProps}
                    onRow={({ id }) => ({
                        onClick: (e) => {
                            navigate(
                                {
                                    to: '/console/$orgId/compliance/complaints/details/$id/manage',
                                    params: {
                                        orgId,
                                        id,
                                    },
                                },
                                e,
                            );
                        },
                        className: 'cursor-pointer',
                    })}
                />
            </Card>
        </>
    );
}
