import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { useEffect, useState } from 'react';
import { Button, Card, Table } from 'antd';
import { PageHeader } from '@/components/Common/PageHeader';
import { OrderBy, PdsDsQuery, PdsDsQueryVariables } from '@/__generated__/graphql';
import { useAntdTableOld } from '@/hooks/Table/useAntdTableOld';
import { PlusOutlined } from '@ant-design/icons';
import { PdsByProductTypeEdit } from './PdsByProductTypeEdit';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { navRoutes } from 'shared/navigation/navRoutes';
import { generatePath } from 'shared/navigation/utils';
import { useQuery } from '@apollo/client';
import { ProductTypeByPk } from '@/graphql/queries/productType';
import { gql } from '@/__generated__/gql';
import { commonActionColumn } from '@/utils/table';
import { Link } from '@/components/Common/Link';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/product-types/$productTypeId/')(
    {
        staticData: {
            title: 'PDS configuration',
        },
        component: ConfigurationPdsByProductType,
    },
);

type Pds = PdsDsQuery['Pds'][number];

function ConfigurationPdsByProductType() {
    const orgId = useOrgId();
    const { productTypeId } = Route.useParams();

    const [idToEdit, setIdToEdit] = useState<number | undefined>(undefined);
    const [variables, setVariables] = useState<PdsDsQueryVariables>({
        where: { productTypeId: { _eq: Number(productTypeId) } },
        orderBy: [{ id: OrderBy.desc }],
        limit: 10,
        offset: 0,
    });

    const { data, loading, refetch } = useQuery(PdsDs, {
        fetchPolicy: 'network-only',
        variables: variables,
    });

    const { data: productType, loading: productTypeLoading } = useQuery(ProductTypeByPk, {
        variables: {
            id: productTypeId,
            orgId: orgId,
        },
    });

    const { tableProps } = useAntdTableOld<Pds>({
        rowKey: 'id',
        data: {
            rows: data?.Pds,
            loading: loading,
            total: data?.PdsAggregate?.aggregate?.count,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
            },
            {
                title: 'Insurer',
                dataIndex: 'Insurer.name',
            },
            {
                title: 'Product type',
                dataIndex: 'ProductType.name',
            },
            {
                ...commonActionColumn,
                render: (_, { id, productTypeId }) => (
                    <Link
                        to="/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId"
                        params={{ orgId, productTypeId, pdsId: id }}
                        type="link"
                        onClick={(e) => e.stopPropagation()}
                    >
                        Manage PDS versions
                    </Link>
                ),
            },
        ],
    });

    useEffect(() => {
        setVariables({
            ...variables,
            where: { productTypeId: { _eq: productTypeId } },
        });
    }, [productTypeId]);

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    {
                        title: (
                            <>
                                Product Types:
                                <strong> {productType?.ProductTypeByPk?.name || ''}</strong>
                            </>
                        ),
                        path: generatePath(navRoutes.configuration_productTypes, { orgId }),
                    },
                    {
                        title: 'PDS configuration',
                        path: generatePath(navRoutes.configuration_productTypes, { orgId }),
                    },
                ]}
            />

            <PageHeader
                title={`PDS configuration for ${productTypeLoading ? '...' : productType?.ProductTypeByPk?.name}`}
            />

            <PdsByProductTypeEdit
                idToEdit={idToEdit}
                productTypeId={+productTypeId}
                open={!!idToEdit}
                onClose={() => {
                    setIdToEdit(undefined);
                    void refetch();
                }}
            />

            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setIdToEdit(-1);
                    }}
                >
                    Add new
                </Button>
            </div>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: () => setIdToEdit(id),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}

const PdsDs = gql(/* GraphQL */ `
    query PdsDs(
        $limit: Int = 10
        $offset: Int = 0
        $orderBy: [PdsOrderBy!] = {}
        $where: PdsBoolExp = {}
    ) {
        Pds(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
            id
            insurerId
            name
            productTypeId
            ProductType {
                id
                name
            }
            Insurer {
                id
                name
            }
        }
        PdsAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`);
