import { AdditionalInfo } from '@/components/Claim/Lodgement/AdditionalInfo';
import { Conditions } from '@/components/Claim/Lodgement/Conditions';
import { Confirmation } from '@/components/Claim/Lodgement/Public/Confirmation';
import { PaymentDetails } from '@/components/Claim/Lodgement/Public/PaymentDetails';
import { PolicyHolder } from '@/components/Claim/Lodgement/Public/PolicyHolder';
import { SaveClaimDraftModal } from '@/components/Claim/Lodgement/Public/SaveClaimDraftModal';
import { ProductDataEntryForm } from '@/components/Claim/ProductType/ProductDataEntryForm';
import { Questionnaire } from '@/components/Claim/Questionnaire';
import { errorMessage } from '@/components/Common/errorMessage';
import { useFavIcon } from '@/hooks/UI/useFavIcon';
import { useCustomDocumentTitle } from '@/hooks/useCustomDocumentTitle';
import { useSearchParams } from '@/hooks/useSearchParams';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { useQuestionnaireService } from '@/services/QuestionnaireService';
import { debounce } from '@/utils/general';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { App, Button, Col, ConfigProvider, Divider, Row, Spin } from 'antd';
import Joi from 'joi';
import { DateTime } from 'luxon';
import { toJS } from 'mobx';
import { useEffect, useMemo, useState } from 'react';
import { QUESTIONNAIRE_MAPPED_FIELDS } from 'shared/constants/claim';
import { filesToFileCollections } from 'shared/utils/file';
import { navRoutes } from 'shared/navigation/navRoutes';
import { usePublicClaimFormState } from '@/hooks/Claim/usePublicClaimFormState';
import { createFileRoute } from '@tanstack/react-router';
import { observer } from 'mobx-react-lite';

const ClaimLodgement = observer(() => {
    const { orgId, questionnaireId, productTypeId, pdsVersionId } = Route.useParams();
    const trpcClient = useTrpcClient();
    const { draftId, accessKey, data } = useSearchParams(
        navRoutes.public_orgSection_claim_lodgement,
    );
    const [isDraftSaving, setIsDraftSaving] = useState(false);
    const client = useApolloClient();
    const { state, loading } = usePublicClaimFormState({
        orgId: BigInt(orgId),
        questionnaireId: BigInt(questionnaireId),
        productTypeId: BigInt(productTypeId),
        draftId,
        accessKey,
    });
    const { message } = App.useApp();
    const service = useQuestionnaireService(client);
    const [saveClaimModalVisible, setSaveClaimModalVisible] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);

    const additionalData = useMemo(
        () => (data ? JSON.parse(window.atob(data)) : {}) as { insurerName?: string },
        [data],
    );

    useCustomDocumentTitle(
        additionalData.insurerName && `New claim - ${additionalData.insurerName}`,
    );
    useFavIcon(state.ui.questionnaireFaviconUrl || state.ui.questionnaireLogoUrl);

    const save = debounce(async () => {
        try {
            message.loading(`Saving...`);

            const causeOfLoss = (service.getFieldValue(QUESTIONNAIRE_MAPPED_FIELDS.CAUSE_OF_LOSS) ||
                '') as string;
            const isoDateOfLoss = service.getFieldValue(
                QUESTIONNAIRE_MAPPED_FIELDS.DATE_OF_LOSS,
            ) as string;

            state.claim.jsonData = {
                ...state.claim.jsonData,
                questionnaireDeclarationTitle: state.ui.questionnaireDeclarationTitle,
                questionnaireDeclarationBody: state.ui.questionnaireDeclarationBody,
            };
            const res = await trpcClient.claim.publicClaimSubmission.mutate(
                {
                    causeOfLoss,
                    orgId: BigInt(orgId),
                    questionnaireId: BigInt(questionnaireId),
                    productTypeId: BigInt(productTypeId),
                    pdsVersionId: BigInt(pdsVersionId),
                    formData: service.root ? service.root.toJS() : undefined,
                    files: filesToFileCollections(service.files),
                    jsonData: {
                        ...state.claim.jsonData,
                        questionnaireDeclarationTitle: state.ui.questionnaireDeclarationTitle,
                        questionnaireDeclarationBody: state.ui.questionnaireDeclarationBody,
                    },
                    contacts: state.claim.contacts,
                    policyNo: state.claim.policyNo,
                    phITC: state.claim.phITC,
                    phAccountBsb: state.claim.phAccountBsb,
                    phAccountNo: state.claim.phAccountNo,
                    additionalInfo: state.claim.additionalInfo,
                    phAccountName: state.claim.phAccountName,
                    phIsRegisteredForGst: state.claim.phIsRegisteredForGst,
                    productTypeData: state.claim.productTypeData,
                    claimOccurredAt: isoDateOfLoss
                        ? DateTime.fromISO(isoDateOfLoss).toJSDate()
                        : undefined,
                },
                {
                    context: {
                        reCaptcha: true,
                    },
                },
            );
            state.claimId = Number(res.id);
            state.claimGeneratedId = res.generatedId || undefined;
            state.claimAccessKey = res.accessKey || undefined;
            state.expToken = res.expToken || '';
            ui.goToNextForm();
        } catch (e) {
            errorMessage.show(e);
        }
    });

    const handleSaveDraft = async (userDetails: {
        email: string;
        name: string;
        phone?: string;
    }) => {
        try {
            message.loading(`Saving...`);
            setIsDraftSaving(true);
            state.claim.orgId = BigInt(orgId);
            state.claim.pdsVersionId = BigInt(pdsVersionId);
            state.claim.formData = service.root ? service.root.toJS() : undefined;
            state.claim.files = filesToFileCollections(service.files);
            state.claim.productTypeId = BigInt(productTypeId);
            state.claim.questionnaireId = BigInt(questionnaireId);
            await trpcClient.claim.saveClaimDraft.mutate(
                {
                    formData: toJS(state),
                    userDetails: {
                        email: userDetails.email,
                        name: userDetails.name,
                        phone: userDetails.phone || undefined,
                    },
                },
                { context: { reCaptcha: true } },
            );

            message.destroy();
            message.success('Saved successfully');
            setSaveClaimModalVisible(false);
        } catch (e) {
            errorMessage.show(e);
        } finally {
            setIsDraftSaving(false);
        }
    };

    const ui = state.ui;

    useEffect(() => {
        async function initializeForm() {
            if (loading) return;

            if (draftId) {
                service.initAndContinue(state.claim.formData, state.questionnaireDef);
            } else {
                service.setQuestionnaireDef(state.questionnaireDef);
            }
            setIsInitialized(true);
        }

        void initializeForm();
    }, [loading, draftId, state.claim.formData, state.questionnaireDef, service]);

    const baseFontSize = ui.theme.fontSize || 16;

    return (
        <ConfigProvider
            theme={{
                token: ui.theme,
            }}
        >
            <SaveClaimDraftModal
                open={saveClaimModalVisible}
                onClose={() => setSaveClaimModalVisible(false)}
                onSave={handleSaveDraft}
                loading={isDraftSaving}
            />

            <div className="min-h-screen p-4">
                <Row justify="center">
                    <Col xs={24} sm={16} md={14} lg={12} xl={10}>
                        <Spin spinning={loading}>
                            {ui.questionnaireLogoUrl && (
                                <div className="text-center">
                                    <img
                                        src={ui.questionnaireLogoUrl}
                                        className="m-auto max-h-24 max-w-full pb-3"
                                        alt="Questionnaire logo"
                                    />
                                </div>
                            )}
                            {ui.backButtonLabel && ui.backButtonUrl && (
                                <Button
                                    type="primary"
                                    className="ml-2"
                                    icon={<ArrowLeftOutlined />}
                                    onClick={() => {
                                        const { error } = Joi.string()
                                            .uri()
                                            .validate(ui.backButtonUrl);
                                        if (error) {
                                            message.error(
                                                'Something went wrong. Please try again later.',
                                            );
                                        } else {
                                            window.location.href = ui.backButtonUrl;
                                        }
                                    }}
                                >
                                    {ui.backButtonLabel}
                                </Button>
                            )}
                            <div
                                className="flex justify-center font-semibold"
                                style={{ fontSize: `${baseFontSize * 1.5}px` }}
                            >
                                Claim Lodgement
                            </div>
                            <Divider className="mb-4 mt-2" />
                            <div>
                                {ui.activeForm === 0 && (
                                    <PolicyHolder
                                        goBack={() => {
                                            // ui.goToPrevForm();
                                        }}
                                        goForward={() => {
                                            ui.goToNextForm();
                                        }}
                                        onSaveDraftOpen={() => setSaveClaimModalVisible(true)}
                                        claim={state.claim}
                                    />
                                )}
                                {ui.activeForm === 1 && (
                                    <PaymentDetails
                                        claim={state.claim}
                                        goBack={() => {
                                            ui.goToPrevForm();
                                        }}
                                        goForward={() => {
                                            ui.goToNextForm();
                                        }}
                                        onSaveDraftOpen={() => setSaveClaimModalVisible(true)}
                                    />
                                )}
                                {ui.activeForm === 2 && (
                                    <ProductDataEntryForm
                                        productTypeDef={state.productTypeDef}
                                        claimData={state.claim}
                                        goBack={() => {
                                            ui.goToPrevForm();
                                        }}
                                        goForward={() => {
                                            ui.goToNextForm();
                                        }}
                                        onSaveDraftOpen={() => setSaveClaimModalVisible(true)}
                                        direction={ui.direction}
                                    />
                                )}
                                {/*{ui.activeForm === 3 && (*/}
                                {/*    <DateOfLoss*/}
                                {/*        formName={FORM.DATE_OF_LOSS}*/}
                                {/*        claim={state.claim}*/}
                                {/*        goBack={() => {*/}
                                {/*            ui.goToPrevForm();*/}
                                {/*        }}*/}
                                {/*        goForward={() => {*/}
                                {/*            ui.goToNextForm();*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*)}*/}
                                {ui.activeForm === 3 && (
                                    <Questionnaire
                                        onSave={save}
                                        state={state}
                                        goBack={() => {
                                            ui.goToPrevForm();
                                        }}
                                        goNext={() => {
                                            ui.goToNextForm();
                                        }}
                                        onSaveDraftOpen={() => setSaveClaimModalVisible(true)}
                                        service={service}
                                        displaySaveButton={false}
                                        isInitialized={isInitialized}
                                        isPublic
                                    />
                                )}
                                {ui.activeForm === 4 && (
                                    <Conditions
                                        onSave={save}
                                        state={state}
                                        goBack={() => {
                                            ui.goToPrevForm();
                                        }}
                                        goNext={() => {
                                            ui.goToNextForm();
                                        }}
                                        onSaveDraftOpen={() => setSaveClaimModalVisible(true)}
                                        displaySaveButton={false}
                                    />
                                )}
                                {ui.activeForm === 5 && (
                                    <AdditionalInfo
                                        onSave={save}
                                        state={state}
                                        goBack={() => {
                                            ui.goToPrevForm();
                                        }}
                                    />
                                )}

                                {ui.activeForm === 6 && <Confirmation formState={state} />}
                            </div>
                        </Spin>
                    </Col>
                </Row>
            </div>
        </ConfigProvider>
    );
});

export const Route = createFileRoute(
    '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId',
)({
    component: ClaimLodgement,
});
