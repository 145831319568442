import { Excess } from '@/components/Claim/Finance/Excess';
import { IncurredCost } from '@/components/Claim/Finance/IncurredCost';
import { Recovery } from '@/components/Claim/Finance/Recovery';
import { Reserve } from '@/components/Claim/Finance/Reserve';
import { VClaimOwnerSelector } from '@/components/Claim/ViewForm/VClaimOwnerSelector';
import { VDropdown } from '@/components/Common/ViewForm/VDropdown';
import { VTextInput } from '@/components/Common/ViewForm/VTextInput';
import { useConfigService } from '@/hooks/Configuration/useConfigService';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useClaimReserveService } from '@/hooks/useClaimReserveService';
import { useFormatter } from '@/hooks/useFormatter';
import { useCurrentUser } from '@/hooks/User/useCurrentUser';
import { claimFormStore } from '../store';
import { PrimaryContactInfo } from './ContactInfo';
import { Card, Col, Row, Space } from 'antd';
import { useMemo } from 'react';
import invariant from 'tiny-invariant';
import { generateCommsTemplateData } from 'shared/utils/comm';
import { ManageStrategy } from './ManageStrategy';
import { ManageTimeline } from './ManageTimeline';
import { SupplierAndJobs } from './SupplierAndJobs';
import { VReadOnly } from '@/components/Common/ViewForm/VReadOnly';
import { trpc } from '@/trpc';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/manage')({
    component: ManageClaim,
});

function ManageClaim() {
    const claim = claimFormStore.use.formData?.();
    invariant(claim, 'Claim is required.');
    const isReadonly = claimFormStore.use.isReadonly();
    const configService = useConfigService();
    const user = useCurrentUser();
    const orgId = useOrgId();
    const formatter = useFormatter();
    const reserveData = useClaimReserveService(claim.id, orgId);
    const { data: financialData, isLoading } = trpc.claim.getFinancialSummary.useQuery(
        {
            claimId: BigInt(claim.id),
        },
        {
            enabled: !!claim,
            refetchInterval: 10000,
        },
    );

    const role = useMemo(() => (claim.ownerId === user.id ? 'owner' : 'user'), [claim.ownerId]);

    return (
        <>
            <Outlet />
            <Space direction="vertical" size={16} className="w-full">
                <Row gutter={16}>
                    <Col span={6}>
                        <PrimaryContactInfo
                            contacts={claim.contacts}
                            name={claim.phCompany || ''}
                            title="Policy Holder"
                            contactType="policy_holder"
                        />
                    </Col>
                    <Col span={6}>
                        <PrimaryContactInfo
                            contacts={claim.contacts}
                            name={claim.Insurer?.name || ''}
                            title="Insurer"
                            contactType="insurer"
                        />
                    </Col>
                    <Col span={6}>
                        <PrimaryContactInfo
                            contacts={claim.contacts}
                            name={claim.brokerCompany || ''}
                            title="Broker"
                            contactType="broker"
                        />
                    </Col>
                    <Col span={6}>
                        <SupplierAndJobs
                            claimId={claim.id}
                            templateData={generateCommsTemplateData(claim, reserveData, formatter)}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={9}>
                        <Card
                            title="Claim snapshot"
                            size="small"
                            className="h-full"
                            bordered={true}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <VReadOnly
                                        label="Claim#"
                                        field="generatedId"
                                        formStore={claimFormStore}
                                        formatValueFn={(value) => value || claim.id}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VClaimOwnerSelector
                                        label="Handling parties"
                                        readonly={isReadonly}
                                        forAdminShowAllOrgs
                                        valueOverride={claim.ExternalOrgs.map(
                                            (el) => el?.ExternalOrg?.name,
                                        )
                                            .join(', ')
                                            .trim()}
                                        formStore={claimFormStore}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VClaimOwnerSelector
                                        label="Owner"
                                        readonly={isReadonly}
                                        forAdminShowAllOrgs
                                        formStore={claimFormStore}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VDropdown
                                        field={'status'}
                                        readonly={role !== 'owner'}
                                        label="Claim Status"
                                        loaderFn={async () => {
                                            const data = await configService.fetchClaimStatuses();
                                            return data.map((item) => ({ value: item }));
                                        }}
                                        formStore={claimFormStore}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VDropdown
                                        formStore={claimFormStore}
                                        field={'decision'}
                                        readonly={isReadonly || role !== 'owner'}
                                        label="Decision"
                                        loaderFn={async () => {
                                            const data = await configService.fetchClaimDecisions();
                                            return data.map((item) => ({ value: item }));
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VDropdown
                                        field={'triageOutcome'}
                                        readonly={isReadonly || role !== 'owner'}
                                        label="Triage outcome"
                                        loaderFn={async () => {
                                            const data =
                                                await configService.fetchClaimTriageOutcome();
                                            return data.map((item) => ({ value: item }));
                                        }}
                                        formStore={claimFormStore}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VTextInput
                                        field={'causeOfLoss'}
                                        label="Cause of Loss"
                                        readonly={isReadonly}
                                        formStore={claimFormStore}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VTextInput
                                        label="Life Cycle"
                                        field={'lifecycle'}
                                        readonly
                                        formStore={claimFormStore}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VTextInput
                                        field={'externalId'}
                                        label="External#"
                                        readonly={isReadonly}
                                        formStore={claimFormStore}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={15}>
                        <Card title="Financials" size="small" className="h-full" bordered={true}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                                    <IncurredCost
                                        reserve={financialData?.reserve || 0}
                                        recovery={financialData?.recovery || 0}
                                        excess={financialData?.excess || 0}
                                        loading={isLoading}
                                        size="small"
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                                    <Reserve
                                        reserve={financialData?.reserve || 0}
                                        paid={financialData?.paid || 0}
                                        loading={isLoading}
                                        size="small"
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                                    <Excess size="small" enableEditing={false} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                                    <Recovery size="small" enableEditing={false} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <ManageTimeline />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <ManageStrategy />
                    </Col>
                </Row>
            </Space>
        </>
    );
}
