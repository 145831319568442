import { gql } from '@/__generated__';

export const ProductTypeByPk = gql(/* GraphQL */ `
    query ProductTypeByPk($id: bigint!, $orgId: bigint!) {
        ProductTypeByPk(id: $id, orgId: $orgId) {
            id
            name
            definition
            thirdPartyContactTypes
            policyFieldMappings
        }
    }
`);
