import { trpc } from '@/trpc';

export type TrpcClient = ReturnType<typeof trpc.useUtils>['client'];

/*
    useTrpc is preferred over useTrpcClient.
*/
export function useTrpcClient() {
    const utils = trpc.useUtils();
    return utils.client;
}
