import { EmailDomainForm } from '@/components/Admin/EmailDomain/EmailDomainForm';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/advanced/email-domains/$id/edit')({
    params: {
        parse({ id }) {
            return {
                id: +id,
            };
        },
    },
    component() {
        const { id } = Route.useParams();

        return <EmailDomainForm action={['edit', { id }]} />;
    },
});
