import { useState } from 'react';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { Card, Checkbox, Table, Tooltip, Typography } from 'antd';
import { errorMessage } from '@/components/Common/errorMessage';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { navRoutes } from 'shared/navigation/navRoutes';
import { PageHeader } from '@/components/Common/PageHeader';
import { User } from '@/components/Common/User';
import { useFormatter } from '@/hooks/useFormatter';
import { Indicator } from '@/components/Common/Indicator';
import { getControlTestStatus } from '@/utils/control';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ControlStatus, ControlTestOutcome } from 'shared/constants/control';
import { useNavigate } from '@/hooks/useNavigate';
import { NewTabLink } from '@/components/Common/NewTabLink';
import { commonActionColumn } from '@/utils/table';
import { RouterInputs, RouterOutputs, trpc } from '@/trpc';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/controls/tests/search')({
    staticData: {
        title: 'Control tests',
    },
    component: ControlTestSearch,
});

type ControlTest = RouterOutputs['controlTest']['listControlTests']['rows'][number];

type ListControlTestQueryVariables = RouterInputs['controlTest']['listControlTests'];

function ControlTestSearch() {
    const orgId = useOrgId();
    const navigate = useNavigate();
    const { formatDate } = useFormatter();

    const [showFailed, setShowFailed] = useState(false);
    const [variables, setVariables] = useState<ListControlTestQueryVariables>({
        where: {
            OR: [{ outcome: null }],
        },
        orderBy: [{ outcome: { sort: 'desc', nulls: 'last' } }, { testDate: 'desc' }],
        limit: 10,
        offset: 0,
    });
    const { data, isPending } = trpc.controlTest.listControlTests.useQuery(variables, {
        throwOnError: (error) => {
            errorMessage.show(error.message);
            return false;
        },
    });

    const { tableProps } = useAntdTable<ControlTest>({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isPending,
            total: data?.total,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                width: 40,
                render: (_, { nextTestDue, outcome, Control }) => {
                    const { color, title } = getControlTestStatus(
                        nextTestDue,
                        outcome as ControlTestOutcome,
                        Control.status as ControlStatus,
                    );
                    return <Indicator tooltip={{ title }} style={{ backgroundColor: color }} />;
                },
            },
            {
                title: 'Tester',
                dataIndex: 'testerId',
                render: (value) => <User userId={value} />,
                sorter: true,
            },
            {
                title: 'Control No.',
                dataIndex: 'controlId',
                render: String,
                sorter: true,
            },
            {
                title: 'Test due date',
                dataIndex: 'nextTestDue',
                render: (value) => formatDate(value),
                sorter: true,
            },
            {
                title: 'Test name',
                dataIndex: 'name',
                render: (value) => (
                    <Tooltip placement="bottom" title={value}>
                        <Typography.Paragraph ellipsis className="mb-0 max-w-60">
                            {value}
                        </Typography.Paragraph>
                    </Tooltip>
                ),
            },
            {
                ...commonActionColumn,
                dataIndex: 'controlId',
                render: (value) => (
                    <NewTabLink
                        to="/console/$orgId/controls/register/edit/$id"
                        params={{
                            orgId,
                            id: value,
                        }}
                    />
                ),
            },
        ],
    });

    const handleCheckboxChange = (e: CheckboxChangeEvent) => {
        const checked = e.target.checked;
        setShowFailed(checked);
        setVariables({
            ...variables,
            where: {
                OR: [{ outcome: null }, ...(checked ? [{ outcome: 'FAIL' }] : [])],
            },
        });
    };

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.controls),
                    toBreadCrumbWithoutLink(navRoutes.controls_tests_search),
                ]}
            />

            <PageHeader title="Test Register" />

            <div className="mb-4 flex justify-end">
                <Checkbox checked={showFailed} onChange={handleCheckboxChange}>
                    Show failed tests
                </Checkbox>
            </div>
            <Card>
                <Table
                    size="small"
                    scroll={{ x: 'max-content' }}
                    onRow={({ controlId }) => ({
                        className: 'cursor-pointer',
                        onClick: (e) =>
                            navigate(
                                {
                                    to: '/console/$orgId/controls/register/edit/$id',
                                    params: {
                                        orgId,
                                        id: controlId,
                                    },
                                },
                                e,
                            ),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
