import { TrpcClient } from '@/hooks/useTrpcClient';
import { logInDev } from '@/utils/general';
import { create } from 'zustand';
import { DEFAULT_ORG_SETTINGS } from 'shared/constants/orgSettings';
import { OrgSettings } from 'shared/types/org';

type OrgSettingsState = {
    isInitialized: boolean;
    orgSetting: OrgSettings;
    trpcClient?: TrpcClient;
    init: (orgId: bigint) => Promise<void>;
    reload: (orgId: bigint) => Promise<void>;
    setTrpcClient: (trpcClient: TrpcClient) => void;
    canBeInitialized: boolean;
};
export const useOrgSettingsStore = create<OrgSettingsState>((set, get) => ({
    isInitialized: false,
    trpc: undefined,
    orgSetting: DEFAULT_ORG_SETTINGS,
    client: null,
    canBeInitialized: false,

    setTrpcClient: (trpcClient: TrpcClient) => {
        set({ trpcClient });
        set({ canBeInitialized: trpcClient !== undefined });
    },

    init: async (orgId: bigint) => {
        try {
            set({ isInitialized: false });
            const trpcClient = get().trpcClient;
            if (!trpcClient) {
                throw new Error('trpcClient is not set');
            }

            const data = await trpcClient.org.getOrgSettings.query({ orgId });
            set({
                orgSetting: data,
                isInitialized: true,
            });
        } catch (e) {
            logInDev('OrgSettingStore.init failed', e);
        }
    },

    reload: async (orgId: bigint) => {
        await get().init(orgId);
    },
}));

// Selector functions
export const selectIsInitialized = (state: OrgSettingsState) => state.isInitialized;
export const selectAllSettings = (state: OrgSettingsState) => state.orgSetting;
