import { DatePicker } from '@/components/Common/DatePicker';
import { errorMessage } from '@/components/Common/errorMessage';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { CoiDefs } from '@/global/conflict';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useFormatter } from '@/hooks/useFormatter';
import { useSafePath } from '@/hooks/useSafePath';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import {
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Select,
    Space,
} from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from '@/hooks/useNavigate';
import { navRoutes } from 'shared/navigation/navRoutes';
import { RouterInputs, trpc } from '@/trpc';
import { HandlingPartySelect } from '@/components/Common/HandlingPartySelect';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/compliance/coi/add')({
    staticData: {
        title: 'New conflict of interest',
    },
    component: CoiAdd,
});

type ConflictOfInterestInput = RouterInputs['conflictOfInterest']['createConflictOfInterest'];

function CoiAdd() {
    const navigate = useNavigate();
    const orgId = useOrgId();
    const safePath = useSafePath<ConflictOfInterestInput>();
    const { fDateShortDayJs } = useFormatter();
    const { mutateAsync: createConflictOfInterest, isPending } =
        trpc.conflictOfInterest.createConflictOfInterest.useMutation();

    const [form] = Form.useForm<ConflictOfInterestInput>();

    const onFinish = async (values: ConflictOfInterestInput) => {
        try {
            message.info('Saving...');
            const { id } = await createConflictOfInterest({ ...values });
            message.destroy();
            message.success('Saved.');

            navigate({
                to: '/console/$orgId/compliance/coi/details/$id/$section',
                params: {
                    id,
                    orgId,
                    section: 'manage',
                },
            });
        } catch (e) {
            message.destroy();
            errorMessage.show(e);
        }
    };

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumb(navRoutes.compliance_coi_search, { orgId }),
                    toBreadCrumbWithoutLink(navRoutes.compliance_coi_add),
                ]}
            />

            <Form
                form={form}
                layout="horizontal"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                onFinish={onFinish}
                onFinishFailed={(errorInfo) => {
                    form.scrollToField(errorInfo.errorFields[0].name);
                }}
            >
                <Row gutter={16} justify="center">
                    <Col sm={24} lg={14}>
                        <Card bordered>
                            <>
                                <Divider orientation="left">Personal details</Divider>
                                <Form.Item
                                    label="First name"
                                    name={safePath('firstName')}
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Field is required',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Last name"
                                    name={safePath('lastName')}
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Field is required',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Position" name={safePath('position')}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Date advised"
                                    name={safePath('dtAdvised')}
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Field is required',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        className="w-full"
                                        format={fDateShortDayJs}
                                        maxDate={dayjs()}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Severity"
                                    name={safePath('severity')}
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Field is required',
                                        },
                                    ]}
                                >
                                    <Select
                                        options={CoiDefs.arySeverity.map((severity) => ({
                                            value: severity,
                                        }))}
                                    />
                                </Form.Item>
                                <Divider orientation="left">Conflict of interest</Divider>
                                <Form.Item
                                    label="Interest disclosed"
                                    name={safePath('interestDisclosed')}
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                                <Form.Item
                                    label="Nature of potential conflict"
                                    name={safePath('natureOfConflict')}
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                                <Form.Item
                                    label="Party to be notified"
                                    name={safePath('partyToBeNotified')}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Estimated value"
                                    name={safePath('estimatedValue')}
                                >
                                    <InputNumber />
                                </Form.Item>
                                <Divider orientation="left">
                                    Action and consequence management
                                </Divider>
                                <Form.Item
                                    label="Handling parties"
                                    name={safePath('handlingPartyIds')}
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Field is required',
                                        },
                                    ]}
                                >
                                    <HandlingPartySelect forAdminShowAllOrgs />
                                </Form.Item>
                                <Form.Item
                                    label="Mechanism chosen to manage the conflict"
                                    name={safePath('conflictManagement')}
                                >
                                    <Select
                                        options={CoiDefs.aryConflictManagement.map((cm) => ({
                                            value: cm,
                                        }))}
                                        mode="multiple"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Solution implemented / Action taken"
                                    name={safePath('solutionImplemented')}
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                                <Form.Item
                                    label="Date implemented"
                                    name={safePath('dtImplemented')}
                                >
                                    <DatePicker
                                        className="w-full"
                                        format={fDateShortDayJs}
                                        maxDate={dayjs()}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Consequence management"
                                    name={safePath('consequenceManagement')}
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                                <Form.Item label="Other comments" name={safePath('comments')}>
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                            </>
                        </Card>
                        <Space className="mt-2 flex justify-end">
                            <Button
                                onClick={() => {
                                    navigate({
                                        to: '/console/$orgId/compliance/coi/search',
                                        params: {
                                            orgId,
                                        },
                                    });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" loading={isPending}>
                                Save
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
