import { errorMessage } from '@/components/Common/errorMessage';
import { PageHeader } from '@/components/Common/PageHeader';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { useNavigate } from '@/hooks/useNavigate';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { RouterInputs, trpc } from '@/trpc';
import { DownOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Dropdown, Input, message, Space, Table, Tag } from 'antd';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { SYSTEM_ACCOUNT_ID } from 'shared/constants/user';
import invariant from 'tiny-invariant';
import { InviteUser } from './InviteUser';
import { UserEdit } from './UserEdit';
import { createFileRoute } from '@tanstack/react-router';
import { commonActionColumn } from '@/utils/table';

export const Route = createFileRoute('/admin-console/users')({
    staticData: {
        title: 'Users',
    },
    component: AdminUsers,
});

type QueryVariables = RouterInputs['admin']['user']['listUsers'];

const DEFAULT_FILTER = {
    AND: [{ id: { gt: SYSTEM_ACCOUNT_ID.SYSTEM } }, { isSupportAccount: false }],
} satisfies QueryVariables['where'];

function AdminUsers() {
    const trpcClient = useTrpcClient();
    const navigate = useNavigate();

    const [userIdToEdit, setUserIdToEdit] = useState<bigint | undefined>();
    const [openInviteUserDialog, setOpenInviteUserDialog] = useState(false);
    const [queryVariables, setQueryVariables] = useState<QueryVariables>({
        where: DEFAULT_FILTER,
        orderBy: {
            createdAt: 'desc',
        },
    });

    const { data, error, refetch, isPending } = trpc.admin.user.listUsers.useQuery(queryVariables);

    const onSync = async (userId: bigint) => {
        try {
            message.loading('Syncing...');
            const result = await trpcClient.admin.user.syncUserWithAuth0.mutate({
                userId: userId!,
            });
            message.destroy();
            if (result.success) {
                message.success('Synced with Auth0.');
            } else {
                errorMessage.show(result.error, 'Sync failed');
            }
        } catch (e) {
            errorMessage.show(e);
        }
    };

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            total: data?.total,
            loading: isPending,
        },
        columns: [
            {
                dataIndex: 'id',
                title: 'ID',
                sorter: true,
                align: 'center',
                render: (value) => <Tag color="blue">{value.toString()}</Tag>,
            },
            {
                dataIndex: 'email',
                title: 'E-mail',
                sorter: true,
                render: (value) => (
                    <div className="flex items-center">
                        <Button type="link" href={`mailto:${value}`}>
                            {value}
                        </Button>
                    </div>
                ),
            },
            {
                title: 'Provider',
                dataIndex: 'provider',
                sorter: true,
                render: (value) => <Tag>{value}</Tag>,
            },
            {
                dataIndex: 'createdAt',
                title: 'Created',
                className: 'whitespace-nowrap',
                render: (value) => DateTime.fromJSDate(value).toFormat('yyyy-MM-dd HH:mm:ss'),
            },
            {
                dataIndex: 'photoUrl',
                title: 'Photo',
                sorter: true,
                render: (_, row) => <Avatar src={row.photoUrl} className="mr-2" />,
            },
            {
                dataIndex: 'isAdmin',
                title: 'System Admin',
                sorter: true,
                render: (value) => (
                    <Tag color={value ? 'green' : 'red'}>{value ? 'Yes' : 'No'}</Tag>
                ),
                align: 'center',
            },
            {
                title: 'Last Sync Status',
                dataIndex: 'lastSyncStatus',
                sorter: true,
                render: (value) => <Tag color={value === 'success' ? 'green' : 'red'}>{value}</Tag>,
            },
            {
                title: 'Last Synced',
                dataIndex: 'lastSynced',
                sorter: true,
                className: 'whitespace-nowrap',
                render: (value) => DateTime.fromJSDate(value).toFormat('yyyy-MM-dd HH:mm:ss'),
            },
            {
                dataIndex: 'auth0Id',
                title: 'Auth0 ID',
                sorter: true,
                className: 'whitespace-nowrap',
            },

            {
                ...commonActionColumn,
                render: (_, { id, Orgs }) => (
                    <Space>
                        <Dropdown
                            trigger={['click']}
                            menu={{
                                items: [
                                    {
                                        key: 'edit',
                                        label: 'Edit',
                                        onClick() {
                                            setUserIdToEdit(id);
                                        },
                                    },
                                    {
                                        key: 'syncWithAuth0',
                                        label: 'Sync with Auth0',
                                        onClick() {
                                            onSync(id);
                                        },
                                    },
                                ],
                            }}
                        >
                            <Button size="small">
                                Actions <DownOutlined />
                            </Button>
                        </Dropdown>
                        <Dropdown
                            trigger={['click']}
                            menu={{
                                items: Orgs.map((org) => ({
                                    key: org.id,
                                    label: org.name,
                                    onClick: () => {
                                        navigate({
                                            to: '/admin-console/organizations/$orgId/users',
                                            params: {
                                                orgId: org.id,
                                            },
                                        });
                                    },
                                })),
                            }}
                        >
                            <Button size="small">
                                Organizations <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Space>
                ),
            },
        ],
        onQueryVariableChange: (options) => {
            setQueryVariables({
                ...queryVariables,
                limit: options?.limit || queryVariables.limit,
                offset: options?.offset || queryVariables.offset,
                orderBy: options?.orderBy || queryVariables.orderBy,
            });
        },
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
    });

    const onSearch = (searchValue: string) => {
        const where: QueryVariables['where'] = { OR: [] } as const;
        invariant(where.OR, 'where.OR is not defined');
        if (searchValue) {
            where.OR.push({
                name: { contains: `%${searchValue}%`, mode: 'insensitive' },
            });
            where.OR.push({
                email: { contains: `%${searchValue}%`, mode: 'insensitive' },
            });
        }

        setQueryVariables({
            ...queryVariables,
            where: {
                AND: [...DEFAULT_FILTER.AND, ...(where.OR.length ? [where] : [])],
            },
        });
    };

    useEffect(() => {
        if (error) {
            errorMessage.show(error);
        }
    }, [error]);

    return (
        <>
            <PageHeader title="Users" />

            <div className="mb-4 flex items-center justify-between">
                <Input.Search
                    placeholder="Search users"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={onSearch}
                    className="w-96"
                />
            </div>
            <InviteUser
                open={openInviteUserDialog}
                onClose={() => {
                    setOpenInviteUserDialog(false);
                    void refetch();
                }}
            />

            <UserEdit
                userId={userIdToEdit}
                open={!!userIdToEdit}
                onClose={() => {
                    setUserIdToEdit(undefined);
                    void refetch();
                }}
            />

            <Card>
                <Table scroll={{ x: true }} {...tableProps} size="small" />
            </Card>
        </>
    );
}
