import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { navRoutes } from 'shared/navigation/navRoutes';
import { useState } from 'react';
import { Button, Card, Checkbox, Popconfirm, Table } from 'antd';
import { PageHeader } from '@/components/Common/PageHeader';
import { InsurerDsQuery, InsurerDsQueryVariables, OrderBy } from '@/__generated__/graphql';
import { useAntdTableOld } from '@/hooks/Table/useAntdTableOld';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { grey } from '@ant-design/colors';
import { errorMessage } from '@/components/Common/errorMessage';
import { InsurerEdit } from './InsurerEdit';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { gql } from '@/__generated__';
import { useMutation, useQuery } from '@apollo/client';
import { commonActionColumn } from '@/utils/table';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/insurers')({
    staticData: {
        title: 'Insurers',
        modules: ['claim'],
    },
    component: ConfigurationInsurers,
});

type Insurer = InsurerDsQuery['Insurer'][number];

function ConfigurationInsurers() {
    const orgId = useOrgId();
    const [deleteInsurerByPk, { loading: deleting }] = useMutation(DeleteInsurerByPk);

    const [idToEdit, setIdToEdit] = useState<number | undefined>(undefined);
    const [variables, setVariables] = useState<InsurerDsQueryVariables>({
        where: undefined,
        orderBy: [{ id: OrderBy.desc }],
        limit: 10,
        offset: 0,
    });

    const { data, loading, refetch } = useQuery(InsurerDs, {
        fetchPolicy: 'network-only',
        variables: variables,
    });

    const { tableProps } = useAntdTableOld<Insurer>({
        rowKey: 'id',
        data: {
            rows: data?.Insurer,
            loading: loading || deleting,
            total: data?.InsurerAggregate?.aggregate?.count,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                sorter: true,
            },
            {
                title: 'Apply GST?',
                dataIndex: 'applyGst',
                render: (value) => <Checkbox checked={!!value} onChange={() => {}} />,
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Popconfirm
                        title="Are you sure?"
                        onPopupClick={(e) => e.stopPropagation()}
                        onConfirm={async () => {
                            try {
                                await deleteInsurerByPk({
                                    variables: {
                                        id: value,
                                        orgId,
                                    },
                                });

                                void refetch();
                            } catch (e) {
                                errorMessage.show(e);
                            }
                        }}
                    >
                        <Button
                            type="text"
                            size="small"
                            icon={<DeleteOutlined style={{ color: grey.primary }} />}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </Popconfirm>
                ),
            },
        ],
    });

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    toBreadCrumb(navRoutes.configuration_insurers, { orgId }),
                ]}
            />
            <PageHeader title="Insurers configuration" />
            <InsurerEdit
                idToEdit={idToEdit}
                open={!!idToEdit}
                onClose={() => {
                    setIdToEdit(undefined);
                    void refetch();
                }}
            />
            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setIdToEdit(-1);
                    }}
                >
                    Add new
                </Button>
            </div>
            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: () => setIdToEdit(id),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}

const DeleteInsurerByPk = gql(/* GraphQL */ `
    mutation DeleteInsurerByPk($id: bigint!, $orgId: bigint!) {
        deleteInsurerByPk(id: $id, orgId: $orgId) {
            id
        }
    }
`);

const InsurerDs = gql(/* GraphQL */ `
    query InsurerDs(
        $limit: Int = 10
        $offset: Int = 0
        $orderBy: [InsurerOrderBy!] = {}
        $where: InsurerBoolExp = {}
    ) {
        Insurer(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
            ...InsurerFragment
        }
        InsurerAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`);
