import { errorMessage } from '@/components/Common/errorMessage';
import { useNavigate } from '@/hooks/useNavigate';
import { useTrpc } from '@/hooks/useTrpc';
import { RouterOutputs } from '@/trpc';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Drawer, Form, Select, Space, message } from 'antd';
import { useEffect, useState } from 'react';

export const Route = createFileRoute('/admin-console/organizations/$orgId/manage-email-domains')({
    params: {
        parse({ orgId }) {
            return {
                orgId: BigInt(orgId),
            };
        },
    },
    component: ManageEmailDomains,
});

type FormValues = {
    emailDomainIds: bigint[];
};

function ManageEmailDomains() {
    const { orgId } = Route.useParams();
    const [form] = Form.useForm<FormValues>();
    const navigate = useNavigate();
    const { trpcClient, trpcUtils } = useTrpc();
    const [open, setOpen] = useState(true);
    const [saving, setSaving] = useState(false);
    const [emailDomains, setEmailDomains] = useState<
        RouterOutputs['admin']['emailDomain']['listEmailDomains']['rows']
    >([]);

    const loadEmailDomains = async (search?: string) => {
        try {
            const result = await trpcClient.admin.emailDomain.listEmailDomains.query({
                limit: 100,
                offset: 0,
                where: {
                    ...(search
                        ? {
                              domain: {
                                  contains: search,
                                  mode: 'insensitive',
                              },
                          }
                        : {}),
                    orgId: null,
                },
            });
            setEmailDomains(result.rows);
        } catch (error) {
            errorMessage.show(error);
        }
    };

    const onFinish = async (values: FormValues) => {
        try {
            setSaving(true);
            await trpcClient.admin.emailDomain.assignEmailDomainsToOrg.mutate({
                orgId,
                emailDomainIds: values.emailDomainIds.map((id) => BigInt(id)),
            });
            await trpcUtils.admin.org.listOrgs.invalidate();
            message.success('Email domains assigned successfully');
            setOpen(false);
        } catch (error) {
            errorMessage.show(error);
        } finally {
            setSaving(false);
        }
    };

    const onFinishFailed = () => {
        message.error('Please check the form for errors');
    };

    // Load initial data
    useEffect(() => {
        loadEmailDomains();
    }, []);

    return (
        <Drawer
            open={open}
            onClose={() => setOpen(false)}
            width={400}
            maskClosable={false}
            afterOpenChange={(open) => {
                if (!open) {
                    navigate({ to: '/admin-console/organizations' });
                }
            }}
            title="Assign Email Domains"
            footer={
                <Space className="flex justify-end">
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button type="primary" onClick={() => form.submit()} loading={saving}>
                        Save Changes
                    </Button>
                </Space>
            }
        >
            <Form<FormValues>
                id="editForm"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Email Domains"
                    name="emailDomainIds"
                    rules={[{ required: true, message: 'Please select at least one email domain' }]}
                >
                    <Select<bigint, { label: string; value: bigint }>
                        mode="multiple"
                        placeholder="Select email domains"
                        showSearch
                        onSearch={loadEmailDomains}
                        filterOption={false}
                        options={emailDomains.map((domain) => ({
                            label: domain.domain,
                            value: domain.id,
                        }))}
                        className="w-full"
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
