import invariant from 'tiny-invariant';
import { complaintFormStore } from '../store';
import { createFileRoute } from '@tanstack/react-router';
import { History } from '@/components/Common/History';

export const Route = createFileRoute('/console/$orgId/compliance/complaints/details/$id/history')({
    component: ComplaintHistory,
});

function ComplaintHistory() {
    const complaint = complaintFormStore.use.formData?.();
    invariant(complaint, 'Complaint is required.');

    return <History idValue={complaint.id} idPropertyName="complaintId" />;
}
