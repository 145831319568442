import { useState } from 'react';
import { navRoutes } from 'shared/navigation/navRoutes';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { App, Button, Card, Checkbox, Col, Divider, Form, Row, Skeleton, Space } from 'antd';
import { errorMessage } from '@/components/Common/errorMessage';
import { UserInOrgAllFieldsFragment } from '@/__generated__/graphql';
import { useUserInOrgByUserId } from '@/hooks/User/useUserInOrgByUserId';
import { useSafePath } from '@/hooks/useSafePath';
import { gql } from '@/__generated__';
import { useMutation } from '@apollo/client';
import { useCurrentUser } from '@/hooks/User/useCurrentUser';
import { useCurrentOrg } from '@/hooks/Org/useCurrentOrg';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/user-settings/notifications')({
    component: NotificationSettings,
});

type UserNotifications = UserInOrgAllFieldsFragment;

const UpdateUserOrg = gql(/* GraphQL */ `
    mutation UpdateUserOrg($userId: bigint = "", $orgId: bigint = "", $data: UserOrgSetInput = {}) {
        updateUserOrg(where: { userId: { _eq: $userId }, orgId: { _eq: $orgId } }, _set: $data) {
            affectedRows
        }
    }
`);

function NotificationSettings() {
    const { message } = App.useApp();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const user = useCurrentUser();
    const org = useCurrentOrg();
    const [form] = Form.useForm();
    const [updateUserOrg] = useMutation(UpdateUserOrg);
    const safePath = useSafePath<UserNotifications>();

    useUserInOrgByUserId({
        userId: user.id ? BigInt(user.id) : undefined,
        onCompleted: (user) => {
            if (user) {
                setLoading(false);
                form.resetFields();
                form.setFieldsValue(user);
            }
        },
        onError: (error) => errorMessage.show(error),
    });

    const onSave = async (values: UserNotifications) => {
        try {
            setSaving(true);
            await updateUserOrg({
                variables: {
                    userId: user.id,
                    orgId: user.orgId,
                    data: values,
                },
            });
            message.destroy();
            message.success('Saved successfully.');
        } catch (e) {
            errorMessage.show(e);
        } finally {
            setSaving(false);
        }
    };

    return (
        <div>
            <BreadCrumbs breadCrumbs={[{ title: navRoutes.userSettings_notifications.title }]} />
            <Row justify="center">
                <Col span={10}>
                    <Card bordered>
                        <Skeleton loading={loading}>
                            <Form
                                form={form}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                onFinish={onSave}
                            >
                                <Divider plain orientation="left">
                                    Tasks
                                </Divider>
                                <Form.Item
                                    name={safePath('notificationAssignedToTask')}
                                    valuePropName="checked"
                                >
                                    <Checkbox>Assigned to task</Checkbox>
                                </Form.Item>
                                {user.isOrgAdmin && (
                                    <Form.Item
                                        name={safePath('notificationNewUnallocatedTask')}
                                        valuePropName="checked"
                                    >
                                        <Checkbox>New unallocated task</Checkbox>
                                    </Form.Item>
                                )}
                                {org.enabledModules.includes('compliance') && (
                                    <>
                                        <Divider plain orientation="left">
                                            Complaints
                                        </Divider>
                                        <Form.Item
                                            name={safePath('notificationComplaintCreated')}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>Complaint submitted</Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name={safePath(
                                                'notificationComplaintCreatedInMyExternalOrg',
                                            )}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                Complaint created for my handling party
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name={safePath(
                                                'notificationAssignedToComplaintAsOwner',
                                            )}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>Assigned as owner</Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name={safePath('notificationComplaintIndicatorChanged')}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>Complaint indicator changed</Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name={safePath('notificationComplaintPublicSubmitted')}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>Public complaint submitted</Checkbox>
                                        </Form.Item>
                                        <Divider plain orientation="left">
                                            Incidents
                                        </Divider>
                                        <Form.Item
                                            name={safePath('notificationIncidentSubmitted')}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>Incident submitted</Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name={safePath('notificationIncidentAssignedAsOwner')}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>Assigned as owner</Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name={safePath('notificationIncidentIndicatorChanged')}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>Incident indicator changed</Checkbox>
                                        </Form.Item>
                                    </>
                                )}
                                <Space direction="horizontal" className="flex justify-end">
                                    <Button type="primary" htmlType="submit" loading={saving}>
                                        Save
                                    </Button>
                                </Space>
                            </Form>
                        </Skeleton>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
