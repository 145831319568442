import { ProductTypeField } from 'shared/types/productType';
import { Checkbox, Form, FormInstance, FormItemProps, Input, InputNumber } from 'antd';
import { CarMakeModelSelector } from '@/components/Claim/ProductType/InputControls/CarMakeModelSelector';
import { DatePickerOld } from '@/components/Common/DatePickerOld';
import { useFormatter } from '@/hooks/useFormatter';
import { useAppStateStore } from '@/stores/AppStateStore';

interface Props {
    field: ProductTypeField;
    index: number;
    form: FormInstance;
}

export function FormItem({ field, index, form }: Props) {
    const { claimLodgementSettings } = useAppStateStore();
    const { fDateShortDayJs } = useFormatter();
    const props: FormItemProps = {};
    const controlProps: { tabIndex?: number } = {};
    const requiredMessage = claimLodgementSettings.fieldRequiredMessage;

    if (index === 0) {
        controlProps.tabIndex = 0;
    }
    if (field.type === 'TEXT') {
        if (field.required) {
            props.rules = [{ required: true, message: requiredMessage }];
        }

        return (
            <Form.Item
                key={index}
                label={field.label}
                name={field.id}
                required={field.required}
                {...props}
            >
                <Input {...controlProps} />
            </Form.Item>
        );
    } else if (field.type === 'EMAIL') {
        if (field.required) {
            props.rules = [{ type: 'email' }, { required: true, message: requiredMessage }];
        } else {
            props.rules = [{ type: 'email' }];
        }
        return (
            <Form.Item
                key={index}
                label={field.label}
                name={field.id}
                required={field.required}
                {...props}
            >
                <Input {...controlProps} />
            </Form.Item>
        );
    } else if (field.type === 'CHECKBOX') {
        return (
            <Form.Item
                key={index}
                label={field.label}
                name={field.id}
                required={field.required}
                valuePropName="checked"
                {...props}
            >
                <Checkbox />
            </Form.Item>
        );
    } else if (field.type === 'NUMBER') {
        if (field.required) {
            props.rules = [{ type: 'number' }, { required: true, message: requiredMessage }];
        }
        return (
            <Form.Item
                key={index}
                label={field.label}
                name={field.id}
                required={field.required}
                {...props}
            >
                <InputNumber {...controlProps} />
            </Form.Item>
        );
    } else if (field.type === 'DATE') {
        if (field.required) {
            props.rules = [{ type: 'date' }, { required: true, message: requiredMessage }];
        }
        return (
            <Form.Item
                key={index}
                label={field.label}
                name={field.id}
                required={field.required}
                {...props}
            >
                <DatePickerOld {...controlProps} format={fDateShortDayJs} />
            </Form.Item>
        );
    } else if (field.type === 'CAR_MAKE_MODEL') {
        if (field.required) {
            props.rules = [
                {
                    validator: () => {
                        throw new Error('Please select a car make and model');
                    },
                },
            ];
        }
        //name={field.id} required={field.required} {...props}
        return (
            <Form.Item key={index} noStyle>
                <CarMakeModelSelector required={field.required} field={field} form={form} />
            </Form.Item>
        );
    } else {
        if (field.required) {
            props.rules = [{ required: true, message: requiredMessage }];
        }
        return (
            <Form.Item
                key={index}
                label={field.label}
                name={field.id}
                required={field.required}
                {...props}
            >
                <Input {...controlProps} />
            </Form.Item>
        );
    }
}
