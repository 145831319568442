import { errorMessage } from '@/components/Common/errorMessage';
import { useNavigate } from '@/hooks/useNavigate';
import { useTrpc } from '@/hooks/useTrpc';
import { RouterInputs, trpc } from '@/trpc';
import { OnFinishFailedFn } from '@/types/form';
import { InfoCircleOutlined } from '@ant-design/icons';
import { createFileRoute } from '@tanstack/react-router';
import {
    Button,
    Card,
    Checkbox,
    Drawer,
    Form,
    Input,
    message,
    Select,
    Skeleton,
    Space,
    Tabs,
} from 'antd';
import { useEffect, useState } from 'react';
import { AUTH0_CONNECTION } from 'shared/constants/auth0';
import { APP_MODULES } from 'shared/types/app';
import invariant from 'tiny-invariant';

export const Route = createFileRoute('/admin-console/organizations/$orgId/edit')({
    params: {
        parse({ orgId }) {
            return {
                orgId: BigInt(orgId),
            };
        },
    },
    component: OrganizationEdit,
});

type Org = Pick<
    RouterInputs['admin']['org']['updateOrg']['set'],
    'name' | 'enabledModules' | 'enabledAuth0Connections' | 'emailId' | 'isEnabled'
>;

const FormItem = Form.Item<Org>;

const moduleOptions = [
    { label: 'Claims Module', value: APP_MODULES.CLAIM },
    { label: 'Compliance Module', value: APP_MODULES.COMPLIANCE },
    { label: 'Risk Module', value: APP_MODULES.RISK },
    { label: 'Expenses & Billing', value: APP_MODULES.BILLING },
    { label: 'Tools Module', value: APP_MODULES.TOOLS },
];

function OrganizationEdit() {
    const { orgId } = Route.useParams();
    const [form] = Form.useForm<Org>();
    const navigate = useNavigate();
    const { trpcClient, trpcUtils } = useTrpc();
    const [open, setOpen] = useState(true);

    const [saving, setSaving] = useState(false);

    const { data, isPending } = trpc.admin.org.getOrg.useQuery(
        { orgId },
        {
            throwOnError(error) {
                errorMessage.show(error);
                return false;
            },
        },
    );

    useEffect(() => {
        if (data) {
            form.setFieldsValue(data);
        }
    }, [data]);

    const onSave = async (values: Org) => {
        try {
            setSaving(true);
            message.loading('Saving...');
            invariant(orgId, 'orgId is required');
            await trpcClient.admin.org.updateOrg.mutate({
                orgId,
                set: {
                    name: values.name,
                    isEnabled: values.isEnabled,
                    enabledModules: values.enabledModules,
                    enabledAuth0Connections: values.enabledAuth0Connections,
                    emailId: values.emailId,
                },
            });

            message.destroy();
            message.success('Saved.');
            setOpen(false);
        } catch (e) {
            errorMessage.show(e);
        } finally {
            setSaving(false);
        }
    };

    const onFinishFailed: OnFinishFailedFn<Org> = (errorInfo) => {
        errorMessage.validation(errorInfo.errorFields.map((field) => field.errors));
    };

    return (
        <Drawer
            open={open}
            onClose={() => setOpen(false)}
            width={800}
            maskClosable={false}
            afterOpenChange={async (open) => {
                if (!open) {
                    await trpcUtils.admin.org.invalidate();
                    navigate({ to: '/admin-console/organizations' });
                }
            }}
            title={`Edit Organization: ${data?.name}`}
            footer={
                <Space className="flex justify-end">
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button type="primary" htmlType="submit" form="editForm" loading={saving}>
                        Save Changes
                    </Button>
                </Space>
            }
        >
            {isPending ? (
                <Skeleton active />
            ) : (
                <Form<Org>
                    id="editForm"
                    layout="vertical"
                    form={form}
                    onFinish={onSave}
                    onFinishFailed={onFinishFailed}
                    className="space-y-6"
                >
                    <Tabs
                        items={[
                            {
                                key: 'basic',
                                forceRender: true,
                                label: 'Basic Information',
                                children: (
                                    <Card className="shadow-sm">
                                        <FormItem
                                            label="Organization Name"
                                            required
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter the organization name',
                                                },
                                                {
                                                    min: 2,
                                                    message: 'Name must be at least 2 characters',
                                                },
                                            ]}
                                            tooltip={{
                                                title: 'The display name of the organization',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Input placeholder="Enter organization name" />
                                        </FormItem>
                                        <FormItem
                                            name="emailId"
                                            label="Organization Email ID"
                                            tooltip={{
                                                title: 'The email local name specific to this organization',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                            help="The organization will be able to send emails to this email ID, e.g. contoso@ai-inbox.curium.app"
                                        >
                                            <Input placeholder="Enter email ID, e.g. contoso" />
                                        </FormItem>
                                        <FormItem
                                            name="isEnabled"
                                            valuePropName="checked"
                                            tooltip={{
                                                title: 'Disable to temporarily suspend organization access',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Checkbox>Organization is active</Checkbox>
                                        </FormItem>
                                    </Card>
                                ),
                            },
                            {
                                key: 'features',
                                label: 'Features',
                                forceRender: true,
                                children: (
                                    <Card className="shadow-sm">
                                        <FormItem
                                            name="enabledModules"
                                            label="Enabled Modules"
                                            tooltip={{
                                                title: 'Select the modules that should be enabled for this organization',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Checkbox.Group
                                                options={moduleOptions}
                                                className="grid grid-cols-1 gap-4 md:grid-cols-2"
                                            />
                                        </FormItem>
                                    </Card>
                                ),
                            },
                            {
                                key: 'authentication',
                                label: 'Authentication',
                                forceRender: true,
                                children: (
                                    <Card className="shadow-sm">
                                        <FormItem
                                            name="enabledAuth0Connections"
                                            label="Authentication Methods"
                                            tooltip={{
                                                title: 'Select the allowed authentication methods for this organization',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Select authentication methods"
                                                allowClear
                                                className="w-full"
                                                options={Object.values(AUTH0_CONNECTION).map(
                                                    (connection) => ({
                                                        label: connection.label,
                                                        value: connection.name,
                                                    }),
                                                )}
                                            />
                                        </FormItem>
                                    </Card>
                                ),
                            },
                        ]}
                    />
                </Form>
            )}
        </Drawer>
    );
}
