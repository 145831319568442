import { createFileRoute } from '@tanstack/react-router';
import { DocumentLibrary } from '@/components/Common/Documents/DocumentLibrary';
import { History } from '@/components/Common/History';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useNavigate } from '@/hooks/useNavigate';
import { trpc } from '@/trpc';
import { FieldError } from '@/types/error';
import { toBreadCrumb } from '@/utils/navigation';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, message, Skeleton, Tabs } from 'antd';
import { useMemo, useState } from 'react';
import { INCIDENT_DETAILS_SECTIONS, IncidentDetailsSection, navRoutes } from 'shared/navigation/navRoutes';
import { CloseIncidentDrawer } from './CloseIncidentDrawer';
import { useExportIncident, useValidateIncidentBeforeClosing } from './hooks';
import { IncidentDetailsManage } from './IncidentDetailsManage';
import { incidentFormStore } from './store';
import { useCurrentUser } from '@/hooks/User/useCurrentUser';
import { errorMessage } from '@/components/Common/errorMessage';
import { RecordNotFoundError } from '@/utils/error';
import { INCIDENT_STATUSES_KEYS } from 'shared/constants/incident';
import { PageContent } from '@/components/Layout/PageContent';

export const Route = createFileRoute('/console/$orgId/compliance/incidents/details/$id/$section')({
    staticData: {
        title: 'Incident details',
    },
    params: {
        parse({ id, section }) {
            return {
                id: BigInt(id),
                section: section as IncidentDetailsSection,
            };
        },
    },
    component: IncidentDetails,
});

function IncidentDetails() {
    const orgId = useOrgId();
    const { isOrgAdmin, id: userId } = useCurrentUser();
    const trpcClient = trpc.useUtils();
    const { id, section } = Route.useParams();
    const updateIncidentMutation = trpc.incident.updateIncident.useMutation();
    const navigate = useNavigate();
    const trpcUtils = trpc.useUtils();
    const [exportIncident, { loading: exportIncidentLoading }] = useExportIncident();
    const {
        validateTimeline,
        validateRequiredFields,
        loading: validateIncidentBeforeClosingLoading,
    } = useValidateIncidentBeforeClosing();

    const [requiredFieldsErrors, setRequiredFieldsErrors] = useState<FieldError[]>([]);
    const [open, setOpen] = useState(false);

    const { formData: incident, save } = incidentFormStore.useInit({
        loadDependencies: [id],

        async onSave(currentValue, newValue) {
            try {
                message.info('Saving...');

                await updateIncidentMutation.mutateAsync({
                    id: currentValue.id,
                    set: newValue,
                });

                message.destroy();
                message.success(
                    newValue.status === INCIDENT_STATUSES_KEYS.open
                        ? 'Incident opened successfully.'
                        : newValue.status === INCIDENT_STATUSES_KEYS.closed
                          ? 'Incident closed successfully.'
                          : 'Saved.',
                );

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad() {
            try {
                const data = await trpcClient.incident.getIncident.fetch({ id });

                return { success: true, data };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onError(error) {
            message.destroy();

            if (error instanceof RecordNotFoundError) {
                await errorMessage.showAsync(
                    "The incident you are trying to access either doesn't exist or you don't have permissions to access it.",
                );
                navigate({
                    to: '/console/$orgId/compliance/incidents',
                    params: { orgId },
                });
                return;
            }

            errorMessage.show(error);
        },
    });

    const canEdit = useMemo(() => {
        if (!incident) {
            return false;
        }

        const hasAccess = isOrgAdmin || (incident.ownerId !== null && incident.ownerId === BigInt(userId));
        const isNotClosed = incident.status && !['closed', 'cancelled'].includes(incident.status);

        return hasAccess && isNotClosed;
    }, [incident, userId, isOrgAdmin]);

    // const canReopen = useMemo(() => {
    //     if (!incident) {
    //         return false;
    //     }

    //     return isOrgAdmin || (incident.ownerId != null && incident.ownerId === BigInt(userId));
    // }, [incident, userId, isOrgAdmin]);

    const openIncident = async () => {
        await save({
            status: INCIDENT_STATUSES_KEYS.open,
        });
        trpcUtils.incident.invalidate();
    };

    const closeIncident = async () => {
        if (!incident) {
            return;
        }

        const timelineErrors = await validateTimeline(incident);

        if (timelineErrors.length) {
            errorMessage.show(timelineErrors[0].errors[0]);
            return;
        }

        const requiredFieldsErrorsLocal = validateRequiredFields(incident);

        if (requiredFieldsErrorsLocal.length) {
            setRequiredFieldsErrors(requiredFieldsErrorsLocal);
        }

        if (requiredFieldsErrorsLocal.length || !incident.closureSummary) {
            setOpen(true);
            return;
        }

        await save({
            status: INCIDENT_STATUSES_KEYS.closed,
        });
        trpcUtils.incident.invalidate();
    };

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[toBreadCrumb(navRoutes.compliance_incidents, { orgId }), { title: 'Incident details' }]}
            />

            <PageContent>
                {incident && (
                    <CloseIncidentDrawer
                        open={open}
                        onSave={async (data) => {
                            await save({
                                status: INCIDENT_STATUSES_KEYS.closed,
                                ...data,
                            });
                        }}
                        onClose={() => {
                            setRequiredFieldsErrors([]);
                            setOpen(false);
                        }}
                        incident={incident}
                        errors={requiredFieldsErrors}
                    />
                )}

                {id === incident?.id ? (
                    <Tabs
                        type="line"
                        activeKey={section}
                        tabBarExtraContent={{
                            right: (
                                <>
                                    {incident.status !== INCIDENT_STATUSES_KEYS.closed && (
                                        <Button
                                            loading={validateIncidentBeforeClosingLoading}
                                            type="primary"
                                            onClick={closeIncident}
                                        >
                                            Close incident
                                        </Button>
                                    )}
                                    {incident.status === INCIDENT_STATUSES_KEYS.closed && (
                                        <Button type="primary" onClick={openIncident}>
                                            Open incident
                                        </Button>
                                    )}
                                    <Button
                                        loading={exportIncidentLoading}
                                        type="link"
                                        icon={<DownloadOutlined />}
                                        onClick={() => {
                                            void exportIncident(id, BigInt(orgId));
                                        }}
                                    >
                                        Download incident
                                    </Button>
                                </>
                            ),
                        }}
                        onTabClick={(key) => {
                            navigate({
                                to: '/console/$orgId/compliance/incidents/details/$id/$section',
                                params: {
                                    orgId,
                                    id,
                                    section: key as IncidentDetailsSection,
                                },
                            });
                        }}
                        items={[
                            {
                                key: INCIDENT_DETAILS_SECTIONS.MANAGE,
                                label: 'Manage incident',
                                children: <IncidentDetailsManage disabled={!canEdit} />,
                            },
                            {
                                key: INCIDENT_DETAILS_SECTIONS.LIBRARY,
                                label: 'Library',
                                children: (
                                    <DocumentLibrary
                                        disableUploading={incident.status === INCIDENT_STATUSES_KEYS.closed}
                                        entityId={Number(incident.id)}
                                        entityType="incident"
                                    />
                                ),
                            },
                            {
                                key: INCIDENT_DETAILS_SECTIONS.HISTORY,
                                label: 'History',
                                children: <History idValue={Number(incident.id)} idPropertyName="incidentId" />,
                            },
                        ]}
                    />
                ) : (
                    <Skeleton active />
                )}
            </PageContent>
        </>
    );
}
