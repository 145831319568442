import { InvoicePrintView } from '@/components/Invoice/InvoicePrintView';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { trpc } from '@/trpc';
import { createFileRoute } from '@tanstack/react-router';
import { Spin } from 'antd';

export const Route = createFileRoute('/public/$orgId/invoice/view/$accessKey')({
    component: ViewInvoice,
});

function ViewInvoice() {
    const orgId = useOrgId();
    const { accessKey } = Route.useParams();

    const { data: invoice, isLoading } = trpc.invoice.getInsurerInvoiceByAccesskey.useQuery({
        accessKey,
        orgId: orgId,
    });

    return isLoading || !invoice ? (
        <Spin />
    ) : (
        <InvoicePrintView invoiceData={invoice} open={true} />
    );
}
