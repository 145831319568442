import { useMemo } from 'react';
import { Tabs } from 'antd';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { PageHeader } from '@/components/Common/PageHeader';
import { SupplierSearch } from './SupplierSearch';
import { SupplierDefinitions } from './SupplierDefinitions';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { navRoutes } from 'shared/navigation/navRoutes';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { Tab } from '@/types/antd';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/suppliers/')({
    component: SuppliersConfiguration,
});

function SuppliersConfiguration() {
    const orgId = useOrgId();

    const tabItems = useMemo(
        () =>
            [
                {
                    key: 'list',
                    label: 'Supplier list',
                    children: <SupplierSearch />,
                },
                {
                    key: 'definitions',
                    label: 'Definitions',
                    children: <SupplierDefinitions />,
                },
            ] as Tab[],
        [],
    );

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    toBreadCrumb(navRoutes.configuration_suppliers, { orgId }),
                ]}
            />

            <PageHeader title="Suppliers configuration" />

            <Tabs type="line" defaultActiveKey="list" items={tabItems} />
        </>
    );
}
