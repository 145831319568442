import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { EventEmitterProvider } from '@/components/Providers/EventEmitterProvider';
import { TrpcProvider } from '@/components/Providers/TrpcProvider';
import { ApolloSetup } from '@/graphql';
import { LaunchDarklyClientSetup } from '@/components/Providers/LaunchDarklyProvider';
import { UIProvider } from '@/components/Providers/UIProvider';
import { Auth0Provider } from '@/components/Providers/Auth0Provider';
import { createRootRoute } from '@tanstack/react-router';
import { AppProvider } from '@/components/Providers/AppProvider';

export const Route = createRootRoute({
    component: Root,
});

function Root() {
    return (
        <LaunchDarklyClientSetup>
            <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}>
                <ApolloSetup>
                    <Auth0Provider>
                        <TrpcProvider>
                            <EventEmitterProvider>
                                <UIProvider>
                                    <AppProvider />
                                </UIProvider>
                            </EventEmitterProvider>
                        </TrpcProvider>
                    </Auth0Provider>
                </ApolloSetup>
            </GoogleReCaptchaProvider>
        </LaunchDarklyClientSetup>
    );
}
