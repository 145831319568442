import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { formatAddress } from '@/utils/address';
import { errorMessage } from '@/components/Common/errorMessage';
import { Col, Divider, Row, Skeleton, Typography } from 'antd';
import { useFormatter } from '@/hooks/useFormatter';
import { getPrimaryContactForType } from 'shared/utils/contact';
import { useQuestionnaireService } from '@/services/QuestionnaireService';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { PdsVersionByInsurerIdAndProductTypeId } from '@/graphql/queries/pdsVersion';
import { CardBase } from 'shared/model/Card';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { RouterOutputs } from '@/trpc';
import { createFileRoute } from '@tanstack/react-router';

const { Text, Title } = Typography;

type State = {
  claim?: RouterOutputs['claim']['getClaimByAccessKey'];
};

const LodgementSummary = observer(() => {
  const formatter = useFormatter();
  const { accessKey } = Route.useParams();
  const client = useApolloClient();
  const formService = useQuestionnaireService(client);
  const trpcClient = useTrpcClient();
  const { formatDate, formatDateTime } = useFormatter();
  const [pdsVersionByInsurerIdAndProductTypeId] = useLazyQuery(
    PdsVersionByInsurerIdAndProductTypeId,
  );

  const state = useLocalObservable<State>(() => ({
    claim: undefined,
  }));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const asyncWrapper = async () => {
      try {
        if (!accessKey) {
          throw new Error('Access key not found.');
        }

        setLoading(true);

        state.claim = await trpcClient.claim.getClaimByAccessKey.query({
          accessKey,
        });
        state.claim.phAccountNo = null;

        const { data: pdsVersionRes } =
          await pdsVersionByInsurerIdAndProductTypeId({
            variables: {
              insurerId: state.claim.insurerId,
              productTypeId: state.claim.productTypeId,
            },
            fetchPolicy: 'network-only',
          });

        formService.init(
          state.claim.formData as unknown as CardBase,
          pdsVersionRes?.PdsVersion?.[0].Questionnaire?.definition,
          false,
        );
      } catch (e) {
        errorMessage.show(e);
      } finally {
        setLoading(false);
      }
    };

    void asyncWrapper();
  }, [accessKey]);

  const claim = state.claim;

  const brokerContactInfo = useMemo(() => {
    if (claim) {
      return getPrimaryContactForType(claim.contacts, 'broker');
    }
  }, [claim?.contacts]);

  const policyHolderContactInfo = useMemo(() => {
    if (claim) {
      return getPrimaryContactForType(claim.contacts, 'policy_holder');
    }
  }, [claim?.contacts]);

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {claim && (
            <div className="w-1/2 self-center">
              <Title level={5}>Policy Holder Info</Title>
              <Row gutter={[16, 8]}>
                <DataRow
                  label="Policy Holder"
                  value={
                    (policyHolderContactInfo?.firstName || '') +
                    ' ' +
                    (policyHolderContactInfo?.lastName || '')
                  }
                />
                <DataRow label="Phone" value={policyHolderContactInfo?.phone} />
                <DataRow
                  label="E-mail"
                  value={policyHolderContactInfo?.email}
                />
              </Row>
              <Divider />
              <Title level={5}>Broker Info</Title>
              <Row gutter={[16, 8]}>
                <DataRow label="Broker" value={claim.brokerCompany} />
                <DataRow label="Office" value={brokerContactInfo?.branch} />
                value=
                {(brokerContactInfo?.firstName || '') +
                  ' ' +
                  (brokerContactInfo?.lastName || '')}{' '}
                <DataRow label="Phone" value={brokerContactInfo?.phone} />
              </Row>
              <Divider />
              <Title level={5}>Policy Info</Title>
              <Row gutter={[16, 8]}>
                <DataRow label="Policy Name" value={claim.policyName} />
                <DataRow label="Status" value={claim.policyStatus} />
                <DataRow
                  label="Risk Address"
                  value={formatAddress({
                    addressLine1: policyHolderContactInfo?.addressLine1,
                    addressLine2: policyHolderContactInfo?.addressLine2,
                    suburb: policyHolderContactInfo?.addressSuburb,
                    city: policyHolderContactInfo?.addressCity,
                    postcode: policyHolderContactInfo?.phone,
                    country: policyHolderContactInfo?.addressCountry,
                  }).join(', ')}
                />
                <DataRow
                  label="Policy Start Date"
                  value={formatDateTime(claim.policyTermFrom)}
                />
                <DataRow
                  label="Policy End Date"
                  value={formatDateTime(claim.policyTermTo)}
                />
                <DataRow
                  label="Policy Issued on"
                  value={formatDate(claim.policyIssuedOn)}
                />
              </Row>
              {formService.getLogicalGroup2Labels().map((label) => (
                <div key={label}>
                  <Divider />
                  <Title level={5}>{label}</Title>
                  <Row gutter={[16, 8]}>
                    {formService
                      .getLogicalGroup2Fields(label, 'titleSystem', formatter)
                      .map((fld, idx) => (
                        <DataRow
                          label={fld.label}
                          value={fld.value}
                          key={idx}
                        />
                      ))}
                  </Row>
                </div>
              ))}
              <Divider />
              <Title level={5}>Confirmation</Title>
              <Row gutter={[16, 8]}>
                <DataRow
                  label="The damage claimed is not a result of illegal act"
                  value={
                    claim.jsonData?.claimNotResultOfIllegalAct === true
                      ? 'Yes'
                      : 'No'
                  }
                />
              </Row>
            </div>
          )}
        </>
      )}
    </>
  );
});

interface DataRowProps {
  label: React.ReactNode | string;
  value: React.ReactNode | string;
  emptyValue?: string;
  showIfEmpty?: boolean;
}

const DataRow = observer(
  ({ label, value, emptyValue = '-', showIfEmpty = false }: DataRowProps) => {
    let isEmpty = false;
    if (typeof value === 'string' && value.trim() === '') {
      isEmpty = true;
    } else if (!value) {
      isEmpty = true;
    }

    return (
      <>
        {!isEmpty || showIfEmpty ? (
          <>
            <Col span={12}>
              <Text type="secondary">{label}</Text>
            </Col>
            <Col span={12}>
              <div className="text-right">
                <Text>{value || emptyValue}</Text>
              </div>
            </Col>
          </>
        ) : (
          <></>
        )}
      </>
    );
  },
);

export const Route = createFileRoute('/public/lodgement-summary/$accessKey')({
  component: LodgementSummary,
});
