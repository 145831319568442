import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { Ref, useEffect, useImperativeHandle, useRef } from 'react';
import { Button, Col, ColProps, Row, Tooltip } from 'antd';
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import './index.sass';
import { errorMessage } from '../errorMessage';
import { textToHtml } from '../../../utils/text';

type ValidationRule = () => Promise<void>;

export interface IFormField {
    stopEditing: () => void;
}

interface Props {
    value?: React.ReactNode;
    emptyValue?: string;
    label?: React.ReactNode | string;
    allowEdit?: boolean;
    editControl?: React.ReactNode;
    onSave?: () => void;
    onCancel?: () => void;
    showTooltip?: boolean;
    autoTooltip?: boolean;
    noWrap?: boolean;
    disableFormatting?: boolean;
    className?: string;
    rules?: ValidationRule[];
    disableEditingButton?: boolean;
    onStartEditing?: () => void;
    setHtml?: boolean;
    labelCol?: ColProps;
    wrapperCol?: ColProps;
    layout?: 'horizontal' | 'vertical';
    hideSaveButton?: boolean;
    hideCancelButton?: boolean;
    size?: 'normal' | 'large';
}

export const FormField = observer(
    React.forwardRef(
        (
            {
                value,
                emptyValue,
                label,
                allowEdit,
                editControl,
                onSave,
                onCancel,
                showTooltip = false,
                autoTooltip = true,
                noWrap = true,
                disableFormatting = false,
                className,
                rules,
                disableEditingButton,
                onStartEditing,
                setHtml = false,
                labelCol = { span: 8 },
                wrapperCol = { span: 16 },
                layout = 'vertical',
                hideCancelButton = false,
                hideSaveButton = false,
                size = 'normal',
            }: Props,
            ref: Ref<IFormField>,
        ) => {
            const state = useLocalObservable<{ isEditing: boolean; isTruncated: boolean }>(() => ({
                isEditing: false,
                isTruncated: false,
            }));
            const emptyValueProp = emptyValue || '-';
            const allowEditProp = allowEdit ?? false;
            const valueRef = useRef<HTMLSpanElement>(null);

            useImperativeHandle<IFormField, IFormField>(ref, () => ({
                stopEditing: () => {
                    state.isEditing = false;
                    onCancel?.();
                },
            }));

            useEffect(() => {
                const intId = setInterval(() => {
                    if (valueRef.current) {
                        state.isTruncated =
                            valueRef.current.scrollWidth > valueRef.current.clientWidth;
                    }
                }, 1000);
                return () => {
                    clearInterval(intId);
                };
            }, []);

            const validate = async () => {
                try {
                    if (rules && rules.length > 0) {
                        for (const rule of rules) {
                            await rule();
                        }
                    }
                    return true;
                } catch (e) {
                    errorMessage.show(e);
                    return false;
                }
            };

            const labelControl = () => (
                <>
                    {label && (
                        <div className="crm-form-field__label-wrapper flex items-center">
                            {typeof label === 'string' ? (
                                <div
                                    className={clsx(
                                        'crm-form-field__label text-gray-500',
                                        size === 'large' && 'crm-form-field__label--large',
                                    )}
                                    dangerouslySetInnerHTML={{ __html: textToHtml(label) }}
                                />
                            ) : (
                                <div
                                    className={clsx(
                                        'crm-form-field__label',
                                        size === 'large' && 'crm-form-field__label--large',
                                    )}
                                >
                                    {label}
                                </div>
                            )}
                            {allowEditProp && (
                                <div className="self-start">
                                    {state.isEditing ? (
                                        <div className="flex">
                                            {!hideSaveButton && (
                                                <Button
                                                    size="small"
                                                    className="pr-0 transition-none"
                                                    type="link"
                                                    title="Save"
                                                    onClick={async (e) => {
                                                        const isValid = await validate();
                                                        if (!isValid) return;
                                                        e.preventDefault();
                                                        state.isEditing = false;
                                                        onSave?.();
                                                    }}
                                                >
                                                    <SaveOutlined />
                                                </Button>
                                            )}
                                            {!hideCancelButton && (
                                                <Button
                                                    size="small"
                                                    className="pl-1 transition-none"
                                                    type="link"
                                                    title="Cancel"
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        state.isEditing = false;
                                                        onCancel?.();
                                                    }}
                                                >
                                                    <CloseOutlined />
                                                </Button>
                                            )}
                                        </div>
                                    ) : (
                                        <Button
                                            size="small"
                                            type="link"
                                            title="Edit"
                                            className="self-start transition-none"
                                            disabled={!!disableEditingButton}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                state.isEditing = true;
                                                setTimeout(() => {
                                                    onStartEditing?.();
                                                }, 5);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </>
            );

            const valueControl = () => {
                return (
                    <>
                        {state.isEditing ? (
                            <div className="flex items-center transition-none">
                                {editControl || <></>}
                            </div>
                        ) : (
                            <div
                                className={clsx(
                                    !disableFormatting &&
                                        'flex items-center overflow-hidden overflow-ellipsis font-bold transition-none',
                                    size === 'large' && 'crm-form-field__value--large',
                                )}
                            >
                                {setHtml ? (
                                    <span
                                        ref={valueRef}
                                        className={clsx(
                                            !disableFormatting &&
                                                clsx(
                                                    'overflow-hidden overflow-ellipsis',
                                                    noWrap && 'whitespace-nowrap',
                                                    size === 'large' &&
                                                        'crm-form-field__value--large',
                                                ),
                                        )}
                                        {...(setHtml && {
                                            dangerouslySetInnerHTML: {
                                                __html: textToHtml(
                                                    (value as string) || emptyValueProp,
                                                ),
                                            },
                                        })}
                                    />
                                ) : (
                                    <span
                                        ref={valueRef}
                                        className={clsx(
                                            !disableFormatting &&
                                                clsx(
                                                    'overflow-hidden overflow-ellipsis',
                                                    noWrap && 'whitespace-nowrap',
                                                    size === 'large' &&
                                                        'crm-form-field__value--large',
                                                ),
                                        )}
                                    >
                                        {value || emptyValueProp}
                                    </span>
                                )}
                            </div>
                        )}
                    </>
                );
            };

            return (
                <Tooltip
                    title={
                        showTooltip || (autoTooltip && state.isTruncated && !state.isEditing) ? (
                            setHtml ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: textToHtml(value as string),
                                    }}
                                />
                            ) : (
                                value
                            )
                        ) : (
                            ''
                        )
                    }
                    placement="topLeft"
                    //color="white"
                    classNames={{
                        root: 'crm-form-field__tooltip-overlay',
                    }}
                >
                    <div className={className}>
                        {layout === 'vertical' ? (
                            <>
                                {labelControl()}
                                {valueControl()}
                            </>
                        ) : (
                            <>
                                <Row className="flex items-center">
                                    <Col {...labelCol}>{labelControl()}</Col>
                                    <Col {...wrapperCol}>{valueControl()}</Col>
                                </Row>
                            </>
                        )}
                    </div>
                </Tooltip>
            );
        },
    ),
);
