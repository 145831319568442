import { errorMessage } from '@/components/Common/errorMessage';
import { Link } from '@/components/Common/Link';
import { PageHeader } from '@/components/Common/PageHeader';
import { PageContent } from '@/components/Layout/PageContent';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { useDeleteConfirmation } from '@/hooks/UI/useDeleteConfirmation';
import { useNavigate } from '@/hooks/useNavigate';
import { useTrpc } from '@/hooks/useTrpc';
import { RouterInputs, RouterOutputs, trpc } from '@/trpc';
import { TableColumnType } from '@/types/common';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Input, message, Space, Table } from 'antd';
import { useState } from 'react';

export const Route = createFileRoute('/admin-console/advanced/email-domains')({
    component: EmailDomains,
});

type EmailDomain = RouterOutputs['admin']['emailDomain']['listEmailDomains']['rows'][number];
type QueryInput = RouterInputs['admin']['emailDomain']['listEmailDomains'];

function EmailDomains() {
    const navigate = useNavigate();
    const { trpcClient, trpcUtils } = useTrpc();
    const deleteConfirmation = useDeleteConfirmation<{ id: number }>({
        entityDisplayName: 'email domain',
        onConfirm: async (domain, { id }) => {
            try {
                message.info('Deleting email domain...');
                await trpcClient.admin.emailDomain.deleteEmailDomain.mutate({
                    id,
                    domain,
                });
                trpcUtils.invalidate();
                message.destroy();
                message.success('Email domain deleted successfully');
            } catch (error) {
                message.destroy();
                errorMessage.show(error);
            }
        },
    });

    const [queryInput, setQueryInput] = useState<QueryInput>({});
    const { data, isLoading } = trpc.admin.emailDomain.listEmailDomains.useQuery(queryInput);

    const columns: TableColumnType<EmailDomain>[] = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: true,
            render: (value) => value.toString(),
        },
        {
            title: 'Domain',
            dataIndex: 'domain',
            sorter: true,
        },
        {
            title: 'Action',
            fixed: 'right',
            width: 150,
            render: (_, record) => (
                <Space>
                    <Link
                        type="link"
                        to="/admin-console/advanced/email-domains/$id/edit"
                        params={{ id: Number(record.id) }}
                        icon={<EditOutlined />}
                    >
                        Edit
                    </Link>
                    <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            deleteConfirmation.showConfirmationModal(record.domain, {
                                id: Number(record.id),
                            });
                        }}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    const { tableProps } = useAntdTable<EmailDomain>({
        columns,
        data: {
            rows: data?.rows,
            total: data?.total,
            loading: isLoading,
        },
        paginationConfig: {
            pageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        },
        onQueryVariableChange: (options) => {
            setQueryInput({ ...options, offset: 0 });
        },
    });

    return (
        <>
            {deleteConfirmation.deleteConfirmationModal}

            <PageHeader title="Email Domains" subTitle="List of email domains." />
            <Space className="mb-4 flex justify-between">
                <Input.Search
                    placeholder="Search domains"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={(value) => {
                        if (value) {
                            setQueryInput({
                                ...queryInput,
                                where: {
                                    domain: { contains: value, mode: 'insensitive' },
                                },
                            });
                        } else {
                            setQueryInput({
                                ...queryInput,
                                where: undefined,
                            });
                        }
                    }}
                />
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        navigate({ to: '/admin-console/advanced/email-domains/add' });
                    }}
                >
                    New domain
                </Button>
            </Space>
            <PageContent>
                <Table {...tableProps} rowKey="id" size="small" />
            </PageContent>
        </>
    );
}
