// TODO: Deprecated, use New complaint by org key instead
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, message, Result, Row, Select, Space } from 'antd';
import { errorMessage } from '@/components/Common/errorMessage';
import { useLocalStore_depr } from '@/hooks/useLocalStore_depr';
import { ComplaintByPkQuery } from '@/__generated__/graphql';
import './index.sass';
import { SelectWithOther } from '@/components/Common/SelectWithOther';
import { useSafePath } from '@/hooks/useSafePath';
import {
    AGES,
    CHANNELS,
    COMPLAINANT_SOLUTIONS,
    COMPLAINANT_TYPES,
    GENDERS,
    RELATIONSHIPS,
} from 'shared/constants/complaint';
import { ComplaintIssueSelect } from '@/components/Complaint/ComplaintIssueSelect';
import { useMutation } from '@apollo/client';
import { gql } from '@/__generated__/gql';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@/hooks/useNavigate';
import { requiredFieldRule } from '@/utils/form';
import { COMPLAINANT_TYPE } from 'shared/types/complaint';

type Complaint = NonNullable<ComplaintByPkQuery['ComplaintByPk']>;

const NewComplaint = observer(() => {
    const { orgId } = Route.useParams();
    const [complaint, initStore, toJSON] = useLocalStore_depr<Complaint>();
    const [insertComplaint] = useMutation(InsertComplaintPublic);
    const navigate = useNavigate();

    const [saving, setSaving] = useState(false);
    const [completed, setCompleted] = useState(false);

    const [form] = Form.useForm();
    const safePath = useSafePath<Complaint>();
    const complainantType = Form.useWatch('complainantType', form);

    useEffect(() => {
        try {
            initStore({ orgId });
            form.setFieldsValue(complaint);
        } catch (e) {
            errorMessage.show(e);
        }
    }, []);

    const save = async () => {
        try {
            setSaving(true);
            message.info('Submitting...');
            await insertComplaint({
                variables: {
                    object: toJSON('CreatedBy', 'Owner', 'Indicators', 'ExternalOrgs'),
                },
            });
            message.destroy();
            setCompleted(true);
        } catch (e) {
            errorMessage.show(e);
        } finally {
            setSaving(false);
        }
    };

    return (
        <div className="m-4">
            {!completed ? (
                <Form
                    form={form}
                    layout="horizontal"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={save}
                    onFinishFailed={(errorInfo) => {
                        form.scrollToField(errorInfo.errorFields[0].name);
                    }}
                    className="crm-new-complaint"
                >
                    <Row justify="center">
                        <Col sm={24} lg={14}>
                            <Card bordered>
                                <Divider orientation="left">Complainant</Divider>
                                <Form.Item
                                    label="What is your name?"
                                    required
                                    name={safePath('complainantFirstName')}
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (!complaint.complainantFirstName)
                                                    throw new Error('Field is required');
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        autoFocus
                                        value={complaint.complainantFirstName || ''}
                                        onChange={(e) =>
                                            (complaint.complainantFirstName = e.target.value)
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="What is your surname?"
                                    required
                                    name={safePath('complainantLastName')}
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (!complaint.complainantLastName)
                                                    throw new Error('Field is required');
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        value={complaint.complainantLastName || ''}
                                        onChange={(e) =>
                                            (complaint.complainantLastName = e.target.value)
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="What is your relationship to the insured person?"
                                    required
                                    name={safePath('relationship')}
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (!complaint.relationship)
                                                    throw new Error('Field is required');
                                            },
                                        },
                                    ]}
                                    validateTrigger="onValidate"
                                >
                                    <SelectWithOther
                                        value={complaint.relationship || ''}
                                        onChange={(value) => (complaint.relationship = value)}
                                        options={RELATIONSHIPS.map((rel) => ({ value: rel! }))}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Do you represent a business or are you an individual/couple?"
                                    name={safePath('complainantType')}
                                >
                                    <Select
                                        value={complaint.complainantType || ''}
                                        onChange={(value) => (complaint.complainantType = value)}
                                    >
                                        {COMPLAINANT_TYPES.map((type) => (
                                            <Select.Option value={type.label} key={type.label}>
                                                {type.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="What is your gender"
                                    name={safePath('complainantGender')}
                                    rules={
                                        complainantType === COMPLAINANT_TYPE.INDIVIDUAL
                                            ? requiredFieldRule
                                            : []
                                    }
                                    required={complainantType === COMPLAINANT_TYPE.INDIVIDUAL}
                                >
                                    <Select
                                        value={complaint.complainantGender || ''}
                                        onChange={(value) => (complaint.complainantGender = value)}
                                    >
                                        {GENDERS.map((gender) => (
                                            <Select.Option value={gender.label} key={gender.label}>
                                                {gender.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="How old are you?"
                                    name={safePath('complainantAge')}
                                >
                                    <Select
                                        value={complaint.complainantAge || ''}
                                        onChange={(value) => (complaint.complainantAge = value)}
                                    >
                                        {AGES.map((age) => (
                                            <Select.Option value={age.label} key={age.label}>
                                                {age.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="What is your postcode?"
                                    name={safePath('complainantPostcode')}
                                >
                                    <Input
                                        value={complaint.complainantPostcode || ''}
                                        onChange={(e) =>
                                            (complaint.complainantPostcode = e.target.value)
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="How are you/have you submitted complaint?"
                                    name={safePath('channel')}
                                    required
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (!complaint.channel)
                                                    throw new Error('Field is required');
                                            },
                                        },
                                    ]}
                                >
                                    <Select
                                        value={complaint.channel || ''}
                                        onChange={(value) => (complaint.channel = value)}
                                    >
                                        {CHANNELS.map((channel) => (
                                            <Select.Option
                                                value={channel.label}
                                                key={channel.label}
                                            >
                                                {channel.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="What is your preferred channel of communication?"
                                    name={safePath('preferredChannel')}
                                    required
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (!complaint.preferredChannel)
                                                    throw new Error('Field is required');
                                            },
                                        },
                                    ]}
                                >
                                    <Select
                                        value={complaint.preferredChannel || ''}
                                        onChange={(value) => (complaint.preferredChannel = value)}
                                    >
                                        {CHANNELS.map((channel) => (
                                            <Select.Option
                                                value={channel.label}
                                                key={channel.label}
                                            >
                                                {channel.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={`What is the best way to contact you?`}
                                    required
                                    name={safePath('preferredChannelValue')}
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (!complaint.preferredChannelValue)
                                                    throw new Error('Field is required');
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        value={complaint.preferredChannelValue || ''}
                                        onChange={(e) =>
                                            (complaint.preferredChannelValue = e.target.value)
                                        }
                                    />
                                </Form.Item>
                                <Divider orientation="left" className="mt-8">
                                    Nature of complaint
                                </Divider>
                                <Form.Item
                                    label="What is the issue you are complaining about?"
                                    required
                                    name={safePath('issue')}
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (!complaint.issue)
                                                    throw new Error('Field is required');
                                            },
                                        },
                                    ]}
                                    validateTrigger={['onChange']}
                                >
                                    <ComplaintIssueSelect
                                        value={complaint.issues}
                                        onChange={(value) => {
                                            complaint.issues = value;
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Tell us about what happened"
                                    name={safePath('incidentDescription')}
                                >
                                    <Input.TextArea
                                        rows={4}
                                        value={complaint.incidentDescription || ''}
                                        onChange={(e) => {
                                            complaint.incidentDescription = e.target.value;
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="What kind of solution would you be satisfied with?"
                                    name={safePath('complainantSolution')}
                                >
                                    <SelectWithOther
                                        value={complaint.complainantSolution || ''}
                                        onChange={(value) =>
                                            (complaint.complainantSolution = value)
                                        }
                                        options={COMPLAINANT_SOLUTIONS.map((rel) => ({
                                            value: rel!,
                                        }))}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="If your complaint relates to a policy you hold, what is the policy number?"
                                    name={safePath('policyNo')}
                                >
                                    <Input
                                        value={complaint.policyNo || ''}
                                        onChange={(e) => (complaint.policyNo = e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="If your complaint relates to a claim you have, what is the claim number?"
                                    name={safePath('externalClaimId')}
                                >
                                    <Input
                                        value={complaint.externalClaimId || ''}
                                        onChange={(e) =>
                                            (complaint.externalClaimId = e.target.value)
                                        }
                                    />
                                </Form.Item>
                            </Card>
                            <Space className="mt-2 flex justify-end">
                                <Button onClick={() => navigate({ moveInHistory: 'forward' })}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit" loading={saving}>
                                    Save
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            ) : (
                <Result status="success" title="Thank you for submitting your complaint!" />
            )}
        </div>
    );
});

const InsertComplaintPublic = gql(/* GraphQL */ `
    mutation InsertComplaintPublic($object: ComplaintInsertInput = {}) {
        insertComplaintOne(object: $object) {
            id
        }
    }
`);

export const Route = createFileRoute('/public/new-complaint/$orgId')({
    component: NewComplaint,
});
