import { useQuestionnaireContext } from '@/components/Claim/Questionnaire/QuestionnaireContext';
import { errorMessage } from '@/components/Common/errorMessage';
import { mobxToJson } from '@/global/mobx';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useCurrentUserId } from '@/hooks/User/useCurrentUserId';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { CustomRequest } from '@/types/antd';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import axios from 'axios';
import mime from 'mime';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { Ref, useEffect, useImperativeHandle } from 'react';
import { v4 as uuid } from 'uuid';
import { S3File } from 'shared/types/S3File';
import './index.sass';

const { Dragger } = Upload;

type Props = {
    multiple?: boolean;
    onChange?: (files: S3File[]) => void;
    value?: S3File[];
    fileCategories?: string[];
    onUploadStart?: () => void;
    onUploadEnd?: () => void;
    onValidate?: () => void;
};

interface FileExt extends File {
    lastModified: number;
    lastModifiedData: Date;
    name: string;
    size: number;
    type: string;
    uid: string;
}

interface UploadFileExt extends UploadFile {
    isDeleted?: boolean;
}

type State = {
    value: S3File[];
    fileList: UploadFileExt[];
};

export interface IUploadControl {
    clearFileList: () => void;
}

export const QuestionnaireUploadControl = observer(
    React.forwardRef(
        (
            { multiple = false, value, onChange, fileCategories, onUploadEnd, onUploadStart, onValidate }: Props,
            ref: Ref<IUploadControl>,
        ) => {
            const state = useLocalObservable<State>(() => ({ fileList: [], value: [] }));
            const { isPublic } = useQuestionnaireContext();
            const trpcClient = useTrpcClient();
            const orgId = useOrgId();

            useImperativeHandle<IUploadControl, any>(ref, () => ({
                clearFileList: () => {
                    state.value = [];
                    state.fileList = [];
                },
            }));

            const userId = useCurrentUserId();

            useEffect(() => {
                state.fileList =
                    value?.map((s3file) => ({
                        name: s3file.name,
                        uid: s3file.id,
                        isDeleted: s3file.isDeleted,
                    })) || [];
                state.value = value || [];
            }, [value]);

            const customRequest: CustomRequest = async (options) => {
                const { onSuccess, onError, onProgress } = options;

                try {
                    onUploadStart?.();

                    const file = options.file as FileExt;
                    file.uid = uuid();
                    const uploadFile: UploadFile = file;
                    uploadFile.status = 'uploading';
                    uploadFile.percent = 1;

                    state.fileList = [...state.fileList, uploadFile];

                    const { url, fields } = await trpcClient.file.getUploadPresignedUrl.mutate(
                        {
                            bucketType: 'private',
                            filename: file.name,
                            orgId,
                        },
                        {
                            ...(isPublic ? { context: { reCaptcha: true } } : {}),
                        },
                    );

                    if (!url) {
                        throw new Error('No presigned url was returned');
                    }

                    const formData = new FormData();
                    Object.keys(fields).forEach((key) => {
                        formData.append(key, fields[key]);
                    });
                    formData.append('file', file);

                    await axios.post(url, formData, {
                        onUploadProgress: (event) => {
                            const percent = Math.floor((event.loaded / (event.total || 1)) * 100);
                            uploadFile.percent = percent;
                            onProgress?.({ percent: percent });
                        },
                    });

                    const s3File: S3File = {
                        id: file.uid,
                        key: fields['key'] as unknown as string,
                        name: file.name,
                        mimeType: mime.getType(file.name) || '',
                        categories: fileCategories || ['lodged'],
                        createdAt: new Date().toISOString(),
                        uploadedById: Number(userId),
                        size: file.size,
                        isDeleted: false,
                    };

                    onSuccess?.('ok');

                    state.value = [...state.value, s3File];

                    onChange?.(mobxToJson(state.value));
                } catch (e) {
                    errorMessage.show(e);
                    const err = new Error('File upload error');
                    onError?.(err);
                } finally {
                    if (state.fileList.every((item) => item.status !== 'uploading')) {
                        onUploadEnd?.();
                    }
                    setTimeout(() => {
                        onValidate?.();
                    }, 100);
                }
            };

            return (
                <>
                    <div className="crm-upload-control">
                        <Dragger
                            name="file"
                            multiple={multiple}
                            onRemove={(file) => {
                                state.fileList = state.fileList.map((item) => {
                                    if (item.uid === file.uid) {
                                        return { ...item, isDeleted: true };
                                    }
                                    return item;
                                });
                                state.value = state.value.map((item) => {
                                    if (item.id === file.uid) {
                                        return { ...item, isDeleted: true };
                                    }
                                    return item;
                                });
                                onChange?.(mobxToJson(state.value));
                            }}
                            onChange={({ file }) => {
                                const { status } = file;
                                state.fileList = state.fileList.map((item: UploadFile): UploadFile => {
                                    if (item.uid === file.uid) {
                                        return file;
                                    }
                                    return item;
                                });
                                if (status === 'done') {
                                    void message.success(`${file.name} file uploaded successfully!`);
                                }
                                if (status === 'error') {
                                    void message.error(`${file.name} file upload failed.`);
                                }
                            }}
                            customRequest={customRequest}
                            progress={{ showInfo: true }}
                            fileList={state.fileList.filter((item) => !item.isDeleted && item.status !== 'removed')}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                        </Dragger>
                    </div>
                </>
            );
        },
    ),
);
