import { Tabs } from 'antd';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { navRoutes } from 'shared/navigation/navRoutes';
import { PaymentUsers } from './PaymentUsers';
import { PaymentProcesses } from './PaymentProcesses';
import { PageHeader } from '@/components/Common/PageHeader';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/payments')({
    staticData: {
        title: 'Payments',
        modules: ['claim'],
        userFlags: ['payment_admin'],
    },
    component: ConfigurationPayments,
});

function ConfigurationPayments() {
    const orgId = useOrgId();

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    toBreadCrumb(navRoutes.configuration_payments, { orgId }),
                ]}
            />

            <PageHeader title="Payments configuration" />

            <Tabs
                type="line"
                defaultActiveKey="processes"
                items={[
                    {
                        key: 'processes',
                        label: 'Payment processes',
                        children: <PaymentProcesses />,
                    },
                    {
                        key: 'users',
                        label: 'Payment users',
                        children: <PaymentUsers />,
                    },
                ]}
            ></Tabs>
        </>
    );
}
