import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DEBUG_MODE } from '@/config/debug';
import { Button, Input, Modal, Typography } from 'antd';
import { CardMultiSelect } from 'shared/model/Card';
import { CardChildOption } from 'shared/model/CardChild';

interface Props {
    card: CardMultiSelect;
    child: CardChildOption;
    proceedToNextCard: () => void;
    readOnly: boolean;
}

export const ChildMultiSelect = observer(({ card, child, proceedToNextCard, readOnly }: Props) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleClick = () => {
        if (!readOnly) {
            child.isSelected = !child.isSelected;
        }

        if (child.showAsOther) {
            setIsDialogOpen(true);
        }
    };

    const handleCloseDlg = () => {
        setIsDialogOpen(false);
        if (!readOnly) {
            child.isSelected = false;
        }
    };

    const handleContinueDlg = () => {
        setIsDialogOpen(false);
        if (!readOnly) {
            card.children?.forEach((c) => {
                c.isSelected = false;
            });
            child.isSelected = true;
        }
    };

    if (child.type !== 'OPTION') {
        return <></>;
    }

    return (
        <>
            <Button
                type={child.isSelected ? 'primary' : 'default'}
                onClick={handleClick}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        proceedToNextCard();
                        e.preventDefault();
                    }
                }}
                className="crm-questionnaire-select-button"
            >
                {child.title}
                {DEBUG_MODE && <div>/ ID: {child.id}</div>}
                {DEBUG_MODE && <div>/ NQ: {child.nextCardId}</div>}
                {DEBUG_MODE && <div>/ AQ: {child.additionalCardId}</div>}
            </Button>
            <Modal
                open={isDialogOpen}
                title={child.title}
                cancelText="Cancel"
                okText="Continue"
                onCancel={handleCloseDlg}
                onOk={handleContinueDlg}
            >
                {child.description && (
                    <div className="mb-4">
                        <Typography.Text type="secondary">{child.description}</Typography.Text>
                    </div>
                )}
                <Input.TextArea
                    rows={6}
                    value={child.value || ''}
                    readOnly={readOnly}
                    onChange={(e) => {
                        child.value = e.target.value;
                    }}
                />
            </Modal>
        </>
    );
});
