import { ModalStaticFunctions } from 'antd/es/modal/confirm';
import { ModalFuncProps } from 'antd';

class Modal {
    modalApi: Omit<ModalStaticFunctions, 'warn'>;
    init(modalApi: Omit<ModalStaticFunctions, 'warn'>) {
        this.modalApi = modalApi;
    }

    confirm(options: ModalFuncProps) {
        return this.modalApi.confirm(options);
    }

    info(options: ModalFuncProps) {
        return this.modalApi.info(options);
    }

    warning(options: ModalFuncProps) {
        return this.modalApi.warning(options);
    }

    error(options: ModalFuncProps) {
        return this.modalApi.error(options);
    }
}

const modal = new Modal();

export { modal };
