import { selectOrg, useAuthStore } from '@/stores/AuthStore';
import { useOrgSettingsStore } from '@/stores/OrgSettingStore';
import { matchByPath } from '@tanstack/react-router';
import { SYSTEM_ORG_ID } from 'shared/constants/org';

/**
 * Returns the orgId from the URL or the auth store. If the orgId is not found, it returns -999.
 * Even if the orgId is returned, it does not guarantee that the user is authorized to access the org.
 *
 * @returns {number} - The orgId
 *
 **/
export function useOrgId(): bigint {
    const org = useAuthStore(selectOrg);
    const orgSettingsStore = useOrgSettingsStore();

    if (org) {
        return org.id;
    }

    const consoleParams = matchByPath('/', location.pathname || '', { to: '/console/$orgId' });

    if (consoleParams?.orgId) {
        return BigInt(consoleParams.orgId);
    }

    if (location.pathname.toLowerCase().startsWith('/admin-console')) {
        return SYSTEM_ORG_ID.SYSTEM_CONSOLE;
    }

    const publicOrgParams = matchByPath('/', location.pathname || '', { to: '/public/$orgId' });

    if (publicOrgParams?.orgId) {
        return BigInt(publicOrgParams?.orgId);
    }

    if (orgSettingsStore.orgSetting?.orgId) {
        return BigInt(orgSettingsStore.orgSetting.orgId);
    }

    return SYSTEM_ORG_ID.NOT_APPLICABLE;
}
