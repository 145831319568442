import { PageHeader } from '@/components/Common/PageHeader';
import { Col, Row } from 'antd';
import { ActiveUsers } from './ActiveUsers';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/reports')({
    staticData: {
        title: 'Reports',
    },
    component: Reports,
});

function Reports() {
    const spanConfig = {
        xs: 24,
        sm: 12,
        md: 8,
        lg: 6,
    };

    return (
        <>
            <PageHeader title="Reports" removeTopMargin />
            <Row gutter={[16, 16]}>
                {/* Users Statistics */}
                <Col {...spanConfig}>
                    <ActiveUsers />
                </Col>
            </Row>
        </>
    );
}
