import { format } from 'date-fns';
import { AsYouType, CountryCode } from 'libphonenumber-js';
import { DateTime } from 'luxon';

type LuxonFormatToken =
    | 'yyyy'
    | 'MM'
    | 'dd'
    | 'HH'
    | 'mm'
    | 'ss'
    | 'dd MMM'
    | 'yyyy-MM-dd'
    | 'HH:mm:ss'
    | 'yyyy-MM-dd HH:mm:ss'
    | 'dd/MM/yyyy HH:mm'
    | 'dd/MM/yyyy'
    | 'dd/MM/yyyy HH:mm:ss'
    | 'dd/MM/yyyy hh:mm a'
    | 'h:mm a'
    | 'date-time'
    | 'date-short'
    | 'time';

const FORMAT_MAPPING: Partial<Record<LuxonFormatToken, string>> = {
    'date-time': 'dd/MM/yyyy HH:mm:ss',
    'date-short': 'dd/MM/yyyy',
    time: 'HH:mm a',
} as const;

const LUXON_FORMAT_TOKENS = {
    'dd MMM': 'dd MMM',
    'yyyy-MM-dd': 'yyyy-MM-dd',
    'HH:mm:ss': 'HH:mm:ss',
    'yyyy-MM-dd HH:mm:ss': 'yyyy-MM-dd HH:mm:ss',
    'dd/MM/yyyy HH:mm': 'dd/MM/yyyy HH:mm',
    'dd/MM/yyyy': 'dd/MM/yyyy',
    'dd/MM/yyyy HH:mm:ss': 'dd/MM/yyyy HH:mm:ss',
    'dd/MM/yyyy hh:mm a': 'dd/MM/yyyy hh:mm a',
    'h:mm a': 'h:mm a',
} as const;

export function useFormatter(
    defaultOpts: { maximumFractionDigits: number } = { maximumFractionDigits: 2 },
) {
    //TODO: Later we'll load formatting configuration from the client settings

    return {
        fDateShortDayJs: 'DD/MM/YYYY',
        fDataShortLxn: 'dd/MM/yyyy',
        fDateTimeDayJs: 'DD/MM/YYYY h:mm A',
        fDateTimeLxn: 'dd/MM/yyyy HH:mm:ss',
        fTimeDayJs: 'h:mm A',
        fTimeLxn: 'HH:mm a',
        lxnDateTimeFormats: LUXON_FORMAT_TOKENS,

        formatCurrency(value: number, options?: { maximumFractionDigits: number }) {
            return Intl.NumberFormat('en-AU', {
                style: 'currency',
                currency: 'AUD',
                maximumFractionDigits:
                    options?.maximumFractionDigits || defaultOpts.maximumFractionDigits,
            }).format(value);
        },

        /**
         * Format ISO date time to a string
         * @param isoDt
         * @param dtFormat Optional date time format, default is dd/MM/yyyy HH:mm, Luxon format
         */
        formatISODateTime(isoDt: string | undefined | null, dtFormat?: LuxonFormatToken) {
            return isoDt ? DateTime.fromISO(isoDt).toFormat(dtFormat || 'dd/MM/yyyy HH:mm') : '';
        },

        formatDateTime(dt: Date | undefined | null, dtFormat?: LuxonFormatToken) {
            return dt ? DateTime.fromJSDate(dt).toFormat(dtFormat || 'dd/MM/yyyy HH:mm') : '';
        },

        /**
         * Format ISO time to a string
         * @param isoDt
         * @param dtFormat Optional date time format, default is HH:mm, Luxon format
         */
        formatISOTime(isoDt: string | undefined | null, dtFormat?: LuxonFormatToken) {
            return isoDt ? DateTime.fromISO(isoDt).toFormat(dtFormat || 'HH:mm') : '';
        },

        /**
         * Format ISO date to a string
         * @param isoDt
         * @param dtFormat Optional date time format, default is dd/MM/yyyy, Luxon format
         */
        formatISODate(isoDt: string | undefined | null, dtFormat?: LuxonFormatToken) {
            return isoDt ? DateTime.fromISO(isoDt).toFormat(dtFormat || 'dd/MM/yyyy') : '';
        },

        /**
         * Format JS date to a string
         * @param dt
         * @param dtFormat Optional date time format, default is dd/MM/yyyy, Luxon format
         */
        formatDate(dt: Date | undefined | null, dtFormat?: LuxonFormatToken) {
            return dt
                ? DateTime.fromJSDate(dt).toFormat(
                      dtFormat ? FORMAT_MAPPING[dtFormat] || dtFormat : 'dd/MM/yyyy',
                  )
                : '';
        },

        formatDateToRelative(dt: Date | undefined | null) {
            if (!dt) {
                return '';
            }

            const formattedDate = DateTime.fromJSDate(dt);
            const { months, years } = formattedDate.diffNow();

            if (months < 1) {
                return formattedDate.toRelative({ locale: 'en' });
            }

            if (years < 1) {
                return this.formatDate(dt, 'dd MMM');
            }

            return this.formatDate(dt);
        },

        numberToMonth(month: number, monthFormat: string = 'MMMM') {
            return format(new Date(2000, month, 1), monthFormat);
        },

        formatPhoneNumber(
            phoneNumber: string | undefined | null,
            defaultCountryCode?: CountryCode,
        ) {
            if (!phoneNumber) {
                return '';
            }

            const asYouType = new AsYouType(defaultCountryCode);
            return asYouType.input(phoneNumber);
        },
    };
}

export type Formatter = ReturnType<typeof useFormatter>;
