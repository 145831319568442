import { App, Card, Col, Row, Space, Tag } from 'antd';
import { ManageFlags } from './ManageFlags';
import { ManageTimeline } from './ManageTimeline';
import { ManageNotes } from './ManageNotes';
import { useFormatter } from '@/hooks/useFormatter';
import { useConfigServiceLoader } from '@/hooks/Configuration/useConfigServiceLoader';
import {
    AGES,
    CHANNELS,
    COMPLAINANT_SOLUTIONS,
    COMPLAINANT_TYPES,
    GENDERS,
    OUTCOMES,
    PRODUCTS,
    RELATED_TO_AFS_LICENSEE,
    RELATIONSHIPS,
} from 'shared/constants/complaint';
import { TaskList } from '@/components/Task/TaskList';
import { complaintFormStore } from '../store';
import invariant from 'tiny-invariant';
import { VReadOnly } from '@/components/Common/ViewForm/VReadOnly';
import { VUserSelector } from '@/components/Common/ViewForm/VUserSelector';
import { VExternalOrgSelect } from '@/components/Common/ViewForm/VExternalOrgSelect';
import { VDropdown } from '@/components/Common/ViewForm/VDropdown';
import { VTextInput } from '@/components/Common/ViewForm/VTextInput';
import { VNumberInput } from '@/components/Common/ViewForm/VNumberInput';
import { VTextArea } from '@/components/Common/ViewForm/VTextArea';
import { VSwitch } from '@/components/Common/ViewForm/VSwitch';
import { VComplaintBrandSelector } from '@/components/Common/ViewForm/VComplaintBrandSelector';
import { VDatePickerOld } from '@/components/Common/ViewForm/VDatePickerOld';
import { VPreferredChannelSelect } from '@/components/Common/ViewForm/VPreferredChannelSelect';
import { requiredFieldRule } from '@/utils/form';
import { VComplaintIssueSelector } from '@/components/Complaint/ViewForm/VComplaintIssueSelector';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { useCanEditComplaint } from '../hooks';
import { useEffect } from 'react';
import { COMPLAINANT_TYPE } from 'shared/types/complaint';

export const Route = createFileRoute('/console/$orgId/compliance/complaints/details/$id/manage')({
    component: ComplaintDetailsManage,
});

function ComplaintDetailsManage() {
    const { message } = App.useApp();
    const complaint = complaintFormStore.use.formData?.();
    invariant(complaint, 'Complaint is required.');
    const canEdit = useCanEditComplaint();
    const setFieldForEditing = complaintFormStore.use.setFieldForEditing();
    const { formatISODate, fDateShortDayJs, formatCurrency } = useFormatter();

    const { data: brand } = useConfigServiceLoader((configService) =>
        configService.fetchComplaintBrandConfiguration(),
    );

    useEffect(() => {
        if (
            !complaint.complainantGender &&
            complaint.complainantType === COMPLAINANT_TYPE.INDIVIDUAL
        ) {
            message.info('Please select gender.');
            setFieldForEditing('complainantGender');
        }
    }, [complaint.complainantType]);

    const disabled = !canEdit;

    return (
        <>
            <Outlet />
            <Space direction="vertical" size={16} className="w-full">
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Card title="Complainant" size="small" className="h-full">
                            <Row gutter={[16, 16]} className="p-2">
                                <Col span={8}>
                                    <VTextInput
                                        label="First name"
                                        field="complainantFirstName"
                                        readonly={disabled}
                                        formStore={complaintFormStore}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VTextInput
                                        label="Surname"
                                        field="complainantLastName"
                                        readonly={disabled}
                                        formStore={complaintFormStore}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VDropdown
                                        label="Relationship"
                                        field="relationship"
                                        formStore={complaintFormStore}
                                        rules={requiredFieldRule}
                                        options={RELATIONSHIPS.map((value) => ({ value }))}
                                        readonly={disabled}
                                        enableOtherOption
                                    />
                                </Col>

                                <Col span={8}>
                                    <VDropdown
                                        label="Type"
                                        field="complainantType"
                                        formStore={complaintFormStore}
                                        rules={requiredFieldRule}
                                        options={COMPLAINANT_TYPES.map(({ label }) => ({
                                            label,
                                            value: label,
                                        }))}
                                        readonly={disabled}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VSwitch
                                        label="Referred by AFCA"
                                        field="referredByAfca"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>
                                {complaint.referredByAfca && (
                                    <>
                                        <Col span={8}>
                                            <VDatePickerOld
                                                label="Date referred (AFCA)"
                                                field="referredByAfcaDate"
                                                formStore={complaintFormStore}
                                                formatValueFn={(value) => formatISODate(value)}
                                                format={fDateShortDayJs}
                                                readonly={disabled}
                                                rules={requiredFieldRule}
                                            />
                                        </Col>

                                        <Col span={8}>
                                            <VTextInput
                                                label="Reference# (AFCA)"
                                                field="afcaReferenceNo"
                                                formStore={complaintFormStore}
                                                readonly={disabled}
                                            />
                                        </Col>
                                    </>
                                )}
                                <Col span={8}>
                                    <VDropdown
                                        label="Gender"
                                        field="complainantGender"
                                        formStore={complaintFormStore}
                                        options={GENDERS.map(({ label }) => ({
                                            label,
                                            value: label,
                                        }))}
                                        readonly={disabled}
                                        rules={
                                            complaint.complainantType ===
                                            COMPLAINANT_TYPE.INDIVIDUAL
                                                ? requiredFieldRule
                                                : undefined
                                        }
                                    />
                                </Col>
                                <Col span={8}>
                                    <VDropdown
                                        label="Age"
                                        field="complainantAge"
                                        formStore={complaintFormStore}
                                        options={AGES.map(({ label }) => ({ label, value: label }))}
                                        readonly={disabled}
                                    />
                                </Col>

                                <Col span={8}>
                                    <VTextInput
                                        label="Postcode"
                                        field="complainantPostcode"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VDropdown
                                        label="Channel"
                                        field="channel"
                                        formStore={complaintFormStore}
                                        options={CHANNELS.map(({ label }) => ({
                                            label,
                                            value: label,
                                        }))}
                                        rules={requiredFieldRule}
                                        readonly={disabled}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VPreferredChannelSelect
                                        label="Preferred channel"
                                        channelFieldName="preferredChannel"
                                        contactFieldName="preferredChannelValue"
                                        formStore={complaintFormStore}
                                        valueOverride={complaint.preferredChannel}
                                        readonly={disabled}
                                    />
                                </Col>

                                <Col span={8}>
                                    <VPreferredChannelSelect
                                        label="Preferred channel - contact"
                                        channelFieldName="preferredChannel"
                                        contactFieldName="preferredChannelValue"
                                        formStore={complaintFormStore}
                                        valueOverride={complaint.preferredChannelValue}
                                        readonly={disabled}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col span={12}>
                        <Card title="Handling info" size="small" className="h-full">
                            <Row gutter={[16, 16]} className="p-2">
                                <Col span={8}>
                                    <VReadOnly
                                        label="Complaint#"
                                        field="id"
                                        formStore={complaintFormStore}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VReadOnly
                                        label="Date received"
                                        field="dtReceived"
                                        formStore={complaintFormStore}
                                        formatValueFn={(value) => formatISODate(value)}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VUserSelector
                                        label="Owner"
                                        field="ownerId"
                                        formStore={complaintFormStore}
                                        rules={requiredFieldRule}
                                        readonly={disabled}
                                        handlingPartyIds={complaint.ExternalOrgs.map(
                                            ({ externalOrgId }) => externalOrgId,
                                        )}
                                    />
                                </Col>

                                <Col span={8}>
                                    <VExternalOrgSelect
                                        label="Handling parties"
                                        field="externalOrgIds"
                                        formStore={complaintFormStore}
                                        rules={requiredFieldRule}
                                        readonly={disabled}
                                        valueOverride={
                                            <div className="flex flex-wrap gap-1">
                                                {complaint.ExternalOrgs?.length
                                                    ? complaint.ExternalOrgs.map((item) => (
                                                          <Tag
                                                              key={item.externalOrgId}
                                                              rootClassName="whitespace-normal m-0"
                                                          >
                                                              {item.ExternalOrg?.name}
                                                          </Tag>
                                                      ))
                                                    : '-'}
                                            </div>
                                        }
                                        showAllOrgs
                                    />
                                </Col>
                                <Col span={8}>
                                    <VDropdown
                                        label="Products"
                                        field="products"
                                        formStore={complaintFormStore}
                                        rules={requiredFieldRule}
                                        readonly={disabled}
                                        formatValueFn={(value) => value?.join?.(', ')}
                                        options={PRODUCTS.map(({ label }) => ({
                                            value: label,
                                        }))}
                                        multiple
                                        maxCount={3}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VTextInput
                                        label="Policy#"
                                        field="policyNo"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>

                                <Col span={8}>
                                    <VReadOnly
                                        label="Status"
                                        field="status"
                                        formStore={complaintFormStore}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VTextInput
                                        label="Claim#"
                                        field="externalClaimId"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>
                                <Col span={8}>
                                    <VDropdown
                                        label="Is the complaint about the authorized representative of an AFS licensee?"
                                        field="afsLicenseeRelated"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                        options={RELATED_TO_AFS_LICENSEE.map(({ label }) => ({
                                            value: label,
                                        }))}
                                    />
                                </Col>

                                {(brand?.isDisplayedOnExternalForm ||
                                    brand?.isDisplayedOnInternalForm) && (
                                    <Col span={8}>
                                        <VComplaintBrandSelector
                                            label="Brand"
                                            field="brand"
                                            formStore={complaintFormStore}
                                            formatValueFn={(value) =>
                                                value?.split?.(';')?.join(' > ')
                                            }
                                            readonly={disabled}
                                            allowClear
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Card>
                    </Col>

                    <Col span={8}>
                        <Card title="Nature of complaint" size="small" className="h-full">
                            <Row gutter={[16, 16]} className="p-2">
                                <Col span={24}>
                                    <VComplaintIssueSelector
                                        label="Issues"
                                        field="issues"
                                        formStore={complaintFormStore}
                                        valueOverride={complaint.issues?.join?.(', ')}
                                        readonly={disabled}
                                    />
                                </Col>

                                <Col span={24}>
                                    <VTextArea
                                        label="Incident description"
                                        field="incidentDescription"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>

                                <Col span={24}>
                                    <VDropdown
                                        label="Solution the complainant is seeking"
                                        field="complainantSolution"
                                        formStore={complaintFormStore}
                                        options={COMPLAINANT_SOLUTIONS.map((value) => ({
                                            value,
                                        }))}
                                        readonly={disabled}
                                        enableOtherOption
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="Outcome details" size="small" className="h-full">
                            <Row gutter={[16, 16]} className="p-2">
                                <Col span={24}>
                                    <VDropdown
                                        label="Outcomes"
                                        field="outcomes"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                        allowClear
                                        formatValueFn={(value) => value?.join?.(', ')}
                                        options={OUTCOMES.map(({ label }) => ({
                                            value: label,
                                        }))}
                                        multiple
                                        maxCount={3}
                                    />
                                </Col>

                                <Col span={24}>
                                    <VNumberInput
                                        label="Monetary remedy amount"
                                        field="monetaryRemedy"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                        formatValueFn={(value) => formatCurrency(value)}
                                    />
                                </Col>

                                <Col span={24}>
                                    <VTextArea
                                        label="Outcome details"
                                        field="outcomeDetails"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>
                                <Col span={24}>
                                    <VSwitch
                                        label="Was the complaint solved in favour of the customer?"
                                        field="wasSolvedInFavourOfCustomer"
                                        formStore={complaintFormStore}
                                        readonly={disabled}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <ManageFlags complaintId={complaint.id} disabled={disabled} />
                    </Col>

                    <Col span={24}>
                        <ManageTimeline complaintId={complaint.id} disabled={disabled} />
                    </Col>

                    <Col xxl={12} md={24}>
                        <TaskList
                            relatedEntity="complaint"
                            relatedEntityId={complaint.id}
                            hideAddTask={disabled}
                        />
                    </Col>
                    <Col xxl={12} md={24}>
                        <ManageNotes complaintId={complaint.id} disabled={disabled} />
                    </Col>
                </Row>
            </Space>
        </>
    );
}
