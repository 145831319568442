import { gql } from '@/__generated__';
import { FaqDsQueryVariables, OrderBy } from '@/__generated__/graphql';
import { errorMessage } from '@/components/Common/errorMessage';
import { PageHeader } from '@/components/Common/PageHeader';
import { FaqDs } from '@/graphql/queries/faq';
import { useAntdTableOld } from '@/hooks/Table/useAntdTableOld';
import { FaqEdit, FaqEditAction } from './FaqEdit';
import { grey } from '@ant-design/colors';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Popconfirm, Space, Table, Tag } from 'antd';
import { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { commonActionColumn } from '@/utils/table';

export const Route = createFileRoute('/admin-console/faq')({
    staticData: {
        title: 'FAQ',
    },
    component: Faqs,
});

function Faqs() {
    const [deleteFaqByPk] = useMutation(DeleteFaqByPk);

    const [faqEditAction, setFaqEditAction] = useState<FaqEditAction>(['none']);
    const [deleting, setDeleting] = useState(false);
    const [variables, setVariables] = useState<FaqDsQueryVariables>({
        where: undefined,
        orderBy: [{ id: OrderBy.desc }],
        limit: 10,
        offset: 0,
    });

    const { data, loading, refetch } = useQuery(FaqDs, {
        fetchPolicy: 'network-only',
        variables,
    });

    const { tableProps } = useAntdTableOld({
        rowKey: 'id',
        data: {
            rows: data?.Faq,
            loading: loading || deleting,
            total: data?.FaqAggregate?.aggregate?.count,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
            },
            {
                title: 'Title',
                dataIndex: 'title',
                sorter: true,
            },
            {
                title: 'Categories',
                dataIndex: 'categories',
                render: (value: string[]) => (
                    <Space className="flex-wrap">
                        {value?.map((item) => (
                            <Tag key={item} color="blue" className="m-0">
                                {item}
                            </Tag>
                        ))}
                    </Space>
                ),
            },
            {
                title: 'Modules',
                dataIndex: 'modules',
                render: (value: string[]) => (
                    <Space className="flex-wrap">
                        {value?.map((item) => (
                            <Tag key={item} color="green" className="m-0">
                                {item}
                            </Tag>
                        ))}
                    </Space>
                ),
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Space>
                        <Button
                            type="text"
                            onClick={() => setFaqEditAction(['edit', value])}
                            title="Edit"
                        >
                            <EditOutlined style={{ color: grey.primary }} />
                        </Button>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={async () => {
                                try {
                                    setDeleting(true);

                                    await deleteFaqByPk({
                                        variables: {
                                            id: value,
                                        },
                                    });

                                    void refetch();
                                } catch (e) {
                                    errorMessage.show(e);
                                } finally {
                                    setDeleting(false);
                                }
                            }}
                        >
                            <Button type="link" size="small" title="Delete">
                                <DeleteOutlined style={{ color: grey.primary }} />
                            </Button>
                        </Popconfirm>
                    </Space>
                ),
            },
        ],
    });

    return (
        <>
            <PageHeader title="FAQ configuration" />

            <FaqEdit
                action={faqEditAction}
                onClose={() => {
                    setFaqEditAction(['none']);
                    void refetch();
                }}
            />

            <div className="mb-4 flex justify-end">
                <Button icon={<PlusOutlined />} onClick={() => setFaqEditAction(['add'])}>
                    Add new
                </Button>
            </div>

            <Card>
                <Table {...tableProps} size="small" />
            </Card>
        </>
    );
}

const DeleteFaqByPk = gql(/* GraphQL */ `
    mutation DeleteFaqByPk($id: bigint!) {
        deleteFaqByPk(id: $id) {
            id
        }
    }
`);
