import { ActionBar } from '@/components/Common/ActionBar';
import { DatePicker } from '@/components/Common/DatePicker';
import { FormField } from '@/components/Common/FormField';
import { useFormatter } from '@/hooks/useFormatter';
import { useInlineError } from '@/hooks/useInlineError';
import { validateLossDate, validateReportedAt } from '@/utils/claim';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { ClaimFormState } from '@/hooks/Claim/useClaimFormState';

interface Props {
    goBack: () => any;
    goNext: () => any;
    state: ClaimFormState;
    onSave: () => any;
}

export const ClaimEvent = observer(({ state, goBack, goNext, onSave }: Props) => {
    const { InlineError, setError, clearError } = useInlineError();
    const { formatDate } = useFormatter();
    const claim = state.claim;

    const readOnly = state.ui.readOnly;

    const handleGoNext = () => {
        clearError();
        if (
            !claim.claimReportedAt ||
            !claim.claimOccurredAt ||
            !claim.policyTermFrom ||
            !claim.policyTermTo
        ) {
            setError({ message: 'All fields are required' });
            return;
        }
        let errors = validateReportedAt({
            claimReportedAt: claim.claimReportedAt,
            claimOccurredAt: claim.claimOccurredAt,
            policyTermFrom: claim.policyTermFrom,
            policyTermTo: claim.policyTermTo,
        });
        if (errors.error) {
            setError({ message: errors });
            return;
        }
        errors = validateLossDate({
            claimOccurredAt: claim.claimOccurredAt,
            policyTermFrom: claim.policyTermFrom,
            policyTermTo: claim.policyTermTo,
        });
        if (errors.error) {
            setError({ message: errors });
            return;
        }

        goNext();
    };

    return (
        <>
            <Form layout="vertical" size="large">
                <Card title="Tell us about your claim" bordered>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Space direction="vertical">
                                <FormField
                                    value={formatDate(claim.policyTermFrom, 'dd/MM/yyyy HH:mm')}
                                    label="Policy start date"
                                />
                                <FormField
                                    value={formatDate(claim.policyTermTo, 'dd/MM/yyyy HH:mm')}
                                    label="Policy end date"
                                />
                            </Space>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="What is the date of loss?">
                                <DatePicker
                                    onChange={(date) => {
                                        claim.claimOccurredAt = date;
                                    }}
                                    showTime
                                    value={claim.claimOccurredAt}
                                    format="DD/MM/YYYY HH:mm"
                                    disabled={readOnly}
                                />
                            </Form.Item>
                            <Form.Item label="When was the loss reported?">
                                <DatePicker
                                    onChange={(date) => {
                                        claim.claimReportedAt = date;
                                    }}
                                    value={claim.claimReportedAt}
                                    format="DD/MM/YYYY HH:mm"
                                    disabled={readOnly}
                                    showTime
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <InlineError />
                </Card>
                <ActionBar
                    renderLeft={() => (
                        <Button icon={<ArrowLeftOutlined />} onClick={goBack}>
                            Back
                        </Button>
                    )}
                    renderRight={() => (
                        <>
                            {!readOnly && (
                                <Button icon={<SaveOutlined />} onClick={onSave}>
                                    Save
                                </Button>
                            )}
                            <Button type="primary" onClick={handleGoNext} htmlType="submit">
                                {state.ui.formAction === 'new' ? 'Confirm' : 'Next'}
                            </Button>
                        </>
                    )}
                />
            </Form>
        </>
    );
});
