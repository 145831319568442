import { observer } from 'mobx-react-lite';
import { useUuid } from '@/hooks/useUuid';
import { useEffect, useState } from 'react';
import ImageMapper from 'react-img-mapper';
import { MapAreas } from 'react-img-mapper/dist/types';

type Props = {
    value?: string;
    onChange?: (value: string) => void;
    imageMap: string;
    imageUrl: string;
};

export const QuestionnaireImageMap = observer(({ imageMap, imageUrl, value, onChange }: Props) => {
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const imageMapId = useUuid();

    const areas: MapAreas[] = [
        {
            id: 'Area 1',
            coords: '182,95,212,92,246,88,295,88,326,100,331,126,339,163,338,186,338,210,288,193,249,189,216,189,184,196,168,203,155,215,157,164,162,118,165,105'
                .split(',')
                .map((c) => parseInt(c)),
            shape: 'poly',
        },
        {
            id: 'Area 2',
            coords: '159,213,190,195,220,191,253,191,277,192,306,196,328,205,342,214,335,238,330,257,325,269,319,285,283,281,257,279,233,280,209,281,188,282,176,283,165,251,158,226'
                .split(',')
                .map((c) => parseInt(c)),
            shape: 'poly',
        },
        {
            id: 'Area 3',
            coords: '174,288,197,282,222,281,246,280,270,280,290,283,305,285,319,287,313,330,311,360,311,384,312,413,316,440,323,460,284,465,253,466,226,466,206,464,189,460,176,459,182,430,184,404,186,371,183,341,180,313'
                .split(',')
                .map((c) => parseInt(c)),

            shape: 'poly',
        },
    ];

    useEffect(() => {
        setSelectedIds(value?.split(';') ?? []);
    }, []);

    areas.forEach((a) => {
        a.preFillColor = selectedIds.includes(a.id!) ? 'rgb(0,0,255,0.5)' : 'transparent';
    });

    const map = {
        name: imageMapId,
        areas: areas,
    };

    return (
        <>
            <ImageMapper
                src="https://drive.google.com/uc?id=1UdYrfsYW0csP8xpAzelRbFH3QhU9ASYk"
                map={map}
                onClick={(a) => {
                    selectedIds.includes(a.id!)
                        ? setSelectedIds(selectedIds.filter((id) => id !== a.id))
                        : setSelectedIds([...selectedIds, a.id!]);
                    onChange?.(selectedIds.join(';'));
                }}
                strokeColor="transparent"
            />
        </>
    );
});
