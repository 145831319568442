import { asyncWithLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import { uuid } from 'shared/utils/general';
import { LOCAL_STORAGE_KEY } from 'shared/constants/localStorage';
import { PropsWithChildren, useEffect } from 'react';
import { useAuthStore } from '@/stores/AuthStore';

export async function getLaunchDarklyProvider() {
    let userKey = uuid();

    try {
        userKey = localStorage.getItem(LOCAL_STORAGE_KEY.LD_ANONYMOUS_USER_KEY) || uuid();
        localStorage.setItem(LOCAL_STORAGE_KEY.LD_ANONYMOUS_USER_KEY, userKey);
    } catch (e) {
        console.error('Error setting LaunchDarkly anonymous user key', e);
    }

    return await asyncWithLDProvider({
        clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID!,
        context: {
            kind: 'user',
            anonymous: true,
            key: userKey,
            type: /Android|iPhone|iPad/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            browserAgent: navigator.userAgent,
            screenResolution: `${window.screen.width}x${window.screen.height}`,
        },
    });
}

export function LaunchDarklyClientSetup({ children }: PropsWithChildren) {
    const ldClient = useLDClient();
    const setLDClient = useAuthStore(({ setLDClient }) => setLDClient);

    useEffect(() => {
        if (ldClient) {
            setLDClient(ldClient);
        }
    }, [ldClient]);

    return children;
}
