import { gql } from '@/__generated__';
import { errorMessage } from '@/components/Common/errorMessage';
import { PageHeader } from '@/components/Common/PageHeader';
import { PageContent } from '@/components/Layout/PageContent';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { useDeleteConfirmation } from '@/hooks/UI/useDeleteConfirmation';
import { useCurrentUser } from '@/hooks/User/useCurrentUser';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { RouterInputs, trpc } from '@/trpc';
import { DownOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import { createFileRoute } from '@tanstack/react-router';
import { App, Button, Card, Col, Dropdown, Input, Row, Spin, Table, Tag } from 'antd';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';
import { useNavigate } from '@/hooks/useNavigate';

export const Route = createFileRoute('/admin-console/organizations/')({
    staticData: {
        title: 'Organizations',
    },
    component: AdminPageOrganizations,
});

function AdminPageOrganizations() {
    const { message } = App.useApp();
    const trpcClient = useTrpcClient();
    const currentUser = useCurrentUser();
    const navigate = useNavigate();

    const [orgIdToDelete, setOrgIdToDelete] = useState<bigint | undefined>();
    const [queryVariables, setQueryVariables] = useState<RouterInputs['admin']['org']['listOrgs']>({
        limit: 10,
        offset: 0,
    });

    const { data: orgs, isLoading, refetch } = trpc.admin.org.listOrgs.useQuery(queryVariables);

    const {
        showConfirmationModal,
        hideConfirmationModal,
        deleteConfirmationModal: DeleteConfirmationModal,
    } = useDeleteConfirmation({
        entityDisplayName: 'organization',
        onConfirm: async (confirmationText) => {
            try {
                invariant(orgIdToDelete, 'orgIdToDelete is required');
                message.loading('Deleting organization...');
                await trpcClient.admin.org.deleteOrg.mutate({
                    orgId: BigInt(orgIdToDelete),
                    orgName: confirmationText,
                });
                message.destroy();
                message.success('Organization deleted successfully');
                refetch();
            } catch (error) {
                errorMessage.show(error);
            } finally {
                hideConfirmationModal();
            }
        },
    });

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: orgs?.rows || [],
            total: orgs?.total || 0,
            loading: isLoading,
        },
        columns: [
            {
                dataIndex: 'id',
                title: 'ID',
                sorter: true,
                align: 'center',
                render: (value) => BigInt(value).toString(),
            },
            {
                dataIndex: 'name',
                title: 'Name',
                sorter: true,
            },
            {
                dataIndex: 'isEnabled',
                title: 'Is enabled?',
                sorter: true,
                render: (value) => (
                    <div style={{ color: value ? 'green' : 'red' }}>{value ? 'Yes' : 'No'}</div>
                ),
            },
            {
                dataIndex: 'emailId',
                title: 'Email ID',
                render: (value) => (value ? <Tag>{value}</Tag> : ''),
            },
            {
                title: 'Modules',
                render: (_, row) => (
                    <>
                        {row.enabledModules.map((module) => (
                            <Tag key={module} color="blue">
                                {module}
                            </Tag>
                        ))}
                    </>
                ),
            },
            {
                title: '# of active users',
                render: (value, row) => <NoOfActiveUsers orgId={Number(row.id)} />,
            },
            {
                fixed: 'right',
                align: 'center',
                render: (v) => (
                    <div className="whitespace-nowrap">
                        <Dropdown
                            trigger={['click']}
                            menu={{
                                items: [
                                    {
                                        key: 'edit',
                                        label: 'Edit',
                                        onClick: () => {
                                            navigate({
                                                to: '/admin-console/organizations/$orgId/edit',
                                                params: {
                                                    orgId: BigInt(v.id),
                                                },
                                            });
                                        },
                                    },
                                    {
                                        key: 'manage-users',
                                        label: 'Manage users',
                                        onClick() {
                                            navigate({
                                                to: '/admin-console/organizations/$orgId/users',
                                                params: {
                                                    orgId: BigInt(v.id),
                                                },
                                            });
                                        },
                                    },
                                    ...(currentUser.isPrivilegedSuperAdmin ||
                                    import.meta.env.VITE_ENV !== 'production'
                                        ? [
                                              {
                                                  key: 'delete',
                                                  label: 'Delete',
                                                  danger: true,
                                                  onClick: () => {
                                                      setOrgIdToDelete(v.id);
                                                      showConfirmationModal(v.name);
                                                  },
                                              },
                                          ]
                                        : []),
                                ],
                            }}
                        >
                            <Button size="small">
                                Actions <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                ),
            },
        ],
        onQueryVariableChange(options) {
            setQueryVariables((prev) => ({
                ...prev,
                ...options,
            }));
        },
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
    });

    return (
        <>
            <PageHeader title="Organizations" subTitle="Organizations can be configured here." />

            {DeleteConfirmationModal}

            <PageContent>
                <div className="mb-4 flex items-center justify-start">
                    <Input.Search
                        placeholder="Search organizations"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onSearch={(value) => {
                            if (value) {
                                setQueryVariables({
                                    ...queryVariables,
                                    where: {
                                        name: { contains: value, mode: 'insensitive' },
                                    },
                                });
                            } else {
                                setQueryVariables({
                                    ...queryVariables,
                                    where: undefined,
                                });
                            }
                        }}
                        className="w-96"
                    />
                </div>

                <Card>
                    <Table
                        {...tableProps}
                        size="small"
                        expandable={{
                            expandedRowRender: (record) => (
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <ClaimsReport orgId={Number(record.id)} />
                                    </Col>
                                </Row>
                            ),
                            rowExpandable: () => true,
                        }}
                    />
                </Card>
            </PageContent>
        </>
    );
}

function NoOfActiveUsers({ orgId }: { orgId: number }) {
    const { loading, data } = useQuery(NoOfActiveUsersInOrg, {
        fetchPolicy: 'network-only',
        variables: { orgId },
        skip: !orgId,
    });

    return <Spin spinning={loading}>{data?.UserInOrgAggregate?.aggregate?.count}</Spin>;
}

function ClaimsReport({ orgId }: { orgId: number }) {
    const [data, setData] = useState<Array<{ order: number; month: string; count: number }>>([]);
    const [getNoOfComplaintsByMonth] = useLazyQuery(NoOfComplaintsByMonth);
    useEffect(() => {
        const load = async () => {
            setData([]);
            const originDate = DateTime.now().startOf('month');
            for (let i = 0; i < 6; i++) {
                const startDate = originDate.minus({ month: i }).startOf('month');
                const { data: res } = await getNoOfComplaintsByMonth({
                    variables: {
                        orgId,
                        startDate,
                        endDate: startDate.endOf('month'),
                    },
                    fetchPolicy: 'network-only',
                });

                setData((prev) => [
                    ...prev,
                    {
                        order: i,
                        month: startDate.toFormat('MMM yy'),
                        count: res?.ComplaintAggregate.aggregate?.count || 0,
                    },
                ]);
            }
        };
        load();
    }, []);

    const columns = [
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
        },
    ];

    return (
        <div className="p-5">
            <Table
                rowKey="month"
                dataSource={data}
                columns={columns}
                bordered
                pagination={false}
                title={() => '# of complaints per month'}
            />
        </div>
    );
}

const NoOfComplaintsByMonth = gql(/* GraphQL */ `
    query NoOfComplaintsByMonth($orgId: bigint!, $startDate: timestamptz!, $endDate: timestamptz!) {
        ComplaintAggregate(
            where: { orgId: { _eq: $orgId }, createdAt: { _gte: $startDate, _lte: $endDate } }
        ) {
            aggregate {
                count
            }
        }
    }
`);

const NoOfActiveUsersInOrg = gql(/* GraphQL */ `
    query NoOfActiveUsersInOrg($orgId: bigint!) {
        UserInOrgAggregate(
            where: { orgId: { _eq: $orgId }, disabled: { _eq: false }, userId: { _gt: 1 } }
        ) {
            aggregate {
                count
            }
        }
    }
`);
