import { useEffect } from 'react';
import { createFileRoute, Navigate } from '@tanstack/react-router';

export const Route = createFileRoute(
    '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId',
)({
    component: ClaimLodgementDeprecated,
});

function ClaimLodgementDeprecated() {
    const params = Route.useParams();
    const searchParams = Route.useSearch();

    useEffect(() => {
        console.log('Redirecting...');
    }, []);

    return (
        <Navigate
            to="/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId"
            params={params}
            search={searchParams}
        />
    );
}
