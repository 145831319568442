import { Col, Row, Tabs } from 'antd';
import {
    navRoutes,
    RISK_REGISTER_DETAILS_SECTIONS,
    RiskRegisterDetailsSection,
} from 'shared/navigation/navRoutes';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { RiskDetails } from './RiskDetails';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { RiskAssessment } from './RiskAssessment';
import { RiskActionPlan } from './RiskActionPlan';
import { History } from '@/components/Common/History';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@/hooks/useNavigate';

export const Route = createFileRoute('/console/$orgId/risk/register/details/$id/$section')({
    staticData: {
        title: 'Risk details',
    },
    params: {
        parse({ id, section }) {
            return {
                id: BigInt(id),
                section: section as RiskRegisterDetailsSection,
            };
        },
    },
    component: RiskManage,
});

function RiskManage() {
    const orgId = useOrgId();
    const { id, section } = Route.useParams();
    const navigate = useNavigate();

    const tabItems = [
        {
            key: RISK_REGISTER_DETAILS_SECTIONS.ASSESSMENT,
            label: 'Risk Assessment',
            children: <RiskAssessment />,
        },
        {
            key: RISK_REGISTER_DETAILS_SECTIONS.ACTION_PLAN,
            label: 'Risk Action Plan',
            children: <RiskActionPlan riskId={Number(id)} />,
        },
        {
            key: RISK_REGISTER_DETAILS_SECTIONS.HISTORY,
            label: 'History',
            children: <History idValue={Number(id)} idPropertyName="riskId" />,
        },
    ];

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.risk),
                    toBreadCrumb(navRoutes.risk_register_search, {
                        orgId,
                    }),
                    {
                        title: 'Details',
                    },
                ]}
            />

            <Row>
                <Col span={24}>
                    <RiskDetails />
                    <Tabs
                        activeKey={section}
                        onTabClick={(key) =>
                            navigate({
                                to: '/console/$orgId/risk/register/details/$id/$section',
                                params: {
                                    orgId,
                                    id,
                                    section: key as RiskRegisterDetailsSection,
                                },
                            })
                        }
                        destroyInactiveTabPane
                        items={tabItems}
                    />
                </Col>
            </Row>
        </>
    );
}
