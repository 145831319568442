import { useLocalObservable } from 'mobx-react-lite';
import { PartialDeep } from 'type-fest';
import { mobxToJson } from '@/global/mobx';
import { extendObservable, makeAutoObservable, runInAction } from 'mobx';
import { ViewModel } from '@/types/common';
import { createViewModel } from 'mobx-utils';

/**
 * @deprecated Use useLocalStore instead
 * useLocalStore hook. Use it instead of useLocalObservable as it comes with some useful methods.
 * It returns array of the following objects:
 *  1) store - observable object
 *  2) initStore - method for initializing store data or setting multiple properties
 *  3) toJSON - converts observable object to JSON objects and allows to remove specified properties
 *  4) storeVM - MobX ViewModel of the store
 * @param initializer
 * @return [store, initData, toJSON, vmStore, vmStoreToJSON]
 */
export function useLocalStore_depr<T>(initializer?: () => PartialDeep<T>): [
    Exclude<T, null | undefined>,
    (initData: PartialDeep<T> & Record<string, any>) => void,
    /**
     * @deprecated Use Hasura.toJSON instead
     */
    <TFields extends (keyof T)[]>(
        ...keysToRemove: TFields
    ) => Omit<
        T,
        TFields[number] | 'createdAt' | 'updatedAt' | 'createdById' | 'CreatedBy' | 'accessKey'
    >,
    ViewModel<T>,
    <TFields extends (keyof T)[]>(
        ...keysToRemove: TFields
    ) => Omit<
        T,
        TFields[number] | 'createdAt' | 'updatedAt' | 'createdById' | 'CreatedBy' | 'accessKey'
    >,
] {
    const init = initializer
        ? () => {
              return { data: initializer() };
          }
        : function () {
              return { data: {} } as { data: T };
          };
    const store = useLocalObservable<{ data: T }>(init as () => { data: T });
    const vmStore = useLocalObservable<{ data: ViewModel<T> }>(() => {
        if (Array.isArray(store.data)) {
            return { data: createViewModel(makeAutoObservable({}) as T) };
        }
        return {
            data: createViewModel(store.data),
        };
    });
    const toJSON = <TFields extends (keyof T)[]>(...keysToRemove: TFields) => {
        //TODO: Depricated. Use Hasura.JSON instead.
        const json: T = mobxToJson(store.data);
        if (keysToRemove && keysToRemove.length > 0) {
            keysToRemove.forEach((el) => {
                delete json[el];
            });
        }
        const systemKeysToRemove = [
            'createdAt',
            'updatedAt',
            'createdById',
            'CreatedBy',
            'accessKey',
        ] as (keyof T)[];
        systemKeysToRemove.forEach((el) => {
            delete json[el];
        });
        return json as Omit<
            T,
            TFields[number] | 'createdAt' | 'updatedAt' | 'createdById' | 'CreatedBy' | 'accessKey'
        >;
    };
    const vmStoreToJSON = <TFields extends (keyof T)[]>(...keysToRemove: TFields) => {
        const json: T = mobxToJson(vmStore.data) as T;
        if (keysToRemove && keysToRemove.length > 0) {
            keysToRemove.forEach((el) => {
                delete json[el];
            });
        }
        const systemKeysToRemove = [
            'createdAt',
            'updatedAt',
            'createdById',
            'CreatedBy',
            'accessKey',
            'localValues',
            'localComputedValues',
            'model',
        ] as (keyof T)[];
        systemKeysToRemove.forEach((el) => {
            delete json[el];
        });
        return json as Omit<
            T,
            TFields[number] | 'createdAt' | 'updatedAt' | 'createdById' | 'CreatedBy' | 'accessKey'
        >;
    };
    const initData = (initData: PartialDeep<T> | any | undefined) => {
        try {
            runInAction(() => {
                try {
                    if (Array.isArray(initData)) {
                        store.data = initData as T;
                        //TODO: Implement createViewModel support for array
                    } else {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        Object.keys(store.data).forEach((k) => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            delete store.data[k];
                        });
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        extendObservable(store.data, mobxToJson(initData));
                        vmStore.data = createViewModel(store.data);
                    }
                } catch (e) {
                    console.log(e);
                }
            });
        } catch {
            /* empty */
        }
    };
    return [
        store.data as Exclude<T, null | undefined>,
        initData,
        toJSON,
        vmStore.data,
        vmStoreToJSON,
    ];
}
