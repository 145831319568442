import { useEffect, useState } from 'react';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    FilterOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { CollapsiblePanel } from '@/components/Common/CollapsiblePanel';
import {
    Button,
    Row,
    Card,
    Col,
    Form,
    Input,
    Space,
    Table,
    Tooltip,
    Typography,
    Switch,
    Popconfirm,
} from 'antd';
import { grey, red } from '@ant-design/colors';
import { errorMessage } from '@/components/Common/errorMessage';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { navRoutes } from 'shared/navigation/navRoutes';
import { PageHeader } from '@/components/Common/PageHeader';
import { RiskCategoryCascader } from '@/components/Risk/RiskCategoryCascader';
import { DateTime } from 'luxon';
import { Falsy, isTruthy } from 'shared/utils/boolean';
import { RiskAdd } from '@/components/Risk/RiskAdd';
import { useSearchParams } from '@/hooks/useSearchParams';
import { DatePicker } from '@/components/Common/DatePicker';
import { UserSelector } from '@/components/Common/UserSelector';
import { capitalize } from 'shared/utils/string';
import { useConfigServiceLoader } from '@/hooks/Configuration/useConfigServiceLoader';
import { commonActionColumn } from '@/utils/table';
import { NewTabLink } from '@/components/Common/NewTabLink';
import { useNavigate } from '@/hooks/useNavigate';
import { ComponentPreview } from '@/components/Common/ComponentPreview';
import { htmlToText } from '@/utils/text.ts';
import { useSafePath } from '@/hooks/useSafePath';
import { SortOrder } from '@/utils/general';
import { RouterInputs, RouterOutputs, trpc } from '@/trpc';
import { useFormatter } from '@/hooks/useFormatter';
import dayjs from 'dayjs';
import { createFileRoute } from '@tanstack/react-router';
import { Action } from '@/types/common';

export const Route = createFileRoute('/console/$orgId/risk/register/search')({
    staticData: {
        title: 'Risk register',
    },
    validateSearch({ assessmentRowIndex, assessmentColIndex, assessmentType }): Partial<{
        assessmentRowIndex: string;
        assessmentColIndex: string;
        assessmentType: 'inherent' | 'residual';
    }> {
        return {
            assessmentRowIndex: assessmentRowIndex as string,
            assessmentColIndex: assessmentColIndex as string,
            assessmentType: assessmentType as 'inherent' | 'residual',
        };
    },
    component: RiskRegisterSearch,
});

type Risk = RouterOutputs['risk']['listRisks']['rows'][number];

type Filter = {
    title: string;
    categoryId: number;
    dateIdentified: Date;
    ownerId: number;
    residualRiskOutOfAppetite: boolean;
};

function RiskRegisterSearch() {
    const orgId = useOrgId();
    const navigate = useNavigate();
    const { fDateShortDayJs } = useFormatter();
    const config = useConfigServiceLoader((configService) => configService.fetchRiskMatrix());

    const [action, setAction] = useState<Action>(['none']);
    const [variables, setVariables] = useState<RouterInputs['risk']['listRisks']>({
        where: undefined,
        orderBy: {
            id: SortOrder.desc,
        },
        limit: 10,
        offset: 0,
    });
    const [showFilter, setShowFilter] = useState(false);

    const [form] = Form.useForm<Filter>();
    const safePath = useSafePath<Filter>();

    const { data, isPending, refetch } = trpc.risk.listRisks.useQuery(variables, {
        throwOnError: (error) => {
            errorMessage.show(error.message);
            return false;
        },
    });
    const deleteRiskMutation = trpc.risk.deleteRisk.useMutation();

    const {
        assessmentRowIndex: rawAssessmentRowIndex,
        assessmentColIndex: rawAssessmentColIndex,
        assessmentType,
    } = useSearchParams(navRoutes.risk_register_search);

    useEffect(() => {
        const assessmentRowIndex = Number(rawAssessmentRowIndex);
        const assessmentColIndex = Number(rawAssessmentColIndex);

        if (
            isNaN(assessmentRowIndex) ||
            isNaN(assessmentColIndex) ||
            (assessmentType !== 'inherent' && assessmentType !== 'residual')
        ) {
            return;
        }

        setVariables((prev) => ({
            ...prev,
            where: {
                ...prev.where,
                riskAssessment: {
                    path: ['selected' + capitalize(assessmentType) + 'Cell'],
                    equals: {
                        rowIndex: assessmentRowIndex,
                        colIndex: assessmentColIndex,
                    },
                },
            },
        }));
    }, [rawAssessmentRowIndex, rawAssessmentColIndex, assessmentType]);

    const onDelete = async (id: bigint) => {
        try {
            await deleteRiskMutation.mutateAsync({
                id,
            });

            void refetch();
        } catch (e) {
            errorMessage.show(e);
        }
    };

    const onSearch = () => {
        const values = form.getFieldsValue();
        const filters: (Falsy | RouterInputs['risk']['listRisks']['where'])[] = [
            values.title && {
                title: { contains: `${values.title}`, mode: 'insensitive' },
            },
            values.categoryId && {
                LevelOneRiskCategory: { id: { equals: values.categoryId } },
            },
            values.dateIdentified && {
                AND: [
                    {
                        dateIdentified: {
                            gte: DateTime.fromJSDate(values.dateIdentified)
                                .startOf('day')
                                .toJSDate(),
                        },
                    },
                    {
                        dateIdentified: {
                            lte: DateTime.fromJSDate(values.dateIdentified).endOf('day').toJSDate(),
                        },
                    },
                ],
            },
            values.ownerId && { ownerId: { equals: values.ownerId } },
            values.residualRiskOutOfAppetite && {
                riskAssessment: {
                    path: ['isResidualRiskOutsideAppetite'],
                    equals: true,
                },
            },
        ];

        const where: RouterInputs['risk']['listRisks']['where'] = {
            AND: filters.filter(isTruthy),
        };

        setVariables((prev) => ({ ...prev, where }));
    };

    const { tableProps } = useAntdTable<Risk>({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isPending || deleteRiskMutation.isPending,
            total: data?.total,
        },
        paginationConfig: { hideOnSinglePage: true },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit,
                offset: options?.offset,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                width: 25,
                render: (_, record) =>
                    !!record.riskAssessment?.isResidualRiskOutsideAppetite && (
                        <Tooltip title="Residual risk is outside of risk appetite">
                            <ExclamationCircleOutlined
                                style={{ color: red.primary }}
                                className="align-middle text-xl"
                            />
                        </Tooltip>
                    ),
            },
            {
                title: 'Risk No.',
                dataIndex: 'id',
                fixed: 'left',
                render: (value, record) => {
                    const matrixType = record.riskAssessment?.matrixType;
                    const isOutOfSync = !config.loading && config.data?.matrixType !== matrixType;

                    return (
                        <>
                            {record.LevelOneCategory?.riskNumberPrefix
                                ? `${record.LevelOneCategory?.riskNumberPrefix}-${value}`
                                : Number(value)}
                            {isOutOfSync && (
                                <Tooltip title="Risk matrix is out of sync with current configuration">
                                    (Out of sync){' '}
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Category',
                dataIndex: 'LevelOneCategory',
                render: (value) => value?.name,
            },
            {
                title: 'Title',
                dataIndex: 'title',
                render: (value) => (
                    <Tooltip title={value}>
                        <Typography.Paragraph
                            ellipsis
                            style={{ maxWidth: 500, minWidth: 100, marginBottom: 0 }}
                        >
                            {value}
                        </Typography.Paragraph>
                    </Tooltip>
                ),
            },
            {
                title: 'Impact',
                dataIndex: 'riskAssessment',
                render: (value) => value.impacts[value?.selectedResidualCell?.rowIndex] || '',
            },
            {
                title: 'Description',
                dataIndex: 'description',
                render: (value) => <ComponentPreview>{htmlToText(value)}</ComponentPreview>,
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Space>
                        <NewTabLink
                            to="/console/$orgId/risk/register/details/$id/$section"
                            params={{
                                orgId,
                                id: value,
                                section: 'assessment',
                            }}
                        />
                        <Tooltip title="Delete">
                            <Popconfirm
                                title="Are you sure?"
                                onPopupClick={(e) => e.stopPropagation()}
                                onConfirm={() => onDelete(value)}
                                okButtonProps={{ danger: true }}
                                okText="Delete"
                            >
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                ),
            },
        ],
    });

    if (config.loading) {
        return <Card loading />;
    }

    return (
        <>
            <RiskAdd
                action={action}
                onClose={() => {
                    setAction(['none']);
                    void refetch();
                }}
            />

            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.risk),
                    toBreadCrumbWithoutLink(navRoutes.risk_register_search),
                ]}
            />

            <PageHeader title="Risk Register" />

            <CollapsiblePanel open={showFilter}>
                <Card title="Filters" size="small" className="mb-4">
                    <Form form={form} layout="vertical" onFinish={onSearch}>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label="Category" name={safePath('categoryId')}>
                                    <RiskCategoryCascader level={1} showSearch />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Title" name={safePath('title')}>
                                    <Input className="w-full" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Date identified"
                                    name={safePath('dateIdentified')}
                                >
                                    <DatePicker
                                        className="w-full"
                                        format={fDateShortDayJs}
                                        maxDate={dayjs()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Owner" name={safePath('ownerId')}>
                                    <UserSelector />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Residual risk is outside of appetite"
                                    name={safePath('residualRiskOutOfAppetite')}
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Space className="flex justify-end">
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    onSearch();
                                }}
                            >
                                Reset
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Search
                            </Button>
                        </Space>
                    </Form>
                </Card>
            </CollapsiblePanel>

            <Space className="mb-4 flex justify-end">
                <Tooltip title="Filters">
                    <Button
                        type="text"
                        size="large"
                        title="Filter"
                        onClick={() => setShowFilter((prev) => !prev)}
                    >
                        <FilterOutlined />
                    </Button>
                </Tooltip>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setAction(['add']);
                    }}
                >
                    Add risk
                </Button>
            </Space>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: (e) =>
                            navigate(
                                {
                                    to: '/console/$orgId/risk/register/details/$id/$section',
                                    params: {
                                        orgId,
                                        id,
                                        section: 'assessment',
                                    },
                                },
                                e,
                            ),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
