/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/~__root';
import { Route as PublicRouteImport } from './routes/~public/~route';
import { Route as AdminConsoleRouteImport } from './routes/~admin-console/~route';
import { Route as IndexImport } from './routes/~index';
import { Route as PublicOrgIdRouteImport } from './routes/~public/~$orgId/~route';
import { Route as ConsoleOrgIdRouteImport } from './routes/~console.$orgId/~route';
import { Route as AdminConsoleUsersRouteImport } from './routes/~admin-console/~users/~route';
import { Route as AdminConsoleReportsRouteImport } from './routes/~admin-console/~reports/~route';
import { Route as AdminConsoleObligationsRouteImport } from './routes/~admin-console/~obligations/~route';
import { Route as AdminConsoleNewOrganizationRouteImport } from './routes/~admin-console/~new-organization/~route';
import { Route as AdminConsoleFaqRouteImport } from './routes/~admin-console/~faq/~route';
import { Route as AdminConsoleControlsRouteImport } from './routes/~admin-console/~controls/~route';
import { Route as AdminConsoleConfigurationRouteImport } from './routes/~admin-console/~configuration/~route';
import { Route as AdminConsoleCommTemplatesRouteImport } from './routes/~admin-console/~comm-templates/~route';
import { Route as AdminConsoleAiPromptsRouteImport } from './routes/~admin-console/~ai-prompts/~route';
import { Route as AdminConsoleAdvancedRouteImport } from './routes/~admin-console/~advanced/~route';
import { Route as authenticationLogoutinRouteImport } from './routes/~(authentication)/~logoutin/~route';
import { Route as authenticationLogoutRouteImport } from './routes/~(authentication)/~logout/~route';
import { Route as authenticationLoginRouteImport } from './routes/~(authentication)/~login/~route';
import { Route as authenticationCallbackRouteImport } from './routes/~(authentication)/~callback/~route';
import { Route as authenticationAdminLoginRouteImport } from './routes/~(authentication)/~admin-login/~route';
import { Route as authenticationAdminCallbackRouteImport } from './routes/~(authentication)/~admin-callback/~route';
import { Route as AdminConsoleIndexImport } from './routes/~admin-console/~index';
import { Route as PublicNewComplaintOrgIdRouteImport } from './routes/~public/~new-complaint.$orgId/~route';
import { Route as PublicNewComplaintByKeyKeyRouteImport } from './routes/~public/~new-complaint-by-key.$key/~route';
import { Route as PublicLodgementSummaryAccessKeyRouteImport } from './routes/~public/~lodgement-summary.$accessKey/~route';
import { Route as ConsoleOrgIdToolsRouteImport } from './routes/~console.$orgId/~tools/~route';
import { Route as ConsoleOrgIdTasksRouteImport } from './routes/~console.$orgId/~tasks/~route';
import { Route as ConsoleOrgIdRiskRouteImport } from './routes/~console.$orgId/~risk/~route';
import { Route as ConsoleOrgIdReportingRouteImport } from './routes/~console.$orgId/~reporting/~route';
import { Route as ConsoleOrgIdGetFileRouteImport } from './routes/~console.$orgId/~get-file/~route';
import { Route as ConsoleOrgIdFaqRouteImport } from './routes/~console.$orgId/~faq/~route';
import { Route as ConsoleOrgIdDebugRouteImport } from './routes/~console.$orgId/~debug/~route';
import { Route as ConsoleOrgIdDashboardRouteImport } from './routes/~console.$orgId/~dashboard/~route';
import { Route as ConsoleOrgIdControlsRouteImport } from './routes/~console.$orgId/~controls/~route';
import { Route as ConsoleOrgIdConfigurationRouteImport } from './routes/~console.$orgId/~configuration/~route';
import { Route as ConsoleOrgIdComplianceRouteImport } from './routes/~console.$orgId/~compliance/~route';
import { Route as ConsoleOrgIdClaimsRouteImport } from './routes/~console.$orgId/~claims/~route';
import { Route as ConsoleOrgIdClaimModuleOnlyHomePageImport } from './routes/~console.$orgId/~claim-module-only-home-page';
import { Route as AdminConsoleConfigurationObligationsRouteImport } from './routes/~admin-console/~configuration/~obligations/~route';
import { Route as AdminConsoleAdvancedJobsRouteImport } from './routes/~admin-console/~advanced/~jobs/~route';
import { Route as AdminConsoleAdvancedEmailDomainsRouteImport } from './routes/~admin-console/~advanced/~email-domains/~route';
import { Route as AdminConsoleAdvancedDbUpgradesImport } from './routes/~admin-console/~advanced/~db-upgrades';
import { Route as AdminConsoleAdvancedAppSettingsImport } from './routes/~admin-console/~advanced/~app-settings';
import { Route as authenticationChangeOrgOrgIdRouteImport } from './routes/~(authentication)/~change-org.$orgId/~route';
import { Route as ConsoleOrgIdIndexImport } from './routes/~console.$orgId/~index';
import { Route as AdminConsoleOrganizationsIndexImport } from './routes/~admin-console/~organizations/~index';
import { Route as PublicOrgIdComplaintNewRouteImport } from './routes/~public/~$orgId/~complaint.new/~route';
import { Route as ConsoleOrgIdUserSettingsProfileRouteImport } from './routes/~console.$orgId/~user-settings/~profile/~route';
import { Route as ConsoleOrgIdUserSettingsNotificationsRouteImport } from './routes/~console.$orgId/~user-settings/~notifications/~route';
import { Route as ConsoleOrgIdToolsWebsiteCheckerRouteImport } from './routes/~console.$orgId/~tools/~website-checker/~route';
import { Route as ConsoleOrgIdToolsAiInboxRouteImport } from './routes/~console.$orgId/~tools/~ai-inbox/~route';
import { Route as ConsoleOrgIdDebugTestRouteImport } from './routes/~console.$orgId/~debug/~test/~route';
import { Route as ConsoleOrgIdDashboardSectionRouteImport } from './routes/~console.$orgId/~dashboard/~$section/~route';
import { Route as ConsoleOrgIdConfigurationUsersRouteImport } from './routes/~console.$orgId/~configuration/~users/~route';
import { Route as ConsoleOrgIdConfigurationTasksRouteImport } from './routes/~console.$orgId/~configuration/~tasks/~route';
import { Route as ConsoleOrgIdConfigurationTaskManagerRouteImport } from './routes/~console.$orgId/~configuration/~task-manager/~route';
import { Route as ConsoleOrgIdConfigurationSystemRouteImport } from './routes/~console.$orgId/~configuration/~system/~route';
import { Route as ConsoleOrgIdConfigurationSuppliersRouteImport } from './routes/~console.$orgId/~configuration/~suppliers/~route';
import { Route as ConsoleOrgIdConfigurationStatisticsRouteImport } from './routes/~console.$orgId/~configuration/~statistics/~route';
import { Route as ConsoleOrgIdConfigurationRiskRouteImport } from './routes/~console.$orgId/~configuration/~risk/~route';
import { Route as ConsoleOrgIdConfigurationQuestionnaireRouteImport } from './routes/~console.$orgId/~configuration/~questionnaire/~route';
import { Route as ConsoleOrgIdConfigurationProductTypesRouteImport } from './routes/~console.$orgId/~configuration/~product-types/~route';
import { Route as ConsoleOrgIdConfigurationPaymentsRouteImport } from './routes/~console.$orgId/~configuration/~payments/~route';
import { Route as ConsoleOrgIdConfigurationInsurersRouteImport } from './routes/~console.$orgId/~configuration/~insurers/~route';
import { Route as ConsoleOrgIdConfigurationHandlingPartiesRouteImport } from './routes/~console.$orgId/~configuration/~handling-parties/~route';
import { Route as ConsoleOrgIdConfigurationExpensesAndBillingRouteImport } from './routes/~console.$orgId/~configuration/~expenses-and-billing/~route';
import { Route as ConsoleOrgIdConfigurationDocumentsRouteImport } from './routes/~console.$orgId/~configuration/~documents/~route';
import { Route as ConsoleOrgIdConfigurationComplianceRouteImport } from './routes/~console.$orgId/~configuration/~compliance/~route';
import { Route as ConsoleOrgIdConfigurationCommsTemplatesRouteImport } from './routes/~console.$orgId/~configuration/~comms-templates/~route';
import { Route as ConsoleOrgIdConfigurationClaimRouteImport } from './routes/~console.$orgId/~configuration/~claim/~route';
import { Route as ConsoleOrgIdConfigurationAssistanceRouteImport } from './routes/~console.$orgId/~configuration/~assistance/~route';
import { Route as ConsoleOrgIdComplianceIncidentsRouteImport } from './routes/~console.$orgId/~compliance/~incidents/~route';
import { Route as ConsoleOrgIdClaimsSearchRouteImport } from './routes/~console.$orgId/~claims/~search/~route';
import { Route as ConsoleOrgIdClaimsLodgeRouteImport } from './routes/~console.$orgId/~claims/~lodge/~route';
import { Route as AdminConsoleOrganizationsOrgIdUsersRouteImport } from './routes/~admin-console/~organizations/~$orgId/~users/~route';
import { Route as AdminConsoleOrganizationsOrgIdManageEmailDomainsImport } from './routes/~admin-console/~organizations/~$orgId/~manage-email-domains';
import { Route as AdminConsoleOrganizationsOrgIdEditImport } from './routes/~admin-console/~organizations/~$orgId/~edit';
import { Route as AdminConsoleObligationsLibraryAddRouteImport } from './routes/~admin-console/~obligations/~library/~add/~route';
import { Route as AdminConsoleAdvancedJobsRecurringImport } from './routes/~admin-console/~advanced/~jobs/~recurring';
import { Route as AdminConsoleAdvancedJobsOnDemandImport } from './routes/~admin-console/~advanced/~jobs/~on-demand';
import { Route as AdminConsoleAdvancedEmailDomainsAddImport } from './routes/~admin-console/~advanced/~email-domains/~add';
import { Route as ConsoleOrgIdTasksIndexImport } from './routes/~console.$orgId/~tasks/~index';
import { Route as ConsoleOrgIdDashboardIndexImport } from './routes/~console.$orgId/~dashboard/~index';
import { Route as AdminConsoleObligationsLibraryIndexImport } from './routes/~admin-console/~obligations/~library/~index';
import { Route as AdminConsoleControlsLibraryIndexImport } from './routes/~admin-console/~controls/~library/~index';
import { Route as PublicOrgIdInvoiceViewAccessKeyRouteImport } from './routes/~public/~$orgId/~invoice.view.$accessKey/~route';
import { Route as ConsoleOrgIdRiskRegisterSearchRouteImport } from './routes/~console.$orgId/~risk/~register/~search/~route';
import { Route as ConsoleOrgIdRiskRasSearchRouteImport } from './routes/~console.$orgId/~risk/~ras/~search/~route';
import { Route as ConsoleOrgIdControlsTestsSearchRouteImport } from './routes/~console.$orgId/~controls/~tests/~search/~route';
import { Route as ConsoleOrgIdControlsRegisterSearchRouteImport } from './routes/~console.$orgId/~controls/~register/~search/~route';
import { Route as ConsoleOrgIdControlsRegisterAddRouteImport } from './routes/~console.$orgId/~controls/~register/~add/~route';
import { Route as ConsoleOrgIdConfigurationSuppliersAddRouteImport } from './routes/~console.$orgId/~configuration/~suppliers/~add/~route';
import { Route as ConsoleOrgIdConfigurationRiskSectionRouteImport } from './routes/~console.$orgId/~configuration/~risk/~$section/~route';
import { Route as ConsoleOrgIdComplianceTrainingSearchRouteImport } from './routes/~console.$orgId/~compliance/~training/~search/~route';
import { Route as ConsoleOrgIdComplianceObligationsSearchRouteImport } from './routes/~console.$orgId/~compliance/~obligations/~search/~route';
import { Route as ConsoleOrgIdComplianceObligationsAddRouteImport } from './routes/~console.$orgId/~compliance/~obligations/~add/~route';
import { Route as ConsoleOrgIdComplianceIncidentsAddImport } from './routes/~console.$orgId/~compliance/~incidents/~add';
import { Route as ConsoleOrgIdComplianceComplaintsSearchRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~search/~route';
import { Route as ConsoleOrgIdComplianceComplaintsAddRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~add/~route';
import { Route as ConsoleOrgIdComplianceCoiSearchRouteImport } from './routes/~console.$orgId/~compliance/~coi/~search/~route';
import { Route as ConsoleOrgIdComplianceCoiAddRouteImport } from './routes/~console.$orgId/~compliance/~coi/~add/~route';
import { Route as ConsoleOrgIdClaimsViewIdRouteImport } from './routes/~console.$orgId/~claims/~view.$id/~route';
import { Route as ConsoleOrgIdClaimsEditIdRouteImport } from './routes/~console.$orgId/~claims/~edit.$id/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~route';
import { Route as AdminConsoleObligationsLibraryIdSectionRouteImport } from './routes/~admin-console/~obligations/~library/~$id.$section/~route';
import { Route as AdminConsoleControlsLibraryIdSectionRouteImport } from './routes/~admin-console/~controls/~library/~$id.$section/~route';
import { Route as AdminConsoleAdvancedEmailDomainsIdEditImport } from './routes/~admin-console/~advanced/~email-domains/~$id.edit';
import { Route as ConsoleOrgIdConfigurationSuppliersIndexImport } from './routes/~console.$orgId/~configuration/~suppliers/~index';
import { Route as ConsoleOrgIdConfigurationRiskIndexImport } from './routes/~console.$orgId/~configuration/~risk/~index';
import { Route as ConsoleOrgIdConfigurationProductTypesIndexImport } from './routes/~console.$orgId/~configuration/~product-types/~index';
import { Route as PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteImport } from './routes/~public/~claim-lodgement.$orgId.$pdsVersionId.$questionnaireId.$productTypeId/~route';
import { Route as ConsoleOrgIdToolsAiInboxIdReviewEmailClassificationImport } from './routes/~console.$orgId/~tools/~ai-inbox/~$id/~review-email-classification';
import { Route as ConsoleOrgIdRiskRasDetailsIdRouteImport } from './routes/~console.$orgId/~risk/~ras/~details.$id/~route';
import { Route as ConsoleOrgIdControlsRegisterEditIdRouteImport } from './routes/~console.$orgId/~controls/~register/~edit.$id/~route';
import { Route as ConsoleOrgIdConfigurationSuppliersEditSupplierIdRouteImport } from './routes/~console.$orgId/~configuration/~suppliers/~edit.$supplierId/~route';
import { Route as ConsoleOrgIdConfigurationSuppliersDetailsSupplierIdRouteImport } from './routes/~console.$orgId/~configuration/~suppliers/~details.$supplierId/~route';
import { Route as ConsoleOrgIdComplianceTrainingUserUserIdRouteImport } from './routes/~console.$orgId/~compliance/~training/~user.$userId/~route';
import { Route as ConsoleOrgIdComplianceTrainingSearchAddTrainingImport } from './routes/~console.$orgId/~compliance/~training/~search/~add-training';
import { Route as ConsoleOrgIdComplianceComplaintsDetailsIdRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~details.$id/~route';
import { Route as ConsoleOrgIdComplianceComplaintsComplaintIdEditRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~$complaintId.edit/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~product-type-data/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~policy-info/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdManageRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~manage/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdLibraryRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~library/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdHistoryRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~history/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdFinancialsRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~financials/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdExpensesRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~expenses/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdContactsRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~contacts/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdCommsRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~comms/~route';
import { Route as ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexImport } from './routes/~console.$orgId/~configuration/~product-types/~$productTypeId/~index';
import { Route as PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteImport } from './routes/~public/~$orgId/~claim.lodgement.$pdsVersionId.$questionnaireId.$productTypeId/~route';
import { Route as ConsoleOrgIdRiskRegisterDetailsIdSectionRouteImport } from './routes/~console.$orgId/~risk/~register/~details.$id.$section/~route';
import { Route as ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteImport } from './routes/~console.$orgId/~configuration/~product-types/~$productTypeId/~pds.$pdsId/~route';
import { Route as ConsoleOrgIdComplianceTrainingUserUserIdAddImport } from './routes/~console.$orgId/~compliance/~training/~user.$userId/~add';
import { Route as ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteImport } from './routes/~console.$orgId/~compliance/~obligations/~details.$id.$section/~route';
import { Route as ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteImport } from './routes/~console.$orgId/~compliance/~incidents/~details.$id.$section/~route';
import { Route as ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~details.$id/~manage/~route';
import { Route as ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~details.$id/~library/~route';
import { Route as ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~details.$id/~history/~route';
import { Route as ConsoleOrgIdComplianceCoiDetailsIdSectionRouteImport } from './routes/~console.$orgId/~compliance/~coi/~details.$id.$section/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdExpensesAddRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~expenses/~add/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdCommsAddRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~comms/~add/~route';
import { Route as ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdImport } from './routes/~console.$orgId/~compliance/~training/~user.$userId/~edit.$recordId';
import { Route as ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~manage/~strategies.add/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~manage/~flag.add/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~expenses/~$expenseId.edit/~route';
import { Route as ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteImport } from './routes/~console.$orgId/~claims/~details.$id/~comms/~message.$messageId/~route';
import { Route as ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~details.$id/~manage/~note.add/~route';
import { Route as ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteImport } from './routes/~console.$orgId/~compliance/~complaints/~details.$id/~manage/~flag.add/~route';

// Create/Update Routes

const PublicRouteRoute = PublicRouteImport.update({
  id: '/public',
  path: '/public',
  getParentRoute: () => rootRoute,
} as any);

const AdminConsoleRouteRoute = AdminConsoleRouteImport.update({
  id: '/admin-console',
  path: '/admin-console',
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any);

const PublicOrgIdRouteRoute = PublicOrgIdRouteImport.update({
  id: '/$orgId',
  path: '/$orgId',
  getParentRoute: () => PublicRouteRoute,
} as any);

const ConsoleOrgIdRouteRoute = ConsoleOrgIdRouteImport.update({
  id: '/console/$orgId',
  path: '/console/$orgId',
  getParentRoute: () => rootRoute,
} as any);

const AdminConsoleUsersRouteRoute = AdminConsoleUsersRouteImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => AdminConsoleRouteRoute,
} as any);

const AdminConsoleReportsRouteRoute = AdminConsoleReportsRouteImport.update({
  id: '/reports',
  path: '/reports',
  getParentRoute: () => AdminConsoleRouteRoute,
} as any);

const AdminConsoleObligationsRouteRoute =
  AdminConsoleObligationsRouteImport.update({
    id: '/obligations',
    path: '/obligations',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const AdminConsoleNewOrganizationRouteRoute =
  AdminConsoleNewOrganizationRouteImport.update({
    id: '/new-organization',
    path: '/new-organization',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const AdminConsoleFaqRouteRoute = AdminConsoleFaqRouteImport.update({
  id: '/faq',
  path: '/faq',
  getParentRoute: () => AdminConsoleRouteRoute,
} as any);

const AdminConsoleControlsRouteRoute = AdminConsoleControlsRouteImport.update({
  id: '/controls',
  path: '/controls',
  getParentRoute: () => AdminConsoleRouteRoute,
} as any);

const AdminConsoleConfigurationRouteRoute =
  AdminConsoleConfigurationRouteImport.update({
    id: '/configuration',
    path: '/configuration',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const AdminConsoleCommTemplatesRouteRoute =
  AdminConsoleCommTemplatesRouteImport.update({
    id: '/comm-templates',
    path: '/comm-templates',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const AdminConsoleAiPromptsRouteRoute = AdminConsoleAiPromptsRouteImport.update(
  {
    id: '/ai-prompts',
    path: '/ai-prompts',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any,
);

const AdminConsoleAdvancedRouteRoute = AdminConsoleAdvancedRouteImport.update({
  id: '/advanced',
  path: '/advanced',
  getParentRoute: () => AdminConsoleRouteRoute,
} as any);

const authenticationLogoutinRouteRoute =
  authenticationLogoutinRouteImport.update({
    id: '/(authentication)/logoutin',
    path: '/logoutin',
    getParentRoute: () => rootRoute,
  } as any);

const authenticationLogoutRouteRoute = authenticationLogoutRouteImport.update({
  id: '/(authentication)/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any);

const authenticationLoginRouteRoute = authenticationLoginRouteImport.update({
  id: '/(authentication)/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any);

const authenticationCallbackRouteRoute =
  authenticationCallbackRouteImport.update({
    id: '/(authentication)/callback',
    path: '/callback',
    getParentRoute: () => rootRoute,
  } as any);

const authenticationAdminLoginRouteRoute =
  authenticationAdminLoginRouteImport.update({
    id: '/(authentication)/admin-login',
    path: '/admin-login',
    getParentRoute: () => rootRoute,
  } as any);

const authenticationAdminCallbackRouteRoute =
  authenticationAdminCallbackRouteImport.update({
    id: '/(authentication)/admin-callback',
    path: '/admin-callback',
    getParentRoute: () => rootRoute,
  } as any);

const AdminConsoleIndexRoute = AdminConsoleIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminConsoleRouteRoute,
} as any);

const PublicNewComplaintOrgIdRouteRoute =
  PublicNewComplaintOrgIdRouteImport.update({
    id: '/new-complaint/$orgId',
    path: '/new-complaint/$orgId',
    getParentRoute: () => PublicRouteRoute,
  } as any);

const PublicNewComplaintByKeyKeyRouteRoute =
  PublicNewComplaintByKeyKeyRouteImport.update({
    id: '/new-complaint-by-key/$key',
    path: '/new-complaint-by-key/$key',
    getParentRoute: () => PublicRouteRoute,
  } as any);

const PublicLodgementSummaryAccessKeyRouteRoute =
  PublicLodgementSummaryAccessKeyRouteImport.update({
    id: '/lodgement-summary/$accessKey',
    path: '/lodgement-summary/$accessKey',
    getParentRoute: () => PublicRouteRoute,
  } as any);

const ConsoleOrgIdToolsRouteRoute = ConsoleOrgIdToolsRouteImport.update({
  id: '/tools',
  path: '/tools',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdTasksRouteRoute = ConsoleOrgIdTasksRouteImport.update({
  id: '/tasks',
  path: '/tasks',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdRiskRouteRoute = ConsoleOrgIdRiskRouteImport.update({
  id: '/risk',
  path: '/risk',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdReportingRouteRoute = ConsoleOrgIdReportingRouteImport.update(
  {
    id: '/reporting',
    path: '/reporting',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any,
);

const ConsoleOrgIdGetFileRouteRoute = ConsoleOrgIdGetFileRouteImport.update({
  id: '/get-file',
  path: '/get-file',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdFaqRouteRoute = ConsoleOrgIdFaqRouteImport.update({
  id: '/faq',
  path: '/faq',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdDebugRouteRoute = ConsoleOrgIdDebugRouteImport.update({
  id: '/debug',
  path: '/debug',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdDashboardRouteRoute = ConsoleOrgIdDashboardRouteImport.update(
  {
    id: '/dashboard',
    path: '/dashboard',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any,
);

const ConsoleOrgIdControlsRouteRoute = ConsoleOrgIdControlsRouteImport.update({
  id: '/controls',
  path: '/controls',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdConfigurationRouteRoute =
  ConsoleOrgIdConfigurationRouteImport.update({
    id: '/configuration',
    path: '/configuration',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any);

const ConsoleOrgIdComplianceRouteRoute =
  ConsoleOrgIdComplianceRouteImport.update({
    id: '/compliance',
    path: '/compliance',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsRouteRoute = ConsoleOrgIdClaimsRouteImport.update({
  id: '/claims',
  path: '/claims',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const ConsoleOrgIdClaimModuleOnlyHomePageRoute =
  ConsoleOrgIdClaimModuleOnlyHomePageImport.update({
    id: '/claim-module-only-home-page',
    path: '/claim-module-only-home-page',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any);

const AdminConsoleConfigurationObligationsRouteRoute =
  AdminConsoleConfigurationObligationsRouteImport.update({
    id: '/obligations',
    path: '/obligations',
    getParentRoute: () => AdminConsoleConfigurationRouteRoute,
  } as any);

const AdminConsoleAdvancedJobsRouteRoute =
  AdminConsoleAdvancedJobsRouteImport.update({
    id: '/jobs',
    path: '/jobs',
    getParentRoute: () => AdminConsoleAdvancedRouteRoute,
  } as any);

const AdminConsoleAdvancedEmailDomainsRouteRoute =
  AdminConsoleAdvancedEmailDomainsRouteImport.update({
    id: '/email-domains',
    path: '/email-domains',
    getParentRoute: () => AdminConsoleAdvancedRouteRoute,
  } as any);

const AdminConsoleAdvancedDbUpgradesRoute =
  AdminConsoleAdvancedDbUpgradesImport.update({
    id: '/db-upgrades',
    path: '/db-upgrades',
    getParentRoute: () => AdminConsoleAdvancedRouteRoute,
  } as any);

const AdminConsoleAdvancedAppSettingsRoute =
  AdminConsoleAdvancedAppSettingsImport.update({
    id: '/app-settings',
    path: '/app-settings',
    getParentRoute: () => AdminConsoleAdvancedRouteRoute,
  } as any);

const authenticationChangeOrgOrgIdRouteRoute =
  authenticationChangeOrgOrgIdRouteImport.update({
    id: '/(authentication)/change-org/$orgId',
    path: '/change-org/$orgId',
    getParentRoute: () => rootRoute,
  } as any);

const ConsoleOrgIdIndexRoute = ConsoleOrgIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ConsoleOrgIdRouteRoute,
} as any);

const AdminConsoleOrganizationsIndexRoute =
  AdminConsoleOrganizationsIndexImport.update({
    id: '/organizations/',
    path: '/organizations/',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const PublicOrgIdComplaintNewRouteRoute =
  PublicOrgIdComplaintNewRouteImport.update({
    id: '/complaint/new',
    path: '/complaint/new',
    getParentRoute: () => PublicOrgIdRouteRoute,
  } as any);

const ConsoleOrgIdUserSettingsProfileRouteRoute =
  ConsoleOrgIdUserSettingsProfileRouteImport.update({
    id: '/user-settings/profile',
    path: '/user-settings/profile',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any);

const ConsoleOrgIdUserSettingsNotificationsRouteRoute =
  ConsoleOrgIdUserSettingsNotificationsRouteImport.update({
    id: '/user-settings/notifications',
    path: '/user-settings/notifications',
    getParentRoute: () => ConsoleOrgIdRouteRoute,
  } as any);

const ConsoleOrgIdToolsWebsiteCheckerRouteRoute =
  ConsoleOrgIdToolsWebsiteCheckerRouteImport.update({
    id: '/website-checker',
    path: '/website-checker',
    getParentRoute: () => ConsoleOrgIdToolsRouteRoute,
  } as any);

const ConsoleOrgIdToolsAiInboxRouteRoute =
  ConsoleOrgIdToolsAiInboxRouteImport.update({
    id: '/ai-inbox',
    path: '/ai-inbox',
    getParentRoute: () => ConsoleOrgIdToolsRouteRoute,
  } as any);

const ConsoleOrgIdDebugTestRouteRoute = ConsoleOrgIdDebugTestRouteImport.update(
  {
    id: '/test',
    path: '/test',
    getParentRoute: () => ConsoleOrgIdDebugRouteRoute,
  } as any,
);

const ConsoleOrgIdDashboardSectionRouteRoute =
  ConsoleOrgIdDashboardSectionRouteImport.update({
    id: '/$section',
    path: '/$section',
    getParentRoute: () => ConsoleOrgIdDashboardRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationUsersRouteRoute =
  ConsoleOrgIdConfigurationUsersRouteImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationTasksRouteRoute =
  ConsoleOrgIdConfigurationTasksRouteImport.update({
    id: '/tasks',
    path: '/tasks',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationTaskManagerRouteRoute =
  ConsoleOrgIdConfigurationTaskManagerRouteImport.update({
    id: '/task-manager',
    path: '/task-manager',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationSystemRouteRoute =
  ConsoleOrgIdConfigurationSystemRouteImport.update({
    id: '/system',
    path: '/system',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationSuppliersRouteRoute =
  ConsoleOrgIdConfigurationSuppliersRouteImport.update({
    id: '/suppliers',
    path: '/suppliers',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationStatisticsRouteRoute =
  ConsoleOrgIdConfigurationStatisticsRouteImport.update({
    id: '/statistics',
    path: '/statistics',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationRiskRouteRoute =
  ConsoleOrgIdConfigurationRiskRouteImport.update({
    id: '/risk',
    path: '/risk',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationQuestionnaireRouteRoute =
  ConsoleOrgIdConfigurationQuestionnaireRouteImport.update({
    id: '/questionnaire',
    path: '/questionnaire',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationProductTypesRouteRoute =
  ConsoleOrgIdConfigurationProductTypesRouteImport.update({
    id: '/product-types',
    path: '/product-types',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationPaymentsRouteRoute =
  ConsoleOrgIdConfigurationPaymentsRouteImport.update({
    id: '/payments',
    path: '/payments',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationInsurersRouteRoute =
  ConsoleOrgIdConfigurationInsurersRouteImport.update({
    id: '/insurers',
    path: '/insurers',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationHandlingPartiesRouteRoute =
  ConsoleOrgIdConfigurationHandlingPartiesRouteImport.update({
    id: '/handling-parties',
    path: '/handling-parties',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute =
  ConsoleOrgIdConfigurationExpensesAndBillingRouteImport.update({
    id: '/expenses-and-billing',
    path: '/expenses-and-billing',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationDocumentsRouteRoute =
  ConsoleOrgIdConfigurationDocumentsRouteImport.update({
    id: '/documents',
    path: '/documents',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationComplianceRouteRoute =
  ConsoleOrgIdConfigurationComplianceRouteImport.update({
    id: '/compliance',
    path: '/compliance',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationCommsTemplatesRouteRoute =
  ConsoleOrgIdConfigurationCommsTemplatesRouteImport.update({
    id: '/comms-templates',
    path: '/comms-templates',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationClaimRouteRoute =
  ConsoleOrgIdConfigurationClaimRouteImport.update({
    id: '/claim',
    path: '/claim',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationAssistanceRouteRoute =
  ConsoleOrgIdConfigurationAssistanceRouteImport.update({
    id: '/assistance',
    path: '/assistance',
    getParentRoute: () => ConsoleOrgIdConfigurationRouteRoute,
  } as any);

const ConsoleOrgIdComplianceIncidentsRouteRoute =
  ConsoleOrgIdComplianceIncidentsRouteImport.update({
    id: '/incidents',
    path: '/incidents',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdClaimsSearchRouteRoute =
  ConsoleOrgIdClaimsSearchRouteImport.update({
    id: '/search',
    path: '/search',
    getParentRoute: () => ConsoleOrgIdClaimsRouteRoute,
  } as any);

const ConsoleOrgIdClaimsLodgeRouteRoute =
  ConsoleOrgIdClaimsLodgeRouteImport.update({
    id: '/lodge',
    path: '/lodge',
    getParentRoute: () => ConsoleOrgIdClaimsRouteRoute,
  } as any);

const AdminConsoleOrganizationsOrgIdUsersRouteRoute =
  AdminConsoleOrganizationsOrgIdUsersRouteImport.update({
    id: '/organizations/$orgId/users',
    path: '/organizations/$orgId/users',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const AdminConsoleOrganizationsOrgIdManageEmailDomainsRoute =
  AdminConsoleOrganizationsOrgIdManageEmailDomainsImport.update({
    id: '/organizations/$orgId/manage-email-domains',
    path: '/organizations/$orgId/manage-email-domains',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const AdminConsoleOrganizationsOrgIdEditRoute =
  AdminConsoleOrganizationsOrgIdEditImport.update({
    id: '/organizations/$orgId/edit',
    path: '/organizations/$orgId/edit',
    getParentRoute: () => AdminConsoleRouteRoute,
  } as any);

const AdminConsoleObligationsLibraryAddRouteRoute =
  AdminConsoleObligationsLibraryAddRouteImport.update({
    id: '/library/add',
    path: '/library/add',
    getParentRoute: () => AdminConsoleObligationsRouteRoute,
  } as any);

const AdminConsoleAdvancedJobsRecurringRoute =
  AdminConsoleAdvancedJobsRecurringImport.update({
    id: '/recurring',
    path: '/recurring',
    getParentRoute: () => AdminConsoleAdvancedJobsRouteRoute,
  } as any);

const AdminConsoleAdvancedJobsOnDemandRoute =
  AdminConsoleAdvancedJobsOnDemandImport.update({
    id: '/on-demand',
    path: '/on-demand',
    getParentRoute: () => AdminConsoleAdvancedJobsRouteRoute,
  } as any);

const AdminConsoleAdvancedEmailDomainsAddRoute =
  AdminConsoleAdvancedEmailDomainsAddImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => AdminConsoleAdvancedEmailDomainsRouteRoute,
  } as any);

const ConsoleOrgIdTasksIndexRoute = ConsoleOrgIdTasksIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ConsoleOrgIdTasksRouteRoute,
} as any);

const ConsoleOrgIdDashboardIndexRoute = ConsoleOrgIdDashboardIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdDashboardRouteRoute,
  } as any,
);

const AdminConsoleObligationsLibraryIndexRoute =
  AdminConsoleObligationsLibraryIndexImport.update({
    id: '/library/',
    path: '/library/',
    getParentRoute: () => AdminConsoleObligationsRouteRoute,
  } as any);

const AdminConsoleControlsLibraryIndexRoute =
  AdminConsoleControlsLibraryIndexImport.update({
    id: '/library/',
    path: '/library/',
    getParentRoute: () => AdminConsoleControlsRouteRoute,
  } as any);

const PublicOrgIdInvoiceViewAccessKeyRouteRoute =
  PublicOrgIdInvoiceViewAccessKeyRouteImport.update({
    id: '/invoice/view/$accessKey',
    path: '/invoice/view/$accessKey',
    getParentRoute: () => PublicOrgIdRouteRoute,
  } as any);

const ConsoleOrgIdRiskRegisterSearchRouteRoute =
  ConsoleOrgIdRiskRegisterSearchRouteImport.update({
    id: '/register/search',
    path: '/register/search',
    getParentRoute: () => ConsoleOrgIdRiskRouteRoute,
  } as any);

const ConsoleOrgIdRiskRasSearchRouteRoute =
  ConsoleOrgIdRiskRasSearchRouteImport.update({
    id: '/ras/search',
    path: '/ras/search',
    getParentRoute: () => ConsoleOrgIdRiskRouteRoute,
  } as any);

const ConsoleOrgIdControlsTestsSearchRouteRoute =
  ConsoleOrgIdControlsTestsSearchRouteImport.update({
    id: '/tests/search',
    path: '/tests/search',
    getParentRoute: () => ConsoleOrgIdControlsRouteRoute,
  } as any);

const ConsoleOrgIdControlsRegisterSearchRouteRoute =
  ConsoleOrgIdControlsRegisterSearchRouteImport.update({
    id: '/register/search',
    path: '/register/search',
    getParentRoute: () => ConsoleOrgIdControlsRouteRoute,
  } as any);

const ConsoleOrgIdControlsRegisterAddRouteRoute =
  ConsoleOrgIdControlsRegisterAddRouteImport.update({
    id: '/register/add',
    path: '/register/add',
    getParentRoute: () => ConsoleOrgIdControlsRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationSuppliersAddRouteRoute =
  ConsoleOrgIdConfigurationSuppliersAddRouteImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdConfigurationSuppliersRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationRiskSectionRouteRoute =
  ConsoleOrgIdConfigurationRiskSectionRouteImport.update({
    id: '/$section',
    path: '/$section',
    getParentRoute: () => ConsoleOrgIdConfigurationRiskRouteRoute,
  } as any);

const ConsoleOrgIdComplianceTrainingSearchRouteRoute =
  ConsoleOrgIdComplianceTrainingSearchRouteImport.update({
    id: '/training/search',
    path: '/training/search',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdComplianceObligationsSearchRouteRoute =
  ConsoleOrgIdComplianceObligationsSearchRouteImport.update({
    id: '/obligations/search',
    path: '/obligations/search',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdComplianceObligationsAddRouteRoute =
  ConsoleOrgIdComplianceObligationsAddRouteImport.update({
    id: '/obligations/add',
    path: '/obligations/add',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdComplianceIncidentsAddRoute =
  ConsoleOrgIdComplianceIncidentsAddImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdComplianceIncidentsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsSearchRouteRoute =
  ConsoleOrgIdComplianceComplaintsSearchRouteImport.update({
    id: '/complaints/search',
    path: '/complaints/search',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsAddRouteRoute =
  ConsoleOrgIdComplianceComplaintsAddRouteImport.update({
    id: '/complaints/add',
    path: '/complaints/add',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdComplianceCoiSearchRouteRoute =
  ConsoleOrgIdComplianceCoiSearchRouteImport.update({
    id: '/coi/search',
    path: '/coi/search',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdComplianceCoiAddRouteRoute =
  ConsoleOrgIdComplianceCoiAddRouteImport.update({
    id: '/coi/add',
    path: '/coi/add',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdClaimsViewIdRouteRoute =
  ConsoleOrgIdClaimsViewIdRouteImport.update({
    id: '/view/$id',
    path: '/view/$id',
    getParentRoute: () => ConsoleOrgIdClaimsRouteRoute,
  } as any);

const ConsoleOrgIdClaimsEditIdRouteRoute =
  ConsoleOrgIdClaimsEditIdRouteImport.update({
    id: '/edit/$id',
    path: '/edit/$id',
    getParentRoute: () => ConsoleOrgIdClaimsRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdRouteRoute =
  ConsoleOrgIdClaimsDetailsIdRouteImport.update({
    id: '/details/$id',
    path: '/details/$id',
    getParentRoute: () => ConsoleOrgIdClaimsRouteRoute,
  } as any);

const AdminConsoleObligationsLibraryIdSectionRouteRoute =
  AdminConsoleObligationsLibraryIdSectionRouteImport.update({
    id: '/library/$id/$section',
    path: '/library/$id/$section',
    getParentRoute: () => AdminConsoleObligationsRouteRoute,
  } as any);

const AdminConsoleControlsLibraryIdSectionRouteRoute =
  AdminConsoleControlsLibraryIdSectionRouteImport.update({
    id: '/library/$id/$section',
    path: '/library/$id/$section',
    getParentRoute: () => AdminConsoleControlsRouteRoute,
  } as any);

const AdminConsoleAdvancedEmailDomainsIdEditRoute =
  AdminConsoleAdvancedEmailDomainsIdEditImport.update({
    id: '/$id/edit',
    path: '/$id/edit',
    getParentRoute: () => AdminConsoleAdvancedEmailDomainsRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationSuppliersIndexRoute =
  ConsoleOrgIdConfigurationSuppliersIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdConfigurationSuppliersRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationRiskIndexRoute =
  ConsoleOrgIdConfigurationRiskIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdConfigurationRiskRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationProductTypesIndexRoute =
  ConsoleOrgIdConfigurationProductTypesIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ConsoleOrgIdConfigurationProductTypesRouteRoute,
  } as any);

const PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute =
  PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteImport.update(
    {
      id: '/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId',
      path: '/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId',
      getParentRoute: () => PublicRouteRoute,
    } as any,
  );

const ConsoleOrgIdToolsAiInboxIdReviewEmailClassificationRoute =
  ConsoleOrgIdToolsAiInboxIdReviewEmailClassificationImport.update({
    id: '/$id/review-email-classification',
    path: '/$id/review-email-classification',
    getParentRoute: () => ConsoleOrgIdToolsAiInboxRouteRoute,
  } as any);

const ConsoleOrgIdRiskRasDetailsIdRouteRoute =
  ConsoleOrgIdRiskRasDetailsIdRouteImport.update({
    id: '/ras/details/$id',
    path: '/ras/details/$id',
    getParentRoute: () => ConsoleOrgIdRiskRouteRoute,
  } as any);

const ConsoleOrgIdControlsRegisterEditIdRouteRoute =
  ConsoleOrgIdControlsRegisterEditIdRouteImport.update({
    id: '/register/edit/$id',
    path: '/register/edit/$id',
    getParentRoute: () => ConsoleOrgIdControlsRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationSuppliersEditSupplierIdRouteRoute =
  ConsoleOrgIdConfigurationSuppliersEditSupplierIdRouteImport.update({
    id: '/edit/$supplierId',
    path: '/edit/$supplierId',
    getParentRoute: () => ConsoleOrgIdConfigurationSuppliersRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationSuppliersDetailsSupplierIdRouteRoute =
  ConsoleOrgIdConfigurationSuppliersDetailsSupplierIdRouteImport.update({
    id: '/details/$supplierId',
    path: '/details/$supplierId',
    getParentRoute: () => ConsoleOrgIdConfigurationSuppliersRouteRoute,
  } as any);

const ConsoleOrgIdComplianceTrainingUserUserIdRouteRoute =
  ConsoleOrgIdComplianceTrainingUserUserIdRouteImport.update({
    id: '/training/user/$userId',
    path: '/training/user/$userId',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdComplianceTrainingSearchAddTrainingRoute =
  ConsoleOrgIdComplianceTrainingSearchAddTrainingImport.update({
    id: '/add-training',
    path: '/add-training',
    getParentRoute: () => ConsoleOrgIdComplianceTrainingSearchRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute =
  ConsoleOrgIdComplianceComplaintsDetailsIdRouteImport.update({
    id: '/complaints/details/$id',
    path: '/complaints/details/$id',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsComplaintIdEditRouteRoute =
  ConsoleOrgIdComplianceComplaintsComplaintIdEditRouteImport.update({
    id: '/complaints/$complaintId/edit',
    path: '/complaints/$complaintId/edit',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute =
  ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteImport.update({
    id: '/product-type-data',
    path: '/product-type-data',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute =
  ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteImport.update({
    id: '/policy-info',
    path: '/policy-info',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdManageRouteRoute =
  ConsoleOrgIdClaimsDetailsIdManageRouteImport.update({
    id: '/manage',
    path: '/manage',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute =
  ConsoleOrgIdClaimsDetailsIdLibraryRouteImport.update({
    id: '/library',
    path: '/library',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute =
  ConsoleOrgIdClaimsDetailsIdHistoryRouteImport.update({
    id: '/history',
    path: '/history',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute =
  ConsoleOrgIdClaimsDetailsIdFinancialsRouteImport.update({
    id: '/financials',
    path: '/financials',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdExpensesRouteRoute =
  ConsoleOrgIdClaimsDetailsIdExpensesRouteImport.update({
    id: '/expenses',
    path: '/expenses',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdContactsRouteRoute =
  ConsoleOrgIdClaimsDetailsIdContactsRouteImport.update({
    id: '/contacts',
    path: '/contacts',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdCommsRouteRoute =
  ConsoleOrgIdClaimsDetailsIdCommsRouteImport.update({
    id: '/comms',
    path: '/comms',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute =
  ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexImport.update({
    id: '/$productTypeId/',
    path: '/$productTypeId/',
    getParentRoute: () => ConsoleOrgIdConfigurationProductTypesRouteRoute,
  } as any);

const PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute =
  PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteImport.update(
    {
      id: '/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId',
      path: '/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId',
      getParentRoute: () => PublicOrgIdRouteRoute,
    } as any,
  );

const ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute =
  ConsoleOrgIdRiskRegisterDetailsIdSectionRouteImport.update({
    id: '/register/details/$id/$section',
    path: '/register/details/$id/$section',
    getParentRoute: () => ConsoleOrgIdRiskRouteRoute,
  } as any);

const ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute =
  ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteImport.update({
    id: '/$productTypeId/pds/$pdsId',
    path: '/$productTypeId/pds/$pdsId',
    getParentRoute: () => ConsoleOrgIdConfigurationProductTypesRouteRoute,
  } as any);

const ConsoleOrgIdComplianceTrainingUserUserIdAddRoute =
  ConsoleOrgIdComplianceTrainingUserUserIdAddImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdComplianceTrainingUserUserIdRouteRoute,
  } as any);

const ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute =
  ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteImport.update({
    id: '/obligations/details/$id/$section',
    path: '/obligations/details/$id/$section',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute =
  ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteImport.update({
    id: '/details/$id/$section',
    path: '/details/$id/$section',
    getParentRoute: () => ConsoleOrgIdComplianceIncidentsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRoute =
  ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteImport.update({
    id: '/manage',
    path: '/manage',
    getParentRoute: () => ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute =
  ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteImport.update({
    id: '/library',
    path: '/library',
    getParentRoute: () => ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute =
  ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteImport.update({
    id: '/history',
    path: '/history',
    getParentRoute: () => ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute,
  } as any);

const ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute =
  ConsoleOrgIdComplianceCoiDetailsIdSectionRouteImport.update({
    id: '/coi/details/$id/$section',
    path: '/coi/details/$id/$section',
    getParentRoute: () => ConsoleOrgIdComplianceRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute =
  ConsoleOrgIdClaimsDetailsIdExpensesAddRouteImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdExpensesRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute =
  ConsoleOrgIdClaimsDetailsIdCommsAddRouteImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdCommsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute =
  ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdImport.update({
    id: '/edit/$recordId',
    path: '/edit/$recordId',
    getParentRoute: () => ConsoleOrgIdComplianceTrainingUserUserIdRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute =
  ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteImport.update({
    id: '/strategies/add',
    path: '/strategies/add',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdManageRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute =
  ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteImport.update({
    id: '/flag/add',
    path: '/flag/add',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdManageRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute =
  ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteImport.update({
    id: '/$expenseId/edit',
    path: '/$expenseId/edit',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdExpensesRouteRoute,
  } as any);

const ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute =
  ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteImport.update({
    id: '/message/$messageId',
    path: '/message/$messageId',
    getParentRoute: () => ConsoleOrgIdClaimsDetailsIdCommsRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute =
  ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteImport.update({
    id: '/note/add',
    path: '/note/add',
    getParentRoute: () =>
      ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRoute,
  } as any);

const ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute =
  ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteImport.update({
    id: '/flag/add',
    path: '/flag/add',
    getParentRoute: () =>
      ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    '/admin-console': {
      id: '/admin-console';
      path: '/admin-console';
      fullPath: '/admin-console';
      preLoaderRoute: typeof AdminConsoleRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/public': {
      id: '/public';
      path: '/public';
      fullPath: '/public';
      preLoaderRoute: typeof PublicRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/admin-console/': {
      id: '/admin-console/';
      path: '/';
      fullPath: '/admin-console/';
      preLoaderRoute: typeof AdminConsoleIndexImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/(authentication)/admin-callback': {
      id: '/(authentication)/admin-callback';
      path: '/admin-callback';
      fullPath: '/admin-callback';
      preLoaderRoute: typeof authenticationAdminCallbackRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/(authentication)/admin-login': {
      id: '/(authentication)/admin-login';
      path: '/admin-login';
      fullPath: '/admin-login';
      preLoaderRoute: typeof authenticationAdminLoginRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/(authentication)/callback': {
      id: '/(authentication)/callback';
      path: '/callback';
      fullPath: '/callback';
      preLoaderRoute: typeof authenticationCallbackRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/(authentication)/login': {
      id: '/(authentication)/login';
      path: '/login';
      fullPath: '/login';
      preLoaderRoute: typeof authenticationLoginRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/(authentication)/logout': {
      id: '/(authentication)/logout';
      path: '/logout';
      fullPath: '/logout';
      preLoaderRoute: typeof authenticationLogoutRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/(authentication)/logoutin': {
      id: '/(authentication)/logoutin';
      path: '/logoutin';
      fullPath: '/logoutin';
      preLoaderRoute: typeof authenticationLogoutinRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/admin-console/advanced': {
      id: '/admin-console/advanced';
      path: '/advanced';
      fullPath: '/admin-console/advanced';
      preLoaderRoute: typeof AdminConsoleAdvancedRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/ai-prompts': {
      id: '/admin-console/ai-prompts';
      path: '/ai-prompts';
      fullPath: '/admin-console/ai-prompts';
      preLoaderRoute: typeof AdminConsoleAiPromptsRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/comm-templates': {
      id: '/admin-console/comm-templates';
      path: '/comm-templates';
      fullPath: '/admin-console/comm-templates';
      preLoaderRoute: typeof AdminConsoleCommTemplatesRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/configuration': {
      id: '/admin-console/configuration';
      path: '/configuration';
      fullPath: '/admin-console/configuration';
      preLoaderRoute: typeof AdminConsoleConfigurationRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/controls': {
      id: '/admin-console/controls';
      path: '/controls';
      fullPath: '/admin-console/controls';
      preLoaderRoute: typeof AdminConsoleControlsRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/faq': {
      id: '/admin-console/faq';
      path: '/faq';
      fullPath: '/admin-console/faq';
      preLoaderRoute: typeof AdminConsoleFaqRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/new-organization': {
      id: '/admin-console/new-organization';
      path: '/new-organization';
      fullPath: '/admin-console/new-organization';
      preLoaderRoute: typeof AdminConsoleNewOrganizationRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/obligations': {
      id: '/admin-console/obligations';
      path: '/obligations';
      fullPath: '/admin-console/obligations';
      preLoaderRoute: typeof AdminConsoleObligationsRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/reports': {
      id: '/admin-console/reports';
      path: '/reports';
      fullPath: '/admin-console/reports';
      preLoaderRoute: typeof AdminConsoleReportsRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/users': {
      id: '/admin-console/users';
      path: '/users';
      fullPath: '/admin-console/users';
      preLoaderRoute: typeof AdminConsoleUsersRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/console/$orgId': {
      id: '/console/$orgId';
      path: '/console/$orgId';
      fullPath: '/console/$orgId';
      preLoaderRoute: typeof ConsoleOrgIdRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/public/$orgId': {
      id: '/public/$orgId';
      path: '/$orgId';
      fullPath: '/public/$orgId';
      preLoaderRoute: typeof PublicOrgIdRouteImport;
      parentRoute: typeof PublicRouteImport;
    };
    '/admin-console/organizations/': {
      id: '/admin-console/organizations/';
      path: '/organizations';
      fullPath: '/admin-console/organizations';
      preLoaderRoute: typeof AdminConsoleOrganizationsIndexImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/console/$orgId/': {
      id: '/console/$orgId/';
      path: '/';
      fullPath: '/console/$orgId/';
      preLoaderRoute: typeof ConsoleOrgIdIndexImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/(authentication)/change-org/$orgId': {
      id: '/(authentication)/change-org/$orgId';
      path: '/change-org/$orgId';
      fullPath: '/change-org/$orgId';
      preLoaderRoute: typeof authenticationChangeOrgOrgIdRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/admin-console/advanced/app-settings': {
      id: '/admin-console/advanced/app-settings';
      path: '/app-settings';
      fullPath: '/admin-console/advanced/app-settings';
      preLoaderRoute: typeof AdminConsoleAdvancedAppSettingsImport;
      parentRoute: typeof AdminConsoleAdvancedRouteImport;
    };
    '/admin-console/advanced/db-upgrades': {
      id: '/admin-console/advanced/db-upgrades';
      path: '/db-upgrades';
      fullPath: '/admin-console/advanced/db-upgrades';
      preLoaderRoute: typeof AdminConsoleAdvancedDbUpgradesImport;
      parentRoute: typeof AdminConsoleAdvancedRouteImport;
    };
    '/admin-console/advanced/email-domains': {
      id: '/admin-console/advanced/email-domains';
      path: '/email-domains';
      fullPath: '/admin-console/advanced/email-domains';
      preLoaderRoute: typeof AdminConsoleAdvancedEmailDomainsRouteImport;
      parentRoute: typeof AdminConsoleAdvancedRouteImport;
    };
    '/admin-console/advanced/jobs': {
      id: '/admin-console/advanced/jobs';
      path: '/jobs';
      fullPath: '/admin-console/advanced/jobs';
      preLoaderRoute: typeof AdminConsoleAdvancedJobsRouteImport;
      parentRoute: typeof AdminConsoleAdvancedRouteImport;
    };
    '/admin-console/configuration/obligations': {
      id: '/admin-console/configuration/obligations';
      path: '/obligations';
      fullPath: '/admin-console/configuration/obligations';
      preLoaderRoute: typeof AdminConsoleConfigurationObligationsRouteImport;
      parentRoute: typeof AdminConsoleConfigurationRouteImport;
    };
    '/console/$orgId/claim-module-only-home-page': {
      id: '/console/$orgId/claim-module-only-home-page';
      path: '/claim-module-only-home-page';
      fullPath: '/console/$orgId/claim-module-only-home-page';
      preLoaderRoute: typeof ConsoleOrgIdClaimModuleOnlyHomePageImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/claims': {
      id: '/console/$orgId/claims';
      path: '/claims';
      fullPath: '/console/$orgId/claims';
      preLoaderRoute: typeof ConsoleOrgIdClaimsRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/compliance': {
      id: '/console/$orgId/compliance';
      path: '/compliance';
      fullPath: '/console/$orgId/compliance';
      preLoaderRoute: typeof ConsoleOrgIdComplianceRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/configuration': {
      id: '/console/$orgId/configuration';
      path: '/configuration';
      fullPath: '/console/$orgId/configuration';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/controls': {
      id: '/console/$orgId/controls';
      path: '/controls';
      fullPath: '/console/$orgId/controls';
      preLoaderRoute: typeof ConsoleOrgIdControlsRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/dashboard': {
      id: '/console/$orgId/dashboard';
      path: '/dashboard';
      fullPath: '/console/$orgId/dashboard';
      preLoaderRoute: typeof ConsoleOrgIdDashboardRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/debug': {
      id: '/console/$orgId/debug';
      path: '/debug';
      fullPath: '/console/$orgId/debug';
      preLoaderRoute: typeof ConsoleOrgIdDebugRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/faq': {
      id: '/console/$orgId/faq';
      path: '/faq';
      fullPath: '/console/$orgId/faq';
      preLoaderRoute: typeof ConsoleOrgIdFaqRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/get-file': {
      id: '/console/$orgId/get-file';
      path: '/get-file';
      fullPath: '/console/$orgId/get-file';
      preLoaderRoute: typeof ConsoleOrgIdGetFileRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/reporting': {
      id: '/console/$orgId/reporting';
      path: '/reporting';
      fullPath: '/console/$orgId/reporting';
      preLoaderRoute: typeof ConsoleOrgIdReportingRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/risk': {
      id: '/console/$orgId/risk';
      path: '/risk';
      fullPath: '/console/$orgId/risk';
      preLoaderRoute: typeof ConsoleOrgIdRiskRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/tasks': {
      id: '/console/$orgId/tasks';
      path: '/tasks';
      fullPath: '/console/$orgId/tasks';
      preLoaderRoute: typeof ConsoleOrgIdTasksRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/tools': {
      id: '/console/$orgId/tools';
      path: '/tools';
      fullPath: '/console/$orgId/tools';
      preLoaderRoute: typeof ConsoleOrgIdToolsRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/public/lodgement-summary/$accessKey': {
      id: '/public/lodgement-summary/$accessKey';
      path: '/lodgement-summary/$accessKey';
      fullPath: '/public/lodgement-summary/$accessKey';
      preLoaderRoute: typeof PublicLodgementSummaryAccessKeyRouteImport;
      parentRoute: typeof PublicRouteImport;
    };
    '/public/new-complaint-by-key/$key': {
      id: '/public/new-complaint-by-key/$key';
      path: '/new-complaint-by-key/$key';
      fullPath: '/public/new-complaint-by-key/$key';
      preLoaderRoute: typeof PublicNewComplaintByKeyKeyRouteImport;
      parentRoute: typeof PublicRouteImport;
    };
    '/public/new-complaint/$orgId': {
      id: '/public/new-complaint/$orgId';
      path: '/new-complaint/$orgId';
      fullPath: '/public/new-complaint/$orgId';
      preLoaderRoute: typeof PublicNewComplaintOrgIdRouteImport;
      parentRoute: typeof PublicRouteImport;
    };
    '/admin-console/controls/library/': {
      id: '/admin-console/controls/library/';
      path: '/library';
      fullPath: '/admin-console/controls/library';
      preLoaderRoute: typeof AdminConsoleControlsLibraryIndexImport;
      parentRoute: typeof AdminConsoleControlsRouteImport;
    };
    '/admin-console/obligations/library/': {
      id: '/admin-console/obligations/library/';
      path: '/library';
      fullPath: '/admin-console/obligations/library';
      preLoaderRoute: typeof AdminConsoleObligationsLibraryIndexImport;
      parentRoute: typeof AdminConsoleObligationsRouteImport;
    };
    '/console/$orgId/dashboard/': {
      id: '/console/$orgId/dashboard/';
      path: '/';
      fullPath: '/console/$orgId/dashboard/';
      preLoaderRoute: typeof ConsoleOrgIdDashboardIndexImport;
      parentRoute: typeof ConsoleOrgIdDashboardRouteImport;
    };
    '/console/$orgId/tasks/': {
      id: '/console/$orgId/tasks/';
      path: '/';
      fullPath: '/console/$orgId/tasks/';
      preLoaderRoute: typeof ConsoleOrgIdTasksIndexImport;
      parentRoute: typeof ConsoleOrgIdTasksRouteImport;
    };
    '/admin-console/advanced/email-domains/add': {
      id: '/admin-console/advanced/email-domains/add';
      path: '/add';
      fullPath: '/admin-console/advanced/email-domains/add';
      preLoaderRoute: typeof AdminConsoleAdvancedEmailDomainsAddImport;
      parentRoute: typeof AdminConsoleAdvancedEmailDomainsRouteImport;
    };
    '/admin-console/advanced/jobs/on-demand': {
      id: '/admin-console/advanced/jobs/on-demand';
      path: '/on-demand';
      fullPath: '/admin-console/advanced/jobs/on-demand';
      preLoaderRoute: typeof AdminConsoleAdvancedJobsOnDemandImport;
      parentRoute: typeof AdminConsoleAdvancedJobsRouteImport;
    };
    '/admin-console/advanced/jobs/recurring': {
      id: '/admin-console/advanced/jobs/recurring';
      path: '/recurring';
      fullPath: '/admin-console/advanced/jobs/recurring';
      preLoaderRoute: typeof AdminConsoleAdvancedJobsRecurringImport;
      parentRoute: typeof AdminConsoleAdvancedJobsRouteImport;
    };
    '/admin-console/obligations/library/add': {
      id: '/admin-console/obligations/library/add';
      path: '/library/add';
      fullPath: '/admin-console/obligations/library/add';
      preLoaderRoute: typeof AdminConsoleObligationsLibraryAddRouteImport;
      parentRoute: typeof AdminConsoleObligationsRouteImport;
    };
    '/admin-console/organizations/$orgId/edit': {
      id: '/admin-console/organizations/$orgId/edit';
      path: '/organizations/$orgId/edit';
      fullPath: '/admin-console/organizations/$orgId/edit';
      preLoaderRoute: typeof AdminConsoleOrganizationsOrgIdEditImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/organizations/$orgId/manage-email-domains': {
      id: '/admin-console/organizations/$orgId/manage-email-domains';
      path: '/organizations/$orgId/manage-email-domains';
      fullPath: '/admin-console/organizations/$orgId/manage-email-domains';
      preLoaderRoute: typeof AdminConsoleOrganizationsOrgIdManageEmailDomainsImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/admin-console/organizations/$orgId/users': {
      id: '/admin-console/organizations/$orgId/users';
      path: '/organizations/$orgId/users';
      fullPath: '/admin-console/organizations/$orgId/users';
      preLoaderRoute: typeof AdminConsoleOrganizationsOrgIdUsersRouteImport;
      parentRoute: typeof AdminConsoleRouteImport;
    };
    '/console/$orgId/claims/lodge': {
      id: '/console/$orgId/claims/lodge';
      path: '/lodge';
      fullPath: '/console/$orgId/claims/lodge';
      preLoaderRoute: typeof ConsoleOrgIdClaimsLodgeRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsRouteImport;
    };
    '/console/$orgId/claims/search': {
      id: '/console/$orgId/claims/search';
      path: '/search';
      fullPath: '/console/$orgId/claims/search';
      preLoaderRoute: typeof ConsoleOrgIdClaimsSearchRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsRouteImport;
    };
    '/console/$orgId/compliance/incidents': {
      id: '/console/$orgId/compliance/incidents';
      path: '/incidents';
      fullPath: '/console/$orgId/compliance/incidents';
      preLoaderRoute: typeof ConsoleOrgIdComplianceIncidentsRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/configuration/assistance': {
      id: '/console/$orgId/configuration/assistance';
      path: '/assistance';
      fullPath: '/console/$orgId/configuration/assistance';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationAssistanceRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/claim': {
      id: '/console/$orgId/configuration/claim';
      path: '/claim';
      fullPath: '/console/$orgId/configuration/claim';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationClaimRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/comms-templates': {
      id: '/console/$orgId/configuration/comms-templates';
      path: '/comms-templates';
      fullPath: '/console/$orgId/configuration/comms-templates';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationCommsTemplatesRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/compliance': {
      id: '/console/$orgId/configuration/compliance';
      path: '/compliance';
      fullPath: '/console/$orgId/configuration/compliance';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationComplianceRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/documents': {
      id: '/console/$orgId/configuration/documents';
      path: '/documents';
      fullPath: '/console/$orgId/configuration/documents';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationDocumentsRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/expenses-and-billing': {
      id: '/console/$orgId/configuration/expenses-and-billing';
      path: '/expenses-and-billing';
      fullPath: '/console/$orgId/configuration/expenses-and-billing';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationExpensesAndBillingRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/handling-parties': {
      id: '/console/$orgId/configuration/handling-parties';
      path: '/handling-parties';
      fullPath: '/console/$orgId/configuration/handling-parties';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationHandlingPartiesRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/insurers': {
      id: '/console/$orgId/configuration/insurers';
      path: '/insurers';
      fullPath: '/console/$orgId/configuration/insurers';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationInsurersRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/payments': {
      id: '/console/$orgId/configuration/payments';
      path: '/payments';
      fullPath: '/console/$orgId/configuration/payments';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationPaymentsRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/product-types': {
      id: '/console/$orgId/configuration/product-types';
      path: '/product-types';
      fullPath: '/console/$orgId/configuration/product-types';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationProductTypesRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/questionnaire': {
      id: '/console/$orgId/configuration/questionnaire';
      path: '/questionnaire';
      fullPath: '/console/$orgId/configuration/questionnaire';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationQuestionnaireRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/risk': {
      id: '/console/$orgId/configuration/risk';
      path: '/risk';
      fullPath: '/console/$orgId/configuration/risk';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationRiskRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/statistics': {
      id: '/console/$orgId/configuration/statistics';
      path: '/statistics';
      fullPath: '/console/$orgId/configuration/statistics';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationStatisticsRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/suppliers': {
      id: '/console/$orgId/configuration/suppliers';
      path: '/suppliers';
      fullPath: '/console/$orgId/configuration/suppliers';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationSuppliersRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/system': {
      id: '/console/$orgId/configuration/system';
      path: '/system';
      fullPath: '/console/$orgId/configuration/system';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationSystemRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/task-manager': {
      id: '/console/$orgId/configuration/task-manager';
      path: '/task-manager';
      fullPath: '/console/$orgId/configuration/task-manager';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationTaskManagerRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/tasks': {
      id: '/console/$orgId/configuration/tasks';
      path: '/tasks';
      fullPath: '/console/$orgId/configuration/tasks';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationTasksRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/configuration/users': {
      id: '/console/$orgId/configuration/users';
      path: '/users';
      fullPath: '/console/$orgId/configuration/users';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationUsersRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRouteImport;
    };
    '/console/$orgId/dashboard/$section': {
      id: '/console/$orgId/dashboard/$section';
      path: '/$section';
      fullPath: '/console/$orgId/dashboard/$section';
      preLoaderRoute: typeof ConsoleOrgIdDashboardSectionRouteImport;
      parentRoute: typeof ConsoleOrgIdDashboardRouteImport;
    };
    '/console/$orgId/debug/test': {
      id: '/console/$orgId/debug/test';
      path: '/test';
      fullPath: '/console/$orgId/debug/test';
      preLoaderRoute: typeof ConsoleOrgIdDebugTestRouteImport;
      parentRoute: typeof ConsoleOrgIdDebugRouteImport;
    };
    '/console/$orgId/tools/ai-inbox': {
      id: '/console/$orgId/tools/ai-inbox';
      path: '/ai-inbox';
      fullPath: '/console/$orgId/tools/ai-inbox';
      preLoaderRoute: typeof ConsoleOrgIdToolsAiInboxRouteImport;
      parentRoute: typeof ConsoleOrgIdToolsRouteImport;
    };
    '/console/$orgId/tools/website-checker': {
      id: '/console/$orgId/tools/website-checker';
      path: '/website-checker';
      fullPath: '/console/$orgId/tools/website-checker';
      preLoaderRoute: typeof ConsoleOrgIdToolsWebsiteCheckerRouteImport;
      parentRoute: typeof ConsoleOrgIdToolsRouteImport;
    };
    '/console/$orgId/user-settings/notifications': {
      id: '/console/$orgId/user-settings/notifications';
      path: '/user-settings/notifications';
      fullPath: '/console/$orgId/user-settings/notifications';
      preLoaderRoute: typeof ConsoleOrgIdUserSettingsNotificationsRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/console/$orgId/user-settings/profile': {
      id: '/console/$orgId/user-settings/profile';
      path: '/user-settings/profile';
      fullPath: '/console/$orgId/user-settings/profile';
      preLoaderRoute: typeof ConsoleOrgIdUserSettingsProfileRouteImport;
      parentRoute: typeof ConsoleOrgIdRouteImport;
    };
    '/public/$orgId/complaint/new': {
      id: '/public/$orgId/complaint/new';
      path: '/complaint/new';
      fullPath: '/public/$orgId/complaint/new';
      preLoaderRoute: typeof PublicOrgIdComplaintNewRouteImport;
      parentRoute: typeof PublicOrgIdRouteImport;
    };
    '/console/$orgId/configuration/product-types/': {
      id: '/console/$orgId/configuration/product-types/';
      path: '/';
      fullPath: '/console/$orgId/configuration/product-types/';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationProductTypesIndexImport;
      parentRoute: typeof ConsoleOrgIdConfigurationProductTypesRouteImport;
    };
    '/console/$orgId/configuration/risk/': {
      id: '/console/$orgId/configuration/risk/';
      path: '/';
      fullPath: '/console/$orgId/configuration/risk/';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationRiskIndexImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRiskRouteImport;
    };
    '/console/$orgId/configuration/suppliers/': {
      id: '/console/$orgId/configuration/suppliers/';
      path: '/';
      fullPath: '/console/$orgId/configuration/suppliers/';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationSuppliersIndexImport;
      parentRoute: typeof ConsoleOrgIdConfigurationSuppliersRouteImport;
    };
    '/admin-console/advanced/email-domains/$id/edit': {
      id: '/admin-console/advanced/email-domains/$id/edit';
      path: '/$id/edit';
      fullPath: '/admin-console/advanced/email-domains/$id/edit';
      preLoaderRoute: typeof AdminConsoleAdvancedEmailDomainsIdEditImport;
      parentRoute: typeof AdminConsoleAdvancedEmailDomainsRouteImport;
    };
    '/admin-console/controls/library/$id/$section': {
      id: '/admin-console/controls/library/$id/$section';
      path: '/library/$id/$section';
      fullPath: '/admin-console/controls/library/$id/$section';
      preLoaderRoute: typeof AdminConsoleControlsLibraryIdSectionRouteImport;
      parentRoute: typeof AdminConsoleControlsRouteImport;
    };
    '/admin-console/obligations/library/$id/$section': {
      id: '/admin-console/obligations/library/$id/$section';
      path: '/library/$id/$section';
      fullPath: '/admin-console/obligations/library/$id/$section';
      preLoaderRoute: typeof AdminConsoleObligationsLibraryIdSectionRouteImport;
      parentRoute: typeof AdminConsoleObligationsRouteImport;
    };
    '/console/$orgId/claims/details/$id': {
      id: '/console/$orgId/claims/details/$id';
      path: '/details/$id';
      fullPath: '/console/$orgId/claims/details/$id';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsRouteImport;
    };
    '/console/$orgId/claims/edit/$id': {
      id: '/console/$orgId/claims/edit/$id';
      path: '/edit/$id';
      fullPath: '/console/$orgId/claims/edit/$id';
      preLoaderRoute: typeof ConsoleOrgIdClaimsEditIdRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsRouteImport;
    };
    '/console/$orgId/claims/view/$id': {
      id: '/console/$orgId/claims/view/$id';
      path: '/view/$id';
      fullPath: '/console/$orgId/claims/view/$id';
      preLoaderRoute: typeof ConsoleOrgIdClaimsViewIdRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsRouteImport;
    };
    '/console/$orgId/compliance/coi/add': {
      id: '/console/$orgId/compliance/coi/add';
      path: '/coi/add';
      fullPath: '/console/$orgId/compliance/coi/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceCoiAddRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/coi/search': {
      id: '/console/$orgId/compliance/coi/search';
      path: '/coi/search';
      fullPath: '/console/$orgId/compliance/coi/search';
      preLoaderRoute: typeof ConsoleOrgIdComplianceCoiSearchRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/complaints/add': {
      id: '/console/$orgId/compliance/complaints/add';
      path: '/complaints/add';
      fullPath: '/console/$orgId/compliance/complaints/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsAddRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/complaints/search': {
      id: '/console/$orgId/compliance/complaints/search';
      path: '/complaints/search';
      fullPath: '/console/$orgId/compliance/complaints/search';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsSearchRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/incidents/add': {
      id: '/console/$orgId/compliance/incidents/add';
      path: '/add';
      fullPath: '/console/$orgId/compliance/incidents/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceIncidentsAddImport;
      parentRoute: typeof ConsoleOrgIdComplianceIncidentsRouteImport;
    };
    '/console/$orgId/compliance/obligations/add': {
      id: '/console/$orgId/compliance/obligations/add';
      path: '/obligations/add';
      fullPath: '/console/$orgId/compliance/obligations/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceObligationsAddRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/obligations/search': {
      id: '/console/$orgId/compliance/obligations/search';
      path: '/obligations/search';
      fullPath: '/console/$orgId/compliance/obligations/search';
      preLoaderRoute: typeof ConsoleOrgIdComplianceObligationsSearchRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/training/search': {
      id: '/console/$orgId/compliance/training/search';
      path: '/training/search';
      fullPath: '/console/$orgId/compliance/training/search';
      preLoaderRoute: typeof ConsoleOrgIdComplianceTrainingSearchRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/configuration/risk/$section': {
      id: '/console/$orgId/configuration/risk/$section';
      path: '/$section';
      fullPath: '/console/$orgId/configuration/risk/$section';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationRiskSectionRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationRiskRouteImport;
    };
    '/console/$orgId/configuration/suppliers/add': {
      id: '/console/$orgId/configuration/suppliers/add';
      path: '/add';
      fullPath: '/console/$orgId/configuration/suppliers/add';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationSuppliersAddRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationSuppliersRouteImport;
    };
    '/console/$orgId/controls/register/add': {
      id: '/console/$orgId/controls/register/add';
      path: '/register/add';
      fullPath: '/console/$orgId/controls/register/add';
      preLoaderRoute: typeof ConsoleOrgIdControlsRegisterAddRouteImport;
      parentRoute: typeof ConsoleOrgIdControlsRouteImport;
    };
    '/console/$orgId/controls/register/search': {
      id: '/console/$orgId/controls/register/search';
      path: '/register/search';
      fullPath: '/console/$orgId/controls/register/search';
      preLoaderRoute: typeof ConsoleOrgIdControlsRegisterSearchRouteImport;
      parentRoute: typeof ConsoleOrgIdControlsRouteImport;
    };
    '/console/$orgId/controls/tests/search': {
      id: '/console/$orgId/controls/tests/search';
      path: '/tests/search';
      fullPath: '/console/$orgId/controls/tests/search';
      preLoaderRoute: typeof ConsoleOrgIdControlsTestsSearchRouteImport;
      parentRoute: typeof ConsoleOrgIdControlsRouteImport;
    };
    '/console/$orgId/risk/ras/search': {
      id: '/console/$orgId/risk/ras/search';
      path: '/ras/search';
      fullPath: '/console/$orgId/risk/ras/search';
      preLoaderRoute: typeof ConsoleOrgIdRiskRasSearchRouteImport;
      parentRoute: typeof ConsoleOrgIdRiskRouteImport;
    };
    '/console/$orgId/risk/register/search': {
      id: '/console/$orgId/risk/register/search';
      path: '/register/search';
      fullPath: '/console/$orgId/risk/register/search';
      preLoaderRoute: typeof ConsoleOrgIdRiskRegisterSearchRouteImport;
      parentRoute: typeof ConsoleOrgIdRiskRouteImport;
    };
    '/public/$orgId/invoice/view/$accessKey': {
      id: '/public/$orgId/invoice/view/$accessKey';
      path: '/invoice/view/$accessKey';
      fullPath: '/public/$orgId/invoice/view/$accessKey';
      preLoaderRoute: typeof PublicOrgIdInvoiceViewAccessKeyRouteImport;
      parentRoute: typeof PublicOrgIdRouteImport;
    };
    '/console/$orgId/configuration/product-types/$productTypeId/': {
      id: '/console/$orgId/configuration/product-types/$productTypeId/';
      path: '/$productTypeId';
      fullPath: '/console/$orgId/configuration/product-types/$productTypeId';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexImport;
      parentRoute: typeof ConsoleOrgIdConfigurationProductTypesRouteImport;
    };
    '/console/$orgId/claims/details/$id/comms': {
      id: '/console/$orgId/claims/details/$id/comms';
      path: '/comms';
      fullPath: '/console/$orgId/claims/details/$id/comms';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/contacts': {
      id: '/console/$orgId/claims/details/$id/contacts';
      path: '/contacts';
      fullPath: '/console/$orgId/claims/details/$id/contacts';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdContactsRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/expenses': {
      id: '/console/$orgId/claims/details/$id/expenses';
      path: '/expenses';
      fullPath: '/console/$orgId/claims/details/$id/expenses';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/financials': {
      id: '/console/$orgId/claims/details/$id/financials';
      path: '/financials';
      fullPath: '/console/$orgId/claims/details/$id/financials';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdFinancialsRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/history': {
      id: '/console/$orgId/claims/details/$id/history';
      path: '/history';
      fullPath: '/console/$orgId/claims/details/$id/history';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdHistoryRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/library': {
      id: '/console/$orgId/claims/details/$id/library';
      path: '/library';
      fullPath: '/console/$orgId/claims/details/$id/library';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdLibraryRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/manage': {
      id: '/console/$orgId/claims/details/$id/manage';
      path: '/manage';
      fullPath: '/console/$orgId/claims/details/$id/manage';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdManageRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/policy-info': {
      id: '/console/$orgId/claims/details/$id/policy-info';
      path: '/policy-info';
      fullPath: '/console/$orgId/claims/details/$id/policy-info';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/product-type-data': {
      id: '/console/$orgId/claims/details/$id/product-type-data';
      path: '/product-type-data';
      fullPath: '/console/$orgId/claims/details/$id/product-type-data';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteImport;
    };
    '/console/$orgId/compliance/complaints/$complaintId/edit': {
      id: '/console/$orgId/compliance/complaints/$complaintId/edit';
      path: '/complaints/$complaintId/edit';
      fullPath: '/console/$orgId/compliance/complaints/$complaintId/edit';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsComplaintIdEditRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/complaints/details/$id': {
      id: '/console/$orgId/compliance/complaints/details/$id';
      path: '/complaints/details/$id';
      fullPath: '/console/$orgId/compliance/complaints/details/$id';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/training/search/add-training': {
      id: '/console/$orgId/compliance/training/search/add-training';
      path: '/add-training';
      fullPath: '/console/$orgId/compliance/training/search/add-training';
      preLoaderRoute: typeof ConsoleOrgIdComplianceTrainingSearchAddTrainingImport;
      parentRoute: typeof ConsoleOrgIdComplianceTrainingSearchRouteImport;
    };
    '/console/$orgId/compliance/training/user/$userId': {
      id: '/console/$orgId/compliance/training/user/$userId';
      path: '/training/user/$userId';
      fullPath: '/console/$orgId/compliance/training/user/$userId';
      preLoaderRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/configuration/suppliers/details/$supplierId': {
      id: '/console/$orgId/configuration/suppliers/details/$supplierId';
      path: '/details/$supplierId';
      fullPath: '/console/$orgId/configuration/suppliers/details/$supplierId';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationSuppliersDetailsSupplierIdRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationSuppliersRouteImport;
    };
    '/console/$orgId/configuration/suppliers/edit/$supplierId': {
      id: '/console/$orgId/configuration/suppliers/edit/$supplierId';
      path: '/edit/$supplierId';
      fullPath: '/console/$orgId/configuration/suppliers/edit/$supplierId';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationSuppliersEditSupplierIdRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationSuppliersRouteImport;
    };
    '/console/$orgId/controls/register/edit/$id': {
      id: '/console/$orgId/controls/register/edit/$id';
      path: '/register/edit/$id';
      fullPath: '/console/$orgId/controls/register/edit/$id';
      preLoaderRoute: typeof ConsoleOrgIdControlsRegisterEditIdRouteImport;
      parentRoute: typeof ConsoleOrgIdControlsRouteImport;
    };
    '/console/$orgId/risk/ras/details/$id': {
      id: '/console/$orgId/risk/ras/details/$id';
      path: '/ras/details/$id';
      fullPath: '/console/$orgId/risk/ras/details/$id';
      preLoaderRoute: typeof ConsoleOrgIdRiskRasDetailsIdRouteImport;
      parentRoute: typeof ConsoleOrgIdRiskRouteImport;
    };
    '/console/$orgId/tools/ai-inbox/$id/review-email-classification': {
      id: '/console/$orgId/tools/ai-inbox/$id/review-email-classification';
      path: '/$id/review-email-classification';
      fullPath: '/console/$orgId/tools/ai-inbox/$id/review-email-classification';
      preLoaderRoute: typeof ConsoleOrgIdToolsAiInboxIdReviewEmailClassificationImport;
      parentRoute: typeof ConsoleOrgIdToolsAiInboxRouteImport;
    };
    '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId': {
      id: '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId';
      path: '/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId';
      fullPath: '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId';
      preLoaderRoute: typeof PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteImport;
      parentRoute: typeof PublicRouteImport;
    };
    '/console/$orgId/claims/details/$id/comms/add': {
      id: '/console/$orgId/claims/details/$id/comms/add';
      path: '/add';
      fullPath: '/console/$orgId/claims/details/$id/comms/add';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsAddRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsRouteImport;
    };
    '/console/$orgId/claims/details/$id/expenses/add': {
      id: '/console/$orgId/claims/details/$id/expenses/add';
      path: '/add';
      fullPath: '/console/$orgId/claims/details/$id/expenses/add';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesAddRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteImport;
    };
    '/console/$orgId/compliance/coi/details/$id/$section': {
      id: '/console/$orgId/compliance/coi/details/$id/$section';
      path: '/coi/details/$id/$section';
      fullPath: '/console/$orgId/compliance/coi/details/$id/$section';
      preLoaderRoute: typeof ConsoleOrgIdComplianceCoiDetailsIdSectionRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/complaints/details/$id/history': {
      id: '/console/$orgId/compliance/complaints/details/$id/history';
      path: '/history';
      fullPath: '/console/$orgId/compliance/complaints/details/$id/history';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteImport;
    };
    '/console/$orgId/compliance/complaints/details/$id/library': {
      id: '/console/$orgId/compliance/complaints/details/$id/library';
      path: '/library';
      fullPath: '/console/$orgId/compliance/complaints/details/$id/library';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteImport;
    };
    '/console/$orgId/compliance/complaints/details/$id/manage': {
      id: '/console/$orgId/compliance/complaints/details/$id/manage';
      path: '/manage';
      fullPath: '/console/$orgId/compliance/complaints/details/$id/manage';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteImport;
    };
    '/console/$orgId/compliance/incidents/details/$id/$section': {
      id: '/console/$orgId/compliance/incidents/details/$id/$section';
      path: '/details/$id/$section';
      fullPath: '/console/$orgId/compliance/incidents/details/$id/$section';
      preLoaderRoute: typeof ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceIncidentsRouteImport;
    };
    '/console/$orgId/compliance/obligations/details/$id/$section': {
      id: '/console/$orgId/compliance/obligations/details/$id/$section';
      path: '/obligations/details/$id/$section';
      fullPath: '/console/$orgId/compliance/obligations/details/$id/$section';
      preLoaderRoute: typeof ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceRouteImport;
    };
    '/console/$orgId/compliance/training/user/$userId/add': {
      id: '/console/$orgId/compliance/training/user/$userId/add';
      path: '/add';
      fullPath: '/console/$orgId/compliance/training/user/$userId/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdAddImport;
      parentRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteImport;
    };
    '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId': {
      id: '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId';
      path: '/$productTypeId/pds/$pdsId';
      fullPath: '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId';
      preLoaderRoute: typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteImport;
      parentRoute: typeof ConsoleOrgIdConfigurationProductTypesRouteImport;
    };
    '/console/$orgId/risk/register/details/$id/$section': {
      id: '/console/$orgId/risk/register/details/$id/$section';
      path: '/register/details/$id/$section';
      fullPath: '/console/$orgId/risk/register/details/$id/$section';
      preLoaderRoute: typeof ConsoleOrgIdRiskRegisterDetailsIdSectionRouteImport;
      parentRoute: typeof ConsoleOrgIdRiskRouteImport;
    };
    '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId': {
      id: '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId';
      path: '/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId';
      fullPath: '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId';
      preLoaderRoute: typeof PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteImport;
      parentRoute: typeof PublicOrgIdRouteImport;
    };
    '/console/$orgId/claims/details/$id/comms/message/$messageId': {
      id: '/console/$orgId/claims/details/$id/comms/message/$messageId';
      path: '/message/$messageId';
      fullPath: '/console/$orgId/claims/details/$id/comms/message/$messageId';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsRouteImport;
    };
    '/console/$orgId/claims/details/$id/expenses/$expenseId/edit': {
      id: '/console/$orgId/claims/details/$id/expenses/$expenseId/edit';
      path: '/$expenseId/edit';
      fullPath: '/console/$orgId/claims/details/$id/expenses/$expenseId/edit';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteImport;
    };
    '/console/$orgId/claims/details/$id/manage/flag/add': {
      id: '/console/$orgId/claims/details/$id/manage/flag/add';
      path: '/flag/add';
      fullPath: '/console/$orgId/claims/details/$id/manage/flag/add';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdManageRouteImport;
    };
    '/console/$orgId/claims/details/$id/manage/strategies/add': {
      id: '/console/$orgId/claims/details/$id/manage/strategies/add';
      path: '/strategies/add';
      fullPath: '/console/$orgId/claims/details/$id/manage/strategies/add';
      preLoaderRoute: typeof ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteImport;
      parentRoute: typeof ConsoleOrgIdClaimsDetailsIdManageRouteImport;
    };
    '/console/$orgId/compliance/training/user/$userId/edit/$recordId': {
      id: '/console/$orgId/compliance/training/user/$userId/edit/$recordId';
      path: '/edit/$recordId';
      fullPath: '/console/$orgId/compliance/training/user/$userId/edit/$recordId';
      preLoaderRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdImport;
      parentRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteImport;
    };
    '/console/$orgId/compliance/complaints/details/$id/manage/flag/add': {
      id: '/console/$orgId/compliance/complaints/details/$id/manage/flag/add';
      path: '/flag/add';
      fullPath: '/console/$orgId/compliance/complaints/details/$id/manage/flag/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteImport;
    };
    '/console/$orgId/compliance/complaints/details/$id/manage/note/add': {
      id: '/console/$orgId/compliance/complaints/details/$id/manage/note/add';
      path: '/note/add';
      fullPath: '/console/$orgId/compliance/complaints/details/$id/manage/note/add';
      preLoaderRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteImport;
      parentRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteImport;
    };
  }
}

// Create and export the route tree

interface AdminConsoleAdvancedEmailDomainsRouteRouteChildren {
  AdminConsoleAdvancedEmailDomainsAddRoute: typeof AdminConsoleAdvancedEmailDomainsAddRoute;
  AdminConsoleAdvancedEmailDomainsIdEditRoute: typeof AdminConsoleAdvancedEmailDomainsIdEditRoute;
}

const AdminConsoleAdvancedEmailDomainsRouteRouteChildren: AdminConsoleAdvancedEmailDomainsRouteRouteChildren =
  {
    AdminConsoleAdvancedEmailDomainsAddRoute:
      AdminConsoleAdvancedEmailDomainsAddRoute,
    AdminConsoleAdvancedEmailDomainsIdEditRoute:
      AdminConsoleAdvancedEmailDomainsIdEditRoute,
  };

const AdminConsoleAdvancedEmailDomainsRouteRouteWithChildren =
  AdminConsoleAdvancedEmailDomainsRouteRoute._addFileChildren(
    AdminConsoleAdvancedEmailDomainsRouteRouteChildren,
  );

interface AdminConsoleAdvancedJobsRouteRouteChildren {
  AdminConsoleAdvancedJobsOnDemandRoute: typeof AdminConsoleAdvancedJobsOnDemandRoute;
  AdminConsoleAdvancedJobsRecurringRoute: typeof AdminConsoleAdvancedJobsRecurringRoute;
}

const AdminConsoleAdvancedJobsRouteRouteChildren: AdminConsoleAdvancedJobsRouteRouteChildren =
  {
    AdminConsoleAdvancedJobsOnDemandRoute:
      AdminConsoleAdvancedJobsOnDemandRoute,
    AdminConsoleAdvancedJobsRecurringRoute:
      AdminConsoleAdvancedJobsRecurringRoute,
  };

const AdminConsoleAdvancedJobsRouteRouteWithChildren =
  AdminConsoleAdvancedJobsRouteRoute._addFileChildren(
    AdminConsoleAdvancedJobsRouteRouteChildren,
  );

interface AdminConsoleAdvancedRouteRouteChildren {
  AdminConsoleAdvancedAppSettingsRoute: typeof AdminConsoleAdvancedAppSettingsRoute;
  AdminConsoleAdvancedDbUpgradesRoute: typeof AdminConsoleAdvancedDbUpgradesRoute;
  AdminConsoleAdvancedEmailDomainsRouteRoute: typeof AdminConsoleAdvancedEmailDomainsRouteRouteWithChildren;
  AdminConsoleAdvancedJobsRouteRoute: typeof AdminConsoleAdvancedJobsRouteRouteWithChildren;
}

const AdminConsoleAdvancedRouteRouteChildren: AdminConsoleAdvancedRouteRouteChildren =
  {
    AdminConsoleAdvancedAppSettingsRoute: AdminConsoleAdvancedAppSettingsRoute,
    AdminConsoleAdvancedDbUpgradesRoute: AdminConsoleAdvancedDbUpgradesRoute,
    AdminConsoleAdvancedEmailDomainsRouteRoute:
      AdminConsoleAdvancedEmailDomainsRouteRouteWithChildren,
    AdminConsoleAdvancedJobsRouteRoute:
      AdminConsoleAdvancedJobsRouteRouteWithChildren,
  };

const AdminConsoleAdvancedRouteRouteWithChildren =
  AdminConsoleAdvancedRouteRoute._addFileChildren(
    AdminConsoleAdvancedRouteRouteChildren,
  );

interface AdminConsoleConfigurationRouteRouteChildren {
  AdminConsoleConfigurationObligationsRouteRoute: typeof AdminConsoleConfigurationObligationsRouteRoute;
}

const AdminConsoleConfigurationRouteRouteChildren: AdminConsoleConfigurationRouteRouteChildren =
  {
    AdminConsoleConfigurationObligationsRouteRoute:
      AdminConsoleConfigurationObligationsRouteRoute,
  };

const AdminConsoleConfigurationRouteRouteWithChildren =
  AdminConsoleConfigurationRouteRoute._addFileChildren(
    AdminConsoleConfigurationRouteRouteChildren,
  );

interface AdminConsoleControlsRouteRouteChildren {
  AdminConsoleControlsLibraryIndexRoute: typeof AdminConsoleControlsLibraryIndexRoute;
  AdminConsoleControlsLibraryIdSectionRouteRoute: typeof AdminConsoleControlsLibraryIdSectionRouteRoute;
}

const AdminConsoleControlsRouteRouteChildren: AdminConsoleControlsRouteRouteChildren =
  {
    AdminConsoleControlsLibraryIndexRoute:
      AdminConsoleControlsLibraryIndexRoute,
    AdminConsoleControlsLibraryIdSectionRouteRoute:
      AdminConsoleControlsLibraryIdSectionRouteRoute,
  };

const AdminConsoleControlsRouteRouteWithChildren =
  AdminConsoleControlsRouteRoute._addFileChildren(
    AdminConsoleControlsRouteRouteChildren,
  );

interface AdminConsoleObligationsRouteRouteChildren {
  AdminConsoleObligationsLibraryIndexRoute: typeof AdminConsoleObligationsLibraryIndexRoute;
  AdminConsoleObligationsLibraryAddRouteRoute: typeof AdminConsoleObligationsLibraryAddRouteRoute;
  AdminConsoleObligationsLibraryIdSectionRouteRoute: typeof AdminConsoleObligationsLibraryIdSectionRouteRoute;
}

const AdminConsoleObligationsRouteRouteChildren: AdminConsoleObligationsRouteRouteChildren =
  {
    AdminConsoleObligationsLibraryIndexRoute:
      AdminConsoleObligationsLibraryIndexRoute,
    AdminConsoleObligationsLibraryAddRouteRoute:
      AdminConsoleObligationsLibraryAddRouteRoute,
    AdminConsoleObligationsLibraryIdSectionRouteRoute:
      AdminConsoleObligationsLibraryIdSectionRouteRoute,
  };

const AdminConsoleObligationsRouteRouteWithChildren =
  AdminConsoleObligationsRouteRoute._addFileChildren(
    AdminConsoleObligationsRouteRouteChildren,
  );

interface AdminConsoleRouteRouteChildren {
  AdminConsoleIndexRoute: typeof AdminConsoleIndexRoute;
  AdminConsoleAdvancedRouteRoute: typeof AdminConsoleAdvancedRouteRouteWithChildren;
  AdminConsoleAiPromptsRouteRoute: typeof AdminConsoleAiPromptsRouteRoute;
  AdminConsoleCommTemplatesRouteRoute: typeof AdminConsoleCommTemplatesRouteRoute;
  AdminConsoleConfigurationRouteRoute: typeof AdminConsoleConfigurationRouteRouteWithChildren;
  AdminConsoleControlsRouteRoute: typeof AdminConsoleControlsRouteRouteWithChildren;
  AdminConsoleFaqRouteRoute: typeof AdminConsoleFaqRouteRoute;
  AdminConsoleNewOrganizationRouteRoute: typeof AdminConsoleNewOrganizationRouteRoute;
  AdminConsoleObligationsRouteRoute: typeof AdminConsoleObligationsRouteRouteWithChildren;
  AdminConsoleReportsRouteRoute: typeof AdminConsoleReportsRouteRoute;
  AdminConsoleUsersRouteRoute: typeof AdminConsoleUsersRouteRoute;
  AdminConsoleOrganizationsIndexRoute: typeof AdminConsoleOrganizationsIndexRoute;
  AdminConsoleOrganizationsOrgIdEditRoute: typeof AdminConsoleOrganizationsOrgIdEditRoute;
  AdminConsoleOrganizationsOrgIdManageEmailDomainsRoute: typeof AdminConsoleOrganizationsOrgIdManageEmailDomainsRoute;
  AdminConsoleOrganizationsOrgIdUsersRouteRoute: typeof AdminConsoleOrganizationsOrgIdUsersRouteRoute;
}

const AdminConsoleRouteRouteChildren: AdminConsoleRouteRouteChildren = {
  AdminConsoleIndexRoute: AdminConsoleIndexRoute,
  AdminConsoleAdvancedRouteRoute: AdminConsoleAdvancedRouteRouteWithChildren,
  AdminConsoleAiPromptsRouteRoute: AdminConsoleAiPromptsRouteRoute,
  AdminConsoleCommTemplatesRouteRoute: AdminConsoleCommTemplatesRouteRoute,
  AdminConsoleConfigurationRouteRoute:
    AdminConsoleConfigurationRouteRouteWithChildren,
  AdminConsoleControlsRouteRoute: AdminConsoleControlsRouteRouteWithChildren,
  AdminConsoleFaqRouteRoute: AdminConsoleFaqRouteRoute,
  AdminConsoleNewOrganizationRouteRoute: AdminConsoleNewOrganizationRouteRoute,
  AdminConsoleObligationsRouteRoute:
    AdminConsoleObligationsRouteRouteWithChildren,
  AdminConsoleReportsRouteRoute: AdminConsoleReportsRouteRoute,
  AdminConsoleUsersRouteRoute: AdminConsoleUsersRouteRoute,
  AdminConsoleOrganizationsIndexRoute: AdminConsoleOrganizationsIndexRoute,
  AdminConsoleOrganizationsOrgIdEditRoute:
    AdminConsoleOrganizationsOrgIdEditRoute,
  AdminConsoleOrganizationsOrgIdManageEmailDomainsRoute:
    AdminConsoleOrganizationsOrgIdManageEmailDomainsRoute,
  AdminConsoleOrganizationsOrgIdUsersRouteRoute:
    AdminConsoleOrganizationsOrgIdUsersRouteRoute,
};

const AdminConsoleRouteRouteWithChildren =
  AdminConsoleRouteRoute._addFileChildren(AdminConsoleRouteRouteChildren);

interface PublicOrgIdRouteRouteChildren {
  PublicOrgIdComplaintNewRouteRoute: typeof PublicOrgIdComplaintNewRouteRoute;
  PublicOrgIdInvoiceViewAccessKeyRouteRoute: typeof PublicOrgIdInvoiceViewAccessKeyRouteRoute;
  PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute: typeof PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
}

const PublicOrgIdRouteRouteChildren: PublicOrgIdRouteRouteChildren = {
  PublicOrgIdComplaintNewRouteRoute: PublicOrgIdComplaintNewRouteRoute,
  PublicOrgIdInvoiceViewAccessKeyRouteRoute:
    PublicOrgIdInvoiceViewAccessKeyRouteRoute,
  PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute:
    PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute,
};

const PublicOrgIdRouteRouteWithChildren =
  PublicOrgIdRouteRoute._addFileChildren(PublicOrgIdRouteRouteChildren);

interface PublicRouteRouteChildren {
  PublicOrgIdRouteRoute: typeof PublicOrgIdRouteRouteWithChildren;
  PublicLodgementSummaryAccessKeyRouteRoute: typeof PublicLodgementSummaryAccessKeyRouteRoute;
  PublicNewComplaintByKeyKeyRouteRoute: typeof PublicNewComplaintByKeyKeyRouteRoute;
  PublicNewComplaintOrgIdRouteRoute: typeof PublicNewComplaintOrgIdRouteRoute;
  PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute: typeof PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
}

const PublicRouteRouteChildren: PublicRouteRouteChildren = {
  PublicOrgIdRouteRoute: PublicOrgIdRouteRouteWithChildren,
  PublicLodgementSummaryAccessKeyRouteRoute:
    PublicLodgementSummaryAccessKeyRouteRoute,
  PublicNewComplaintByKeyKeyRouteRoute: PublicNewComplaintByKeyKeyRouteRoute,
  PublicNewComplaintOrgIdRouteRoute: PublicNewComplaintOrgIdRouteRoute,
  PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute:
    PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute,
};

const PublicRouteRouteWithChildren = PublicRouteRoute._addFileChildren(
  PublicRouteRouteChildren,
);

interface ConsoleOrgIdClaimsDetailsIdCommsRouteRouteChildren {
  ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute;
  ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute;
}

const ConsoleOrgIdClaimsDetailsIdCommsRouteRouteChildren: ConsoleOrgIdClaimsDetailsIdCommsRouteRouteChildren =
  {
    ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute:
      ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute,
    ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute:
      ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute,
  };

const ConsoleOrgIdClaimsDetailsIdCommsRouteRouteWithChildren =
  ConsoleOrgIdClaimsDetailsIdCommsRouteRoute._addFileChildren(
    ConsoleOrgIdClaimsDetailsIdCommsRouteRouteChildren,
  );

interface ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteChildren {
  ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute;
  ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute;
}

const ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteChildren: ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteChildren =
  {
    ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute:
      ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute,
    ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute:
      ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute,
  };

const ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteWithChildren =
  ConsoleOrgIdClaimsDetailsIdExpensesRouteRoute._addFileChildren(
    ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteChildren,
  );

interface ConsoleOrgIdClaimsDetailsIdManageRouteRouteChildren {
  ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute;
  ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute;
}

const ConsoleOrgIdClaimsDetailsIdManageRouteRouteChildren: ConsoleOrgIdClaimsDetailsIdManageRouteRouteChildren =
  {
    ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute:
      ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute,
    ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute:
      ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute,
  };

const ConsoleOrgIdClaimsDetailsIdManageRouteRouteWithChildren =
  ConsoleOrgIdClaimsDetailsIdManageRouteRoute._addFileChildren(
    ConsoleOrgIdClaimsDetailsIdManageRouteRouteChildren,
  );

interface ConsoleOrgIdClaimsDetailsIdRouteRouteChildren {
  ConsoleOrgIdClaimsDetailsIdCommsRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdCommsRouteRouteWithChildren;
  ConsoleOrgIdClaimsDetailsIdContactsRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdContactsRouteRoute;
  ConsoleOrgIdClaimsDetailsIdExpensesRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteWithChildren;
  ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute;
  ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute;
  ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute;
  ConsoleOrgIdClaimsDetailsIdManageRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdManageRouteRouteWithChildren;
  ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute;
  ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute;
}

const ConsoleOrgIdClaimsDetailsIdRouteRouteChildren: ConsoleOrgIdClaimsDetailsIdRouteRouteChildren =
  {
    ConsoleOrgIdClaimsDetailsIdCommsRouteRoute:
      ConsoleOrgIdClaimsDetailsIdCommsRouteRouteWithChildren,
    ConsoleOrgIdClaimsDetailsIdContactsRouteRoute:
      ConsoleOrgIdClaimsDetailsIdContactsRouteRoute,
    ConsoleOrgIdClaimsDetailsIdExpensesRouteRoute:
      ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteWithChildren,
    ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute:
      ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute,
    ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute:
      ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute,
    ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute:
      ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute,
    ConsoleOrgIdClaimsDetailsIdManageRouteRoute:
      ConsoleOrgIdClaimsDetailsIdManageRouteRouteWithChildren,
    ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute:
      ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute,
    ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute:
      ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute,
  };

const ConsoleOrgIdClaimsDetailsIdRouteRouteWithChildren =
  ConsoleOrgIdClaimsDetailsIdRouteRoute._addFileChildren(
    ConsoleOrgIdClaimsDetailsIdRouteRouteChildren,
  );

interface ConsoleOrgIdClaimsRouteRouteChildren {
  ConsoleOrgIdClaimsLodgeRouteRoute: typeof ConsoleOrgIdClaimsLodgeRouteRoute;
  ConsoleOrgIdClaimsSearchRouteRoute: typeof ConsoleOrgIdClaimsSearchRouteRoute;
  ConsoleOrgIdClaimsDetailsIdRouteRoute: typeof ConsoleOrgIdClaimsDetailsIdRouteRouteWithChildren;
  ConsoleOrgIdClaimsEditIdRouteRoute: typeof ConsoleOrgIdClaimsEditIdRouteRoute;
  ConsoleOrgIdClaimsViewIdRouteRoute: typeof ConsoleOrgIdClaimsViewIdRouteRoute;
}

const ConsoleOrgIdClaimsRouteRouteChildren: ConsoleOrgIdClaimsRouteRouteChildren =
  {
    ConsoleOrgIdClaimsLodgeRouteRoute: ConsoleOrgIdClaimsLodgeRouteRoute,
    ConsoleOrgIdClaimsSearchRouteRoute: ConsoleOrgIdClaimsSearchRouteRoute,
    ConsoleOrgIdClaimsDetailsIdRouteRoute:
      ConsoleOrgIdClaimsDetailsIdRouteRouteWithChildren,
    ConsoleOrgIdClaimsEditIdRouteRoute: ConsoleOrgIdClaimsEditIdRouteRoute,
    ConsoleOrgIdClaimsViewIdRouteRoute: ConsoleOrgIdClaimsViewIdRouteRoute,
  };

const ConsoleOrgIdClaimsRouteRouteWithChildren =
  ConsoleOrgIdClaimsRouteRoute._addFileChildren(
    ConsoleOrgIdClaimsRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceIncidentsRouteRouteChildren {
  ConsoleOrgIdComplianceIncidentsAddRoute: typeof ConsoleOrgIdComplianceIncidentsAddRoute;
  ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute: typeof ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute;
}

const ConsoleOrgIdComplianceIncidentsRouteRouteChildren: ConsoleOrgIdComplianceIncidentsRouteRouteChildren =
  {
    ConsoleOrgIdComplianceIncidentsAddRoute:
      ConsoleOrgIdComplianceIncidentsAddRoute,
    ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute:
      ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute,
  };

const ConsoleOrgIdComplianceIncidentsRouteRouteWithChildren =
  ConsoleOrgIdComplianceIncidentsRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceIncidentsRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceTrainingSearchRouteRouteChildren {
  ConsoleOrgIdComplianceTrainingSearchAddTrainingRoute: typeof ConsoleOrgIdComplianceTrainingSearchAddTrainingRoute;
}

const ConsoleOrgIdComplianceTrainingSearchRouteRouteChildren: ConsoleOrgIdComplianceTrainingSearchRouteRouteChildren =
  {
    ConsoleOrgIdComplianceTrainingSearchAddTrainingRoute:
      ConsoleOrgIdComplianceTrainingSearchAddTrainingRoute,
  };

const ConsoleOrgIdComplianceTrainingSearchRouteRouteWithChildren =
  ConsoleOrgIdComplianceTrainingSearchRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceTrainingSearchRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteChildren {
  ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute;
  ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute;
}

const ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteChildren: ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteChildren =
  {
    ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute:
      ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute,
    ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute:
      ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute,
  };

const ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteWithChildren =
  ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteChildren {
  ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute;
  ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute;
  ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteWithChildren;
}

const ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteChildren: ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteChildren =
  {
    ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute:
      ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute,
    ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute:
      ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute,
    ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRoute:
      ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteWithChildren,
  };

const ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteWithChildren =
  ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteChildren {
  ConsoleOrgIdComplianceTrainingUserUserIdAddRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdAddRoute;
  ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute;
}

const ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteChildren: ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteChildren =
  {
    ConsoleOrgIdComplianceTrainingUserUserIdAddRoute:
      ConsoleOrgIdComplianceTrainingUserUserIdAddRoute,
    ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute:
      ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute,
  };

const ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteWithChildren =
  ConsoleOrgIdComplianceTrainingUserUserIdRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteChildren,
  );

interface ConsoleOrgIdComplianceRouteRouteChildren {
  ConsoleOrgIdComplianceIncidentsRouteRoute: typeof ConsoleOrgIdComplianceIncidentsRouteRouteWithChildren;
  ConsoleOrgIdComplianceCoiAddRouteRoute: typeof ConsoleOrgIdComplianceCoiAddRouteRoute;
  ConsoleOrgIdComplianceCoiSearchRouteRoute: typeof ConsoleOrgIdComplianceCoiSearchRouteRoute;
  ConsoleOrgIdComplianceComplaintsAddRouteRoute: typeof ConsoleOrgIdComplianceComplaintsAddRouteRoute;
  ConsoleOrgIdComplianceComplaintsSearchRouteRoute: typeof ConsoleOrgIdComplianceComplaintsSearchRouteRoute;
  ConsoleOrgIdComplianceObligationsAddRouteRoute: typeof ConsoleOrgIdComplianceObligationsAddRouteRoute;
  ConsoleOrgIdComplianceObligationsSearchRouteRoute: typeof ConsoleOrgIdComplianceObligationsSearchRouteRoute;
  ConsoleOrgIdComplianceTrainingSearchRouteRoute: typeof ConsoleOrgIdComplianceTrainingSearchRouteRouteWithChildren;
  ConsoleOrgIdComplianceComplaintsComplaintIdEditRouteRoute: typeof ConsoleOrgIdComplianceComplaintsComplaintIdEditRouteRoute;
  ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute: typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteWithChildren;
  ConsoleOrgIdComplianceTrainingUserUserIdRouteRoute: typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteWithChildren;
  ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute: typeof ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute;
  ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute: typeof ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute;
}

const ConsoleOrgIdComplianceRouteRouteChildren: ConsoleOrgIdComplianceRouteRouteChildren =
  {
    ConsoleOrgIdComplianceIncidentsRouteRoute:
      ConsoleOrgIdComplianceIncidentsRouteRouteWithChildren,
    ConsoleOrgIdComplianceCoiAddRouteRoute:
      ConsoleOrgIdComplianceCoiAddRouteRoute,
    ConsoleOrgIdComplianceCoiSearchRouteRoute:
      ConsoleOrgIdComplianceCoiSearchRouteRoute,
    ConsoleOrgIdComplianceComplaintsAddRouteRoute:
      ConsoleOrgIdComplianceComplaintsAddRouteRoute,
    ConsoleOrgIdComplianceComplaintsSearchRouteRoute:
      ConsoleOrgIdComplianceComplaintsSearchRouteRoute,
    ConsoleOrgIdComplianceObligationsAddRouteRoute:
      ConsoleOrgIdComplianceObligationsAddRouteRoute,
    ConsoleOrgIdComplianceObligationsSearchRouteRoute:
      ConsoleOrgIdComplianceObligationsSearchRouteRoute,
    ConsoleOrgIdComplianceTrainingSearchRouteRoute:
      ConsoleOrgIdComplianceTrainingSearchRouteRouteWithChildren,
    ConsoleOrgIdComplianceComplaintsComplaintIdEditRouteRoute:
      ConsoleOrgIdComplianceComplaintsComplaintIdEditRouteRoute,
    ConsoleOrgIdComplianceComplaintsDetailsIdRouteRoute:
      ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteWithChildren,
    ConsoleOrgIdComplianceTrainingUserUserIdRouteRoute:
      ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteWithChildren,
    ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute:
      ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute,
    ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute:
      ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute,
  };

const ConsoleOrgIdComplianceRouteRouteWithChildren =
  ConsoleOrgIdComplianceRouteRoute._addFileChildren(
    ConsoleOrgIdComplianceRouteRouteChildren,
  );

interface ConsoleOrgIdConfigurationProductTypesRouteRouteChildren {
  ConsoleOrgIdConfigurationProductTypesIndexRoute: typeof ConsoleOrgIdConfigurationProductTypesIndexRoute;
  ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute: typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute;
  ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute: typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute;
}

const ConsoleOrgIdConfigurationProductTypesRouteRouteChildren: ConsoleOrgIdConfigurationProductTypesRouteRouteChildren =
  {
    ConsoleOrgIdConfigurationProductTypesIndexRoute:
      ConsoleOrgIdConfigurationProductTypesIndexRoute,
    ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute:
      ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute,
    ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute:
      ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute,
  };

const ConsoleOrgIdConfigurationProductTypesRouteRouteWithChildren =
  ConsoleOrgIdConfigurationProductTypesRouteRoute._addFileChildren(
    ConsoleOrgIdConfigurationProductTypesRouteRouteChildren,
  );

interface ConsoleOrgIdConfigurationRiskRouteRouteChildren {
  ConsoleOrgIdConfigurationRiskIndexRoute: typeof ConsoleOrgIdConfigurationRiskIndexRoute;
  ConsoleOrgIdConfigurationRiskSectionRouteRoute: typeof ConsoleOrgIdConfigurationRiskSectionRouteRoute;
}

const ConsoleOrgIdConfigurationRiskRouteRouteChildren: ConsoleOrgIdConfigurationRiskRouteRouteChildren =
  {
    ConsoleOrgIdConfigurationRiskIndexRoute:
      ConsoleOrgIdConfigurationRiskIndexRoute,
    ConsoleOrgIdConfigurationRiskSectionRouteRoute:
      ConsoleOrgIdConfigurationRiskSectionRouteRoute,
  };

const ConsoleOrgIdConfigurationRiskRouteRouteWithChildren =
  ConsoleOrgIdConfigurationRiskRouteRoute._addFileChildren(
    ConsoleOrgIdConfigurationRiskRouteRouteChildren,
  );

interface ConsoleOrgIdConfigurationSuppliersRouteRouteChildren {
  ConsoleOrgIdConfigurationSuppliersIndexRoute: typeof ConsoleOrgIdConfigurationSuppliersIndexRoute;
  ConsoleOrgIdConfigurationSuppliersAddRouteRoute: typeof ConsoleOrgIdConfigurationSuppliersAddRouteRoute;
  ConsoleOrgIdConfigurationSuppliersDetailsSupplierIdRouteRoute: typeof ConsoleOrgIdConfigurationSuppliersDetailsSupplierIdRouteRoute;
  ConsoleOrgIdConfigurationSuppliersEditSupplierIdRouteRoute: typeof ConsoleOrgIdConfigurationSuppliersEditSupplierIdRouteRoute;
}

const ConsoleOrgIdConfigurationSuppliersRouteRouteChildren: ConsoleOrgIdConfigurationSuppliersRouteRouteChildren =
  {
    ConsoleOrgIdConfigurationSuppliersIndexRoute:
      ConsoleOrgIdConfigurationSuppliersIndexRoute,
    ConsoleOrgIdConfigurationSuppliersAddRouteRoute:
      ConsoleOrgIdConfigurationSuppliersAddRouteRoute,
    ConsoleOrgIdConfigurationSuppliersDetailsSupplierIdRouteRoute:
      ConsoleOrgIdConfigurationSuppliersDetailsSupplierIdRouteRoute,
    ConsoleOrgIdConfigurationSuppliersEditSupplierIdRouteRoute:
      ConsoleOrgIdConfigurationSuppliersEditSupplierIdRouteRoute,
  };

const ConsoleOrgIdConfigurationSuppliersRouteRouteWithChildren =
  ConsoleOrgIdConfigurationSuppliersRouteRoute._addFileChildren(
    ConsoleOrgIdConfigurationSuppliersRouteRouteChildren,
  );

interface ConsoleOrgIdConfigurationRouteRouteChildren {
  ConsoleOrgIdConfigurationAssistanceRouteRoute: typeof ConsoleOrgIdConfigurationAssistanceRouteRoute;
  ConsoleOrgIdConfigurationClaimRouteRoute: typeof ConsoleOrgIdConfigurationClaimRouteRoute;
  ConsoleOrgIdConfigurationCommsTemplatesRouteRoute: typeof ConsoleOrgIdConfigurationCommsTemplatesRouteRoute;
  ConsoleOrgIdConfigurationComplianceRouteRoute: typeof ConsoleOrgIdConfigurationComplianceRouteRoute;
  ConsoleOrgIdConfigurationDocumentsRouteRoute: typeof ConsoleOrgIdConfigurationDocumentsRouteRoute;
  ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute: typeof ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute;
  ConsoleOrgIdConfigurationHandlingPartiesRouteRoute: typeof ConsoleOrgIdConfigurationHandlingPartiesRouteRoute;
  ConsoleOrgIdConfigurationInsurersRouteRoute: typeof ConsoleOrgIdConfigurationInsurersRouteRoute;
  ConsoleOrgIdConfigurationPaymentsRouteRoute: typeof ConsoleOrgIdConfigurationPaymentsRouteRoute;
  ConsoleOrgIdConfigurationProductTypesRouteRoute: typeof ConsoleOrgIdConfigurationProductTypesRouteRouteWithChildren;
  ConsoleOrgIdConfigurationQuestionnaireRouteRoute: typeof ConsoleOrgIdConfigurationQuestionnaireRouteRoute;
  ConsoleOrgIdConfigurationRiskRouteRoute: typeof ConsoleOrgIdConfigurationRiskRouteRouteWithChildren;
  ConsoleOrgIdConfigurationStatisticsRouteRoute: typeof ConsoleOrgIdConfigurationStatisticsRouteRoute;
  ConsoleOrgIdConfigurationSuppliersRouteRoute: typeof ConsoleOrgIdConfigurationSuppliersRouteRouteWithChildren;
  ConsoleOrgIdConfigurationSystemRouteRoute: typeof ConsoleOrgIdConfigurationSystemRouteRoute;
  ConsoleOrgIdConfigurationTaskManagerRouteRoute: typeof ConsoleOrgIdConfigurationTaskManagerRouteRoute;
  ConsoleOrgIdConfigurationTasksRouteRoute: typeof ConsoleOrgIdConfigurationTasksRouteRoute;
  ConsoleOrgIdConfigurationUsersRouteRoute: typeof ConsoleOrgIdConfigurationUsersRouteRoute;
}

const ConsoleOrgIdConfigurationRouteRouteChildren: ConsoleOrgIdConfigurationRouteRouteChildren =
  {
    ConsoleOrgIdConfigurationAssistanceRouteRoute:
      ConsoleOrgIdConfigurationAssistanceRouteRoute,
    ConsoleOrgIdConfigurationClaimRouteRoute:
      ConsoleOrgIdConfigurationClaimRouteRoute,
    ConsoleOrgIdConfigurationCommsTemplatesRouteRoute:
      ConsoleOrgIdConfigurationCommsTemplatesRouteRoute,
    ConsoleOrgIdConfigurationComplianceRouteRoute:
      ConsoleOrgIdConfigurationComplianceRouteRoute,
    ConsoleOrgIdConfigurationDocumentsRouteRoute:
      ConsoleOrgIdConfigurationDocumentsRouteRoute,
    ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute:
      ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute,
    ConsoleOrgIdConfigurationHandlingPartiesRouteRoute:
      ConsoleOrgIdConfigurationHandlingPartiesRouteRoute,
    ConsoleOrgIdConfigurationInsurersRouteRoute:
      ConsoleOrgIdConfigurationInsurersRouteRoute,
    ConsoleOrgIdConfigurationPaymentsRouteRoute:
      ConsoleOrgIdConfigurationPaymentsRouteRoute,
    ConsoleOrgIdConfigurationProductTypesRouteRoute:
      ConsoleOrgIdConfigurationProductTypesRouteRouteWithChildren,
    ConsoleOrgIdConfigurationQuestionnaireRouteRoute:
      ConsoleOrgIdConfigurationQuestionnaireRouteRoute,
    ConsoleOrgIdConfigurationRiskRouteRoute:
      ConsoleOrgIdConfigurationRiskRouteRouteWithChildren,
    ConsoleOrgIdConfigurationStatisticsRouteRoute:
      ConsoleOrgIdConfigurationStatisticsRouteRoute,
    ConsoleOrgIdConfigurationSuppliersRouteRoute:
      ConsoleOrgIdConfigurationSuppliersRouteRouteWithChildren,
    ConsoleOrgIdConfigurationSystemRouteRoute:
      ConsoleOrgIdConfigurationSystemRouteRoute,
    ConsoleOrgIdConfigurationTaskManagerRouteRoute:
      ConsoleOrgIdConfigurationTaskManagerRouteRoute,
    ConsoleOrgIdConfigurationTasksRouteRoute:
      ConsoleOrgIdConfigurationTasksRouteRoute,
    ConsoleOrgIdConfigurationUsersRouteRoute:
      ConsoleOrgIdConfigurationUsersRouteRoute,
  };

const ConsoleOrgIdConfigurationRouteRouteWithChildren =
  ConsoleOrgIdConfigurationRouteRoute._addFileChildren(
    ConsoleOrgIdConfigurationRouteRouteChildren,
  );

interface ConsoleOrgIdControlsRouteRouteChildren {
  ConsoleOrgIdControlsRegisterAddRouteRoute: typeof ConsoleOrgIdControlsRegisterAddRouteRoute;
  ConsoleOrgIdControlsRegisterSearchRouteRoute: typeof ConsoleOrgIdControlsRegisterSearchRouteRoute;
  ConsoleOrgIdControlsTestsSearchRouteRoute: typeof ConsoleOrgIdControlsTestsSearchRouteRoute;
  ConsoleOrgIdControlsRegisterEditIdRouteRoute: typeof ConsoleOrgIdControlsRegisterEditIdRouteRoute;
}

const ConsoleOrgIdControlsRouteRouteChildren: ConsoleOrgIdControlsRouteRouteChildren =
  {
    ConsoleOrgIdControlsRegisterAddRouteRoute:
      ConsoleOrgIdControlsRegisterAddRouteRoute,
    ConsoleOrgIdControlsRegisterSearchRouteRoute:
      ConsoleOrgIdControlsRegisterSearchRouteRoute,
    ConsoleOrgIdControlsTestsSearchRouteRoute:
      ConsoleOrgIdControlsTestsSearchRouteRoute,
    ConsoleOrgIdControlsRegisterEditIdRouteRoute:
      ConsoleOrgIdControlsRegisterEditIdRouteRoute,
  };

const ConsoleOrgIdControlsRouteRouteWithChildren =
  ConsoleOrgIdControlsRouteRoute._addFileChildren(
    ConsoleOrgIdControlsRouteRouteChildren,
  );

interface ConsoleOrgIdDashboardRouteRouteChildren {
  ConsoleOrgIdDashboardIndexRoute: typeof ConsoleOrgIdDashboardIndexRoute;
  ConsoleOrgIdDashboardSectionRouteRoute: typeof ConsoleOrgIdDashboardSectionRouteRoute;
}

const ConsoleOrgIdDashboardRouteRouteChildren: ConsoleOrgIdDashboardRouteRouteChildren =
  {
    ConsoleOrgIdDashboardIndexRoute: ConsoleOrgIdDashboardIndexRoute,
    ConsoleOrgIdDashboardSectionRouteRoute:
      ConsoleOrgIdDashboardSectionRouteRoute,
  };

const ConsoleOrgIdDashboardRouteRouteWithChildren =
  ConsoleOrgIdDashboardRouteRoute._addFileChildren(
    ConsoleOrgIdDashboardRouteRouteChildren,
  );

interface ConsoleOrgIdDebugRouteRouteChildren {
  ConsoleOrgIdDebugTestRouteRoute: typeof ConsoleOrgIdDebugTestRouteRoute;
}

const ConsoleOrgIdDebugRouteRouteChildren: ConsoleOrgIdDebugRouteRouteChildren =
  {
    ConsoleOrgIdDebugTestRouteRoute: ConsoleOrgIdDebugTestRouteRoute,
  };

const ConsoleOrgIdDebugRouteRouteWithChildren =
  ConsoleOrgIdDebugRouteRoute._addFileChildren(
    ConsoleOrgIdDebugRouteRouteChildren,
  );

interface ConsoleOrgIdRiskRouteRouteChildren {
  ConsoleOrgIdRiskRasSearchRouteRoute: typeof ConsoleOrgIdRiskRasSearchRouteRoute;
  ConsoleOrgIdRiskRegisterSearchRouteRoute: typeof ConsoleOrgIdRiskRegisterSearchRouteRoute;
  ConsoleOrgIdRiskRasDetailsIdRouteRoute: typeof ConsoleOrgIdRiskRasDetailsIdRouteRoute;
  ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute: typeof ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute;
}

const ConsoleOrgIdRiskRouteRouteChildren: ConsoleOrgIdRiskRouteRouteChildren = {
  ConsoleOrgIdRiskRasSearchRouteRoute: ConsoleOrgIdRiskRasSearchRouteRoute,
  ConsoleOrgIdRiskRegisterSearchRouteRoute:
    ConsoleOrgIdRiskRegisterSearchRouteRoute,
  ConsoleOrgIdRiskRasDetailsIdRouteRoute:
    ConsoleOrgIdRiskRasDetailsIdRouteRoute,
  ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute:
    ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute,
};

const ConsoleOrgIdRiskRouteRouteWithChildren =
  ConsoleOrgIdRiskRouteRoute._addFileChildren(
    ConsoleOrgIdRiskRouteRouteChildren,
  );

interface ConsoleOrgIdTasksRouteRouteChildren {
  ConsoleOrgIdTasksIndexRoute: typeof ConsoleOrgIdTasksIndexRoute;
}

const ConsoleOrgIdTasksRouteRouteChildren: ConsoleOrgIdTasksRouteRouteChildren =
  {
    ConsoleOrgIdTasksIndexRoute: ConsoleOrgIdTasksIndexRoute,
  };

const ConsoleOrgIdTasksRouteRouteWithChildren =
  ConsoleOrgIdTasksRouteRoute._addFileChildren(
    ConsoleOrgIdTasksRouteRouteChildren,
  );

interface ConsoleOrgIdToolsAiInboxRouteRouteChildren {
  ConsoleOrgIdToolsAiInboxIdReviewEmailClassificationRoute: typeof ConsoleOrgIdToolsAiInboxIdReviewEmailClassificationRoute;
}

const ConsoleOrgIdToolsAiInboxRouteRouteChildren: ConsoleOrgIdToolsAiInboxRouteRouteChildren =
  {
    ConsoleOrgIdToolsAiInboxIdReviewEmailClassificationRoute:
      ConsoleOrgIdToolsAiInboxIdReviewEmailClassificationRoute,
  };

const ConsoleOrgIdToolsAiInboxRouteRouteWithChildren =
  ConsoleOrgIdToolsAiInboxRouteRoute._addFileChildren(
    ConsoleOrgIdToolsAiInboxRouteRouteChildren,
  );

interface ConsoleOrgIdToolsRouteRouteChildren {
  ConsoleOrgIdToolsAiInboxRouteRoute: typeof ConsoleOrgIdToolsAiInboxRouteRouteWithChildren;
  ConsoleOrgIdToolsWebsiteCheckerRouteRoute: typeof ConsoleOrgIdToolsWebsiteCheckerRouteRoute;
}

const ConsoleOrgIdToolsRouteRouteChildren: ConsoleOrgIdToolsRouteRouteChildren =
  {
    ConsoleOrgIdToolsAiInboxRouteRoute:
      ConsoleOrgIdToolsAiInboxRouteRouteWithChildren,
    ConsoleOrgIdToolsWebsiteCheckerRouteRoute:
      ConsoleOrgIdToolsWebsiteCheckerRouteRoute,
  };

const ConsoleOrgIdToolsRouteRouteWithChildren =
  ConsoleOrgIdToolsRouteRoute._addFileChildren(
    ConsoleOrgIdToolsRouteRouteChildren,
  );

interface ConsoleOrgIdRouteRouteChildren {
  ConsoleOrgIdIndexRoute: typeof ConsoleOrgIdIndexRoute;
  ConsoleOrgIdClaimModuleOnlyHomePageRoute: typeof ConsoleOrgIdClaimModuleOnlyHomePageRoute;
  ConsoleOrgIdClaimsRouteRoute: typeof ConsoleOrgIdClaimsRouteRouteWithChildren;
  ConsoleOrgIdComplianceRouteRoute: typeof ConsoleOrgIdComplianceRouteRouteWithChildren;
  ConsoleOrgIdConfigurationRouteRoute: typeof ConsoleOrgIdConfigurationRouteRouteWithChildren;
  ConsoleOrgIdControlsRouteRoute: typeof ConsoleOrgIdControlsRouteRouteWithChildren;
  ConsoleOrgIdDashboardRouteRoute: typeof ConsoleOrgIdDashboardRouteRouteWithChildren;
  ConsoleOrgIdDebugRouteRoute: typeof ConsoleOrgIdDebugRouteRouteWithChildren;
  ConsoleOrgIdFaqRouteRoute: typeof ConsoleOrgIdFaqRouteRoute;
  ConsoleOrgIdGetFileRouteRoute: typeof ConsoleOrgIdGetFileRouteRoute;
  ConsoleOrgIdReportingRouteRoute: typeof ConsoleOrgIdReportingRouteRoute;
  ConsoleOrgIdRiskRouteRoute: typeof ConsoleOrgIdRiskRouteRouteWithChildren;
  ConsoleOrgIdTasksRouteRoute: typeof ConsoleOrgIdTasksRouteRouteWithChildren;
  ConsoleOrgIdToolsRouteRoute: typeof ConsoleOrgIdToolsRouteRouteWithChildren;
  ConsoleOrgIdUserSettingsNotificationsRouteRoute: typeof ConsoleOrgIdUserSettingsNotificationsRouteRoute;
  ConsoleOrgIdUserSettingsProfileRouteRoute: typeof ConsoleOrgIdUserSettingsProfileRouteRoute;
}

const ConsoleOrgIdRouteRouteChildren: ConsoleOrgIdRouteRouteChildren = {
  ConsoleOrgIdIndexRoute: ConsoleOrgIdIndexRoute,
  ConsoleOrgIdClaimModuleOnlyHomePageRoute:
    ConsoleOrgIdClaimModuleOnlyHomePageRoute,
  ConsoleOrgIdClaimsRouteRoute: ConsoleOrgIdClaimsRouteRouteWithChildren,
  ConsoleOrgIdComplianceRouteRoute:
    ConsoleOrgIdComplianceRouteRouteWithChildren,
  ConsoleOrgIdConfigurationRouteRoute:
    ConsoleOrgIdConfigurationRouteRouteWithChildren,
  ConsoleOrgIdControlsRouteRoute: ConsoleOrgIdControlsRouteRouteWithChildren,
  ConsoleOrgIdDashboardRouteRoute: ConsoleOrgIdDashboardRouteRouteWithChildren,
  ConsoleOrgIdDebugRouteRoute: ConsoleOrgIdDebugRouteRouteWithChildren,
  ConsoleOrgIdFaqRouteRoute: ConsoleOrgIdFaqRouteRoute,
  ConsoleOrgIdGetFileRouteRoute: ConsoleOrgIdGetFileRouteRoute,
  ConsoleOrgIdReportingRouteRoute: ConsoleOrgIdReportingRouteRoute,
  ConsoleOrgIdRiskRouteRoute: ConsoleOrgIdRiskRouteRouteWithChildren,
  ConsoleOrgIdTasksRouteRoute: ConsoleOrgIdTasksRouteRouteWithChildren,
  ConsoleOrgIdToolsRouteRoute: ConsoleOrgIdToolsRouteRouteWithChildren,
  ConsoleOrgIdUserSettingsNotificationsRouteRoute:
    ConsoleOrgIdUserSettingsNotificationsRouteRoute,
  ConsoleOrgIdUserSettingsProfileRouteRoute:
    ConsoleOrgIdUserSettingsProfileRouteRoute,
};

const ConsoleOrgIdRouteRouteWithChildren =
  ConsoleOrgIdRouteRoute._addFileChildren(ConsoleOrgIdRouteRouteChildren);

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute;
  '/admin-console': typeof AdminConsoleRouteRouteWithChildren;
  '/public': typeof PublicRouteRouteWithChildren;
  '/admin-console/': typeof AdminConsoleIndexRoute;
  '/admin-callback': typeof authenticationAdminCallbackRouteRoute;
  '/admin-login': typeof authenticationAdminLoginRouteRoute;
  '/callback': typeof authenticationCallbackRouteRoute;
  '/login': typeof authenticationLoginRouteRoute;
  '/logout': typeof authenticationLogoutRouteRoute;
  '/logoutin': typeof authenticationLogoutinRouteRoute;
  '/admin-console/advanced': typeof AdminConsoleAdvancedRouteRouteWithChildren;
  '/admin-console/ai-prompts': typeof AdminConsoleAiPromptsRouteRoute;
  '/admin-console/comm-templates': typeof AdminConsoleCommTemplatesRouteRoute;
  '/admin-console/configuration': typeof AdminConsoleConfigurationRouteRouteWithChildren;
  '/admin-console/controls': typeof AdminConsoleControlsRouteRouteWithChildren;
  '/admin-console/faq': typeof AdminConsoleFaqRouteRoute;
  '/admin-console/new-organization': typeof AdminConsoleNewOrganizationRouteRoute;
  '/admin-console/obligations': typeof AdminConsoleObligationsRouteRouteWithChildren;
  '/admin-console/reports': typeof AdminConsoleReportsRouteRoute;
  '/admin-console/users': typeof AdminConsoleUsersRouteRoute;
  '/console/$orgId': typeof ConsoleOrgIdRouteRouteWithChildren;
  '/public/$orgId': typeof PublicOrgIdRouteRouteWithChildren;
  '/admin-console/organizations': typeof AdminConsoleOrganizationsIndexRoute;
  '/console/$orgId/': typeof ConsoleOrgIdIndexRoute;
  '/change-org/$orgId': typeof authenticationChangeOrgOrgIdRouteRoute;
  '/admin-console/advanced/app-settings': typeof AdminConsoleAdvancedAppSettingsRoute;
  '/admin-console/advanced/db-upgrades': typeof AdminConsoleAdvancedDbUpgradesRoute;
  '/admin-console/advanced/email-domains': typeof AdminConsoleAdvancedEmailDomainsRouteRouteWithChildren;
  '/admin-console/advanced/jobs': typeof AdminConsoleAdvancedJobsRouteRouteWithChildren;
  '/admin-console/configuration/obligations': typeof AdminConsoleConfigurationObligationsRouteRoute;
  '/console/$orgId/claim-module-only-home-page': typeof ConsoleOrgIdClaimModuleOnlyHomePageRoute;
  '/console/$orgId/claims': typeof ConsoleOrgIdClaimsRouteRouteWithChildren;
  '/console/$orgId/compliance': typeof ConsoleOrgIdComplianceRouteRouteWithChildren;
  '/console/$orgId/configuration': typeof ConsoleOrgIdConfigurationRouteRouteWithChildren;
  '/console/$orgId/controls': typeof ConsoleOrgIdControlsRouteRouteWithChildren;
  '/console/$orgId/dashboard': typeof ConsoleOrgIdDashboardRouteRouteWithChildren;
  '/console/$orgId/debug': typeof ConsoleOrgIdDebugRouteRouteWithChildren;
  '/console/$orgId/faq': typeof ConsoleOrgIdFaqRouteRoute;
  '/console/$orgId/get-file': typeof ConsoleOrgIdGetFileRouteRoute;
  '/console/$orgId/reporting': typeof ConsoleOrgIdReportingRouteRoute;
  '/console/$orgId/risk': typeof ConsoleOrgIdRiskRouteRouteWithChildren;
  '/console/$orgId/tasks': typeof ConsoleOrgIdTasksRouteRouteWithChildren;
  '/console/$orgId/tools': typeof ConsoleOrgIdToolsRouteRouteWithChildren;
  '/public/lodgement-summary/$accessKey': typeof PublicLodgementSummaryAccessKeyRouteRoute;
  '/public/new-complaint-by-key/$key': typeof PublicNewComplaintByKeyKeyRouteRoute;
  '/public/new-complaint/$orgId': typeof PublicNewComplaintOrgIdRouteRoute;
  '/admin-console/controls/library': typeof AdminConsoleControlsLibraryIndexRoute;
  '/admin-console/obligations/library': typeof AdminConsoleObligationsLibraryIndexRoute;
  '/console/$orgId/dashboard/': typeof ConsoleOrgIdDashboardIndexRoute;
  '/console/$orgId/tasks/': typeof ConsoleOrgIdTasksIndexRoute;
  '/admin-console/advanced/email-domains/add': typeof AdminConsoleAdvancedEmailDomainsAddRoute;
  '/admin-console/advanced/jobs/on-demand': typeof AdminConsoleAdvancedJobsOnDemandRoute;
  '/admin-console/advanced/jobs/recurring': typeof AdminConsoleAdvancedJobsRecurringRoute;
  '/admin-console/obligations/library/add': typeof AdminConsoleObligationsLibraryAddRouteRoute;
  '/admin-console/organizations/$orgId/edit': typeof AdminConsoleOrganizationsOrgIdEditRoute;
  '/admin-console/organizations/$orgId/manage-email-domains': typeof AdminConsoleOrganizationsOrgIdManageEmailDomainsRoute;
  '/admin-console/organizations/$orgId/users': typeof AdminConsoleOrganizationsOrgIdUsersRouteRoute;
  '/console/$orgId/claims/lodge': typeof ConsoleOrgIdClaimsLodgeRouteRoute;
  '/console/$orgId/claims/search': typeof ConsoleOrgIdClaimsSearchRouteRoute;
  '/console/$orgId/compliance/incidents': typeof ConsoleOrgIdComplianceIncidentsRouteRouteWithChildren;
  '/console/$orgId/configuration/assistance': typeof ConsoleOrgIdConfigurationAssistanceRouteRoute;
  '/console/$orgId/configuration/claim': typeof ConsoleOrgIdConfigurationClaimRouteRoute;
  '/console/$orgId/configuration/comms-templates': typeof ConsoleOrgIdConfigurationCommsTemplatesRouteRoute;
  '/console/$orgId/configuration/compliance': typeof ConsoleOrgIdConfigurationComplianceRouteRoute;
  '/console/$orgId/configuration/documents': typeof ConsoleOrgIdConfigurationDocumentsRouteRoute;
  '/console/$orgId/configuration/expenses-and-billing': typeof ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute;
  '/console/$orgId/configuration/handling-parties': typeof ConsoleOrgIdConfigurationHandlingPartiesRouteRoute;
  '/console/$orgId/configuration/insurers': typeof ConsoleOrgIdConfigurationInsurersRouteRoute;
  '/console/$orgId/configuration/payments': typeof ConsoleOrgIdConfigurationPaymentsRouteRoute;
  '/console/$orgId/configuration/product-types': typeof ConsoleOrgIdConfigurationProductTypesRouteRouteWithChildren;
  '/console/$orgId/configuration/questionnaire': typeof ConsoleOrgIdConfigurationQuestionnaireRouteRoute;
  '/console/$orgId/configuration/risk': typeof ConsoleOrgIdConfigurationRiskRouteRouteWithChildren;
  '/console/$orgId/configuration/statistics': typeof ConsoleOrgIdConfigurationStatisticsRouteRoute;
  '/console/$orgId/configuration/suppliers': typeof ConsoleOrgIdConfigurationSuppliersRouteRouteWithChildren;
  '/console/$orgId/configuration/system': typeof ConsoleOrgIdConfigurationSystemRouteRoute;
  '/console/$orgId/configuration/task-manager': typeof ConsoleOrgIdConfigurationTaskManagerRouteRoute;
  '/console/$orgId/configuration/tasks': typeof ConsoleOrgIdConfigurationTasksRouteRoute;
  '/console/$orgId/configuration/users': typeof ConsoleOrgIdConfigurationUsersRouteRoute;
  '/console/$orgId/dashboard/$section': typeof ConsoleOrgIdDashboardSectionRouteRoute;
  '/console/$orgId/debug/test': typeof ConsoleOrgIdDebugTestRouteRoute;
  '/console/$orgId/tools/ai-inbox': typeof ConsoleOrgIdToolsAiInboxRouteRouteWithChildren;
  '/console/$orgId/tools/website-checker': typeof ConsoleOrgIdToolsWebsiteCheckerRouteRoute;
  '/console/$orgId/user-settings/notifications': typeof ConsoleOrgIdUserSettingsNotificationsRouteRoute;
  '/console/$orgId/user-settings/profile': typeof ConsoleOrgIdUserSettingsProfileRouteRoute;
  '/public/$orgId/complaint/new': typeof PublicOrgIdComplaintNewRouteRoute;
  '/console/$orgId/configuration/product-types/': typeof ConsoleOrgIdConfigurationProductTypesIndexRoute;
  '/console/$orgId/configuration/risk/': typeof ConsoleOrgIdConfigurationRiskIndexRoute;
  '/console/$orgId/configuration/suppliers/': typeof ConsoleOrgIdConfigurationSuppliersIndexRoute;
  '/admin-console/advanced/email-domains/$id/edit': typeof AdminConsoleAdvancedEmailDomainsIdEditRoute;
  '/admin-console/controls/library/$id/$section': typeof AdminConsoleControlsLibraryIdSectionRouteRoute;
  '/admin-console/obligations/library/$id/$section': typeof AdminConsoleObligationsLibraryIdSectionRouteRoute;
  '/console/$orgId/claims/details/$id': typeof ConsoleOrgIdClaimsDetailsIdRouteRouteWithChildren;
  '/console/$orgId/claims/edit/$id': typeof ConsoleOrgIdClaimsEditIdRouteRoute;
  '/console/$orgId/claims/view/$id': typeof ConsoleOrgIdClaimsViewIdRouteRoute;
  '/console/$orgId/compliance/coi/add': typeof ConsoleOrgIdComplianceCoiAddRouteRoute;
  '/console/$orgId/compliance/coi/search': typeof ConsoleOrgIdComplianceCoiSearchRouteRoute;
  '/console/$orgId/compliance/complaints/add': typeof ConsoleOrgIdComplianceComplaintsAddRouteRoute;
  '/console/$orgId/compliance/complaints/search': typeof ConsoleOrgIdComplianceComplaintsSearchRouteRoute;
  '/console/$orgId/compliance/incidents/add': typeof ConsoleOrgIdComplianceIncidentsAddRoute;
  '/console/$orgId/compliance/obligations/add': typeof ConsoleOrgIdComplianceObligationsAddRouteRoute;
  '/console/$orgId/compliance/obligations/search': typeof ConsoleOrgIdComplianceObligationsSearchRouteRoute;
  '/console/$orgId/compliance/training/search': typeof ConsoleOrgIdComplianceTrainingSearchRouteRouteWithChildren;
  '/console/$orgId/configuration/risk/$section': typeof ConsoleOrgIdConfigurationRiskSectionRouteRoute;
  '/console/$orgId/configuration/suppliers/add': typeof ConsoleOrgIdConfigurationSuppliersAddRouteRoute;
  '/console/$orgId/controls/register/add': typeof ConsoleOrgIdControlsRegisterAddRouteRoute;
  '/console/$orgId/controls/register/search': typeof ConsoleOrgIdControlsRegisterSearchRouteRoute;
  '/console/$orgId/controls/tests/search': typeof ConsoleOrgIdControlsTestsSearchRouteRoute;
  '/console/$orgId/risk/ras/search': typeof ConsoleOrgIdRiskRasSearchRouteRoute;
  '/console/$orgId/risk/register/search': typeof ConsoleOrgIdRiskRegisterSearchRouteRoute;
  '/public/$orgId/invoice/view/$accessKey': typeof PublicOrgIdInvoiceViewAccessKeyRouteRoute;
  '/console/$orgId/configuration/product-types/$productTypeId': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute;
  '/console/$orgId/claims/details/$id/comms': typeof ConsoleOrgIdClaimsDetailsIdCommsRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/contacts': typeof ConsoleOrgIdClaimsDetailsIdContactsRouteRoute;
  '/console/$orgId/claims/details/$id/expenses': typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/financials': typeof ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute;
  '/console/$orgId/claims/details/$id/history': typeof ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute;
  '/console/$orgId/claims/details/$id/library': typeof ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute;
  '/console/$orgId/claims/details/$id/manage': typeof ConsoleOrgIdClaimsDetailsIdManageRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/policy-info': typeof ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute;
  '/console/$orgId/claims/details/$id/product-type-data': typeof ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute;
  '/console/$orgId/compliance/complaints/$complaintId/edit': typeof ConsoleOrgIdComplianceComplaintsComplaintIdEditRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id': typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteWithChildren;
  '/console/$orgId/compliance/training/search/add-training': typeof ConsoleOrgIdComplianceTrainingSearchAddTrainingRoute;
  '/console/$orgId/compliance/training/user/$userId': typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteWithChildren;
  '/console/$orgId/configuration/suppliers/details/$supplierId': typeof ConsoleOrgIdConfigurationSuppliersDetailsSupplierIdRouteRoute;
  '/console/$orgId/configuration/suppliers/edit/$supplierId': typeof ConsoleOrgIdConfigurationSuppliersEditSupplierIdRouteRoute;
  '/console/$orgId/controls/register/edit/$id': typeof ConsoleOrgIdControlsRegisterEditIdRouteRoute;
  '/console/$orgId/risk/ras/details/$id': typeof ConsoleOrgIdRiskRasDetailsIdRouteRoute;
  '/console/$orgId/tools/ai-inbox/$id/review-email-classification': typeof ConsoleOrgIdToolsAiInboxIdReviewEmailClassificationRoute;
  '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId': typeof PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
  '/console/$orgId/claims/details/$id/comms/add': typeof ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute;
  '/console/$orgId/claims/details/$id/expenses/add': typeof ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute;
  '/console/$orgId/compliance/coi/details/$id/$section': typeof ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/history': typeof ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/library': typeof ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteWithChildren;
  '/console/$orgId/compliance/incidents/details/$id/$section': typeof ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/obligations/details/$id/$section': typeof ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/training/user/$userId/add': typeof ConsoleOrgIdComplianceTrainingUserUserIdAddRoute;
  '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute;
  '/console/$orgId/risk/register/details/$id/$section': typeof ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute;
  '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId': typeof PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
  '/console/$orgId/claims/details/$id/comms/message/$messageId': typeof ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute;
  '/console/$orgId/claims/details/$id/expenses/$expenseId/edit': typeof ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute;
  '/console/$orgId/claims/details/$id/manage/flag/add': typeof ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute;
  '/console/$orgId/claims/details/$id/manage/strategies/add': typeof ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute;
  '/console/$orgId/compliance/training/user/$userId/edit/$recordId': typeof ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage/flag/add': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage/note/add': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute;
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute;
  '/public': typeof PublicRouteRouteWithChildren;
  '/admin-console': typeof AdminConsoleIndexRoute;
  '/admin-callback': typeof authenticationAdminCallbackRouteRoute;
  '/admin-login': typeof authenticationAdminLoginRouteRoute;
  '/callback': typeof authenticationCallbackRouteRoute;
  '/login': typeof authenticationLoginRouteRoute;
  '/logout': typeof authenticationLogoutRouteRoute;
  '/logoutin': typeof authenticationLogoutinRouteRoute;
  '/admin-console/advanced': typeof AdminConsoleAdvancedRouteRouteWithChildren;
  '/admin-console/ai-prompts': typeof AdminConsoleAiPromptsRouteRoute;
  '/admin-console/comm-templates': typeof AdminConsoleCommTemplatesRouteRoute;
  '/admin-console/configuration': typeof AdminConsoleConfigurationRouteRouteWithChildren;
  '/admin-console/controls': typeof AdminConsoleControlsRouteRouteWithChildren;
  '/admin-console/faq': typeof AdminConsoleFaqRouteRoute;
  '/admin-console/new-organization': typeof AdminConsoleNewOrganizationRouteRoute;
  '/admin-console/obligations': typeof AdminConsoleObligationsRouteRouteWithChildren;
  '/admin-console/reports': typeof AdminConsoleReportsRouteRoute;
  '/admin-console/users': typeof AdminConsoleUsersRouteRoute;
  '/public/$orgId': typeof PublicOrgIdRouteRouteWithChildren;
  '/admin-console/organizations': typeof AdminConsoleOrganizationsIndexRoute;
  '/console/$orgId': typeof ConsoleOrgIdIndexRoute;
  '/change-org/$orgId': typeof authenticationChangeOrgOrgIdRouteRoute;
  '/admin-console/advanced/app-settings': typeof AdminConsoleAdvancedAppSettingsRoute;
  '/admin-console/advanced/db-upgrades': typeof AdminConsoleAdvancedDbUpgradesRoute;
  '/admin-console/advanced/email-domains': typeof AdminConsoleAdvancedEmailDomainsRouteRouteWithChildren;
  '/admin-console/advanced/jobs': typeof AdminConsoleAdvancedJobsRouteRouteWithChildren;
  '/admin-console/configuration/obligations': typeof AdminConsoleConfigurationObligationsRouteRoute;
  '/console/$orgId/claim-module-only-home-page': typeof ConsoleOrgIdClaimModuleOnlyHomePageRoute;
  '/console/$orgId/claims': typeof ConsoleOrgIdClaimsRouteRouteWithChildren;
  '/console/$orgId/compliance': typeof ConsoleOrgIdComplianceRouteRouteWithChildren;
  '/console/$orgId/configuration': typeof ConsoleOrgIdConfigurationRouteRouteWithChildren;
  '/console/$orgId/controls': typeof ConsoleOrgIdControlsRouteRouteWithChildren;
  '/console/$orgId/debug': typeof ConsoleOrgIdDebugRouteRouteWithChildren;
  '/console/$orgId/faq': typeof ConsoleOrgIdFaqRouteRoute;
  '/console/$orgId/get-file': typeof ConsoleOrgIdGetFileRouteRoute;
  '/console/$orgId/reporting': typeof ConsoleOrgIdReportingRouteRoute;
  '/console/$orgId/risk': typeof ConsoleOrgIdRiskRouteRouteWithChildren;
  '/console/$orgId/tools': typeof ConsoleOrgIdToolsRouteRouteWithChildren;
  '/public/lodgement-summary/$accessKey': typeof PublicLodgementSummaryAccessKeyRouteRoute;
  '/public/new-complaint-by-key/$key': typeof PublicNewComplaintByKeyKeyRouteRoute;
  '/public/new-complaint/$orgId': typeof PublicNewComplaintOrgIdRouteRoute;
  '/admin-console/controls/library': typeof AdminConsoleControlsLibraryIndexRoute;
  '/admin-console/obligations/library': typeof AdminConsoleObligationsLibraryIndexRoute;
  '/console/$orgId/dashboard': typeof ConsoleOrgIdDashboardIndexRoute;
  '/console/$orgId/tasks': typeof ConsoleOrgIdTasksIndexRoute;
  '/admin-console/advanced/email-domains/add': typeof AdminConsoleAdvancedEmailDomainsAddRoute;
  '/admin-console/advanced/jobs/on-demand': typeof AdminConsoleAdvancedJobsOnDemandRoute;
  '/admin-console/advanced/jobs/recurring': typeof AdminConsoleAdvancedJobsRecurringRoute;
  '/admin-console/obligations/library/add': typeof AdminConsoleObligationsLibraryAddRouteRoute;
  '/admin-console/organizations/$orgId/edit': typeof AdminConsoleOrganizationsOrgIdEditRoute;
  '/admin-console/organizations/$orgId/manage-email-domains': typeof AdminConsoleOrganizationsOrgIdManageEmailDomainsRoute;
  '/admin-console/organizations/$orgId/users': typeof AdminConsoleOrganizationsOrgIdUsersRouteRoute;
  '/console/$orgId/claims/lodge': typeof ConsoleOrgIdClaimsLodgeRouteRoute;
  '/console/$orgId/claims/search': typeof ConsoleOrgIdClaimsSearchRouteRoute;
  '/console/$orgId/compliance/incidents': typeof ConsoleOrgIdComplianceIncidentsRouteRouteWithChildren;
  '/console/$orgId/configuration/assistance': typeof ConsoleOrgIdConfigurationAssistanceRouteRoute;
  '/console/$orgId/configuration/claim': typeof ConsoleOrgIdConfigurationClaimRouteRoute;
  '/console/$orgId/configuration/comms-templates': typeof ConsoleOrgIdConfigurationCommsTemplatesRouteRoute;
  '/console/$orgId/configuration/compliance': typeof ConsoleOrgIdConfigurationComplianceRouteRoute;
  '/console/$orgId/configuration/documents': typeof ConsoleOrgIdConfigurationDocumentsRouteRoute;
  '/console/$orgId/configuration/expenses-and-billing': typeof ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute;
  '/console/$orgId/configuration/handling-parties': typeof ConsoleOrgIdConfigurationHandlingPartiesRouteRoute;
  '/console/$orgId/configuration/insurers': typeof ConsoleOrgIdConfigurationInsurersRouteRoute;
  '/console/$orgId/configuration/payments': typeof ConsoleOrgIdConfigurationPaymentsRouteRoute;
  '/console/$orgId/configuration/questionnaire': typeof ConsoleOrgIdConfigurationQuestionnaireRouteRoute;
  '/console/$orgId/configuration/statistics': typeof ConsoleOrgIdConfigurationStatisticsRouteRoute;
  '/console/$orgId/configuration/system': typeof ConsoleOrgIdConfigurationSystemRouteRoute;
  '/console/$orgId/configuration/task-manager': typeof ConsoleOrgIdConfigurationTaskManagerRouteRoute;
  '/console/$orgId/configuration/tasks': typeof ConsoleOrgIdConfigurationTasksRouteRoute;
  '/console/$orgId/configuration/users': typeof ConsoleOrgIdConfigurationUsersRouteRoute;
  '/console/$orgId/dashboard/$section': typeof ConsoleOrgIdDashboardSectionRouteRoute;
  '/console/$orgId/debug/test': typeof ConsoleOrgIdDebugTestRouteRoute;
  '/console/$orgId/tools/ai-inbox': typeof ConsoleOrgIdToolsAiInboxRouteRouteWithChildren;
  '/console/$orgId/tools/website-checker': typeof ConsoleOrgIdToolsWebsiteCheckerRouteRoute;
  '/console/$orgId/user-settings/notifications': typeof ConsoleOrgIdUserSettingsNotificationsRouteRoute;
  '/console/$orgId/user-settings/profile': typeof ConsoleOrgIdUserSettingsProfileRouteRoute;
  '/public/$orgId/complaint/new': typeof PublicOrgIdComplaintNewRouteRoute;
  '/console/$orgId/configuration/product-types': typeof ConsoleOrgIdConfigurationProductTypesIndexRoute;
  '/console/$orgId/configuration/risk': typeof ConsoleOrgIdConfigurationRiskIndexRoute;
  '/console/$orgId/configuration/suppliers': typeof ConsoleOrgIdConfigurationSuppliersIndexRoute;
  '/admin-console/advanced/email-domains/$id/edit': typeof AdminConsoleAdvancedEmailDomainsIdEditRoute;
  '/admin-console/controls/library/$id/$section': typeof AdminConsoleControlsLibraryIdSectionRouteRoute;
  '/admin-console/obligations/library/$id/$section': typeof AdminConsoleObligationsLibraryIdSectionRouteRoute;
  '/console/$orgId/claims/details/$id': typeof ConsoleOrgIdClaimsDetailsIdRouteRouteWithChildren;
  '/console/$orgId/claims/edit/$id': typeof ConsoleOrgIdClaimsEditIdRouteRoute;
  '/console/$orgId/claims/view/$id': typeof ConsoleOrgIdClaimsViewIdRouteRoute;
  '/console/$orgId/compliance/coi/add': typeof ConsoleOrgIdComplianceCoiAddRouteRoute;
  '/console/$orgId/compliance/coi/search': typeof ConsoleOrgIdComplianceCoiSearchRouteRoute;
  '/console/$orgId/compliance/complaints/add': typeof ConsoleOrgIdComplianceComplaintsAddRouteRoute;
  '/console/$orgId/compliance/complaints/search': typeof ConsoleOrgIdComplianceComplaintsSearchRouteRoute;
  '/console/$orgId/compliance/incidents/add': typeof ConsoleOrgIdComplianceIncidentsAddRoute;
  '/console/$orgId/compliance/obligations/add': typeof ConsoleOrgIdComplianceObligationsAddRouteRoute;
  '/console/$orgId/compliance/obligations/search': typeof ConsoleOrgIdComplianceObligationsSearchRouteRoute;
  '/console/$orgId/compliance/training/search': typeof ConsoleOrgIdComplianceTrainingSearchRouteRouteWithChildren;
  '/console/$orgId/configuration/risk/$section': typeof ConsoleOrgIdConfigurationRiskSectionRouteRoute;
  '/console/$orgId/configuration/suppliers/add': typeof ConsoleOrgIdConfigurationSuppliersAddRouteRoute;
  '/console/$orgId/controls/register/add': typeof ConsoleOrgIdControlsRegisterAddRouteRoute;
  '/console/$orgId/controls/register/search': typeof ConsoleOrgIdControlsRegisterSearchRouteRoute;
  '/console/$orgId/controls/tests/search': typeof ConsoleOrgIdControlsTestsSearchRouteRoute;
  '/console/$orgId/risk/ras/search': typeof ConsoleOrgIdRiskRasSearchRouteRoute;
  '/console/$orgId/risk/register/search': typeof ConsoleOrgIdRiskRegisterSearchRouteRoute;
  '/public/$orgId/invoice/view/$accessKey': typeof PublicOrgIdInvoiceViewAccessKeyRouteRoute;
  '/console/$orgId/configuration/product-types/$productTypeId': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute;
  '/console/$orgId/claims/details/$id/comms': typeof ConsoleOrgIdClaimsDetailsIdCommsRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/contacts': typeof ConsoleOrgIdClaimsDetailsIdContactsRouteRoute;
  '/console/$orgId/claims/details/$id/expenses': typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/financials': typeof ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute;
  '/console/$orgId/claims/details/$id/history': typeof ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute;
  '/console/$orgId/claims/details/$id/library': typeof ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute;
  '/console/$orgId/claims/details/$id/manage': typeof ConsoleOrgIdClaimsDetailsIdManageRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/policy-info': typeof ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute;
  '/console/$orgId/claims/details/$id/product-type-data': typeof ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute;
  '/console/$orgId/compliance/complaints/$complaintId/edit': typeof ConsoleOrgIdComplianceComplaintsComplaintIdEditRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id': typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteWithChildren;
  '/console/$orgId/compliance/training/search/add-training': typeof ConsoleOrgIdComplianceTrainingSearchAddTrainingRoute;
  '/console/$orgId/compliance/training/user/$userId': typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteWithChildren;
  '/console/$orgId/configuration/suppliers/details/$supplierId': typeof ConsoleOrgIdConfigurationSuppliersDetailsSupplierIdRouteRoute;
  '/console/$orgId/configuration/suppliers/edit/$supplierId': typeof ConsoleOrgIdConfigurationSuppliersEditSupplierIdRouteRoute;
  '/console/$orgId/controls/register/edit/$id': typeof ConsoleOrgIdControlsRegisterEditIdRouteRoute;
  '/console/$orgId/risk/ras/details/$id': typeof ConsoleOrgIdRiskRasDetailsIdRouteRoute;
  '/console/$orgId/tools/ai-inbox/$id/review-email-classification': typeof ConsoleOrgIdToolsAiInboxIdReviewEmailClassificationRoute;
  '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId': typeof PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
  '/console/$orgId/claims/details/$id/comms/add': typeof ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute;
  '/console/$orgId/claims/details/$id/expenses/add': typeof ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute;
  '/console/$orgId/compliance/coi/details/$id/$section': typeof ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/history': typeof ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/library': typeof ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteWithChildren;
  '/console/$orgId/compliance/incidents/details/$id/$section': typeof ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/obligations/details/$id/$section': typeof ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/training/user/$userId/add': typeof ConsoleOrgIdComplianceTrainingUserUserIdAddRoute;
  '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute;
  '/console/$orgId/risk/register/details/$id/$section': typeof ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute;
  '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId': typeof PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
  '/console/$orgId/claims/details/$id/comms/message/$messageId': typeof ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute;
  '/console/$orgId/claims/details/$id/expenses/$expenseId/edit': typeof ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute;
  '/console/$orgId/claims/details/$id/manage/flag/add': typeof ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute;
  '/console/$orgId/claims/details/$id/manage/strategies/add': typeof ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute;
  '/console/$orgId/compliance/training/user/$userId/edit/$recordId': typeof ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage/flag/add': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage/note/add': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/': typeof IndexRoute;
  '/admin-console': typeof AdminConsoleRouteRouteWithChildren;
  '/public': typeof PublicRouteRouteWithChildren;
  '/admin-console/': typeof AdminConsoleIndexRoute;
  '/(authentication)/admin-callback': typeof authenticationAdminCallbackRouteRoute;
  '/(authentication)/admin-login': typeof authenticationAdminLoginRouteRoute;
  '/(authentication)/callback': typeof authenticationCallbackRouteRoute;
  '/(authentication)/login': typeof authenticationLoginRouteRoute;
  '/(authentication)/logout': typeof authenticationLogoutRouteRoute;
  '/(authentication)/logoutin': typeof authenticationLogoutinRouteRoute;
  '/admin-console/advanced': typeof AdminConsoleAdvancedRouteRouteWithChildren;
  '/admin-console/ai-prompts': typeof AdminConsoleAiPromptsRouteRoute;
  '/admin-console/comm-templates': typeof AdminConsoleCommTemplatesRouteRoute;
  '/admin-console/configuration': typeof AdminConsoleConfigurationRouteRouteWithChildren;
  '/admin-console/controls': typeof AdminConsoleControlsRouteRouteWithChildren;
  '/admin-console/faq': typeof AdminConsoleFaqRouteRoute;
  '/admin-console/new-organization': typeof AdminConsoleNewOrganizationRouteRoute;
  '/admin-console/obligations': typeof AdminConsoleObligationsRouteRouteWithChildren;
  '/admin-console/reports': typeof AdminConsoleReportsRouteRoute;
  '/admin-console/users': typeof AdminConsoleUsersRouteRoute;
  '/console/$orgId': typeof ConsoleOrgIdRouteRouteWithChildren;
  '/public/$orgId': typeof PublicOrgIdRouteRouteWithChildren;
  '/admin-console/organizations/': typeof AdminConsoleOrganizationsIndexRoute;
  '/console/$orgId/': typeof ConsoleOrgIdIndexRoute;
  '/(authentication)/change-org/$orgId': typeof authenticationChangeOrgOrgIdRouteRoute;
  '/admin-console/advanced/app-settings': typeof AdminConsoleAdvancedAppSettingsRoute;
  '/admin-console/advanced/db-upgrades': typeof AdminConsoleAdvancedDbUpgradesRoute;
  '/admin-console/advanced/email-domains': typeof AdminConsoleAdvancedEmailDomainsRouteRouteWithChildren;
  '/admin-console/advanced/jobs': typeof AdminConsoleAdvancedJobsRouteRouteWithChildren;
  '/admin-console/configuration/obligations': typeof AdminConsoleConfigurationObligationsRouteRoute;
  '/console/$orgId/claim-module-only-home-page': typeof ConsoleOrgIdClaimModuleOnlyHomePageRoute;
  '/console/$orgId/claims': typeof ConsoleOrgIdClaimsRouteRouteWithChildren;
  '/console/$orgId/compliance': typeof ConsoleOrgIdComplianceRouteRouteWithChildren;
  '/console/$orgId/configuration': typeof ConsoleOrgIdConfigurationRouteRouteWithChildren;
  '/console/$orgId/controls': typeof ConsoleOrgIdControlsRouteRouteWithChildren;
  '/console/$orgId/dashboard': typeof ConsoleOrgIdDashboardRouteRouteWithChildren;
  '/console/$orgId/debug': typeof ConsoleOrgIdDebugRouteRouteWithChildren;
  '/console/$orgId/faq': typeof ConsoleOrgIdFaqRouteRoute;
  '/console/$orgId/get-file': typeof ConsoleOrgIdGetFileRouteRoute;
  '/console/$orgId/reporting': typeof ConsoleOrgIdReportingRouteRoute;
  '/console/$orgId/risk': typeof ConsoleOrgIdRiskRouteRouteWithChildren;
  '/console/$orgId/tasks': typeof ConsoleOrgIdTasksRouteRouteWithChildren;
  '/console/$orgId/tools': typeof ConsoleOrgIdToolsRouteRouteWithChildren;
  '/public/lodgement-summary/$accessKey': typeof PublicLodgementSummaryAccessKeyRouteRoute;
  '/public/new-complaint-by-key/$key': typeof PublicNewComplaintByKeyKeyRouteRoute;
  '/public/new-complaint/$orgId': typeof PublicNewComplaintOrgIdRouteRoute;
  '/admin-console/controls/library/': typeof AdminConsoleControlsLibraryIndexRoute;
  '/admin-console/obligations/library/': typeof AdminConsoleObligationsLibraryIndexRoute;
  '/console/$orgId/dashboard/': typeof ConsoleOrgIdDashboardIndexRoute;
  '/console/$orgId/tasks/': typeof ConsoleOrgIdTasksIndexRoute;
  '/admin-console/advanced/email-domains/add': typeof AdminConsoleAdvancedEmailDomainsAddRoute;
  '/admin-console/advanced/jobs/on-demand': typeof AdminConsoleAdvancedJobsOnDemandRoute;
  '/admin-console/advanced/jobs/recurring': typeof AdminConsoleAdvancedJobsRecurringRoute;
  '/admin-console/obligations/library/add': typeof AdminConsoleObligationsLibraryAddRouteRoute;
  '/admin-console/organizations/$orgId/edit': typeof AdminConsoleOrganizationsOrgIdEditRoute;
  '/admin-console/organizations/$orgId/manage-email-domains': typeof AdminConsoleOrganizationsOrgIdManageEmailDomainsRoute;
  '/admin-console/organizations/$orgId/users': typeof AdminConsoleOrganizationsOrgIdUsersRouteRoute;
  '/console/$orgId/claims/lodge': typeof ConsoleOrgIdClaimsLodgeRouteRoute;
  '/console/$orgId/claims/search': typeof ConsoleOrgIdClaimsSearchRouteRoute;
  '/console/$orgId/compliance/incidents': typeof ConsoleOrgIdComplianceIncidentsRouteRouteWithChildren;
  '/console/$orgId/configuration/assistance': typeof ConsoleOrgIdConfigurationAssistanceRouteRoute;
  '/console/$orgId/configuration/claim': typeof ConsoleOrgIdConfigurationClaimRouteRoute;
  '/console/$orgId/configuration/comms-templates': typeof ConsoleOrgIdConfigurationCommsTemplatesRouteRoute;
  '/console/$orgId/configuration/compliance': typeof ConsoleOrgIdConfigurationComplianceRouteRoute;
  '/console/$orgId/configuration/documents': typeof ConsoleOrgIdConfigurationDocumentsRouteRoute;
  '/console/$orgId/configuration/expenses-and-billing': typeof ConsoleOrgIdConfigurationExpensesAndBillingRouteRoute;
  '/console/$orgId/configuration/handling-parties': typeof ConsoleOrgIdConfigurationHandlingPartiesRouteRoute;
  '/console/$orgId/configuration/insurers': typeof ConsoleOrgIdConfigurationInsurersRouteRoute;
  '/console/$orgId/configuration/payments': typeof ConsoleOrgIdConfigurationPaymentsRouteRoute;
  '/console/$orgId/configuration/product-types': typeof ConsoleOrgIdConfigurationProductTypesRouteRouteWithChildren;
  '/console/$orgId/configuration/questionnaire': typeof ConsoleOrgIdConfigurationQuestionnaireRouteRoute;
  '/console/$orgId/configuration/risk': typeof ConsoleOrgIdConfigurationRiskRouteRouteWithChildren;
  '/console/$orgId/configuration/statistics': typeof ConsoleOrgIdConfigurationStatisticsRouteRoute;
  '/console/$orgId/configuration/suppliers': typeof ConsoleOrgIdConfigurationSuppliersRouteRouteWithChildren;
  '/console/$orgId/configuration/system': typeof ConsoleOrgIdConfigurationSystemRouteRoute;
  '/console/$orgId/configuration/task-manager': typeof ConsoleOrgIdConfigurationTaskManagerRouteRoute;
  '/console/$orgId/configuration/tasks': typeof ConsoleOrgIdConfigurationTasksRouteRoute;
  '/console/$orgId/configuration/users': typeof ConsoleOrgIdConfigurationUsersRouteRoute;
  '/console/$orgId/dashboard/$section': typeof ConsoleOrgIdDashboardSectionRouteRoute;
  '/console/$orgId/debug/test': typeof ConsoleOrgIdDebugTestRouteRoute;
  '/console/$orgId/tools/ai-inbox': typeof ConsoleOrgIdToolsAiInboxRouteRouteWithChildren;
  '/console/$orgId/tools/website-checker': typeof ConsoleOrgIdToolsWebsiteCheckerRouteRoute;
  '/console/$orgId/user-settings/notifications': typeof ConsoleOrgIdUserSettingsNotificationsRouteRoute;
  '/console/$orgId/user-settings/profile': typeof ConsoleOrgIdUserSettingsProfileRouteRoute;
  '/public/$orgId/complaint/new': typeof PublicOrgIdComplaintNewRouteRoute;
  '/console/$orgId/configuration/product-types/': typeof ConsoleOrgIdConfigurationProductTypesIndexRoute;
  '/console/$orgId/configuration/risk/': typeof ConsoleOrgIdConfigurationRiskIndexRoute;
  '/console/$orgId/configuration/suppliers/': typeof ConsoleOrgIdConfigurationSuppliersIndexRoute;
  '/admin-console/advanced/email-domains/$id/edit': typeof AdminConsoleAdvancedEmailDomainsIdEditRoute;
  '/admin-console/controls/library/$id/$section': typeof AdminConsoleControlsLibraryIdSectionRouteRoute;
  '/admin-console/obligations/library/$id/$section': typeof AdminConsoleObligationsLibraryIdSectionRouteRoute;
  '/console/$orgId/claims/details/$id': typeof ConsoleOrgIdClaimsDetailsIdRouteRouteWithChildren;
  '/console/$orgId/claims/edit/$id': typeof ConsoleOrgIdClaimsEditIdRouteRoute;
  '/console/$orgId/claims/view/$id': typeof ConsoleOrgIdClaimsViewIdRouteRoute;
  '/console/$orgId/compliance/coi/add': typeof ConsoleOrgIdComplianceCoiAddRouteRoute;
  '/console/$orgId/compliance/coi/search': typeof ConsoleOrgIdComplianceCoiSearchRouteRoute;
  '/console/$orgId/compliance/complaints/add': typeof ConsoleOrgIdComplianceComplaintsAddRouteRoute;
  '/console/$orgId/compliance/complaints/search': typeof ConsoleOrgIdComplianceComplaintsSearchRouteRoute;
  '/console/$orgId/compliance/incidents/add': typeof ConsoleOrgIdComplianceIncidentsAddRoute;
  '/console/$orgId/compliance/obligations/add': typeof ConsoleOrgIdComplianceObligationsAddRouteRoute;
  '/console/$orgId/compliance/obligations/search': typeof ConsoleOrgIdComplianceObligationsSearchRouteRoute;
  '/console/$orgId/compliance/training/search': typeof ConsoleOrgIdComplianceTrainingSearchRouteRouteWithChildren;
  '/console/$orgId/configuration/risk/$section': typeof ConsoleOrgIdConfigurationRiskSectionRouteRoute;
  '/console/$orgId/configuration/suppliers/add': typeof ConsoleOrgIdConfigurationSuppliersAddRouteRoute;
  '/console/$orgId/controls/register/add': typeof ConsoleOrgIdControlsRegisterAddRouteRoute;
  '/console/$orgId/controls/register/search': typeof ConsoleOrgIdControlsRegisterSearchRouteRoute;
  '/console/$orgId/controls/tests/search': typeof ConsoleOrgIdControlsTestsSearchRouteRoute;
  '/console/$orgId/risk/ras/search': typeof ConsoleOrgIdRiskRasSearchRouteRoute;
  '/console/$orgId/risk/register/search': typeof ConsoleOrgIdRiskRegisterSearchRouteRoute;
  '/public/$orgId/invoice/view/$accessKey': typeof PublicOrgIdInvoiceViewAccessKeyRouteRoute;
  '/console/$orgId/configuration/product-types/$productTypeId/': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdIndexRoute;
  '/console/$orgId/claims/details/$id/comms': typeof ConsoleOrgIdClaimsDetailsIdCommsRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/contacts': typeof ConsoleOrgIdClaimsDetailsIdContactsRouteRoute;
  '/console/$orgId/claims/details/$id/expenses': typeof ConsoleOrgIdClaimsDetailsIdExpensesRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/financials': typeof ConsoleOrgIdClaimsDetailsIdFinancialsRouteRoute;
  '/console/$orgId/claims/details/$id/history': typeof ConsoleOrgIdClaimsDetailsIdHistoryRouteRoute;
  '/console/$orgId/claims/details/$id/library': typeof ConsoleOrgIdClaimsDetailsIdLibraryRouteRoute;
  '/console/$orgId/claims/details/$id/manage': typeof ConsoleOrgIdClaimsDetailsIdManageRouteRouteWithChildren;
  '/console/$orgId/claims/details/$id/policy-info': typeof ConsoleOrgIdClaimsDetailsIdPolicyInfoRouteRoute;
  '/console/$orgId/claims/details/$id/product-type-data': typeof ConsoleOrgIdClaimsDetailsIdProductTypeDataRouteRoute;
  '/console/$orgId/compliance/complaints/$complaintId/edit': typeof ConsoleOrgIdComplianceComplaintsComplaintIdEditRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id': typeof ConsoleOrgIdComplianceComplaintsDetailsIdRouteRouteWithChildren;
  '/console/$orgId/compliance/training/search/add-training': typeof ConsoleOrgIdComplianceTrainingSearchAddTrainingRoute;
  '/console/$orgId/compliance/training/user/$userId': typeof ConsoleOrgIdComplianceTrainingUserUserIdRouteRouteWithChildren;
  '/console/$orgId/configuration/suppliers/details/$supplierId': typeof ConsoleOrgIdConfigurationSuppliersDetailsSupplierIdRouteRoute;
  '/console/$orgId/configuration/suppliers/edit/$supplierId': typeof ConsoleOrgIdConfigurationSuppliersEditSupplierIdRouteRoute;
  '/console/$orgId/controls/register/edit/$id': typeof ConsoleOrgIdControlsRegisterEditIdRouteRoute;
  '/console/$orgId/risk/ras/details/$id': typeof ConsoleOrgIdRiskRasDetailsIdRouteRoute;
  '/console/$orgId/tools/ai-inbox/$id/review-email-classification': typeof ConsoleOrgIdToolsAiInboxIdReviewEmailClassificationRoute;
  '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId': typeof PublicClaimLodgementOrgIdPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
  '/console/$orgId/claims/details/$id/comms/add': typeof ConsoleOrgIdClaimsDetailsIdCommsAddRouteRoute;
  '/console/$orgId/claims/details/$id/expenses/add': typeof ConsoleOrgIdClaimsDetailsIdExpensesAddRouteRoute;
  '/console/$orgId/compliance/coi/details/$id/$section': typeof ConsoleOrgIdComplianceCoiDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/history': typeof ConsoleOrgIdComplianceComplaintsDetailsIdHistoryRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/library': typeof ConsoleOrgIdComplianceComplaintsDetailsIdLibraryRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageRouteRouteWithChildren;
  '/console/$orgId/compliance/incidents/details/$id/$section': typeof ConsoleOrgIdComplianceIncidentsDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/obligations/details/$id/$section': typeof ConsoleOrgIdComplianceObligationsDetailsIdSectionRouteRoute;
  '/console/$orgId/compliance/training/user/$userId/add': typeof ConsoleOrgIdComplianceTrainingUserUserIdAddRoute;
  '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId': typeof ConsoleOrgIdConfigurationProductTypesProductTypeIdPdsPdsIdRouteRoute;
  '/console/$orgId/risk/register/details/$id/$section': typeof ConsoleOrgIdRiskRegisterDetailsIdSectionRouteRoute;
  '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId': typeof PublicOrgIdClaimLodgementPdsVersionIdQuestionnaireIdProductTypeIdRouteRoute;
  '/console/$orgId/claims/details/$id/comms/message/$messageId': typeof ConsoleOrgIdClaimsDetailsIdCommsMessageMessageIdRouteRoute;
  '/console/$orgId/claims/details/$id/expenses/$expenseId/edit': typeof ConsoleOrgIdClaimsDetailsIdExpensesExpenseIdEditRouteRoute;
  '/console/$orgId/claims/details/$id/manage/flag/add': typeof ConsoleOrgIdClaimsDetailsIdManageFlagAddRouteRoute;
  '/console/$orgId/claims/details/$id/manage/strategies/add': typeof ConsoleOrgIdClaimsDetailsIdManageStrategiesAddRouteRoute;
  '/console/$orgId/compliance/training/user/$userId/edit/$recordId': typeof ConsoleOrgIdComplianceTrainingUserUserIdEditRecordIdRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage/flag/add': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageFlagAddRouteRoute;
  '/console/$orgId/compliance/complaints/details/$id/manage/note/add': typeof ConsoleOrgIdComplianceComplaintsDetailsIdManageNoteAddRouteRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | '/'
    | '/admin-console'
    | '/public'
    | '/admin-console/'
    | '/admin-callback'
    | '/admin-login'
    | '/callback'
    | '/login'
    | '/logout'
    | '/logoutin'
    | '/admin-console/advanced'
    | '/admin-console/ai-prompts'
    | '/admin-console/comm-templates'
    | '/admin-console/configuration'
    | '/admin-console/controls'
    | '/admin-console/faq'
    | '/admin-console/new-organization'
    | '/admin-console/obligations'
    | '/admin-console/reports'
    | '/admin-console/users'
    | '/console/$orgId'
    | '/public/$orgId'
    | '/admin-console/organizations'
    | '/console/$orgId/'
    | '/change-org/$orgId'
    | '/admin-console/advanced/app-settings'
    | '/admin-console/advanced/db-upgrades'
    | '/admin-console/advanced/email-domains'
    | '/admin-console/advanced/jobs'
    | '/admin-console/configuration/obligations'
    | '/console/$orgId/claim-module-only-home-page'
    | '/console/$orgId/claims'
    | '/console/$orgId/compliance'
    | '/console/$orgId/configuration'
    | '/console/$orgId/controls'
    | '/console/$orgId/dashboard'
    | '/console/$orgId/debug'
    | '/console/$orgId/faq'
    | '/console/$orgId/get-file'
    | '/console/$orgId/reporting'
    | '/console/$orgId/risk'
    | '/console/$orgId/tasks'
    | '/console/$orgId/tools'
    | '/public/lodgement-summary/$accessKey'
    | '/public/new-complaint-by-key/$key'
    | '/public/new-complaint/$orgId'
    | '/admin-console/controls/library'
    | '/admin-console/obligations/library'
    | '/console/$orgId/dashboard/'
    | '/console/$orgId/tasks/'
    | '/admin-console/advanced/email-domains/add'
    | '/admin-console/advanced/jobs/on-demand'
    | '/admin-console/advanced/jobs/recurring'
    | '/admin-console/obligations/library/add'
    | '/admin-console/organizations/$orgId/edit'
    | '/admin-console/organizations/$orgId/manage-email-domains'
    | '/admin-console/organizations/$orgId/users'
    | '/console/$orgId/claims/lodge'
    | '/console/$orgId/claims/search'
    | '/console/$orgId/compliance/incidents'
    | '/console/$orgId/configuration/assistance'
    | '/console/$orgId/configuration/claim'
    | '/console/$orgId/configuration/comms-templates'
    | '/console/$orgId/configuration/compliance'
    | '/console/$orgId/configuration/documents'
    | '/console/$orgId/configuration/expenses-and-billing'
    | '/console/$orgId/configuration/handling-parties'
    | '/console/$orgId/configuration/insurers'
    | '/console/$orgId/configuration/payments'
    | '/console/$orgId/configuration/product-types'
    | '/console/$orgId/configuration/questionnaire'
    | '/console/$orgId/configuration/risk'
    | '/console/$orgId/configuration/statistics'
    | '/console/$orgId/configuration/suppliers'
    | '/console/$orgId/configuration/system'
    | '/console/$orgId/configuration/task-manager'
    | '/console/$orgId/configuration/tasks'
    | '/console/$orgId/configuration/users'
    | '/console/$orgId/dashboard/$section'
    | '/console/$orgId/debug/test'
    | '/console/$orgId/tools/ai-inbox'
    | '/console/$orgId/tools/website-checker'
    | '/console/$orgId/user-settings/notifications'
    | '/console/$orgId/user-settings/profile'
    | '/public/$orgId/complaint/new'
    | '/console/$orgId/configuration/product-types/'
    | '/console/$orgId/configuration/risk/'
    | '/console/$orgId/configuration/suppliers/'
    | '/admin-console/advanced/email-domains/$id/edit'
    | '/admin-console/controls/library/$id/$section'
    | '/admin-console/obligations/library/$id/$section'
    | '/console/$orgId/claims/details/$id'
    | '/console/$orgId/claims/edit/$id'
    | '/console/$orgId/claims/view/$id'
    | '/console/$orgId/compliance/coi/add'
    | '/console/$orgId/compliance/coi/search'
    | '/console/$orgId/compliance/complaints/add'
    | '/console/$orgId/compliance/complaints/search'
    | '/console/$orgId/compliance/incidents/add'
    | '/console/$orgId/compliance/obligations/add'
    | '/console/$orgId/compliance/obligations/search'
    | '/console/$orgId/compliance/training/search'
    | '/console/$orgId/configuration/risk/$section'
    | '/console/$orgId/configuration/suppliers/add'
    | '/console/$orgId/controls/register/add'
    | '/console/$orgId/controls/register/search'
    | '/console/$orgId/controls/tests/search'
    | '/console/$orgId/risk/ras/search'
    | '/console/$orgId/risk/register/search'
    | '/public/$orgId/invoice/view/$accessKey'
    | '/console/$orgId/configuration/product-types/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms'
    | '/console/$orgId/claims/details/$id/contacts'
    | '/console/$orgId/claims/details/$id/expenses'
    | '/console/$orgId/claims/details/$id/financials'
    | '/console/$orgId/claims/details/$id/history'
    | '/console/$orgId/claims/details/$id/library'
    | '/console/$orgId/claims/details/$id/manage'
    | '/console/$orgId/claims/details/$id/policy-info'
    | '/console/$orgId/claims/details/$id/product-type-data'
    | '/console/$orgId/compliance/complaints/$complaintId/edit'
    | '/console/$orgId/compliance/complaints/details/$id'
    | '/console/$orgId/compliance/training/search/add-training'
    | '/console/$orgId/compliance/training/user/$userId'
    | '/console/$orgId/configuration/suppliers/details/$supplierId'
    | '/console/$orgId/configuration/suppliers/edit/$supplierId'
    | '/console/$orgId/controls/register/edit/$id'
    | '/console/$orgId/risk/ras/details/$id'
    | '/console/$orgId/tools/ai-inbox/$id/review-email-classification'
    | '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms/add'
    | '/console/$orgId/claims/details/$id/expenses/add'
    | '/console/$orgId/compliance/coi/details/$id/$section'
    | '/console/$orgId/compliance/complaints/details/$id/history'
    | '/console/$orgId/compliance/complaints/details/$id/library'
    | '/console/$orgId/compliance/complaints/details/$id/manage'
    | '/console/$orgId/compliance/incidents/details/$id/$section'
    | '/console/$orgId/compliance/obligations/details/$id/$section'
    | '/console/$orgId/compliance/training/user/$userId/add'
    | '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId'
    | '/console/$orgId/risk/register/details/$id/$section'
    | '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms/message/$messageId'
    | '/console/$orgId/claims/details/$id/expenses/$expenseId/edit'
    | '/console/$orgId/claims/details/$id/manage/flag/add'
    | '/console/$orgId/claims/details/$id/manage/strategies/add'
    | '/console/$orgId/compliance/training/user/$userId/edit/$recordId'
    | '/console/$orgId/compliance/complaints/details/$id/manage/flag/add'
    | '/console/$orgId/compliance/complaints/details/$id/manage/note/add';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | '/'
    | '/public'
    | '/admin-console'
    | '/admin-callback'
    | '/admin-login'
    | '/callback'
    | '/login'
    | '/logout'
    | '/logoutin'
    | '/admin-console/advanced'
    | '/admin-console/ai-prompts'
    | '/admin-console/comm-templates'
    | '/admin-console/configuration'
    | '/admin-console/controls'
    | '/admin-console/faq'
    | '/admin-console/new-organization'
    | '/admin-console/obligations'
    | '/admin-console/reports'
    | '/admin-console/users'
    | '/public/$orgId'
    | '/admin-console/organizations'
    | '/console/$orgId'
    | '/change-org/$orgId'
    | '/admin-console/advanced/app-settings'
    | '/admin-console/advanced/db-upgrades'
    | '/admin-console/advanced/email-domains'
    | '/admin-console/advanced/jobs'
    | '/admin-console/configuration/obligations'
    | '/console/$orgId/claim-module-only-home-page'
    | '/console/$orgId/claims'
    | '/console/$orgId/compliance'
    | '/console/$orgId/configuration'
    | '/console/$orgId/controls'
    | '/console/$orgId/debug'
    | '/console/$orgId/faq'
    | '/console/$orgId/get-file'
    | '/console/$orgId/reporting'
    | '/console/$orgId/risk'
    | '/console/$orgId/tools'
    | '/public/lodgement-summary/$accessKey'
    | '/public/new-complaint-by-key/$key'
    | '/public/new-complaint/$orgId'
    | '/admin-console/controls/library'
    | '/admin-console/obligations/library'
    | '/console/$orgId/dashboard'
    | '/console/$orgId/tasks'
    | '/admin-console/advanced/email-domains/add'
    | '/admin-console/advanced/jobs/on-demand'
    | '/admin-console/advanced/jobs/recurring'
    | '/admin-console/obligations/library/add'
    | '/admin-console/organizations/$orgId/edit'
    | '/admin-console/organizations/$orgId/manage-email-domains'
    | '/admin-console/organizations/$orgId/users'
    | '/console/$orgId/claims/lodge'
    | '/console/$orgId/claims/search'
    | '/console/$orgId/compliance/incidents'
    | '/console/$orgId/configuration/assistance'
    | '/console/$orgId/configuration/claim'
    | '/console/$orgId/configuration/comms-templates'
    | '/console/$orgId/configuration/compliance'
    | '/console/$orgId/configuration/documents'
    | '/console/$orgId/configuration/expenses-and-billing'
    | '/console/$orgId/configuration/handling-parties'
    | '/console/$orgId/configuration/insurers'
    | '/console/$orgId/configuration/payments'
    | '/console/$orgId/configuration/questionnaire'
    | '/console/$orgId/configuration/statistics'
    | '/console/$orgId/configuration/system'
    | '/console/$orgId/configuration/task-manager'
    | '/console/$orgId/configuration/tasks'
    | '/console/$orgId/configuration/users'
    | '/console/$orgId/dashboard/$section'
    | '/console/$orgId/debug/test'
    | '/console/$orgId/tools/ai-inbox'
    | '/console/$orgId/tools/website-checker'
    | '/console/$orgId/user-settings/notifications'
    | '/console/$orgId/user-settings/profile'
    | '/public/$orgId/complaint/new'
    | '/console/$orgId/configuration/product-types'
    | '/console/$orgId/configuration/risk'
    | '/console/$orgId/configuration/suppliers'
    | '/admin-console/advanced/email-domains/$id/edit'
    | '/admin-console/controls/library/$id/$section'
    | '/admin-console/obligations/library/$id/$section'
    | '/console/$orgId/claims/details/$id'
    | '/console/$orgId/claims/edit/$id'
    | '/console/$orgId/claims/view/$id'
    | '/console/$orgId/compliance/coi/add'
    | '/console/$orgId/compliance/coi/search'
    | '/console/$orgId/compliance/complaints/add'
    | '/console/$orgId/compliance/complaints/search'
    | '/console/$orgId/compliance/incidents/add'
    | '/console/$orgId/compliance/obligations/add'
    | '/console/$orgId/compliance/obligations/search'
    | '/console/$orgId/compliance/training/search'
    | '/console/$orgId/configuration/risk/$section'
    | '/console/$orgId/configuration/suppliers/add'
    | '/console/$orgId/controls/register/add'
    | '/console/$orgId/controls/register/search'
    | '/console/$orgId/controls/tests/search'
    | '/console/$orgId/risk/ras/search'
    | '/console/$orgId/risk/register/search'
    | '/public/$orgId/invoice/view/$accessKey'
    | '/console/$orgId/configuration/product-types/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms'
    | '/console/$orgId/claims/details/$id/contacts'
    | '/console/$orgId/claims/details/$id/expenses'
    | '/console/$orgId/claims/details/$id/financials'
    | '/console/$orgId/claims/details/$id/history'
    | '/console/$orgId/claims/details/$id/library'
    | '/console/$orgId/claims/details/$id/manage'
    | '/console/$orgId/claims/details/$id/policy-info'
    | '/console/$orgId/claims/details/$id/product-type-data'
    | '/console/$orgId/compliance/complaints/$complaintId/edit'
    | '/console/$orgId/compliance/complaints/details/$id'
    | '/console/$orgId/compliance/training/search/add-training'
    | '/console/$orgId/compliance/training/user/$userId'
    | '/console/$orgId/configuration/suppliers/details/$supplierId'
    | '/console/$orgId/configuration/suppliers/edit/$supplierId'
    | '/console/$orgId/controls/register/edit/$id'
    | '/console/$orgId/risk/ras/details/$id'
    | '/console/$orgId/tools/ai-inbox/$id/review-email-classification'
    | '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms/add'
    | '/console/$orgId/claims/details/$id/expenses/add'
    | '/console/$orgId/compliance/coi/details/$id/$section'
    | '/console/$orgId/compliance/complaints/details/$id/history'
    | '/console/$orgId/compliance/complaints/details/$id/library'
    | '/console/$orgId/compliance/complaints/details/$id/manage'
    | '/console/$orgId/compliance/incidents/details/$id/$section'
    | '/console/$orgId/compliance/obligations/details/$id/$section'
    | '/console/$orgId/compliance/training/user/$userId/add'
    | '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId'
    | '/console/$orgId/risk/register/details/$id/$section'
    | '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms/message/$messageId'
    | '/console/$orgId/claims/details/$id/expenses/$expenseId/edit'
    | '/console/$orgId/claims/details/$id/manage/flag/add'
    | '/console/$orgId/claims/details/$id/manage/strategies/add'
    | '/console/$orgId/compliance/training/user/$userId/edit/$recordId'
    | '/console/$orgId/compliance/complaints/details/$id/manage/flag/add'
    | '/console/$orgId/compliance/complaints/details/$id/manage/note/add';
  id:
    | '__root__'
    | '/'
    | '/admin-console'
    | '/public'
    | '/admin-console/'
    | '/(authentication)/admin-callback'
    | '/(authentication)/admin-login'
    | '/(authentication)/callback'
    | '/(authentication)/login'
    | '/(authentication)/logout'
    | '/(authentication)/logoutin'
    | '/admin-console/advanced'
    | '/admin-console/ai-prompts'
    | '/admin-console/comm-templates'
    | '/admin-console/configuration'
    | '/admin-console/controls'
    | '/admin-console/faq'
    | '/admin-console/new-organization'
    | '/admin-console/obligations'
    | '/admin-console/reports'
    | '/admin-console/users'
    | '/console/$orgId'
    | '/public/$orgId'
    | '/admin-console/organizations/'
    | '/console/$orgId/'
    | '/(authentication)/change-org/$orgId'
    | '/admin-console/advanced/app-settings'
    | '/admin-console/advanced/db-upgrades'
    | '/admin-console/advanced/email-domains'
    | '/admin-console/advanced/jobs'
    | '/admin-console/configuration/obligations'
    | '/console/$orgId/claim-module-only-home-page'
    | '/console/$orgId/claims'
    | '/console/$orgId/compliance'
    | '/console/$orgId/configuration'
    | '/console/$orgId/controls'
    | '/console/$orgId/dashboard'
    | '/console/$orgId/debug'
    | '/console/$orgId/faq'
    | '/console/$orgId/get-file'
    | '/console/$orgId/reporting'
    | '/console/$orgId/risk'
    | '/console/$orgId/tasks'
    | '/console/$orgId/tools'
    | '/public/lodgement-summary/$accessKey'
    | '/public/new-complaint-by-key/$key'
    | '/public/new-complaint/$orgId'
    | '/admin-console/controls/library/'
    | '/admin-console/obligations/library/'
    | '/console/$orgId/dashboard/'
    | '/console/$orgId/tasks/'
    | '/admin-console/advanced/email-domains/add'
    | '/admin-console/advanced/jobs/on-demand'
    | '/admin-console/advanced/jobs/recurring'
    | '/admin-console/obligations/library/add'
    | '/admin-console/organizations/$orgId/edit'
    | '/admin-console/organizations/$orgId/manage-email-domains'
    | '/admin-console/organizations/$orgId/users'
    | '/console/$orgId/claims/lodge'
    | '/console/$orgId/claims/search'
    | '/console/$orgId/compliance/incidents'
    | '/console/$orgId/configuration/assistance'
    | '/console/$orgId/configuration/claim'
    | '/console/$orgId/configuration/comms-templates'
    | '/console/$orgId/configuration/compliance'
    | '/console/$orgId/configuration/documents'
    | '/console/$orgId/configuration/expenses-and-billing'
    | '/console/$orgId/configuration/handling-parties'
    | '/console/$orgId/configuration/insurers'
    | '/console/$orgId/configuration/payments'
    | '/console/$orgId/configuration/product-types'
    | '/console/$orgId/configuration/questionnaire'
    | '/console/$orgId/configuration/risk'
    | '/console/$orgId/configuration/statistics'
    | '/console/$orgId/configuration/suppliers'
    | '/console/$orgId/configuration/system'
    | '/console/$orgId/configuration/task-manager'
    | '/console/$orgId/configuration/tasks'
    | '/console/$orgId/configuration/users'
    | '/console/$orgId/dashboard/$section'
    | '/console/$orgId/debug/test'
    | '/console/$orgId/tools/ai-inbox'
    | '/console/$orgId/tools/website-checker'
    | '/console/$orgId/user-settings/notifications'
    | '/console/$orgId/user-settings/profile'
    | '/public/$orgId/complaint/new'
    | '/console/$orgId/configuration/product-types/'
    | '/console/$orgId/configuration/risk/'
    | '/console/$orgId/configuration/suppliers/'
    | '/admin-console/advanced/email-domains/$id/edit'
    | '/admin-console/controls/library/$id/$section'
    | '/admin-console/obligations/library/$id/$section'
    | '/console/$orgId/claims/details/$id'
    | '/console/$orgId/claims/edit/$id'
    | '/console/$orgId/claims/view/$id'
    | '/console/$orgId/compliance/coi/add'
    | '/console/$orgId/compliance/coi/search'
    | '/console/$orgId/compliance/complaints/add'
    | '/console/$orgId/compliance/complaints/search'
    | '/console/$orgId/compliance/incidents/add'
    | '/console/$orgId/compliance/obligations/add'
    | '/console/$orgId/compliance/obligations/search'
    | '/console/$orgId/compliance/training/search'
    | '/console/$orgId/configuration/risk/$section'
    | '/console/$orgId/configuration/suppliers/add'
    | '/console/$orgId/controls/register/add'
    | '/console/$orgId/controls/register/search'
    | '/console/$orgId/controls/tests/search'
    | '/console/$orgId/risk/ras/search'
    | '/console/$orgId/risk/register/search'
    | '/public/$orgId/invoice/view/$accessKey'
    | '/console/$orgId/configuration/product-types/$productTypeId/'
    | '/console/$orgId/claims/details/$id/comms'
    | '/console/$orgId/claims/details/$id/contacts'
    | '/console/$orgId/claims/details/$id/expenses'
    | '/console/$orgId/claims/details/$id/financials'
    | '/console/$orgId/claims/details/$id/history'
    | '/console/$orgId/claims/details/$id/library'
    | '/console/$orgId/claims/details/$id/manage'
    | '/console/$orgId/claims/details/$id/policy-info'
    | '/console/$orgId/claims/details/$id/product-type-data'
    | '/console/$orgId/compliance/complaints/$complaintId/edit'
    | '/console/$orgId/compliance/complaints/details/$id'
    | '/console/$orgId/compliance/training/search/add-training'
    | '/console/$orgId/compliance/training/user/$userId'
    | '/console/$orgId/configuration/suppliers/details/$supplierId'
    | '/console/$orgId/configuration/suppliers/edit/$supplierId'
    | '/console/$orgId/controls/register/edit/$id'
    | '/console/$orgId/risk/ras/details/$id'
    | '/console/$orgId/tools/ai-inbox/$id/review-email-classification'
    | '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms/add'
    | '/console/$orgId/claims/details/$id/expenses/add'
    | '/console/$orgId/compliance/coi/details/$id/$section'
    | '/console/$orgId/compliance/complaints/details/$id/history'
    | '/console/$orgId/compliance/complaints/details/$id/library'
    | '/console/$orgId/compliance/complaints/details/$id/manage'
    | '/console/$orgId/compliance/incidents/details/$id/$section'
    | '/console/$orgId/compliance/obligations/details/$id/$section'
    | '/console/$orgId/compliance/training/user/$userId/add'
    | '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId'
    | '/console/$orgId/risk/register/details/$id/$section'
    | '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId'
    | '/console/$orgId/claims/details/$id/comms/message/$messageId'
    | '/console/$orgId/claims/details/$id/expenses/$expenseId/edit'
    | '/console/$orgId/claims/details/$id/manage/flag/add'
    | '/console/$orgId/claims/details/$id/manage/strategies/add'
    | '/console/$orgId/compliance/training/user/$userId/edit/$recordId'
    | '/console/$orgId/compliance/complaints/details/$id/manage/flag/add'
    | '/console/$orgId/compliance/complaints/details/$id/manage/note/add';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  AdminConsoleRouteRoute: typeof AdminConsoleRouteRouteWithChildren;
  PublicRouteRoute: typeof PublicRouteRouteWithChildren;
  authenticationAdminCallbackRouteRoute: typeof authenticationAdminCallbackRouteRoute;
  authenticationAdminLoginRouteRoute: typeof authenticationAdminLoginRouteRoute;
  authenticationCallbackRouteRoute: typeof authenticationCallbackRouteRoute;
  authenticationLoginRouteRoute: typeof authenticationLoginRouteRoute;
  authenticationLogoutRouteRoute: typeof authenticationLogoutRouteRoute;
  authenticationLogoutinRouteRoute: typeof authenticationLogoutinRouteRoute;
  ConsoleOrgIdRouteRoute: typeof ConsoleOrgIdRouteRouteWithChildren;
  authenticationChangeOrgOrgIdRouteRoute: typeof authenticationChangeOrgOrgIdRouteRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AdminConsoleRouteRoute: AdminConsoleRouteRouteWithChildren,
  PublicRouteRoute: PublicRouteRouteWithChildren,
  authenticationAdminCallbackRouteRoute: authenticationAdminCallbackRouteRoute,
  authenticationAdminLoginRouteRoute: authenticationAdminLoginRouteRoute,
  authenticationCallbackRouteRoute: authenticationCallbackRouteRoute,
  authenticationLoginRouteRoute: authenticationLoginRouteRoute,
  authenticationLogoutRouteRoute: authenticationLogoutRouteRoute,
  authenticationLogoutinRouteRoute: authenticationLogoutinRouteRoute,
  ConsoleOrgIdRouteRoute: ConsoleOrgIdRouteRouteWithChildren,
  authenticationChangeOrgOrgIdRouteRoute:
    authenticationChangeOrgOrgIdRouteRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "~__root.tsx",
      "children": [
        "/",
        "/admin-console",
        "/public",
        "/(authentication)/admin-callback",
        "/(authentication)/admin-login",
        "/(authentication)/callback",
        "/(authentication)/login",
        "/(authentication)/logout",
        "/(authentication)/logoutin",
        "/console/$orgId",
        "/(authentication)/change-org/$orgId"
      ]
    },
    "/": {
      "filePath": "~index.tsx"
    },
    "/admin-console": {
      "filePath": "~admin-console/~route.tsx",
      "children": [
        "/admin-console/",
        "/admin-console/advanced",
        "/admin-console/ai-prompts",
        "/admin-console/comm-templates",
        "/admin-console/configuration",
        "/admin-console/controls",
        "/admin-console/faq",
        "/admin-console/new-organization",
        "/admin-console/obligations",
        "/admin-console/reports",
        "/admin-console/users",
        "/admin-console/organizations/",
        "/admin-console/organizations/$orgId/edit",
        "/admin-console/organizations/$orgId/manage-email-domains",
        "/admin-console/organizations/$orgId/users"
      ]
    },
    "/public": {
      "filePath": "~public/~route.tsx",
      "children": [
        "/public/$orgId",
        "/public/lodgement-summary/$accessKey",
        "/public/new-complaint-by-key/$key",
        "/public/new-complaint/$orgId",
        "/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId"
      ]
    },
    "/admin-console/": {
      "filePath": "~admin-console/~index.tsx",
      "parent": "/admin-console"
    },
    "/(authentication)/admin-callback": {
      "filePath": "~(authentication)/~admin-callback/~route.tsx"
    },
    "/(authentication)/admin-login": {
      "filePath": "~(authentication)/~admin-login/~route.tsx"
    },
    "/(authentication)/callback": {
      "filePath": "~(authentication)/~callback/~route.tsx"
    },
    "/(authentication)/login": {
      "filePath": "~(authentication)/~login/~route.tsx"
    },
    "/(authentication)/logout": {
      "filePath": "~(authentication)/~logout/~route.tsx"
    },
    "/(authentication)/logoutin": {
      "filePath": "~(authentication)/~logoutin/~route.tsx"
    },
    "/admin-console/advanced": {
      "filePath": "~admin-console/~advanced/~route.tsx",
      "parent": "/admin-console",
      "children": [
        "/admin-console/advanced/app-settings",
        "/admin-console/advanced/db-upgrades",
        "/admin-console/advanced/email-domains",
        "/admin-console/advanced/jobs"
      ]
    },
    "/admin-console/ai-prompts": {
      "filePath": "~admin-console/~ai-prompts/~route.tsx",
      "parent": "/admin-console"
    },
    "/admin-console/comm-templates": {
      "filePath": "~admin-console/~comm-templates/~route.tsx",
      "parent": "/admin-console"
    },
    "/admin-console/configuration": {
      "filePath": "~admin-console/~configuration/~route.tsx",
      "parent": "/admin-console",
      "children": [
        "/admin-console/configuration/obligations"
      ]
    },
    "/admin-console/controls": {
      "filePath": "~admin-console/~controls/~route.tsx",
      "parent": "/admin-console",
      "children": [
        "/admin-console/controls/library/",
        "/admin-console/controls/library/$id/$section"
      ]
    },
    "/admin-console/faq": {
      "filePath": "~admin-console/~faq/~route.tsx",
      "parent": "/admin-console"
    },
    "/admin-console/new-organization": {
      "filePath": "~admin-console/~new-organization/~route.tsx",
      "parent": "/admin-console"
    },
    "/admin-console/obligations": {
      "filePath": "~admin-console/~obligations/~route.tsx",
      "parent": "/admin-console",
      "children": [
        "/admin-console/obligations/library/",
        "/admin-console/obligations/library/add",
        "/admin-console/obligations/library/$id/$section"
      ]
    },
    "/admin-console/reports": {
      "filePath": "~admin-console/~reports/~route.tsx",
      "parent": "/admin-console"
    },
    "/admin-console/users": {
      "filePath": "~admin-console/~users/~route.tsx",
      "parent": "/admin-console"
    },
    "/console/$orgId": {
      "filePath": "~console.$orgId/~route.tsx",
      "children": [
        "/console/$orgId/",
        "/console/$orgId/claim-module-only-home-page",
        "/console/$orgId/claims",
        "/console/$orgId/compliance",
        "/console/$orgId/configuration",
        "/console/$orgId/controls",
        "/console/$orgId/dashboard",
        "/console/$orgId/debug",
        "/console/$orgId/faq",
        "/console/$orgId/get-file",
        "/console/$orgId/reporting",
        "/console/$orgId/risk",
        "/console/$orgId/tasks",
        "/console/$orgId/tools",
        "/console/$orgId/user-settings/notifications",
        "/console/$orgId/user-settings/profile"
      ]
    },
    "/public/$orgId": {
      "filePath": "~public/~$orgId/~route.tsx",
      "parent": "/public",
      "children": [
        "/public/$orgId/complaint/new",
        "/public/$orgId/invoice/view/$accessKey",
        "/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId"
      ]
    },
    "/admin-console/organizations/": {
      "filePath": "~admin-console/~organizations/~index.tsx",
      "parent": "/admin-console"
    },
    "/console/$orgId/": {
      "filePath": "~console.$orgId/~index.tsx",
      "parent": "/console/$orgId"
    },
    "/(authentication)/change-org/$orgId": {
      "filePath": "~(authentication)/~change-org.$orgId/~route.tsx"
    },
    "/admin-console/advanced/app-settings": {
      "filePath": "~admin-console/~advanced/~app-settings.tsx",
      "parent": "/admin-console/advanced"
    },
    "/admin-console/advanced/db-upgrades": {
      "filePath": "~admin-console/~advanced/~db-upgrades.tsx",
      "parent": "/admin-console/advanced"
    },
    "/admin-console/advanced/email-domains": {
      "filePath": "~admin-console/~advanced/~email-domains/~route.tsx",
      "parent": "/admin-console/advanced",
      "children": [
        "/admin-console/advanced/email-domains/add",
        "/admin-console/advanced/email-domains/$id/edit"
      ]
    },
    "/admin-console/advanced/jobs": {
      "filePath": "~admin-console/~advanced/~jobs/~route.tsx",
      "parent": "/admin-console/advanced",
      "children": [
        "/admin-console/advanced/jobs/on-demand",
        "/admin-console/advanced/jobs/recurring"
      ]
    },
    "/admin-console/configuration/obligations": {
      "filePath": "~admin-console/~configuration/~obligations/~route.tsx",
      "parent": "/admin-console/configuration"
    },
    "/console/$orgId/claim-module-only-home-page": {
      "filePath": "~console.$orgId/~claim-module-only-home-page.tsx",
      "parent": "/console/$orgId"
    },
    "/console/$orgId/claims": {
      "filePath": "~console.$orgId/~claims/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/claims/lodge",
        "/console/$orgId/claims/search",
        "/console/$orgId/claims/details/$id",
        "/console/$orgId/claims/edit/$id",
        "/console/$orgId/claims/view/$id"
      ]
    },
    "/console/$orgId/compliance": {
      "filePath": "~console.$orgId/~compliance/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/compliance/incidents",
        "/console/$orgId/compliance/coi/add",
        "/console/$orgId/compliance/coi/search",
        "/console/$orgId/compliance/complaints/add",
        "/console/$orgId/compliance/complaints/search",
        "/console/$orgId/compliance/obligations/add",
        "/console/$orgId/compliance/obligations/search",
        "/console/$orgId/compliance/training/search",
        "/console/$orgId/compliance/complaints/$complaintId/edit",
        "/console/$orgId/compliance/complaints/details/$id",
        "/console/$orgId/compliance/training/user/$userId",
        "/console/$orgId/compliance/coi/details/$id/$section",
        "/console/$orgId/compliance/obligations/details/$id/$section"
      ]
    },
    "/console/$orgId/configuration": {
      "filePath": "~console.$orgId/~configuration/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/configuration/assistance",
        "/console/$orgId/configuration/claim",
        "/console/$orgId/configuration/comms-templates",
        "/console/$orgId/configuration/compliance",
        "/console/$orgId/configuration/documents",
        "/console/$orgId/configuration/expenses-and-billing",
        "/console/$orgId/configuration/handling-parties",
        "/console/$orgId/configuration/insurers",
        "/console/$orgId/configuration/payments",
        "/console/$orgId/configuration/product-types",
        "/console/$orgId/configuration/questionnaire",
        "/console/$orgId/configuration/risk",
        "/console/$orgId/configuration/statistics",
        "/console/$orgId/configuration/suppliers",
        "/console/$orgId/configuration/system",
        "/console/$orgId/configuration/task-manager",
        "/console/$orgId/configuration/tasks",
        "/console/$orgId/configuration/users"
      ]
    },
    "/console/$orgId/controls": {
      "filePath": "~console.$orgId/~controls/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/controls/register/add",
        "/console/$orgId/controls/register/search",
        "/console/$orgId/controls/tests/search",
        "/console/$orgId/controls/register/edit/$id"
      ]
    },
    "/console/$orgId/dashboard": {
      "filePath": "~console.$orgId/~dashboard/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/dashboard/",
        "/console/$orgId/dashboard/$section"
      ]
    },
    "/console/$orgId/debug": {
      "filePath": "~console.$orgId/~debug/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/debug/test"
      ]
    },
    "/console/$orgId/faq": {
      "filePath": "~console.$orgId/~faq/~route.tsx",
      "parent": "/console/$orgId"
    },
    "/console/$orgId/get-file": {
      "filePath": "~console.$orgId/~get-file/~route.tsx",
      "parent": "/console/$orgId"
    },
    "/console/$orgId/reporting": {
      "filePath": "~console.$orgId/~reporting/~route.tsx",
      "parent": "/console/$orgId"
    },
    "/console/$orgId/risk": {
      "filePath": "~console.$orgId/~risk/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/risk/ras/search",
        "/console/$orgId/risk/register/search",
        "/console/$orgId/risk/ras/details/$id",
        "/console/$orgId/risk/register/details/$id/$section"
      ]
    },
    "/console/$orgId/tasks": {
      "filePath": "~console.$orgId/~tasks/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/tasks/"
      ]
    },
    "/console/$orgId/tools": {
      "filePath": "~console.$orgId/~tools/~route.tsx",
      "parent": "/console/$orgId",
      "children": [
        "/console/$orgId/tools/ai-inbox",
        "/console/$orgId/tools/website-checker"
      ]
    },
    "/public/lodgement-summary/$accessKey": {
      "filePath": "~public/~lodgement-summary.$accessKey/~route.tsx",
      "parent": "/public"
    },
    "/public/new-complaint-by-key/$key": {
      "filePath": "~public/~new-complaint-by-key.$key/~route.tsx",
      "parent": "/public"
    },
    "/public/new-complaint/$orgId": {
      "filePath": "~public/~new-complaint.$orgId/~route.tsx",
      "parent": "/public"
    },
    "/admin-console/controls/library/": {
      "filePath": "~admin-console/~controls/~library/~index.tsx",
      "parent": "/admin-console/controls"
    },
    "/admin-console/obligations/library/": {
      "filePath": "~admin-console/~obligations/~library/~index.tsx",
      "parent": "/admin-console/obligations"
    },
    "/console/$orgId/dashboard/": {
      "filePath": "~console.$orgId/~dashboard/~index.tsx",
      "parent": "/console/$orgId/dashboard"
    },
    "/console/$orgId/tasks/": {
      "filePath": "~console.$orgId/~tasks/~index.tsx",
      "parent": "/console/$orgId/tasks"
    },
    "/admin-console/advanced/email-domains/add": {
      "filePath": "~admin-console/~advanced/~email-domains/~add.tsx",
      "parent": "/admin-console/advanced/email-domains"
    },
    "/admin-console/advanced/jobs/on-demand": {
      "filePath": "~admin-console/~advanced/~jobs/~on-demand.tsx",
      "parent": "/admin-console/advanced/jobs"
    },
    "/admin-console/advanced/jobs/recurring": {
      "filePath": "~admin-console/~advanced/~jobs/~recurring.tsx",
      "parent": "/admin-console/advanced/jobs"
    },
    "/admin-console/obligations/library/add": {
      "filePath": "~admin-console/~obligations/~library/~add/~route.tsx",
      "parent": "/admin-console/obligations"
    },
    "/admin-console/organizations/$orgId/edit": {
      "filePath": "~admin-console/~organizations/~$orgId/~edit.tsx",
      "parent": "/admin-console"
    },
    "/admin-console/organizations/$orgId/manage-email-domains": {
      "filePath": "~admin-console/~organizations/~$orgId/~manage-email-domains.tsx",
      "parent": "/admin-console"
    },
    "/admin-console/organizations/$orgId/users": {
      "filePath": "~admin-console/~organizations/~$orgId/~users/~route.tsx",
      "parent": "/admin-console"
    },
    "/console/$orgId/claims/lodge": {
      "filePath": "~console.$orgId/~claims/~lodge/~route.tsx",
      "parent": "/console/$orgId/claims"
    },
    "/console/$orgId/claims/search": {
      "filePath": "~console.$orgId/~claims/~search/~route.tsx",
      "parent": "/console/$orgId/claims"
    },
    "/console/$orgId/compliance/incidents": {
      "filePath": "~console.$orgId/~compliance/~incidents/~route.tsx",
      "parent": "/console/$orgId/compliance",
      "children": [
        "/console/$orgId/compliance/incidents/add",
        "/console/$orgId/compliance/incidents/details/$id/$section"
      ]
    },
    "/console/$orgId/configuration/assistance": {
      "filePath": "~console.$orgId/~configuration/~assistance/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/claim": {
      "filePath": "~console.$orgId/~configuration/~claim/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/comms-templates": {
      "filePath": "~console.$orgId/~configuration/~comms-templates/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/compliance": {
      "filePath": "~console.$orgId/~configuration/~compliance/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/documents": {
      "filePath": "~console.$orgId/~configuration/~documents/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/expenses-and-billing": {
      "filePath": "~console.$orgId/~configuration/~expenses-and-billing/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/handling-parties": {
      "filePath": "~console.$orgId/~configuration/~handling-parties/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/insurers": {
      "filePath": "~console.$orgId/~configuration/~insurers/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/payments": {
      "filePath": "~console.$orgId/~configuration/~payments/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/product-types": {
      "filePath": "~console.$orgId/~configuration/~product-types/~route.tsx",
      "parent": "/console/$orgId/configuration",
      "children": [
        "/console/$orgId/configuration/product-types/",
        "/console/$orgId/configuration/product-types/$productTypeId/",
        "/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId"
      ]
    },
    "/console/$orgId/configuration/questionnaire": {
      "filePath": "~console.$orgId/~configuration/~questionnaire/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/risk": {
      "filePath": "~console.$orgId/~configuration/~risk/~route.tsx",
      "parent": "/console/$orgId/configuration",
      "children": [
        "/console/$orgId/configuration/risk/",
        "/console/$orgId/configuration/risk/$section"
      ]
    },
    "/console/$orgId/configuration/statistics": {
      "filePath": "~console.$orgId/~configuration/~statistics/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/suppliers": {
      "filePath": "~console.$orgId/~configuration/~suppliers/~route.tsx",
      "parent": "/console/$orgId/configuration",
      "children": [
        "/console/$orgId/configuration/suppliers/",
        "/console/$orgId/configuration/suppliers/add",
        "/console/$orgId/configuration/suppliers/details/$supplierId",
        "/console/$orgId/configuration/suppliers/edit/$supplierId"
      ]
    },
    "/console/$orgId/configuration/system": {
      "filePath": "~console.$orgId/~configuration/~system/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/task-manager": {
      "filePath": "~console.$orgId/~configuration/~task-manager/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/tasks": {
      "filePath": "~console.$orgId/~configuration/~tasks/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/configuration/users": {
      "filePath": "~console.$orgId/~configuration/~users/~route.tsx",
      "parent": "/console/$orgId/configuration"
    },
    "/console/$orgId/dashboard/$section": {
      "filePath": "~console.$orgId/~dashboard/~$section/~route.tsx",
      "parent": "/console/$orgId/dashboard"
    },
    "/console/$orgId/debug/test": {
      "filePath": "~console.$orgId/~debug/~test/~route.tsx",
      "parent": "/console/$orgId/debug"
    },
    "/console/$orgId/tools/ai-inbox": {
      "filePath": "~console.$orgId/~tools/~ai-inbox/~route.tsx",
      "parent": "/console/$orgId/tools",
      "children": [
        "/console/$orgId/tools/ai-inbox/$id/review-email-classification"
      ]
    },
    "/console/$orgId/tools/website-checker": {
      "filePath": "~console.$orgId/~tools/~website-checker/~route.tsx",
      "parent": "/console/$orgId/tools"
    },
    "/console/$orgId/user-settings/notifications": {
      "filePath": "~console.$orgId/~user-settings/~notifications/~route.tsx",
      "parent": "/console/$orgId"
    },
    "/console/$orgId/user-settings/profile": {
      "filePath": "~console.$orgId/~user-settings/~profile/~route.tsx",
      "parent": "/console/$orgId"
    },
    "/public/$orgId/complaint/new": {
      "filePath": "~public/~$orgId/~complaint.new/~route.tsx",
      "parent": "/public/$orgId"
    },
    "/console/$orgId/configuration/product-types/": {
      "filePath": "~console.$orgId/~configuration/~product-types/~index.tsx",
      "parent": "/console/$orgId/configuration/product-types"
    },
    "/console/$orgId/configuration/risk/": {
      "filePath": "~console.$orgId/~configuration/~risk/~index.tsx",
      "parent": "/console/$orgId/configuration/risk"
    },
    "/console/$orgId/configuration/suppliers/": {
      "filePath": "~console.$orgId/~configuration/~suppliers/~index.tsx",
      "parent": "/console/$orgId/configuration/suppliers"
    },
    "/admin-console/advanced/email-domains/$id/edit": {
      "filePath": "~admin-console/~advanced/~email-domains/~$id.edit.tsx",
      "parent": "/admin-console/advanced/email-domains"
    },
    "/admin-console/controls/library/$id/$section": {
      "filePath": "~admin-console/~controls/~library/~$id.$section/~route.tsx",
      "parent": "/admin-console/controls"
    },
    "/admin-console/obligations/library/$id/$section": {
      "filePath": "~admin-console/~obligations/~library/~$id.$section/~route.tsx",
      "parent": "/admin-console/obligations"
    },
    "/console/$orgId/claims/details/$id": {
      "filePath": "~console.$orgId/~claims/~details.$id/~route.tsx",
      "parent": "/console/$orgId/claims",
      "children": [
        "/console/$orgId/claims/details/$id/comms",
        "/console/$orgId/claims/details/$id/contacts",
        "/console/$orgId/claims/details/$id/expenses",
        "/console/$orgId/claims/details/$id/financials",
        "/console/$orgId/claims/details/$id/history",
        "/console/$orgId/claims/details/$id/library",
        "/console/$orgId/claims/details/$id/manage",
        "/console/$orgId/claims/details/$id/policy-info",
        "/console/$orgId/claims/details/$id/product-type-data"
      ]
    },
    "/console/$orgId/claims/edit/$id": {
      "filePath": "~console.$orgId/~claims/~edit.$id/~route.tsx",
      "parent": "/console/$orgId/claims"
    },
    "/console/$orgId/claims/view/$id": {
      "filePath": "~console.$orgId/~claims/~view.$id/~route.tsx",
      "parent": "/console/$orgId/claims"
    },
    "/console/$orgId/compliance/coi/add": {
      "filePath": "~console.$orgId/~compliance/~coi/~add/~route.tsx",
      "parent": "/console/$orgId/compliance"
    },
    "/console/$orgId/compliance/coi/search": {
      "filePath": "~console.$orgId/~compliance/~coi/~search/~route.tsx",
      "parent": "/console/$orgId/compliance"
    },
    "/console/$orgId/compliance/complaints/add": {
      "filePath": "~console.$orgId/~compliance/~complaints/~add/~route.tsx",
      "parent": "/console/$orgId/compliance"
    },
    "/console/$orgId/compliance/complaints/search": {
      "filePath": "~console.$orgId/~compliance/~complaints/~search/~route.tsx",
      "parent": "/console/$orgId/compliance"
    },
    "/console/$orgId/compliance/incidents/add": {
      "filePath": "~console.$orgId/~compliance/~incidents/~add.tsx",
      "parent": "/console/$orgId/compliance/incidents"
    },
    "/console/$orgId/compliance/obligations/add": {
      "filePath": "~console.$orgId/~compliance/~obligations/~add/~route.tsx",
      "parent": "/console/$orgId/compliance"
    },
    "/console/$orgId/compliance/obligations/search": {
      "filePath": "~console.$orgId/~compliance/~obligations/~search/~route.tsx",
      "parent": "/console/$orgId/compliance"
    },
    "/console/$orgId/compliance/training/search": {
      "filePath": "~console.$orgId/~compliance/~training/~search/~route.tsx",
      "parent": "/console/$orgId/compliance",
      "children": [
        "/console/$orgId/compliance/training/search/add-training"
      ]
    },
    "/console/$orgId/configuration/risk/$section": {
      "filePath": "~console.$orgId/~configuration/~risk/~$section/~route.tsx",
      "parent": "/console/$orgId/configuration/risk"
    },
    "/console/$orgId/configuration/suppliers/add": {
      "filePath": "~console.$orgId/~configuration/~suppliers/~add/~route.tsx",
      "parent": "/console/$orgId/configuration/suppliers"
    },
    "/console/$orgId/controls/register/add": {
      "filePath": "~console.$orgId/~controls/~register/~add/~route.tsx",
      "parent": "/console/$orgId/controls"
    },
    "/console/$orgId/controls/register/search": {
      "filePath": "~console.$orgId/~controls/~register/~search/~route.tsx",
      "parent": "/console/$orgId/controls"
    },
    "/console/$orgId/controls/tests/search": {
      "filePath": "~console.$orgId/~controls/~tests/~search/~route.tsx",
      "parent": "/console/$orgId/controls"
    },
    "/console/$orgId/risk/ras/search": {
      "filePath": "~console.$orgId/~risk/~ras/~search/~route.tsx",
      "parent": "/console/$orgId/risk"
    },
    "/console/$orgId/risk/register/search": {
      "filePath": "~console.$orgId/~risk/~register/~search/~route.tsx",
      "parent": "/console/$orgId/risk"
    },
    "/public/$orgId/invoice/view/$accessKey": {
      "filePath": "~public/~$orgId/~invoice.view.$accessKey/~route.tsx",
      "parent": "/public/$orgId"
    },
    "/console/$orgId/configuration/product-types/$productTypeId/": {
      "filePath": "~console.$orgId/~configuration/~product-types/~$productTypeId/~index.tsx",
      "parent": "/console/$orgId/configuration/product-types"
    },
    "/console/$orgId/claims/details/$id/comms": {
      "filePath": "~console.$orgId/~claims/~details.$id/~comms/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id",
      "children": [
        "/console/$orgId/claims/details/$id/comms/add",
        "/console/$orgId/claims/details/$id/comms/message/$messageId"
      ]
    },
    "/console/$orgId/claims/details/$id/contacts": {
      "filePath": "~console.$orgId/~claims/~details.$id/~contacts/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id"
    },
    "/console/$orgId/claims/details/$id/expenses": {
      "filePath": "~console.$orgId/~claims/~details.$id/~expenses/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id",
      "children": [
        "/console/$orgId/claims/details/$id/expenses/add",
        "/console/$orgId/claims/details/$id/expenses/$expenseId/edit"
      ]
    },
    "/console/$orgId/claims/details/$id/financials": {
      "filePath": "~console.$orgId/~claims/~details.$id/~financials/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id"
    },
    "/console/$orgId/claims/details/$id/history": {
      "filePath": "~console.$orgId/~claims/~details.$id/~history/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id"
    },
    "/console/$orgId/claims/details/$id/library": {
      "filePath": "~console.$orgId/~claims/~details.$id/~library/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id"
    },
    "/console/$orgId/claims/details/$id/manage": {
      "filePath": "~console.$orgId/~claims/~details.$id/~manage/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id",
      "children": [
        "/console/$orgId/claims/details/$id/manage/flag/add",
        "/console/$orgId/claims/details/$id/manage/strategies/add"
      ]
    },
    "/console/$orgId/claims/details/$id/policy-info": {
      "filePath": "~console.$orgId/~claims/~details.$id/~policy-info/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id"
    },
    "/console/$orgId/claims/details/$id/product-type-data": {
      "filePath": "~console.$orgId/~claims/~details.$id/~product-type-data/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id"
    },
    "/console/$orgId/compliance/complaints/$complaintId/edit": {
      "filePath": "~console.$orgId/~compliance/~complaints/~$complaintId.edit/~route.tsx",
      "parent": "/console/$orgId/compliance"
    },
    "/console/$orgId/compliance/complaints/details/$id": {
      "filePath": "~console.$orgId/~compliance/~complaints/~details.$id/~route.tsx",
      "parent": "/console/$orgId/compliance",
      "children": [
        "/console/$orgId/compliance/complaints/details/$id/history",
        "/console/$orgId/compliance/complaints/details/$id/library",
        "/console/$orgId/compliance/complaints/details/$id/manage"
      ]
    },
    "/console/$orgId/compliance/training/search/add-training": {
      "filePath": "~console.$orgId/~compliance/~training/~search/~add-training.tsx",
      "parent": "/console/$orgId/compliance/training/search"
    },
    "/console/$orgId/compliance/training/user/$userId": {
      "filePath": "~console.$orgId/~compliance/~training/~user.$userId/~route.tsx",
      "parent": "/console/$orgId/compliance",
      "children": [
        "/console/$orgId/compliance/training/user/$userId/add",
        "/console/$orgId/compliance/training/user/$userId/edit/$recordId"
      ]
    },
    "/console/$orgId/configuration/suppliers/details/$supplierId": {
      "filePath": "~console.$orgId/~configuration/~suppliers/~details.$supplierId/~route.tsx",
      "parent": "/console/$orgId/configuration/suppliers"
    },
    "/console/$orgId/configuration/suppliers/edit/$supplierId": {
      "filePath": "~console.$orgId/~configuration/~suppliers/~edit.$supplierId/~route.tsx",
      "parent": "/console/$orgId/configuration/suppliers"
    },
    "/console/$orgId/controls/register/edit/$id": {
      "filePath": "~console.$orgId/~controls/~register/~edit.$id/~route.tsx",
      "parent": "/console/$orgId/controls"
    },
    "/console/$orgId/risk/ras/details/$id": {
      "filePath": "~console.$orgId/~risk/~ras/~details.$id/~route.tsx",
      "parent": "/console/$orgId/risk"
    },
    "/console/$orgId/tools/ai-inbox/$id/review-email-classification": {
      "filePath": "~console.$orgId/~tools/~ai-inbox/~$id/~review-email-classification.tsx",
      "parent": "/console/$orgId/tools/ai-inbox"
    },
    "/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId": {
      "filePath": "~public/~claim-lodgement.$orgId.$pdsVersionId.$questionnaireId.$productTypeId/~route.tsx",
      "parent": "/public"
    },
    "/console/$orgId/claims/details/$id/comms/add": {
      "filePath": "~console.$orgId/~claims/~details.$id/~comms/~add/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id/comms"
    },
    "/console/$orgId/claims/details/$id/expenses/add": {
      "filePath": "~console.$orgId/~claims/~details.$id/~expenses/~add/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id/expenses"
    },
    "/console/$orgId/compliance/coi/details/$id/$section": {
      "filePath": "~console.$orgId/~compliance/~coi/~details.$id.$section/~route.tsx",
      "parent": "/console/$orgId/compliance"
    },
    "/console/$orgId/compliance/complaints/details/$id/history": {
      "filePath": "~console.$orgId/~compliance/~complaints/~details.$id/~history/~route.tsx",
      "parent": "/console/$orgId/compliance/complaints/details/$id"
    },
    "/console/$orgId/compliance/complaints/details/$id/library": {
      "filePath": "~console.$orgId/~compliance/~complaints/~details.$id/~library/~route.tsx",
      "parent": "/console/$orgId/compliance/complaints/details/$id"
    },
    "/console/$orgId/compliance/complaints/details/$id/manage": {
      "filePath": "~console.$orgId/~compliance/~complaints/~details.$id/~manage/~route.tsx",
      "parent": "/console/$orgId/compliance/complaints/details/$id",
      "children": [
        "/console/$orgId/compliance/complaints/details/$id/manage/flag/add",
        "/console/$orgId/compliance/complaints/details/$id/manage/note/add"
      ]
    },
    "/console/$orgId/compliance/incidents/details/$id/$section": {
      "filePath": "~console.$orgId/~compliance/~incidents/~details.$id.$section/~route.tsx",
      "parent": "/console/$orgId/compliance/incidents"
    },
    "/console/$orgId/compliance/obligations/details/$id/$section": {
      "filePath": "~console.$orgId/~compliance/~obligations/~details.$id.$section/~route.tsx",
      "parent": "/console/$orgId/compliance"
    },
    "/console/$orgId/compliance/training/user/$userId/add": {
      "filePath": "~console.$orgId/~compliance/~training/~user.$userId/~add.tsx",
      "parent": "/console/$orgId/compliance/training/user/$userId"
    },
    "/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId": {
      "filePath": "~console.$orgId/~configuration/~product-types/~$productTypeId/~pds.$pdsId/~route.tsx",
      "parent": "/console/$orgId/configuration/product-types"
    },
    "/console/$orgId/risk/register/details/$id/$section": {
      "filePath": "~console.$orgId/~risk/~register/~details.$id.$section/~route.tsx",
      "parent": "/console/$orgId/risk"
    },
    "/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId": {
      "filePath": "~public/~$orgId/~claim.lodgement.$pdsVersionId.$questionnaireId.$productTypeId/~route.tsx",
      "parent": "/public/$orgId"
    },
    "/console/$orgId/claims/details/$id/comms/message/$messageId": {
      "filePath": "~console.$orgId/~claims/~details.$id/~comms/~message.$messageId/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id/comms"
    },
    "/console/$orgId/claims/details/$id/expenses/$expenseId/edit": {
      "filePath": "~console.$orgId/~claims/~details.$id/~expenses/~$expenseId.edit/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id/expenses"
    },
    "/console/$orgId/claims/details/$id/manage/flag/add": {
      "filePath": "~console.$orgId/~claims/~details.$id/~manage/~flag.add/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id/manage"
    },
    "/console/$orgId/claims/details/$id/manage/strategies/add": {
      "filePath": "~console.$orgId/~claims/~details.$id/~manage/~strategies.add/~route.tsx",
      "parent": "/console/$orgId/claims/details/$id/manage"
    },
    "/console/$orgId/compliance/training/user/$userId/edit/$recordId": {
      "filePath": "~console.$orgId/~compliance/~training/~user.$userId/~edit.$recordId.tsx",
      "parent": "/console/$orgId/compliance/training/user/$userId"
    },
    "/console/$orgId/compliance/complaints/details/$id/manage/flag/add": {
      "filePath": "~console.$orgId/~compliance/~complaints/~details.$id/~manage/~flag.add/~route.tsx",
      "parent": "/console/$orgId/compliance/complaints/details/$id/manage"
    },
    "/console/$orgId/compliance/complaints/details/$id/manage/note/add": {
      "filePath": "~console.$orgId/~compliance/~complaints/~details.$id/~manage/~note.add/~route.tsx",
      "parent": "/console/$orgId/compliance/complaints/details/$id/manage"
    }
  }
}
ROUTE_MANIFEST_END */
