import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import {
    navRoutes,
    RISK_CONFIGURATION_SECTIONS,
    RiskConfigurationSection,
} from 'shared/navigation/navRoutes';
import { Tabs, TabsProps } from 'antd';
import { PageHeader } from '@/components/Common/PageHeader';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { ConfigurationRiskAppetite } from './RiskAppetite';
import { ConfigurationRiskCategories } from './RiskCategories';
import { ConfigurationRiskMatrix } from './RiskMatrix';
import { RiskMatrixContextProvider } from '@/components/Risk/RiskMatrix/RiskMatrixContext';
import { RiskMatrixActions } from '@/components/Risk/RiskMatrixActions';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@/hooks/useNavigate';

export const Route = createFileRoute('/console/$orgId/configuration/risk/$section')({
    validateSearch({ newCategoryId }): Partial<{ newCategoryId: number }> {
        return {
            newCategoryId: newCategoryId ? +newCategoryId : undefined,
        };
    },
    params: {
        parse({ section }) {
            return {
                section: section as RiskConfigurationSection,
            };
        },
    },
    component: ConfigurationRisk,
});

function ConfigurationRisk() {
    const orgId = useOrgId();
    const navigate = useNavigate();
    const { section } = Route.useParams();

    const items: TabsProps['items'] = [
        {
            key: RISK_CONFIGURATION_SECTIONS.APPETITE,
            label: 'Risk Appetite',
            destroyInactiveTabPane: true,
            children: <ConfigurationRiskAppetite />,
        },
        {
            key: RISK_CONFIGURATION_SECTIONS.CATEGORIES,
            label: 'Risk Categories',
            destroyInactiveTabPane: true,
            children: <ConfigurationRiskCategories />,
        },
        {
            key: RISK_CONFIGURATION_SECTIONS.MATRIX,
            label: 'Risk Matrix',
            destroyInactiveTabPane: true,
            children: <ConfigurationRiskMatrix />,
        },
    ];

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    toBreadCrumbWithoutLink(navRoutes.configuration_risk_appetite),
                ]}
            />
            <RiskMatrixContextProvider>
                <PageHeader title="Risk configuration" />
                <Tabs
                    type="line"
                    activeKey={section}
                    onTabClick={(key) =>
                        navigate({
                            to: '/console/$orgId/configuration/risk/$section',
                            params: {
                                orgId,
                                section: key as RiskConfigurationSection,
                            },
                        })
                    }
                    items={items}
                    tabBarExtraContent={{
                        right:
                            section === RISK_CONFIGURATION_SECTIONS.MATRIX ? (
                                <RiskMatrixActions />
                            ) : null,
                    }}
                />
            </RiskMatrixContextProvider>
        </>
    );
}
