import { Button, Card, Col, Form, Input, message, Row, Select, Space, Typography } from 'antd';
import { errorMessage } from '@/components/Common/errorMessage';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useSafePath } from '@/hooks/useSafePath';
import { UserSelector } from '@/components/Common/UserSelector';
import { navRoutes } from 'shared/navigation/navRoutes';
import {
    CONTROL_FREQUENCY,
    CONTROL_STATUSES,
    CONTROL_TYPES,
    ControlFrequency,
    ControlStatus,
    ControlType,
} from 'shared/constants/control';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { useCreateSignalStore } from '@/context/signal';
import { ControlContext, ControlSignals } from '../../ControlContext';
import { trpc } from '@/trpc';
import { RouterInputs } from '@/trpc';
import { RichTextInput } from '@/components/Common/RichTextInput';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@/hooks/useNavigate';

export const Route = createFileRoute('/console/$orgId/controls/register/add')({
    staticData: {
        title: 'New control',
    },
    component: ControlAdd,
});

type ControlInput = RouterInputs['control']['createControl'];

function ControlAdd() {
    const [form] = Form.useForm<ControlInput>();
    const orgId = useOrgId();
    const navigate = useNavigate();
    const signalStore = useCreateSignalStore<ControlSignals>();
    const { mutateAsync: createControl, isPending: createControlLoading } =
        trpc.control.createControl.useMutation();

    const safePath = useSafePath<ControlInput>();

    const onFinish = async (values: ControlInput) => {
        try {
            message.info('Saving...');
            const { id } = await createControl(values);
            message.destroy();
            message.success('Saved.');

            navigate({
                to: '/console/$orgId/controls/register/edit/$id',
                params: {
                    orgId,
                    id,
                },
            });
        } catch (e) {
            errorMessage.show(e);
        }
    };

    return (
        <ControlContext.Provider value={signalStore}>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.controls),
                    toBreadCrumb(navRoutes.controls_register_search, {
                        orgId,
                    }),
                    {
                        title: 'Add Control',
                    },
                ]}
            />
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={18} xxl={16}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card>
                                <Typography.Title level={4} className="mb-4 mt-0">
                                    Add Control
                                </Typography.Title>
                                <Form
                                    form={form}
                                    id="controlEdit"
                                    layout="vertical"
                                    onFinish={onFinish}
                                >
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={24} md={12} lg={6}>
                                            <Form.Item
                                                label="Status"
                                                name={safePath('status')}
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <Select
                                                    options={Object.keys(CONTROL_STATUSES).map(
                                                        (key) => ({
                                                            label: CONTROL_STATUSES[
                                                                key as ControlStatus
                                                            ],
                                                            value: key,
                                                        }),
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6}>
                                            <Form.Item
                                                label="Control Owner"
                                                name={safePath('ownerId')}
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <UserSelector />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6}>
                                            <Form.Item
                                                label="Frequency"
                                                name={safePath('frequency')}
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <Select
                                                    options={Object.keys(CONTROL_FREQUENCY).map(
                                                        (key) => ({
                                                            label: CONTROL_FREQUENCY[
                                                                key as ControlFrequency
                                                            ],
                                                            value: key,
                                                        }),
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6}>
                                            <Form.Item
                                                label="Type"
                                                name={safePath('type')}
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <Select
                                                    options={Object.keys(CONTROL_TYPES).map(
                                                        (key) => ({
                                                            label: CONTROL_TYPES[
                                                                key as ControlType
                                                            ],
                                                            value: key,
                                                        }),
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24}>
                                            <Form.Item
                                                label="Control name"
                                                name={safePath('name')}
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item
                                                label="Control objective"
                                                name={safePath('objective')}
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item
                                                label="Control Description"
                                                name={safePath('description')}
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <RichTextInput />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <Space direction="horizontal" className="flex justify-end">
                                    <Button
                                        onClick={() =>
                                            navigate({
                                                to: '/console/$orgId/controls/register/search',
                                                params: {
                                                    orgId,
                                                },
                                            })
                                        }
                                    >
                                        Cancel
                                    </Button>
                                    <Space>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            form="controlEdit"
                                            loading={createControlLoading}
                                        >
                                            Save
                                        </Button>
                                    </Space>
                                </Space>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ControlContext.Provider>
    );
}
