export const AU_STATES = [
    'NSW', // New South Wales
    'QLD', // Queensland
    'SA', // South Australia
    'TAS', // Tasmania
    'VIC', // Victoria
    'WA', // Western Australia
    'ACT', // Australian Capital Territory
    'NT', // Northern Territory
] as const;
