import { mobxToJson } from '../global/mobx';

type KeysToRemove =
    | 'createdAt'
    | 'updatedAt'
    | 'createdById'
    | 'CreatedBy'
    | 'accessKey'
    | '__typename'
    | 'localValues'
    | 'localComputedValues'
    | 'model'
    | '$isSet';

/**
 * Curried function.
 * Converts the obj parameter to JSON, removes properties specified as keysToRemove and throws an error if the resulting
 * object has an extra properties against TTo type.
 * @example
 *  toJSON<TTo>()({firstName: 'David', orgId: 1},'orgId')
 */
export const toJSON =
    <TTo extends Record<any, any>>(
        options: { removeId?: boolean; removeOrgId?: boolean } = { removeId: true, removeOrgId: true },
    ) =>
    <TFrom extends Record<any, any> = {}, TKeys extends (keyof TFrom)[] = (keyof TFrom)[]>(
        obj: TFrom,
        ...keysToRemove: TKeys
    ) => {
        const _options = { ...{ removeId: true }, ...options };
        let json: TFrom = mobxToJson(obj) as TFrom;
        if (keysToRemove && keysToRemove.length > 0) {
            keysToRemove.forEach((el) => {
                delete json[el];
            });
        }
        const systemKeysToRemove = [
            'createdAt',
            'updatedAt',
            'createdById',
            'CreatedBy',
            'accessKey',
            '__typename',
            'localValues',
            'localComputedValues',
            'model',
            '$isSet',
        ] as unknown as (keyof TFrom)[];

        systemKeysToRemove.forEach((el) => {
            delete json[el];
        });
        if (_options.removeId) {
            delete json['id'];
        }
        if (_options.removeOrgId) {
            delete json['orgId'];
        }

        return json as unknown as TTo extends {
            [Property in keyof Omit<TFrom, TKeys[number] | KeysToRemove>]: TTo[Property];
        }
            ? Omit<TFrom, TKeys[number] | KeysToRemove>
            : {
                  [Property in keyof Omit<Omit<TFrom, TKeys[number] | KeysToRemove>, keyof TTo>]: 'EXTRA PROPERTY';
              };
    };

export const Hasura = {
    toJSON,
};

/**
 * @deprecated Use useToJSON from src/hooks/useToJSON.ts
 * @param options
 */
export function useToJSON<TTo extends Record<any, any> | null | undefined>(
    options: { removeId: boolean; removeOrgId: boolean } = { removeId: true, removeOrgId: true },
) {
    return toJSON<NonNullable<TTo>>(options);
}
