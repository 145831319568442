import { Col, Row, Typography, Spin, Card } from 'antd';
import { trpc } from '@/trpc';
import { formatFileSize } from 'shared/utils/file';
import {
    CloudUploadOutlined,
    TeamOutlined,
    UserOutlined,
    FileTextOutlined,
    MailOutlined,
    ExclamationCircleOutlined,
    RobotOutlined,
    MessageOutlined,
    AlertOutlined,
} from '@ant-design/icons';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/')({
    staticData: {
        title: 'Home',
    },
    component: AdminHome,
});

function AdminHome() {
    const { data: systemStats, isLoading } = trpc.admin.stats.getSystemStats.useQuery();

    const spanConfig = {
        xs: 24,
        sm: 12,
        md: 8,
        lg: 6,
    };

    return (
        <div className="p-6">
            <Typography.Title level={3} className="mb-8 mt-0 text-center">
                System Statistics
            </Typography.Title>

            <Row gutter={[16, 16]}>
                {/* Users Statistics */}
                <Col {...spanConfig}>
                    <Card
                        title={
                            <div className="flex items-center gap-2">
                                <UserOutlined className="text-green-500" />
                                <span>User Statistics</span>
                            </div>
                        }
                        className="h-full"
                    >
                        {isLoading ? (
                            <div className="flex justify-center py-8">
                                <Spin size="large" />
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div>
                                    <Typography.Text type="secondary">Total Users</Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.users.totalActiveUsers || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Users Last 24 Hours
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.users.userLoggedInLast24Hours || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Users Last 7 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.users.userLoggedInLast7Days || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Users Last 30 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.users.userLoggedInLast30Days || 0}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Card>
                </Col>

                {/* Organizations Statistics */}
                <Col {...spanConfig}>
                    <Card
                        title={
                            <div className="flex items-center gap-2">
                                <TeamOutlined className="text-purple-500" />
                                <span>Organization Statistics</span>
                            </div>
                        }
                        className="h-full"
                    >
                        {isLoading ? (
                            <div className="flex justify-center py-8">
                                <Spin size="large" />
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div>
                                    <Typography.Text type="secondary">
                                        Total Organizations
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.orgs.totalActiveOrgs || 0}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Card>
                </Col>

                {/* Complaints Statistics */}
                <Col {...spanConfig}>
                    <Card
                        title={
                            <div className="flex items-center gap-2">
                                <ExclamationCircleOutlined className="text-red-500" />
                                <span>Complaints Statistics</span>
                            </div>
                        }
                        className="h-full"
                    >
                        {isLoading ? (
                            <div className="flex justify-center py-8">
                                <Spin size="large" />
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div>
                                    <Typography.Text type="secondary">
                                        Total Complaints
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.complaints.totalComplaints || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Complaints Last 24 Hours
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.complaints.complaintsLast24Hours || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Complaints Last 7 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.complaints.complaintsLast7Days || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Complaints Last 30 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.complaints.complaintsLast30Days || 0}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Card>
                </Col>

                {/* Incident Statistics */}
                <Col {...spanConfig}>
                    <Card
                        title={
                            <div className="flex items-center gap-2">
                                <AlertOutlined className="text-rose-500" />
                                <span>Incident Statistics</span>
                            </div>
                        }
                        className="h-full"
                    >
                        {isLoading ? (
                            <div className="flex justify-center py-8">
                                <Spin size="large" />
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div>
                                    <Typography.Text type="secondary">
                                        Total Incidents
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.incidents.totalIncidents || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Incidents Last 24 Hours
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.incidents.incidentsLast24Hours || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Incidents Last 7 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.incidents.incidentsLast7Days || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Incidents Last 30 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.incidents.incidentsLast30Days || 0}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Card>
                </Col>

                {/* Claims Statistics */}
                <Col {...spanConfig}>
                    <Card
                        title={
                            <div className="flex items-center gap-2">
                                <FileTextOutlined className="text-orange-500" />
                                <span>Claims Statistics</span>
                            </div>
                        }
                        className="h-full"
                    >
                        {isLoading ? (
                            <div className="flex justify-center py-8">
                                <Spin size="large" />
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div>
                                    <Typography.Text type="secondary">Total Claims</Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.claims.totalClaims || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Claims Last 24 Hours
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.claims.claimsLast24Hours || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Claims Last 7 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.claims.claimsLast7Days || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Claims Last 30 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.claims.claimsLast30Days || 0}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Card>
                </Col>

                {/* File Storage Statistics */}
                <Col {...spanConfig}>
                    <Card
                        title={
                            <div className="flex items-center gap-2">
                                <CloudUploadOutlined className="text-blue-500" />
                                <span>File Storage Statistics</span>
                            </div>
                        }
                        className="h-full"
                    >
                        {isLoading ? (
                            <div className="flex justify-center py-8">
                                <Spin size="large" />
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div>
                                    <Typography.Text type="secondary">
                                        Total Storage Used
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {formatFileSize(systemStats?.files.totalFileSize || 0)}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Last 24 Hours
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {formatFileSize(
                                            systemStats?.files.fileSizeLast24Hours || 0,
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">Last 7 Days</Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {formatFileSize(systemStats?.files.fileSizeLast7Days || 0)}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">Last 30 Days</Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {formatFileSize(systemStats?.files.fileSizeLast30Days || 0)}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Card>
                </Col>

                {/* Email Statistics */}
                <Col {...spanConfig}>
                    <Card
                        title={
                            <div className="flex items-center gap-2">
                                <MailOutlined className="text-yellow-500" />
                                <span>Email Statistics</span>
                            </div>
                        }
                        className="h-full"
                    >
                        {isLoading ? (
                            <div className="flex justify-center py-8">
                                <Spin size="large" />
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div>
                                    <Typography.Text type="secondary">
                                        Total Emails Sent
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.emails.totalEmailsSent || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Emails Last 24 Hours
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.emails.emailsSentLast24Hours || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Emails Last 7 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.emails.emailsSentLast7Days || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        Emails Last 30 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.emails.emailsSentLast30Days || 0}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Card>
                </Col>

                {/* AI Statistics */}
                <Col {...spanConfig}>
                    <Card
                        title={
                            <div className="flex items-center gap-2">
                                <RobotOutlined className="text-cyan-500" />
                                <span>AI Statistics</span>
                            </div>
                        }
                        className="h-full"
                    >
                        {isLoading ? (
                            <div className="flex justify-center py-8">
                                <Spin size="large" />
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div>
                                    <Typography.Text type="secondary">
                                        Total AI Tokens Used
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.ai.totalTokensUsed || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        AI Tokens Used Last 24 Hours
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.ai.tokensUsedLast24Hours || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        AI Tokens Used Last 7 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.ai.tokensUsedLast7Days || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        AI Tokens Used Last 30 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.ai.tokensUsedLast30Days || 0}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Card>
                </Col>

                {/* SMS Statistics */}
                <Col {...spanConfig}>
                    <Card
                        title={
                            <div className="flex items-center gap-2">
                                <MessageOutlined className="text-indigo-500" />
                                <span>SMS Statistics</span>
                            </div>
                        }
                        className="h-full"
                    >
                        {isLoading ? (
                            <div className="flex justify-center py-8">
                                <Spin size="large" />
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div>
                                    <Typography.Text type="secondary">
                                        Total SMS Sent
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.sms.totalSmsSent || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        SMS Last 24 Hours
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.sms.smsSentLast24Hours || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        SMS Last 7 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.sms.smsSentLast7Days || 0}
                                    </div>
                                </div>
                                <div>
                                    <Typography.Text type="secondary">
                                        SMS Last 30 Days
                                    </Typography.Text>
                                    <div className="text-lg font-semibold">
                                        {systemStats?.sms.smsSentLast30Days || 0}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
