import { gql } from '@/__generated__';

export const FileDs = gql(/* GraphQL */ `
    query FileDs($limit: Int!, $offset: Int!, $orderBy: [FileOrderBy!], $where: FileBoolExp) {
        FileAggregate(where: $where) {
            aggregate {
                count
            }
        }
        Files(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
            additionalInfo
            categories
            claimId
            createdAt
            createdById
            description
            id
            internalName
            isDeleted
            key
            mimeType
            name
            size
            CreatedBy {
                fullName
                photoUrl
            }
        }
    }
`);

export const FileByPk = gql(/* GraphQL */ `
    query FileByPk($id: uuid = "") {
        FileByPk(id: $id) {
            name
            mimeType
            key
            internalName
            id
            description
            additionalInfo
            categories
        }
    }
`);
