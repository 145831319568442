import React from 'react';
import { Collapse } from 'antd';
import './index.sass';
import clsx from 'clsx';
import { CollapseProps } from 'antd/es/collapse/Collapse';

interface Props extends Omit<CollapseProps, 'open' | 'children' | 'className'> {
    open: boolean;
    children: React.ReactNode;
    className?: string;
    forceRender?: boolean;
}

export const CollapsiblePanel = ({
    children,
    open,
    className,
    forceRender = false,
    ...rest
}: Props) => {
    return (
        <Collapse
            activeKey={open ? [1] : []}
            bordered={false}
            className={clsx('crm-collapsible-panel', className)}
            {...rest}
            items={[{ children: children, key: 1, showArrow: false, forceRender }]}
        />
    );
};
