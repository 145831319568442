import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/tools')({
    staticData: {
        title: 'Tools',
        roles: ['user', 'org_admin'],
        modules: ['tools'],
    },
    component: Outlet,
});
