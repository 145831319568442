import { useMemo, useState } from 'react';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { DASHBOARD_SECTIONS, DashboardSection, navRoutes } from 'shared/navigation/navRoutes';
import { Button, Tabs } from 'antd';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useNavigate } from '@/hooks/useNavigate';
import { ManagementDashboard } from './Management';
import { ComplaintsDashboard, useComplaintsDashboardQuery } from './Complaints';
import { IncidentsDashboard, useIncidentsDashboardQuery } from './Incidents';
import { PeopleAndCultureDashboard } from './PeopleAndCulture';
import { RisksDashboard } from './Risks';
import { ClaimsDashboard } from './Claims';
import { useIsDashboardsEnabled } from '../hooks';
import { Tab } from '@/types/antd';
import { trpc } from '@/trpc';
import { skipToken } from '@tanstack/react-query';
import { isTruthy } from 'shared/utils/boolean';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/dashboard/$section')({
    params: {
        parse({ section }) {
            return {
                section: section as DashboardSection,
            };
        },
    },
    component: Dashboard,
});

type TabItem = Omit<Tab, 'key'> & {
    key: DashboardSection;
};

function Dashboard() {
    const { section } = Route.useParams();
    const navigate = useNavigate();
    const orgId = useOrgId();
    const isDashboardsEnabled = useIsDashboardsEnabled();

    // refetchSignal is used only for dashboards that have tiles that fetch data innerly
    const [refetchSignal, setRefetchSignal] = useState({
        complaints: 0,
        incidents: 0,
        peopleAndCulture: 0,
        claims: 0,
    });

    const { data, loading, refetch, setFilters } = useDashboardsQuery({
        skip: {
            complaintsDashboard:
                !isDashboardsEnabled.complaints || (section !== 'management' && section !== 'complaints'),
            incidentsDashboard: !isDashboardsEnabled.incidents || (section !== 'management' && section !== 'incidents'),
            peopleAndCultureDashboard:
                !isDashboardsEnabled['people-and-culture'] ||
                (section !== 'management' && section !== 'people-and-culture'),
            risksDashboard: !isDashboardsEnabled.risks || (section !== 'management' && section !== 'risks'),
        },
    });

    const tabs: TabItem[] = useMemo(
        () =>
            [
                isDashboardsEnabled.management && {
                    key: DASHBOARD_SECTIONS.MANAGEMENT,
                    label: 'Management overview',
                    children: (
                        <ManagementDashboard loading={loading.managementDashboard} data={data.managementDashboard} />
                    ),
                },
                isDashboardsEnabled.complaints && {
                    key: DASHBOARD_SECTIONS.COMPLAINTS,
                    label: 'Complaints',
                    children: (
                        <ComplaintsDashboard
                            key={refetchSignal.complaints}
                            setFilters={setFilters.complaints}
                            loading={loading.complaintsDashboard}
                            data={data.complaintsDashboard}
                        />
                    ),
                },
                isDashboardsEnabled.incidents && {
                    key: DASHBOARD_SECTIONS.INCIDENTS,
                    label: 'Incidents & Breaches',
                    children: (
                        <IncidentsDashboard
                            key={refetchSignal.incidents}
                            loading={loading.incidentsDashboard}
                            data={data.incidentsDashboard}
                        />
                    ),
                },
                isDashboardsEnabled['people-and-culture'] && {
                    key: DASHBOARD_SECTIONS.PEOPLE_AND_CULTURE,
                    label: 'People & Culture',
                    children: (
                        <PeopleAndCultureDashboard
                            key={refetchSignal.peopleAndCulture}
                            loading={loading.peopleAndCultureDashboard}
                            data={data.peopleAndCultureDashboard}
                        />
                    ),
                },
                isDashboardsEnabled.risks && {
                    key: DASHBOARD_SECTIONS.RISKS,
                    label: 'Risks',
                    children: <RisksDashboard loading={loading.risksDashboard} data={data.risksDashboard} />,
                },
                isDashboardsEnabled.claims && {
                    key: DASHBOARD_SECTIONS.CLAIMS,
                    label: 'Claims',
                    children: <ClaimsDashboard key={refetchSignal.claims} />,
                },
            ].filter(isTruthy),
        [
            refetchSignal,
            loading,
            isDashboardsEnabled.management,
            isDashboardsEnabled.complaints,
            isDashboardsEnabled.incidents,
            isDashboardsEnabled['people-and-culture'],
            isDashboardsEnabled.risks,
            isDashboardsEnabled.claims,
        ],
    );

    const setRefetchSignalHelper = (dashboard: keyof typeof refetchSignal) => {
        setRefetchSignal((prev) => ({
            ...prev,
            [dashboard]: prev[dashboard] + 1,
        }));
    };

    const onClick = () => {
        if (isDashboardsEnabled.management && section === 'management') {
            if (isDashboardsEnabled.complaints) {
                void refetch.complaintsDashboardRefetch();
            }

            if (isDashboardsEnabled.incidents) {
                void refetch.incidentsDashboardRefetch();
            }

            if (isDashboardsEnabled['people-and-culture']) {
                refetch.peopleAndCultureDashboard();
            }

            if (isDashboardsEnabled.risks) {
                refetch.risksDashboard();
            }
        }

        if (isDashboardsEnabled.complaints && section === 'complaints') {
            setRefetchSignalHelper('complaints');
            void refetch.complaintsDashboardRefetch();
        }

        if (isDashboardsEnabled.incidents && section === 'incidents') {
            setRefetchSignalHelper('incidents');
            void refetch.incidentsDashboardRefetch();
        }

        if (isDashboardsEnabled['people-and-culture'] && section === 'people-and-culture') {
            setRefetchSignalHelper('peopleAndCulture');
            refetch.peopleAndCultureDashboard();
        }

        if (isDashboardsEnabled.risks && section === 'risks') {
            void refetch.risksDashboard();
        }

        if (isDashboardsEnabled.claims && section === 'claims') {
            setRefetchSignalHelper('claims');
        }
    };

    return (
        <>
            <BreadCrumbs breadCrumbs={[{ title: navRoutes.dashboard.title }]} />
            <Tabs
                type="line"
                activeKey={section}
                onTabClick={(key) => {
                    navigate({
                        to: '/console/$orgId/dashboard/$section',
                        params: {
                            orgId,
                            section: key as DashboardSection,
                        },
                    });
                }}
                items={tabs}
                tabBarExtraContent={
                    <Button type="primary" onClick={onClick} loading={Object.values(loading).includes(true)}>
                        Refresh dashboard
                    </Button>
                }
                destroyInactiveTabPane
            />
        </>
    );
}

const useDashboardsQuery = (
    {
        skip,
    }: {
        skip: {
            complaintsDashboard?: boolean;
            incidentsDashboard?: boolean;
            peopleAndCultureDashboard?: boolean;
            risksDashboard?: boolean;
        };
    } = { skip: {} },
) => {
    const utils = trpc.useUtils();
    const [complaintsDashboardFilters, setComplaintsDashboardFilters] = useState<
        Parameters<typeof useComplaintsDashboardQuery>[0]['filters']
    >({});

    // const [peopleAndCultureDashboardInput, setPeopleAndCultureDashboardInput] = useState({
    //     fresh: false,
    // });
    // const [risksDashboardInput, setRisksDashboardInput] = useState({
    //     fresh: false,
    // });

    const {
        data: complaintsDashboardData,
        loading: complaintsDashboardLoading,
        refetch: complaintsDashboardRefetch,
    } = useComplaintsDashboardQuery({
        filters: complaintsDashboardFilters,
        skip: skip.complaintsDashboard,
    });
    const {
        data: incidentsDashboardData,
        loading: incidentsDashboardLoading,
        refetch: incidentsDashboardRefetch,
    } = useIncidentsDashboardQuery({
        skip: skip.incidentsDashboard,
    });
    const { data: peopleAndCultureDashboardData, fetchStatus: peopleAndCultureDashboardFetchStatus } =
        trpc.dashboard.peopleAndCultureDashboard.useQuery(
            // skip.peopleAndCultureDashboard ? skipToken : peopleAndCultureDashboardInput,
            skip.peopleAndCultureDashboard ? skipToken : undefined,
        );
    const { data: risksDashboardData, fetchStatus: risksDashboardFetchStatus } = trpc.dashboard.risksDashboard.useQuery(
        // skip.risksDashboard ? skipToken : risksDashboardInput,
        skip.risksDashboard ? skipToken : undefined,
    );

    return {
        setFilters: {
            complaints: setComplaintsDashboardFilters,
        },
        data: {
            managementDashboard: {
                complaints: complaintsDashboardData,
                incidents: incidentsDashboardData,
                peopleAndCulture: {
                    status: peopleAndCultureDashboardData?.status,
                    insights: {
                        user: peopleAndCultureDashboardData?.userInsights,
                        task: peopleAndCultureDashboardData?.taskInsights,
                    },
                },
                risks: {
                    status: risksDashboardData?.status,
                    insights: risksDashboardData?.insights,
                },
            },
            complaintsDashboard: complaintsDashboardData,
            incidentsDashboard: incidentsDashboardData,
            peopleAndCultureDashboard: peopleAndCultureDashboardData,
            risksDashboard: risksDashboardData,
        },
        loading: {
            managementDashboard:
                complaintsDashboardLoading ||
                incidentsDashboardLoading ||
                peopleAndCultureDashboardFetchStatus === 'fetching' ||
                risksDashboardFetchStatus === 'fetching',
            complaintsDashboard: complaintsDashboardLoading,
            incidentsDashboard: incidentsDashboardLoading,
            peopleAndCultureDashboard: peopleAndCultureDashboardFetchStatus === 'fetching',
            risksDashboard: risksDashboardFetchStatus === 'fetching',
        },
        refetch: {
            complaintsDashboardRefetch,
            incidentsDashboardRefetch,
            peopleAndCultureDashboard: () => {
                // setPeopleAndCultureDashboardInput({ fresh: true });
                utils.dashboard.peopleAndCultureDashboard.invalidate();
            },
            risksDashboard: () => {
                // setRisksDashboardInput({ fresh: true });
                utils.dashboard.risksDashboard.invalidate();
            },
        },
    };
};
