import { useEffect } from 'react';
import logo from '@/resources/images/logo.svg';
import { useAuthStore } from '@/stores/AuthStore';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@/hooks/useNavigate';
import { Link } from '@/components/Common/Link';

export const Route = createFileRoute('/')({
    component: LandingPage,
});

function LandingPage() {
    const navigate = useNavigate();
    const authStore = useAuthStore();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const reason = searchParams.get('reason');

        if (reason === 'logout') {
            navigate({
                to: '/login',
            });
        }
    }, []);

    return (
        <div className="relative min-h-screen w-screen bg-gray-100 md:justify-center">
            {!authStore.isLoginInProgress && (
                <Link
                    type="primary"
                    size="large"
                    className="absolute right-6 top-4 no-underline"
                    to="/login"
                >
                    Log in
                </Link>
            )}

            <img
                src={logo}
                alt="Logo"
                className="w-300px absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            />
        </div>
    );
}
