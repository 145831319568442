import { theme } from '@/styles/theme';
import { App, ConfigProvider, Modal } from 'antd';
import { PropsWithChildren } from 'react';
import { errorMessage } from '@/components/Common/errorMessage';
import { modal } from '@/components/Common/Modal';
import '@/styles/main.sass';
import '@/styles/main.css';

function UISetup({ children }: PropsWithChildren) {
    const [modalApi, contextHolder] = Modal.useModal();

    // We don't want to wait for useEffect as it will block the rendering
    errorMessage.init(modalApi);
    modal.init(modalApi);

    return (
        <>
            {contextHolder}
            {children}
        </>
    );
}

export function UIProvider({ children }: PropsWithChildren) {
    return (
        <ConfigProvider theme={theme}>
            <App component={false}>
                <UISetup>{children}</UISetup>
            </App>
        </ConfigProvider>
    );
}
