import { gql } from '@/__generated__';
import { SupportAccountInOrgBoolExp, SupportAccountInOrgDsQuery } from '@/__generated__/graphql';
import { errorMessage } from '@/components/Common/errorMessage';
import { PageHeader } from '@/components/Common/PageHeader';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { useTableDataSource } from '@/hooks/Table/useTableDataSource';
import { trpc } from '@/trpc';
import { commonActionColumn } from '@/utils/table';
import { useLazyQuery } from '@apollo/client';
import { Button, Card, Popconfirm, Table, Tag } from 'antd';
import { useEffect } from 'react';
import { ASSISTANCE_APPROVAL_STATUS } from 'shared/types/assistance';
import { navRoutes } from 'shared/navigation/navRoutes';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/assistance')({
    staticData: {
        title: 'Assistance',
    },
    component: ConfigurationAssistance,
});

type User = SupportAccountInOrgDsQuery['SupportAccountInOrg'][number];

function ConfigurationAssistance() {
    const { mutate: updateSupportAccountApprovalStatus } =
        trpc.user.updateSupportAccountApprovalStatus.useMutation({
            onError: (error) => errorMessage.show(error),
        });
    const [getSupportAccountInOrgDs] = useLazyQuery(SupportAccountInOrgDs);

    const { tableProps, error, refetch } = useTableDataSource<User, any>({
        rowKey: 'userId',
        columns: [
            {
                dataIndex: 'userId',
                title: 'Account ID',
                sorter: true,
                align: 'center',
            },
            {
                dataIndex: 'name',
                title: 'Account name',
                sorter: true,
            },
            {
                title: 'Approval status',
                dataIndex: 'supportAccountApprovalStatus',
                sorter: true,
                render: (value) => (
                    <Tag
                        color={value === ASSISTANCE_APPROVAL_STATUS.REQUESTED ? 'orange' : 'green'}
                    >
                        {value}
                    </Tag>
                ),
            },
            {
                ...commonActionColumn,
                render: (_, { supportAccountApprovalStatus, name, userId }) => (
                    <>
                        {supportAccountApprovalStatus === ASSISTANCE_APPROVAL_STATUS.REQUESTED && (
                            <>
                                <Popconfirm
                                    title={`Approve ${name}?`}
                                    onConfirm={async () => {
                                        await updateSupportAccountApprovalStatus({
                                            userId,
                                            status: ASSISTANCE_APPROVAL_STATUS.APPROVED,
                                        });

                                        void refetch();
                                    }}
                                >
                                    <Button type="link">Approve</Button>
                                </Popconfirm>
                                <Popconfirm
                                    title={`Reject ${name}?`}
                                    onConfirm={async () => {
                                        await updateSupportAccountApprovalStatus({
                                            userId,
                                            status: ASSISTANCE_APPROVAL_STATUS.REJECTED,
                                        });

                                        void refetch();
                                    }}
                                >
                                    <Button type="link">Reject</Button>
                                </Popconfirm>
                            </>
                        )}
                        {supportAccountApprovalStatus === ASSISTANCE_APPROVAL_STATUS.APPROVED && (
                            <Popconfirm
                                title={`Revoke ${name}?`}
                                onConfirm={async () => {
                                    await updateSupportAccountApprovalStatus({
                                        userId,
                                        status: ASSISTANCE_APPROVAL_STATUS.REJECTED,
                                    });

                                    void refetch();
                                }}
                            >
                                <Button type="link">Revoke</Button>
                            </Popconfirm>
                        )}
                    </>
                ),
            },
        ],
        getResults: async (options) => {
            try {
                const where: SupportAccountInOrgBoolExp = {
                    _and: [
                        {
                            supportAccountApprovalStatus: {
                                _in: [
                                    ASSISTANCE_APPROVAL_STATUS.REQUESTED,
                                    ASSISTANCE_APPROVAL_STATUS.APPROVED,
                                ],
                            },
                        },
                    ],
                };
                if (options?.where) {
                    where._and?.push(options.where);
                }
                const { data } = await getSupportAccountInOrgDs({
                    variables: {
                        limit: options?.limit || 10,
                        offset: options?.offset || 0,
                        ...(options?.orderBy && { orderBy: options.orderBy }),
                        where,
                    },
                    fetchPolicy: 'network-only',
                });
                return {
                    rows: data?.SupportAccountInOrg,
                    total: data?.SupportAccountInOrgAggregate.aggregate?.count || 0,
                };
            } catch (e) {
                return { error: e };
            }
        },
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
    });

    useEffect(() => {
        if (error) {
            errorMessage.show(error);
        }
    }, [error]);

    return (
        <>
            <BreadCrumbs breadCrumbs={[{ title: navRoutes.configuration_assistance.title }]} />

            <PageHeader
                title="Assistance & Support"
                subTitle="You can view, approve and reject access for support accounts here."
            />

            <Card>
                <Table scroll={{ x: true }} size="small" {...tableProps} />
            </Card>
        </>
    );
}

const SupportAccountInOrgDs = gql(/* GraphQL */ `
    query SupportAccountInOrgDs(
        $limit: Int!
        $offset: Int!
        $orderBy: [SupportAccountInOrgOrderBy!]
        $where: SupportAccountInOrgBoolExp
    ) {
        SupportAccountInOrgAggregate(where: $where) {
            aggregate {
                count
            }
        }
        SupportAccountInOrg(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
            auth0Id
            createdAt
            disabled
            email
            userId
            name
            orgId
            roles
            lastSeen
            photoUrl
            userFirstName
            userLastName
            position
            isEmployee
            allowAccessToAllExternalOrgs
            supportAccountApprovalStatus
        }
    }
`);
