import { ErrorFallback } from '@/components/Common/ErrorFallback';
import { OfflineOverlay } from '@/components/Common/OfflineOverlay';
import { DebugInfo } from '@/components/DebugInfo';
import { MaintenanceModeOverlay } from '@/components/MaintenanceModeOverlay';
import { useAppBuildVersion } from '@/hooks/useAppBuildVersion';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { useOfflineDetection } from '@/hooks/useOfflineDetection';
import * as Sentry from '@sentry/react';
import { Outlet } from '@tanstack/react-router';

export function AppProvider() {
    useOfflineDetection();
    useDocumentTitle();
    useAppBuildVersion();

    return (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <OfflineOverlay />
            <MaintenanceModeOverlay>
                <Outlet />
                <DebugInfo />
            </MaintenanceModeOverlay>
        </Sentry.ErrorBoundary>
    );
}
