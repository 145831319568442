import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import qs from 'query-string';
import { createFileRoute, matchByPath } from '@tanstack/react-router';

export const Route = createFileRoute('/(authentication)/login')({
    component: Login,
});

function Login() {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        const urlParams = qs.parse(window.location.search);
        const redirectToWithoutQuery = (urlParams.redirectTo as string)?.split('?')[0];
        const params = matchByPath('/', redirectToWithoutQuery || '', {
            to: '/console/$orgId',
        });
        const orgId = params?.orgId;

        void loginWithRedirect({
            authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                scope: 'openid profile email offline_access',
                isLogin: true,
                orgId,
            },
            appState: {
                redirectTo: urlParams.redirectTo || '',
            },
        });
    }, []);

    return null;
}
