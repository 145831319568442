import { useEffect, useState } from 'react';
import { errorMessage } from '@/components/Common/errorMessage';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';

type Props<OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType> = {
    fetchOptions: () => Promise<OptionType[]>;
    fetchOnInit?: boolean;
};

export function useAsyncOptions<
    OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({ fetchOnInit, fetchOptions }: Props<OptionType>) {
    const [options, setOptions] = useState<OptionType[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!fetchOnInit) {
            return;
        }

        const asyncWrapper = async () => {
            setOptions([]);
            setLoading(true);

            try {
                const data = await fetchOptions();
                setOptions(data);
            } catch (e) {
                errorMessage.show(e);
            } finally {
                setLoading(false);
            }
        };

        void asyncWrapper();
    }, [fetchOptions]);

    return {
        loading,
        options,
    };
}
