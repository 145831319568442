import { matchByPath, useLocation } from '@tanstack/react-router';
import { useEffect } from 'react';
import { isRoute, navRoutes } from 'shared/navigation/navRoutes';

export function useDocumentTitle() {
    const location = useLocation();
    const defaultSuffix = ' - Curium';

    useEffect(() => {
        const findRouteTitle = (): string => {
            let title = 'Curium';

            const findTitle = (routes: typeof navRoutes): boolean => {
                let key: keyof typeof navRoutes;

                for (key in routes) {
                    const route = routes[key];

                    if (isRoute(route)) {
                        if (!route.title) {
                            return true;
                        }

                        const params = matchByPath('/', location.pathname, { to: route.path });

                        if (params) {
                            title = `${route.title} ${params?.id ? ` - ${params?.id}` : ''} ${defaultSuffix}`;
                            return true;
                        }
                    }
                    // else {
                    // TODO: Recurse into nested routes
                    // findTitle(route as any);
                    // }
                }
                return false;
            };

            findTitle(navRoutes);
            return title;
        };

        document.title = findRouteTitle();
    }, [location]);
}
