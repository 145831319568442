import { gql } from '@/__generated__/gql';
import { FaqBoolExp, FaqDsQuery, FaqDsQueryVariables, OrderBy } from '@/__generated__/graphql';
import { errorMessage } from '@/components/Common/errorMessage';
import { PageHeader } from '@/components/Common/PageHeader';
import { RichText } from '@/components/Common/RichText';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { FaqDs } from '@/graphql/queries/faq';
import { useCurrentOrg } from '@/hooks/Org/useCurrentOrg';
import { useAntdTableOld } from '@/hooks/Table/useAntdTableOld';
import { useSafePath } from '@/hooks/useSafePath';
import { trpc } from '@/trpc';
import { debounce } from '@/utils/general';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { useQuery } from '@apollo/client';
import { skipToken } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Col, Form, Input, Row, Select, Space, Spin, Table, Tag } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { navRoutes } from 'shared/navigation/navRoutes';
import { APP_MODULES } from 'shared/types/app';
import { isTruthy } from 'shared/utils/boolean';

export const Route = createFileRoute('/console/$orgId/faq')({
    staticData: {
        title: 'FAQ',
        roles: ['user', 'org_admin'],
    },
    component: FaqSearch,
});

type Faq = FaqDsQuery['Faq'][number];

type Filter = {
    fullTextSearch: string;
    categories: string[];
};

function FaqSearch() {
    const org = useCurrentOrg();
    const [variables, setVariables] = useState<FaqDsQueryVariables>({
        where: undefined,
        orderBy: [{ updatedAt: OrderBy.desc }],
        limit: 10,
        offset: 0,
    });

    const [form] = Form.useForm<Filter>();
    const safePath = useSafePath<Filter>();

    const { loading, data } = useQuery(FaqDs, {
        fetchPolicy: 'network-only',
        variables,
        onError: (e) => errorMessage.show(e),
    });

    const { data: rawCategories } = useQuery(FaqCategories, {
        fetchPolicy: 'network-only',
    });
    const categories = useMemo(
        () =>
            [...new Set(rawCategories?.Faq.flatMap(({ categories }) => categories) || [])].filter(
                isTruthy,
            ),
        [rawCategories],
    );

    const onSearch = useCallback(
        debounce(() => {
            const where: { _and: FaqBoolExp[] } = { _and: [] };
            const item = form.getFieldsValue();

            const modules: FaqBoolExp[] = [{ modules: { _isNull: true } }];

            if (org.enabledModules.includes('claim')) {
                modules.push({ modules: { _contains: APP_MODULES.CLAIM } });
            }

            if (org.enabledModules.includes('compliance') || org.enabledModules.includes('risk')) {
                modules.push({ modules: { _contains: APP_MODULES.COMPLIANCE } });
            }

            if (modules.length) {
                where._and.push({ _or: modules });
            }

            if (item.categories?.length) {
                where._and.push({
                    _or: item.categories.map((item) => ({
                        categories: { _contains: item },
                    })),
                });
            }

            if (item.fullTextSearch) {
                where._and.push({
                    _or: [
                        { title: { _ilike: `%${item.fullTextSearch}%` } },
                        { content: { _ilike: `%${item.fullTextSearch}%` } },
                    ],
                });
            }

            setVariables((prev) => ({
                ...prev,
                where: {
                    ...prev.where,
                    ...where,
                },
            }));
        }),
        [form, org],
    );

    const { tableProps } = useAntdTableOld<Faq>({
        data: {
            rows: data?.Faq,
            loading,
            total: data?.FaqAggregate?.aggregate?.count,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length === 0 ? variables.orderBy : options?.orderBy,
            });
        },
        rowKey: 'id',
        columns: [
            {
                className: 'flex justify-between gap-4 cursor-pointer items-center',
                render: (_, item) => (
                    <>
                        <h4>{item.title}</h4>
                        <Space className="flex-wrap justify-end">
                            {item.categories?.map((category: string) => (
                                <Tag key={category} color="blue" className="m-0">
                                    {category}
                                </Tag>
                            ))}
                        </Space>
                    </>
                ),
            },
        ],
    });

    return (
        <>
            <BreadCrumbs breadCrumbs={[toBreadCrumbWithoutLink(navRoutes.faq)]} />

            <PageHeader title="FAQ Search" />

            <Card title="Filters" size="small" className="mb-4">
                <Form form={form} layout="vertical">
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Form.Item label="Full text search" name={safePath('fullTextSearch')}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Form.Item label="Categories" name={safePath('categories')}>
                                <Select
                                    mode="multiple"
                                    options={categories.map((item) => ({
                                        value: item,
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Space className="flex justify-end">
                                <Button
                                    onClick={() => {
                                        form.resetFields();
                                        onSearch();
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button type="primary" onClick={onSearch}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>

            <Card>
                <Table
                    {...tableProps}
                    showHeader={false}
                    size="small"
                    expandable={{
                        expandRowByClick: true,
                        expandedRowRender: ({ content, videoFileKey }) => (
                            <Row className="flex justify-center py-6">
                                <Col xxl={16} xl={16} lg={20} md={24} sm={24} xs={24}>
                                    <Card bordered={false}>
                                        <RichText data={content} className="prose mb-6" />
                                        <VideoPlayer videoFileKey={videoFileKey || ''} />
                                    </Card>
                                </Col>
                            </Row>
                        ),
                        rowExpandable: () => true,
                    }}
                />
            </Card>
        </>
    );
}

type Props = {
    videoFileKey?: string;
};

const VideoPlayer = ({ videoFileKey }: Props) => {
    const { data, isLoading } = trpc.file.getDownloadUrl.useQuery(
        videoFileKey
            ? {
                  key: videoFileKey,
              }
            : skipToken,
        {
            throwOnError: (error) => {
                errorMessage.show(error);
                return false;
            },
        },
    );

    if (!videoFileKey) return null;

    return (
        <Spin spinning={isLoading} size="large" tip="Loading video...">
            <div className="flex justify-center">
                <ReactPlayer
                    wrapper={({ children }) => (
                        <div className="min-h-20 max-w-[640px] shadow-2xl">{children}</div>
                    )}
                    url={data}
                    controls
                />
            </div>
        </Spin>
    );
};

const FaqCategories = gql(/* GraphQL */ `
    query FaqCategories {
        Faq(distinctOn: [categories]) {
            categories
        }
    }
`);
