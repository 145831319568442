export function capitalize<T extends string = string>(s: T): Capitalize<T> {
    if (!s) return '' as Capitalize<T>;
    if (s.length === 0) return '' as Capitalize<T>;
    return (s[0].toUpperCase() + s.slice(1)) as Capitalize<T>;
}

export function uncapitalize<T extends string = string>(s: T): Uncapitalize<T> {
    if (!s) return '' as Uncapitalize<T>;
    if (s.length === 0) return '' as Uncapitalize<T>;
    return (s[0].toLowerCase() + s.slice(1)) as Uncapitalize<T>;
}

/**
 * Truncates a string to a specified length and appends "..." at the end if truncated.
 * @param text - The string to be truncated.
 * @param maxLength - The maximum length of the truncated string including the "..." characters.
 * @returns The truncated string with "..." if it exceeds the maxLength.
 */
export function truncateText(text: string, maxLength: number): string {
    // Ensure maxLength is greater than 3 to accommodate "..."
    if (maxLength <= 3) {
        return text.slice(0, maxLength);
    }

    // Truncate the text if it exceeds the maxLength and append "..."
    return text.length > maxLength ? text.slice(0, maxLength - 3) + '...' : text;
}
