import { navRoutes } from 'shared/navigation/navRoutes';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { useState } from 'react';
import { Card, Table, Tag } from 'antd';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { CoiDefs } from '@/global/conflict';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { useFormatter } from '@/hooks/useFormatter';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useNavigate } from '@/hooks/useNavigate';
import { NewTabLink } from '@/components/Common/NewTabLink';
import { Link } from '@/components/Common/Link';
import { commonActionColumn } from '@/utils/table';
import { PlusOutlined } from '@ant-design/icons';
import { RouterInputs, trpc } from '@/trpc';
import { errorMessage } from '@/components/Common/errorMessage';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/compliance/coi/search')({
    staticData: {
        title: 'Conflict of interest',
    },
    component: CoiSearch,
});

function CoiSearch() {
    const orgId = useOrgId();

    const [variables, setVariables] = useState<
        RouterInputs['conflictOfInterest']['listConflictsOfInterest']
    >({
        orderBy: { createdAt: 'desc' },
        limit: 10,
        offset: 0,
    });
    const { formatDate } = useFormatter();
    const navigate = useNavigate();

    const { data, isPending } = trpc.conflictOfInterest.listConflictsOfInterest.useQuery(
        variables,
        {
            throwOnError: (error) => {
                errorMessage.show(error.message);
                return false;
            },
        },
    );

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isPending,
            total: data?.total,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                render: (value) => String(value),
            },
            {
                title: 'Date advised',
                dataIndex: 'dtAdvised',
                sorter: true,
                render: (value) => <>{formatDate(value)}</>,
            },
            {
                title: 'Severity',
                dataIndex: 'severity',
                sorter: true,
                render: (value) => (
                    <Tag color={CoiDefs.severityToColor[value as CoiDefs.enumSeverity]}>
                        {value}
                    </Tag>
                ),
            },
            {
                title: 'Name',
                dataIndex: 'firstName',
                sorter: true,
            },
            {
                title: 'Surname',
                dataIndex: 'lastName',
                sorter: true,
            },
            {
                title: 'Position',
                dataIndex: 'position',
                sorter: true,
            },
            {
                title: 'Created at',
                dataIndex: 'createdAt',
                sorter: true,
                render: (value) => formatDate(value, 'date-time'),
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <NewTabLink
                        to="/console/$orgId/compliance/coi/details/$id/$section"
                        params={{
                            orgId,
                            id: value,
                            section: 'manage',
                        }}
                    />
                ),
            },
        ],
    });

    return (
        <>
            <BreadCrumbs breadCrumbs={[toBreadCrumbWithoutLink(navRoutes.compliance_coi_search)]} />

            <div className="mb-4 flex justify-end">
                <Link
                    to="/console/$orgId/compliance/coi/add"
                    params={{ orgId }}
                    icon={<PlusOutlined />}
                >
                    New item
                </Link>
            </div>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: (e) =>
                            navigate(
                                {
                                    to: '/console/$orgId/compliance/coi/details/$id/$section',
                                    params: {
                                        orgId,
                                        id,
                                        section: 'manage',
                                    },
                                },
                                e,
                            ),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
