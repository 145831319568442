import { useState } from 'react';
import { PageHeader } from '@/components/Common/PageHeader';
import { gql } from '@/__generated__';
import { useAntdTableOld } from '@/hooks/Table/useAntdTableOld';
import {
    App,
    Button,
    Card,
    Col,
    Form,
    InputNumber,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import { DeleteOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { grey } from '@ant-design/colors';
import {
    OrderBy,
    ObligationLibraryDsQuery,
    ObligationLibraryDsQueryVariables,
} from '@/__generated__/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { errorMessage } from '@/components/Common/errorMessage';
import { CollapsiblePanel } from '@/components/Common/CollapsiblePanel';
import { Key, TableRowSelection } from 'antd/es/table/interface';
import { OrgSelector } from '@/components/Common/OrgSelector';
import { ComponentPreview } from '@/components/Common/ComponentPreview';
import { useConfigServiceLoader } from '@/hooks/Configuration/useConfigServiceLoader';
import { CountrySelect } from '@/components/Obligation/CountrySelect';
import { ControlLibrarySelector } from '@/components/Control/ControlLibrarySelect';
import { LegislationSelect } from '@/components/Obligation/LegislationSelect';
import { SubObligationLibrarySelect } from '@/components/Obligation/SubObligationLibrarySelect';
import { useNavigate } from '@/hooks/useNavigate';
import { htmlToText } from '@/utils/text';
import { trpc } from '@/trpc';
import { createFileRoute } from '@tanstack/react-router';
import { commonActionColumn } from '@/utils/table';

export const Route = createFileRoute('/admin-console/obligations/library/')({
    staticData: {
        title: 'Obligations library',
    },
    component: ObligationsLibrary,
});

type ObligationLibraryItem = ObligationLibraryDsQuery['ObligationLibrary'][number];

type FilterForm = {
    id: number;
    orgs: number[];
    countries: string[];
    controls: number[];
    legislations: string[];
    sections: string[];
    customLabels: { [key: string]: string | string[] };
};

type SendToOrgForm = {
    orgs: number[];
};

function ObligationsLibrary() {
    const { message } = App.useApp();
    const { isPending: sendToOrgLoading, ...sendObligationsToOrganizationsMutation } =
        trpc.obligation.sendObligationsToOrganizations.useMutation();
    const { data: obligationLegislations } = useConfigServiceLoader((service) =>
        service.fetchObligation_LegislationItems(),
    );
    const { data: customLabelsConfig } = useConfigServiceLoader((service) =>
        service.fetchObligationLibraryLabels(),
    );
    const [deleteObligationLibraryByPk] = useMutation(DeleteObligationLibraryByPk);
    const navigate = useNavigate();

    const [showFilter, setShowFilter] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<ObligationLibraryItem[]>([]);
    const [variables, setVariables] = useState<ObligationLibraryDsQueryVariables>({
        where: undefined,
        orderBy: [{ id: OrderBy.desc }],
        limit: 10,
        offset: 0,
    });

    const { data, loading, refetch } = useQuery(ObligationLibraryDs, {
        variables,
        fetchPolicy: 'network-only',
        onError: (e) => errorMessage.show(e),
    });

    const [filterForm] = Form.useForm<FilterForm>();
    const [sendToOrgForm] = Form.useForm<SendToOrgForm>();

    const customLabelColumns = customLabelsConfig
        ? customLabelsConfig
              .filter((labelConfig) => labelConfig.showInTable)
              .map((labelConfig) => ({
                  title: labelConfig.label,
                  dataIndex: `customLabels.${labelConfig.id}`,
                  render: (value: string | string[]) => {
                      if (Array.isArray(value)) {
                          return value
                              .map((val) => {
                                  const option = labelConfig.options.find(
                                      (option) => option.internalName === val,
                                  );
                                  return option ? option.label : val;
                              })
                              .join(', ');
                      } else {
                          const option = labelConfig.options.find(
                              (option) => option.internalName === value,
                          );
                          return option ? option.label : value;
                      }
                  },
              }))
        : [];

    const { tableProps } = useAntdTableOld<ObligationLibraryItem>({
        data: {
            rows: data?.ObligationLibrary,
            loading: loading,
            total: data?.ObligationLibraryAggregate?.aggregate?.count,
        },
        paginationConfig: { hideOnSinglePage: true },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                ...{
                    limit: options?.limit || 10,
                    offset: options?.offset || 0,
                    orderBy: options?.orderBy?.length === 0 ? variables.orderBy : options?.orderBy,
                },
            });
        },
        rowKey: 'id',
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: 'Legislation',
                dataIndex: 'legislation',
                render: (value) => {
                    const legislation = obligationLegislations?.find(
                        (l) => l.internalName === value,
                    );
                    return legislation?.label || value || '-';
                },
            },
            {
                title: 'Sub obligation',
                dataIndex: 'section',
                render: (value) => (
                    <Tooltip placement="bottom" title={value}>
                        <Typography.Paragraph
                            ellipsis
                            style={{ maxWidth: 250, minWidth: 100, marginBottom: 0 }}
                        >
                            {value}
                        </Typography.Paragraph>
                    </Tooltip>
                ),
            },
            {
                title: 'Core obligation',
                dataIndex: 'coreObligation',
                className: 'max-w-xs align-top',
                render: (value) => <ComponentPreview>{htmlToText(value)}</ComponentPreview>,
            },
            {
                title: 'Exact wording from legislation',
                dataIndex: 'legislationDescription',
                className: 'max-w-xs align-top',
                render: (value) => <ComponentPreview>{htmlToText(value)}</ComponentPreview>,
            },
            {
                title: 'Federal register of legislation URLs',
                dataIndex: 'federalRegisterOfLegislationURLs',
                className: 'max-w-xs align-top',
                render: (links?: { url: string; name?: string }) => {
                    if (!Array.isArray(links)) {
                        return '-';
                    }

                    return (
                        <ComponentPreview noOfLines={3}>
                            {links.map((link) => (
                                <Typography.Paragraph
                                    key={link}
                                    ellipsis={{ rows: 1 }}
                                    className="text-primary mb-0"
                                >
                                    <a
                                        key={link.url}
                                        href={link.url}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        {link.name || link.url}
                                    </a>
                                </Typography.Paragraph>
                            ))}
                        </ComponentPreview>
                    );
                },
            },
            {
                title: 'Regulator',
                dataIndex: 'regulator',
            },
            {
                title: 'Key controls',
                dataIndex: 'Controls',
                render: (_, record) =>
                    record.Controls?.map((item) => (
                        <Tag
                            className="max-w-200px"
                            rootClassName="whitespace-normal"
                            key={item.Control.id}
                        >
                            {item.Control.objective}
                        </Tag>
                    )),
            },
            {
                title: 'Assigned to',
                dataIndex: 'OrgsObligations',
                render: (_, record) =>
                    record.OrgsObligations?.map((item) => item.Org.name).join(', '),
            },
            ...customLabelColumns,
            {
                ...commonActionColumn,
                render: (_, record) => (
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure?"
                            onPopupClick={(e) => {
                                e.stopPropagation();
                            }}
                            onConfirm={() => {
                                void onDelete(record.id);
                            }}
                            okButtonProps={{ danger: true }}
                            okText="Delete"
                        >
                            <Button
                                type="text"
                                icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                        </Popconfirm>
                    </Tooltip>
                ),
            },
        ],
    });

    const rowSelection: TableRowSelection<ObligationLibraryItem> = {
        type: 'checkbox',
        selectedRowKeys,
        onChange: (selectedRowKeys: Key[], selectedRows: ObligationLibraryItem[]) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
    };

    const onSearch = () => {
        const values = filterForm.getFieldsValue();
        const where: ObligationLibraryDsQueryVariables['where'] = {
            _and: [],
        };
        if (values.id) {
            where._and?.push({ id: { _eq: values.id } });
        }
        if (values.orgs?.length) {
            where._and?.push({
                OrgsObligations: {
                    orgId: { _in: values.orgs },
                },
            });
        }
        if (values.countries?.length) {
            where._and?.push({
                country: { _in: values.countries },
            });
        }
        if (values.controls?.length) {
            where._and?.push({
                Controls: {
                    controlLibraryItemId: { _in: values.controls },
                },
            });
        }
        if (values.legislations?.length) {
            where._and?.push({
                legislation: { _in: values.legislations },
            });
        }
        if (values.sections?.length) {
            where._and?.push({
                section: { _in: values.sections },
            });
        }
        if (values.customLabels) {
            Object.keys(values.customLabels).forEach((labelId) => {
                const labelValues = values.customLabels[labelId];

                if (Array.isArray(labelValues) && labelValues.length > 0) {
                    const conditions = labelValues.map((val) => ({
                        customLabels: {
                            _contains: { [labelId]: val },
                        },
                    }));
                    where._and?.push({
                        _or: [
                            ...conditions,
                            {
                                customLabels: {
                                    _contains: { [labelId]: labelValues },
                                },
                            },
                        ],
                    });
                }
            });
        }

        setVariables({ ...variables, where });
    };

    const onSendToOrganizations = async (values: SendToOrgForm) => {
        const { orgs } = values;

        try {
            message.info('Sending to organizations...');

            await sendObligationsToOrganizationsMutation.mutateAsync({
                obligationIds: selectedRows.flatMap(({ id }) => BigInt(id)),
                orgIds: orgs.map(BigInt),
            });

            message.destroy();
            message.success('Successfully sent to organizations');
            setIsModalVisible(false);
            sendToOrgForm.resetFields();
            setSelectedRows([]);
            setSelectedRowKeys([]);
            void refetch(variables);
        } catch (e) {
            errorMessage.show(e);
        }
    };

    const onDelete = async (id: number) => {
        try {
            message.info('Deleting...');
            await deleteObligationLibraryByPk({
                variables: {
                    id,
                },
            });
            message.destroy();
            message.success('Deleted.');
            void refetch(variables);
        } catch (e) {
            errorMessage.show(e);
        }
    };

    return (
        <>
            <Modal
                confirmLoading={sendToOrgLoading}
                open={isModalVisible}
                maskClosable={false}
                title="Assign controls to register"
                onCancel={() => {
                    setIsModalVisible(false);
                    sendToOrgForm.resetFields();
                }}
                okText="Submit"
                okButtonProps={{
                    autoFocus: true,
                    htmlType: 'submit',
                    form: 'sendToOrgForm',
                }}
            >
                <Form
                    form={sendToOrgForm}
                    layout="vertical"
                    id="sendToOrgForm"
                    onFinish={onSendToOrganizations}
                >
                    <Form.Item
                        label="Select the organization(s)"
                        name="orgs"
                        rules={[{ required: true }]}
                    >
                        <OrgSelector mode="multiple" />
                    </Form.Item>
                </Form>
            </Modal>

            <PageHeader title="Obligations library" />

            <CollapsiblePanel open={showFilter}>
                <Card title="Filters" size="small" className="mb-4">
                    <Form form={filterForm} layout="vertical" onFinish={onSearch}>
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Form.Item label="Country" name="countries">
                                    <CountrySelect mode="multiple" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="ID" name="id">
                                    <InputNumber className="w-full" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Legislation" name="legislations">
                                    <LegislationSelect mode="multiple" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Sub Obligation" name="sections">
                                    <SubObligationLibrarySelect mode="multiple" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Key Controls" name="controls">
                                    <ControlLibrarySelector mode="multiple" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Organization" name="orgs">
                                    <OrgSelector mode="multiple" />
                                </Form.Item>
                            </Col>
                            {customLabelsConfig?.map((labelConfig) => (
                                <Col span={6} key={labelConfig.id}>
                                    <Form.Item
                                        label={labelConfig.label}
                                        name={['customLabels', labelConfig.id]}
                                    >
                                        <Select
                                            mode="multiple"
                                            options={labelConfig.options.map((option) => ({
                                                label: option.label,
                                                value: option.internalName,
                                            }))}
                                        />
                                    </Form.Item>
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Space className="flex justify-end">
                                    <Button
                                        onClick={() => {
                                            filterForm.resetFields();
                                            onSearch();
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </CollapsiblePanel>

            <Space className="mb-4 flex justify-between">
                <Button
                    type="primary"
                    onClick={() => setIsModalVisible(true)}
                    disabled={!(selectedRowKeys.length > 0)}
                >
                    Send to organization
                </Button>
                <Space>
                    <Tooltip title="Filters">
                        <Button
                            type="text"
                            size="large"
                            title="Filter"
                            onClick={() => setShowFilter(!showFilter)}
                        >
                            <FilterOutlined />
                        </Button>
                    </Tooltip>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() =>
                            navigate({
                                to: '/admin-console/obligations/library/add',
                            })
                        }
                    >
                        New obligation
                    </Button>
                </Space>
            </Space>

            <Card>
                <Table
                    {...tableProps}
                    rowSelection={rowSelection}
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick(e) {
                            navigate(
                                {
                                    to: '/admin-console/obligations/library/$id/$section',
                                    params: {
                                        id,
                                        section: 'details',
                                    },
                                },
                                e,
                            );
                        },
                    })}
                    size="small"
                    scroll={{ x: 'max-content' }}
                />
            </Card>
        </>
    );
}

const ObligationLibraryDs = gql(/* GraphQL */ `
    query ObligationLibraryDs(
        $where: ObligationLibraryBoolExp
        $orderBy: [ObligationLibraryOrderBy!]
        $limit: Int
        $offset: Int
    ) {
        ObligationLibrary(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
            id
            legislation
            legislationDescription
            section
            regulatoryGuidance
            country
            customLabels
            legislationReference
            regulator
            coreObligation
            whatItMeans
            breachExamples
            regulatoryGuidanceDescription
            regulatoryGuidanceURLs
            austLIIURLs
            federalRegisterOfLegislationURLs
            createdAt
            updatedAt
            Controls {
                Control {
                    id
                    objective
                    description
                    owner
                    createdAt
                    updatedAt
                }
            }
            OrgsObligations(distinctOn: orgId) {
                Org {
                    id
                    name
                }
            }
        }
        ObligationLibraryAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`);

const DeleteObligationLibraryByPk = gql(/* GraphQL */ `
    mutation DeleteObligationLibraryByPk($id: bigint!) {
        deleteObligationLibraryByPk(id: $id) {
            id
        }
    }
`);
