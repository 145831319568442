import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { QuestionnaireUploadControl } from '../QuestionnaireUploadControl';
import { Form, Input, InputNumber, InputProps, Select } from 'antd';
import { Card } from 'shared/model/Card';
import {
    CardChild,
    CardChildCurrency,
    CardChildDate,
    CardChildDateTime,
    CardChildEmail,
    CardChildImageMap,
    CardChildPhoneNumber,
    CardChildSelect,
    CardChildText,
    CardChildTime,
    CardChildUpload,
    CardChildNumber,
    processJoiStringSchema,
} from 'shared/model/CardChild';
import { DatePickerOld } from '@/components/Common/DatePickerOld';
import { useFormatter } from '@/hooks/useFormatter';
import { TimePicker } from '@/components/Common/TimePicker';
import './index.sass';
import Joi, { AnySchema } from 'joi';
import { QuestionnaireImageMap } from '@/components/Claim/Questionnaire/QuestionnaireImageMap';
import { PhoneNumberInput } from '@/components/Common/PhoneNumberInput';
import { validatePhoneNumber } from '@/utils/phone';
import { InputNumberProps } from 'antd/es/input-number';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { useAppStateStore } from '@/stores/AppStateStore';

interface Props {
    card: Card;
    readOnly: boolean;
    child: CardChild;
    formValues: Record<string, any>;
}

export const ChildFormInput = observer(({ child, readOnly, formValues }: Props) => {
    const { claimLodgementSettings } = useAppStateStore();
    const [fileUploading, setFileUploading] = React.useState(false);

    const requiredMessage = claimLodgementSettings.fieldRequiredMessage;

    const { fDateShortDayJs, fDateTimeDayJs } = useFormatter();
    const componentConfig = useMemo(() => {
        return child.componentConfig || {};
    }, [child.componentConfig]);

    const required = child.required === true;

    return (
        <>
            {child instanceof CardChildText && (
                <Form.Item
                    label={child.title}
                    extra={child.description}
                    name={child.id}
                    validateFirst
                    required={required}
                    rules={[
                        { required: required, message: requiredMessage },
                        { validator: validator(child, formValues) },
                    ]}
                >
                    <Input
                        //error={!!child.validationError}
                        {...(componentConfig as InputProps)}
                        readOnly={readOnly}
                    />
                </Form.Item>
            )}
            {child instanceof CardChildNumber && (
                <Form.Item
                    label={child.title}
                    extra={child.description}
                    name={child.id}
                    validateFirst
                    required={required}
                    rules={[
                        { required: required, message: requiredMessage },
                        { validator: validator(child, formValues) },
                    ]}
                >
                    <InputNumber {...(componentConfig as InputNumberProps)} readOnly={readOnly} />
                </Form.Item>
            )}
            {child instanceof CardChildDate && (
                <Form.Item
                    label={child.title}
                    extra={child.description}
                    name={child.id}
                    validateFirst
                    required={required}
                    rules={[
                        { required: required, message: requiredMessage },
                        {
                            validator: validator(child, formValues),
                        },
                    ]}
                >
                    <DatePickerOld
                        disabled={readOnly}
                        {...(typeof componentConfig === 'object' && componentConfig)}
                        format={fDateShortDayJs}
                    />
                </Form.Item>
            )}
            {child instanceof CardChildDateTime && (
                <Form.Item
                    label={child.title}
                    extra={child.description}
                    name={child.id}
                    validateFirst
                    required={required}
                    rules={[
                        { required: required, message: requiredMessage },
                        {
                            validator: validator(child, formValues),
                        },
                    ]}
                >
                    <DatePickerOld
                        format={fDateTimeDayJs}
                        disabled={readOnly}
                        showTime
                        {...(typeof componentConfig === 'object' && componentConfig)}
                    />
                </Form.Item>
            )}
            {child instanceof CardChildTime && (
                <Form.Item
                    label={child.title}
                    extra={child.description}
                    name={child.id}
                    validateFirst
                    required={required}
                    rules={[
                        { required: required, message: requiredMessage },
                        { validator: validator(child, formValues) },
                    ]}
                >
                    <TimePicker
                        disabled={readOnly}
                        {...(typeof componentConfig === 'object' && componentConfig)}
                    />
                </Form.Item>
            )}
            {child instanceof CardChildCurrency && (
                <Form.Item
                    label={child.title}
                    extra={child.description}
                    name={child.id}
                    validateFirst
                    required={required}
                    rules={[
                        { required: required, message: requiredMessage },
                        { validator: validator(child, formValues) },
                    ]}
                >
                    <InputNumber
                        prefix="$"
                        // error={!!option.validationError}
                        //{...(componentConfig as InputNumberProps)}
                        readOnly={readOnly}
                    />
                </Form.Item>
            )}
            {child instanceof CardChildEmail && (
                <Form.Item
                    label={child.title}
                    extra={child.description}
                    name={child.id}
                    validateFirst
                    required={required}
                    rules={[{ required: required, message: requiredMessage }, { type: 'email' }]}
                >
                    <Input
                        // error={!!option.validationError}
                        //{...(componentConfig as InputNumberProps)}
                        type="email"
                        readOnly={readOnly}
                    />
                </Form.Item>
            )}
            {child instanceof CardChildPhoneNumber && (
                <Form.Item
                    label={child.title}
                    extra={child.description}
                    name={child.id}
                    validateFirst
                    required={required}
                    rules={[
                        { required: required, message: requiredMessage },
                        {
                            validator: async (_, value) => {
                                if (!validatePhoneNumber(value))
                                    throw new Error('Invalid phone number');
                            },
                        },
                    ]}
                >
                    <PhoneNumberInput readOnly={readOnly} />
                </Form.Item>
            )}
            {child instanceof CardChildImageMap && (
                <Form.Item
                    label={child.title}
                    extra={child.description}
                    name={child.id}
                    validateFirst
                    required={required}
                    rules={[
                        { required: required, message: requiredMessage },
                        { validator: validator(child, formValues) },
                    ]}
                >
                    <QuestionnaireImageMap imageMap={child.imageMap} imageUrl={child.imageUrl} />
                </Form.Item>
            )}
            {child instanceof CardChildSelect && (
                <Form.Item
                    label={child.title}
                    extra={child.description}
                    name={child.id}
                    validateFirst
                    required={required}
                    rules={[
                        { required: required, message: requiredMessage },
                        { validator: validator(child, formValues) },
                    ]}
                >
                    <Select
                        disabled={readOnly}
                        showSearch
                        options={child.options || []}
                        placeholder="Search to select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label?.toLowerCase() ?? '').includes(
                                input?.toLowerCase() ?? '',
                            )
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '')
                                .toLowerCase()
                                .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                    />
                </Form.Item>
            )}
            {child instanceof CardChildUpload && (
                <Form.Item
                    label={child.title}
                    extra={child.description}
                    name={child.id}
                    required={required}
                    rules={[
                        { required: required, message: requiredMessage },
                        {
                            validator: async () => {
                                if (fileUploading) {
                                    throw new Error('File is uploading. Please wait.');
                                }
                            },
                        },
                    ]}
                    validateTrigger={['onSubmit', 'onValidate']}
                    className="crm-questionnaire-upload-control"
                >
                    <QuestionnaireUploadControl
                        multiple={true}
                        fileCategories={child.fileCategories || []}
                        onUploadStart={() => {
                            setFileUploading(true);
                        }}
                        onUploadEnd={() => {
                            setFileUploading(false);
                        }}
                    />
                </Form.Item>
            )}
        </>
    );
});

function validator(child: CardChild, formValues: Record<string, any>) {
    return async (_rule: RuleObject, value: StoreValue) => {
        let validationSchema: AnySchema;
        try {
            if (!child.validationSchema) {
                return;
            }
            const strValidationSchema = processJoiStringSchema(child.validationSchema, formValues);
            validationSchema = new Function(strValidationSchema)() as unknown as Joi.AnySchema;
        } catch (e) {
            console.error(e);
            return;
        }

        const validationResult = validationSchema.label(child.title).validate(value);
        if (validationResult.error) {
            throw new Error(validationResult.error.message);
        }
    };
}
