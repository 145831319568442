import { makeObservable } from 'mobx';
import Joi, { ValidationResult } from 'joi';
import { CardBase, CardFactory } from './Card';
import { Except } from 'type-fest';
import {
    CardChildDef,
    CardChildDefBase,
    CardChildDefCurrency,
    CardChildDefDate,
    CardChildDefDateTime,
    CardChildDefEmail,
    CardChildDefNumber,
    CardChildDefOption,
    CardChildDefSelect,
    CardChildDefPhoneNumber,
    CardChildDefSpecifiedItem,
    CardChildDefText,
    CardChildDefTime,
    CardChildDefUpload,
    CardChildDefYesNo,
    CardChildInputType,
    CardChildDefImageMap,
    FormValues,
} from '../types/questionnaire/definition';
import { S3File } from '../types/S3File';
import { SpecifiedItem } from '../types/specifiedItem';

export abstract class CardChildBase implements CardChildDefBase {
    id: string;
    title: string;
    type: CardChildInputType = 'TEXT';
    internalName?: string;
    componentConfig?: any;
    nextCardId?: string;
    parentCardId?: string;
    order?: number;
    additionalCard?: CardBase;
    parentCard?: CardBase;
    validationResult?: ValidationResult;
    isSelected: boolean = false;
    nextCard?: CardBase;
    validationSchema?: string;
    description?: string;
    additionalCardId?: string;
    required?: boolean;

    get validationError(): ValidationResult | undefined {
        return this.validationResult && !!this.validationResult.error
            ? this.validationResult
            : undefined;
    }

    protected constructor(data: CardChildBase | CardChildDef, parent?: CardBase) {
        Object.assign(this, data);
        if (parent) {
            this.parentCard = parent;
        }
        if ('additionalCard' in data && !!data.additionalCard) {
            this.additionalCard = CardFactory.create(data.additionalCard, parent);
        }
        if ('nextCard' in data && !!data.nextCard) {
            this.nextCard = CardFactory.create(data.nextCard, parent);
        }
        makeObservable(this, {
            additionalCard: true,
            additionalCardId: true,
            componentConfig: true,
            description: true,
            id: true,
            internalName: true,
            isSelected: true,
            nextCard: true,
            nextCardId: true,
            order: true,
            parentCard: true,
            parentCardId: true,
            title: true,
            type: true,
            validate: true,
            validationError: true,
            validationResult: true,
            validationSchema: true,
            required: true,
        });
    }

    abstract value: any;

    validate(formValues: FormValues) {
        try {
            this.validationResult = undefined;
            const value = this.value;
            if (this.validationSchema) {
                const strValidationSchema = processJoiStringSchema(
                    this.validationSchema,
                    formValues,
                );
                const validationSchema = new Function(
                    strValidationSchema,
                )() as unknown as Joi.AnySchema;

                if (
                    this.type === 'TEXT' ||
                    this.type === 'TIME' ||
                    this.type === 'DATETIME' ||
                    this.type === 'DATE' ||
                    this.type === 'NUMBER' ||
                    this.type === 'CURRENCY' ||
                    this.type === 'EMAIL' ||
                    this.type === 'PHONE_NUMBER'
                ) {
                    this.validationResult = validationSchema.label(this.title).validate(value);
                } else {
                    this.validationResult = Joi.custom(() => {
                        return true;
                    }).validate(value);
                }
            } else {
                this.validationResult = {
                    value: value,
                    error: undefined,
                };
            }
        } catch {
            // It's better ignore the error and let user finish the form
            // TODO: Email error to the admin
            this.validationResult = {
                value: this.value,
                error: undefined,
            };
        }
    }

    toJS() {
        const json = JSON.parse(
            JSON.stringify(this, (key, value) => {
                if (key === 'validationResults' || key === 'parentCard' || key === 'value') {
                    return undefined;
                }
                return value;
            }),
        );
        if (this.nextCard) {
            json.nextCard = this.nextCard.toJS();
        }
        return json;
    }
}

export class CardChildText extends CardChildBase implements Except<CardChildDefText, 'type'> {
    value: string | null;
    constructor(data: CardChildText, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        makeObservable(this, { value: true });
    }

    validate(formValues: FormValues) {
        super.validate(formValues);
    }

    toJS() {
        const json = super.toJS();
        json.value = this.value;
        return json;
    }
}

export class CardChildEmail extends CardChildBase implements Except<CardChildDefEmail, 'type'> {
    value: string | null;
    constructor(data: CardChildEmail, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        makeObservable(this, { value: true });
    }

    validate(formValues: FormValues) {
        super.validate(formValues);
    }

    toJS() {
        const json = super.toJS();
        json.value = this.value;
        return json;
    }
}

export class CardChildPhoneNumber
    extends CardChildBase
    implements Except<CardChildDefPhoneNumber, 'type'>
{
    value: string | null;
    constructor(data: CardChildPhoneNumber, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        makeObservable(this, { value: true });
    }

    validate(formValues: FormValues) {
        super.validate(formValues);
    }

    toJS() {
        const json = super.toJS();
        json.value = this.value;
        return json;
    }
}

export class CardChildDate extends CardChildBase implements Except<CardChildDefDate, 'type'> {
    value: string | null;
    constructor(data: CardChildDate, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        makeObservable(this, { value: true });
    }
    validate(formValues: FormValues) {
        super.validate(formValues);
    }

    toJS() {
        const json = super.toJS();
        json.value = this.value;
        return json;
    }
}

export class CardChildNumber extends CardChildBase implements Except<CardChildDefNumber, 'type'> {
    value: number | null;
    constructor(data: CardChildNumber, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        makeObservable(this, { value: true });
    }
    validate(formValues: FormValues) {
        super.validate(formValues);
    }

    toJS() {
        const json = super.toJS();
        json.value = this.value;
        return json;
    }
}

export class CardChildOption extends CardChildBase implements Except<CardChildDefOption, 'type'> {
    value: string | null;
    showAsOther: boolean;

    constructor(data: CardChildOption, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        this.showAsOther = data.showAsOther;
        makeObservable(this, { value: true, showAsOther: true });
    }
    validate(formValues: FormValues) {
        super.validate(formValues);
    }

    toJS() {
        const json = super.toJS();
        json.value = this.value;
        json.showAsOther = this.showAsOther;
        return json;
    }
}

export class CardChildCurrency
    extends CardChildBase
    implements Except<CardChildDefCurrency, 'type'>
{
    value: number | null;
    constructor(data: CardChildCurrency, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        makeObservable(this, { value: true });
    }
    validate(formValues: FormValues) {
        super.validate(formValues);
    }

    toJS() {
        const json = super.toJS();
        json.value = this.value;
        return json;
    }
}

export class CardChildUpload extends CardChildBase implements Except<CardChildDefUpload, 'type'> {
    value: S3File[] | null;
    fileCategories: string[] = [];
    allowedFileTypes: string[] = [];

    constructor(data: CardChildUpload, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        this.fileCategories = data.fileCategories;
        this.allowedFileTypes = data.allowedFileTypes;
        makeObservable(this, { value: true, fileCategories: true, allowedFileTypes: true });
    }

    validate(formValues: FormValues) {
        super.validate(formValues);
    }

    toJS() {
        const json = super.toJS();
        json.value = this.value;
        json.fileCategories = this.fileCategories;
        json.allowedFileTypes = this.allowedFileTypes;
        return json;
    }
}

export class CardChildDateTime
    extends CardChildBase
    implements Except<CardChildDefDateTime, 'type'>
{
    value: string | null;
    constructor(data: CardChildDateTime, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        makeObservable(this, { value: true });
    }
    validate(formValues: FormValues) {
        super.validate(formValues);
    }

    toJS() {
        const json = super.toJS();
        json.value = this.value;
        return json;
    }
}

export class CardChildTime extends CardChildBase implements Except<CardChildDefTime, 'type'> {
    value: string | null;
    constructor(data: CardChildTime, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        makeObservable(this, { value: true });
    }
}

export class CardChildSelect extends CardChildBase implements Except<CardChildDefSelect, 'type'> {
    value: string | null;
    options: Array<{ label: string; value: string }>;

    constructor(data: CardChildSelect, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        makeObservable(this, { value: true });
        this.options = data.options;
    }
    validate(formValues: FormValues) {
        super.validate(formValues);
    }

    toJS() {
        const json = super.toJS();
        json.value = this.value;
        json.options = this.options;
        return json;
    }
}

export class CardChildSpecifiedItem
    extends CardChildBase
    implements Except<CardChildDefSpecifiedItem, 'type'>
{
    value: SpecifiedItem[] | null;
    specifiedItemId: number | null;
    pdsConfigItemId: number | null;

    constructor(data: CardChildSpecifiedItem, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        this.specifiedItemId = data.specifiedItemId;
        this.pdsConfigItemId = data.pdsConfigItemId;
        makeObservable(this, { value: true, specifiedItemId: true, pdsConfigItemId: true });
    }

    validate(formValues: FormValues) {
        super.validate(formValues);
    }

    toJS() {
        const json = super.toJS();
        json.value = this.value;
        json.specifiedItemId = this.specifiedItemId;
        json.pdsConfigItemId = this.pdsConfigItemId;
        return json;
    }
}

export class CardChildYesNo extends CardChildBase implements Except<CardChildDefYesNo, 'type'> {
    value: boolean | null;

    constructor(data: CardChildYesNo, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        makeObservable(this, { value: true });
    }
    validate(formValues: FormValues) {
        super.validate(formValues);
    }

    toJS() {
        const json = super.toJS();
        json.value = this.value;
        return json;
    }
}

export class CardChildImageMap
    extends CardChildBase
    implements Except<CardChildDefImageMap, 'type'>
{
    value: string | null;
    imageMap: string;
    imageUrl: string;

    constructor(data: CardChildImageMap, parent?: CardBase) {
        super(data, parent);
        this.value = data.value;
        makeObservable(this, { value: true });
        this.imageMap = data.imageMap;
        this.imageUrl = data.imageUrl;
    }
    validate(formValues: FormValues) {
        super.validate(formValues);
    }

    toJS() {
        const json = super.toJS();
        json.value = this.value;
        json.imageMap = this.imageMap;
        json.imageUrl = this.imageUrl;
        return json;
    }
}

export class CardChildFactory {
    static create(data: CardChildBase | CardChildDef, parent?: CardBase) {
        switch (data.type) {
            case 'TEXT':
                return new CardChildText(data as CardChildText, parent);
            case 'DATE':
                return new CardChildDate(data as CardChildDate, parent);
            case 'NUMBER':
                return new CardChildNumber(data as CardChildNumber, parent);
            case 'TIME':
                return new CardChildTime(data as CardChildTime, parent);
            case 'CURRENCY':
                return new CardChildCurrency(data as CardChildCurrency, parent);
            case 'UPLOAD':
                return new CardChildUpload(data as CardChildUpload, parent);
            case 'SELECT':
                return new CardChildSelect(data as CardChildSelect, parent);
            case 'YES_NO':
                return new CardChildYesNo(data as CardChildYesNo, parent);
            case 'SPECIFIED_ITEM':
                return new CardChildSpecifiedItem(data as CardChildSpecifiedItem, parent);
            case 'OPTION':
                return new CardChildOption(data as CardChildOption, parent);
            case 'DATETIME':
                return new CardChildDateTime(data as CardChildDateTime, parent);
            case 'EMAIL':
                return new CardChildEmail(data as CardChildEmail, parent);
            case 'PHONE_NUMBER':
                return new CardChildPhoneNumber(data as CardChildPhoneNumber, parent);
            case 'IMAGE_MAP':
                return new CardChildImageMap(data as CardChildImageMap, parent);
            default:
                throw new Error(`Unknown CardChild type: ${data['type']}`);
        }
    }
}

export type CardChild =
    | CardChildText
    | CardChildDate
    | CardChildNumber
    | CardChildTime
    | CardChildCurrency
    | CardChildUpload
    | CardChildSelect
    | CardChildYesNo
    | CardChildSpecifiedItem
    | CardChildOption
    | CardChildDateTime
    | CardChildEmail
    | CardChildPhoneNumber
    | CardChildImageMap;

/***
 * Process Joi schema string with form values or mock values if formValues is undefined
 * @param schema
 * @param formValues
 * @returns
 */
export function processJoiStringSchema(schema: string, formValues: FormValues | undefined) {
    let _schema = schema;
    if (!schema.startsWith('Joi')) {
        _schema = `Joi.${schema}`;
    }
    _schema = `return ${_schema};`;

    const regex = /\{#(\d+)\}/g;

    let processedSchema = _schema;

    let match: RegExpExecArray | null;
    while ((match = regex.exec(_schema)) !== null) {
        if (formValues) {
            processedSchema = processedSchema.replace(match[0], formValues[match[1]]);
        } else {
            // Mock the value with null for schema validation purpose
            processedSchema = processedSchema.replace(match[0], '1');
        }
    }
    return processedSchema;
}
