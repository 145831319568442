import { Card, Space, Typography } from 'antd';
import { createFileRoute } from '@tanstack/react-router';
import { Link } from '@/components/Common/Link';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useFilterNavigationItem } from '@/hooks/useFilterNavigationItem';
import { useGenerateNavigationUIItems } from '@/hooks/useGenerateNavigationUIItems';
import { useCurrentUser } from '@/hooks/User/useCurrentUser';
import { NavigationItem } from '@/utils/navigation';
import { DashboardOutlined, FormOutlined, SearchOutlined } from '@ant-design/icons';
import { useGetRouteMetadata } from '@/hooks/useGetRouteMetadata';

const navigationItems: NavigationItem[] = [
    {
        icon: FormOutlined,
        to: '/console/$orgId/claims/lodge',
    },
    {
        icon: SearchOutlined,
        to: '/console/$orgId/claims/search',
    },
];

export const Route = createFileRoute('/console/$orgId/claim-module-only-home-page')({
    component: ClaimsModuleOnlyHomePage,
});

function ClaimsModuleOnlyHomePage() {
    const user = useCurrentUser();
    const orgId = useOrgId();
    const claimNavItems = useGenerateNavigationUIItems(navigationItems);
    const filterNavigationItem = useFilterNavigationItem();
    const getRouteMetadata = useGetRouteMetadata();

    return (
        <div className="p-8">
            <Card className="mb-8 text-center">
                <Typography.Title level={2} className="mb-4">
                    Welcome, {user.firstName || user.name}!
                </Typography.Title>
                <Typography.Text className="text-lg">What would you like to do today?</Typography.Text>
            </Card>

            {!!claimNavItems.length && (
                <Card title="Claims" className="mb-8">
                    <Space size="large" align="center" className="w-full justify-center">
                        {filterNavigationItem({ to: '/console/$orgId/dashboard/$section' }) && (
                            <Link
                                to="/console/$orgId/dashboard/$section"
                                params={{
                                    orgId,
                                    section: 'management',
                                }}
                                type="primary"
                                size="large"
                                icon={<DashboardOutlined />}
                            >
                                {getRouteMetadata('/console/$orgId/dashboard/$section')?.title}
                            </Link>
                        )}
                        {claimNavItems.map(({ to, icon, label }) => (
                            <Link
                                key={to}
                                to={to}
                                params={{
                                    orgId,
                                }}
                                type="default"
                                size="large"
                                icon={icon}
                            >
                                {label}
                            </Link>
                        ))}
                    </Space>
                </Card>
            )}
        </div>
    );
}
