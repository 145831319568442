import { errorMessage } from '@/components/Common/errorMessage';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { createFileRoute } from '@tanstack/react-router';
import { Button } from 'antd';
import { DateTime } from 'luxon';

export const Route = createFileRoute('/console/$orgId/debug/test')({
    staticData: {
        title: 'Test',
    },
    component: TestPage,
});

function TestPage() {
    const trpcClient = useTrpcClient();
    //const { data: helloWorld } = trpc.test.helloWorld.useQuery();

    //const { data: appBuildVersion } = trpc.appSetting.getAppBuildVersion.useQuery();

    //console.log(helloWorld);

    return (
        <div>
            <Button
                onClick={async () => {
                    try {
                        await trpcClient.test.unauthorized.query();
                    } catch (error) {
                        errorMessage.show(error);
                    }
                }}
            >
                Generate Unauthorized Error
            </Button>
            <Button
                onClick={async () => {
                    try {
                        await trpcClient.test.badRequest.query();
                    } catch (error) {
                        errorMessage.show(error);
                    }
                }}
            >
                Generate Bad Request Error
            </Button>
            <Button
                onClick={async () => {
                    await trpcClient.test.updateIncidentTest.mutate();
                }}
            >
                Update Incident Test
            </Button>
            <Button
                onClick={async () => {
                    const now = DateTime.now();
                    const nowIso = now.toISO();
                    console.log(nowIso, now.toJSDate());
                    console.log(DateTime.fromISO(nowIso).toJSDate());
                    console.log(DateTime.utc().toISO());
                }}
            >
                Date test
            </Button>
        </div>
    );
}
