import { IsNever } from 'type-fest';
import { Route } from 'shared/navigation/navRoutes';
import { FileRouteTypes } from '@/routeTree.gen';
import { generatePath, PathParam } from 'shared/navigation/utils';
import { Tree } from '@/utils/tree';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';

export type NavigationItem = {
    icon?: FC;
    tag?: {
        featureFlag?: keyof ReturnType<typeof useFlags>;
        color?: string;
        label?: string;
    };
    to: FileRouteTypes['to'];
};

export type NavigationTree = Tree<NavigationItem>;

/**
 * Function to generate breadcrumb for a route
 * @param route
 * @param params
 * @returns
 * @example
 * toBreadCrumb(navRoutes.compliance_complaints_search, { orgId });
 * toBreadCrumb(navRoutes.compliance_complaints_add, { orgId });
 * toBreadCrumb(navRoutes.compliance_complaints_edit, { complaintId: complaintId!, orgId });
 **/
export function toBreadCrumb<R extends Route>(
    route: R,
    ...params: IsNever<PathParam<R['path']>> extends true
        ? []
        : [
              {
                  [key in PathParam<R['path']>]: string | number | bigint;
              },
          ]
): { title: string; path: string };
/**
 * Function to generate breadcrumb for a route
 * @param route
 * @param params
 * @returns
 * @example
 * toBreadCrumb(navRoutes.compliance_complaints_search, { orgId });
 * toBreadCrumb(navRoutes.compliance_complaints_add, { orgId });
 * toBreadCrumb(navRoutes.compliance_complaints_edit, { complaintId: complaintId!, orgId });
 **/
export function toBreadCrumb<R extends Route>(
    route: R,
    params?: {
        [key in PathParam<R['path']>]: string | number | bigint;
    },
) {
    return {
        title: route.title,
        path: generatePath(route, params as any),
    };
}

export function toBreadCrumbWithoutLink(route: { title: string }) {
    return {
        title: route.title,
    };
}
