import { UploadButton } from '@/components/Common/Documents/UploadButton';
import { errorMessage } from '@/components/Common/errorMessage';
import { Link } from '@/components/Common/Link';
import { PageHeader } from '@/components/Common/PageHeader';
import { User } from '@/components/Common/User';
import { EmailDetailsAction, EmailDetailsDrawer } from '@/components/EmailClassification/EmailDetailsDrawer';
import { PageContent } from '@/components/Layout/PageContent';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { useFormatter } from '@/hooks/useFormatter';
import { useNavigate } from '@/hooks/useNavigate';
import { useCurrentUser } from '@/hooks/User/useCurrentUser';
import { RouterInputs, RouterOutputs, trpc } from '@/trpc';
import {
    AlertOutlined,
    CheckCircleOutlined,
    DeleteOutlined,
    EyeOutlined,
    ReloadOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { createFileRoute } from '@tanstack/react-router';
import { Badge, Button, Empty, message, Popconfirm, Progress, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { useState } from 'react';
import { canDeleteEmailClassification } from 'server/permissions/emailClassification';
import { S3File } from 'shared/types/S3File';

export const Route = createFileRoute('/console/$orgId/tools/ai-inbox')({
    staticData: {
        title: 'Compliance AI inbox',
        featureFlag: 'complianceInbox',
    },
    component: ComplianceAiInbox,
});

type QueryVariables = RouterInputs['emailClassification']['listEmailClassification'];
type EmailClassification = RouterOutputs['emailClassification']['listEmailClassification']['rows'][number];

function ComplianceAiInbox() {
    const navigate = useNavigate();
    const [emailDetailsAction, setEmailDetailsAction] = useState<EmailDetailsAction>(['hide']);
    const orgId = useOrgId();
    const user = useCurrentUser();

    const { mutateAsync: deleteEmailClassification } = trpc.emailClassification.deleteEmailClassification.useMutation();
    const { mutateAsync: rerunClassification } = trpc.emailClassification.rerunClassification.useMutation();
    const { mutateAsync: processEmail } = trpc.emailClassification.processEmailFile.useMutation();
    const { formatDateTime } = useFormatter();
    const [variables, setVariables] = useState<QueryVariables>({
        limit: 10,
        offset: 0,
        orderBy: [{ uploadedAt: 'desc' }],
    });

    const { data, isLoading, refetch } = trpc.emailClassification.listEmailClassification.useQuery(variables);

    const getScoreColor = (score: number) => {
        if (score >= 0.8) return 'success';
        if (score >= 0.6) return 'warning';
        return 'error';
    };

    const handleRerunClassification = async (record: EmailClassification) => {
        try {
            message.loading('Rerunning classification...', 0);
            await rerunClassification({ emailClassificationId: record.id });
            await refetch();
            message.destroy();
            message.success('Classification rerun successfully');
        } catch (error) {
            message.destroy();
            errorMessage.show(error);
        }
    };

    const renderCreateRecordButton = (record: EmailClassification) => {
        if (record.emailType === 'INCIDENT' || record.emailType === 'BREACH' || record.emailType === 'COMPLAINT') {
            return (
                <Tooltip
                    title={
                        record.emailType === 'INCIDENT' || record.emailType === 'BREACH'
                            ? 'Create incident'
                            : 'Create complaint'
                    }
                >
                    <Link
                        to={
                            record.emailType === 'INCIDENT' || record.emailType === 'BREACH'
                                ? '/console/$orgId/compliance/incidents/add'
                                : '/console/$orgId/compliance/complaints/add'
                        }
                        search={{
                            emailClassificationId: record.id,
                        }}
                        params={{
                            orgId,
                        }}
                        icon={
                            record.emailType === 'INCIDENT' || record.emailType === 'BREACH' ? (
                                <AlertOutlined className="text-red-500" />
                            ) : (
                                <WarningOutlined className="text-amber-500" />
                            )
                        }
                        type="text"
                    />
                </Tooltip>
            );
        }
        return null;
    };

    const handleDeleteEmail = async (emailClassificationId: bigint) => {
        try {
            message.loading('Deleting email classification...', 0);
            await deleteEmailClassification({ emailClassificationId });
            await refetch();
            message.destroy();
            message.success('Email classification deleted successfully');
        } catch (error) {
            message.destroy();
            errorMessage.show(error);
        }
    };

    const { tableProps } = useAntdTable<EmailClassification>({
        data: {
            rows: data?.rows || [],
            loading: isLoading,
            total: data?.total,
        },
        rowKey: 'id',
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit ?? variables.limit,
                offset: options?.offset ?? variables.offset,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                render: (value) => <Tag>{value.toString()}</Tag>,
            },
            {
                title: 'Email Type',
                dataIndex: 'emailType',
                sorter: true,
                render: (value) => (
                    <Space direction="vertical" size={2}>
                        <Tag color="blue">{value}</Tag>
                    </Space>
                ),
            },
            {
                title: <>Score</>,
                dataIndex: 'score',
                sorter: true,
                render: (value) => (
                    <Space direction="vertical" size={2} className="w-[100px]">
                        <Progress
                            percent={Math.round(value * 100)}
                            size="small"
                            status={getScoreColor(value) === 'error' ? 'exception' : 'normal'}
                            className="mb-0"
                        />
                    </Space>
                ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: true,
                render: (status: string) => (
                    <Space direction="vertical" size={2}>
                        <Badge
                            status={
                                status === 'PROCESSED'
                                    ? 'success'
                                    : status === 'ERROR'
                                      ? 'error'
                                      : status === 'REVIEWED'
                                        ? 'success'
                                        : 'processing'
                            }
                            text={
                                status === 'PENDING'
                                    ? 'Pending'
                                    : status === 'PROCESSED'
                                      ? 'Processed'
                                      : status === 'ERROR'
                                        ? 'Error'
                                        : 'Reviewed'
                            }
                            className="whitespace-nowrap"
                        />
                    </Space>
                ),
            },
            {
                title: 'Error',
                dataIndex: 'error',
                render: (error: string | null) =>
                    error ? (
                        <Tooltip title={error}>
                            <Tag color="error">Error</Tag>
                        </Tooltip>
                    ) : (
                        '-'
                    ),
            },
            {
                title: 'Uploaded At',
                dataIndex: 'uploadedAt',
                sorter: true,
                render: (_, record) => (
                    <Typography.Text type="secondary">
                        {record.uploadedAt ? formatDateTime(record.uploadedAt) : '-'}
                    </Typography.Text>
                ),
            },
            {
                title: 'Uploaded By',
                dataIndex: 'uploadedById',
                render: (uploadedById: bigint) => <User userId={uploadedById} />,
            },
            {
                title: 'Reviewed By',
                dataIndex: 'reviewedById',
                render: (reviewedById: bigint | null) => (reviewedById ? <User userId={reviewedById} /> : '-'),
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                fixed: 'right',
                render: (_, record) => {
                    return (
                        <Space>
                            <Tooltip title="Review">
                                <Button
                                    type="text"
                                    icon={<CheckCircleOutlined className="text-green-500" />}
                                    onClick={() =>
                                        navigate({
                                            to: '/console/$orgId/tools/ai-inbox/$id/review-email-classification',
                                            params: { orgId, id: record.id },
                                        })
                                    }
                                />
                            </Tooltip>
                            <Tooltip title="View email">
                                <Button
                                    type="text"
                                    icon={<EyeOutlined className="text-blue-500" />}
                                    onClick={() => handleViewEmail(record)}
                                />
                            </Tooltip>
                            {renderCreateRecordButton(record)}
                            <Tooltip title="Rerun classification">
                                <Button
                                    type="text"
                                    icon={<ReloadOutlined className="text-blue-500" />}
                                    onClick={() => handleRerunClassification(record)}
                                />
                            </Tooltip>
                            {canDeleteEmailClassification(user, record) && (
                                <Popconfirm
                                    title="Are you sure you want to delete this email classification?"
                                    onConfirm={async () => await handleDeleteEmail(record.id)}
                                >
                                    <Button type="text" icon={<DeleteOutlined className="text-red-500" />} />
                                </Popconfirm>
                            )}
                        </Space>
                    );
                },
            },
        ],
        paginationConfig: {
            hideOnSinglePage: false,
            showSizeChanger: true,
            showTotal: (total: number) => `Total ${total} items`,
        },
    });

    const handleProcessEmail = async (uploadedFile: S3File) => {
        try {
            message.loading('Processing email...', 0);
            await processEmail({ fileKey: uploadedFile.key });
            await refetch();
            message.destroy();
            message.success('Email processed successfully');
        } catch (error) {
            message.destroy();
            errorMessage.show(error);
        }
    };

    const handleViewEmail = (record: EmailClassification) => {
        setEmailDetailsAction(['view', record.id]);
    };

    return (
        <>
            <PageHeader title="Compliance AI Inbox" />
            <PageContent>
                <Space className="mb-6 flex justify-between">
                    <Typography.Text type="secondary">Drag and drop your email files here to upload.</Typography.Text>
                    <Space>
                        <UploadButton
                            buttonText="Upload email(s)"
                            accept="message/rfc822"
                            multiple={false}
                            onUploaded={handleProcessEmail}
                        />
                    </Space>
                </Space>
                <Table
                    {...tableProps}
                    scroll={{ x: 'max-content' }}
                    className="shadow-sm"
                    locale={{
                        emptyText: (
                            <Empty
                                className="flex h-[450px] flex-col justify-center"
                                description="No emails to display."
                            />
                        ),
                    }}
                />
                <EmailDetailsDrawer action={emailDetailsAction} onClose={() => setEmailDetailsAction(['hide'])} />
            </PageContent>
        </>
    );
}
