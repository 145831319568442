import { unallocatedPhotoUrl } from '@/global/constants';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { RouterOutputs, trpc } from '@/trpc';
import { Avatar, Button, Popover, Spin } from 'antd';
import { FormField } from '../FormField';
import { skipToken } from '@tanstack/react-query';
import clsx from 'clsx';
import { errorMessage } from '../errorMessage';

type Props = {
    photoUrl?: string | null;
    name?: string | null;
    size?: 'small' | 'medium' | 'large';
    className?: string;
    userId?: bigint;
    orgId?: bigint;
    showPopover?: boolean;
    unallocatedLabel?: string;
    showAvatar?: boolean;
};

const SIZES = {
    small: 18,
    medium: 22,
    large: 28,
} as const;

export const User = ({
    name: nameProp,
    photoUrl: photoUrlProp,
    orgId: orgIdProp,
    userId,
    size = 'medium',
    className,
    showPopover = true,
    showAvatar = true,
    unallocatedLabel = 'Unallocated',
}: Props) => {
    const currentOrgId = useOrgId();
    const isNeedToLoad = !nameProp;
    const orgId = BigInt(orgIdProp || currentOrgId);

    const { data: user, isPending } = trpc.user.getUser.useQuery(
        (isNeedToLoad || showPopover) && userId
            ? {
                  id: BigInt(userId),
                  orgId,
                  ...(showPopover && { select: ['HandlingParties'] }),
              }
            : skipToken,
        {
            throwOnError({ message }) {
                errorMessage.show(message);
                return false;
            },
        },
    );

    const name = nameProp || user?.fullName;
    const photoUrl = photoUrlProp || user?.photoUrl;

    if (isPending && isNeedToLoad && userId) {
        return (
            <div className={clsx('flex items-center', className)}>
                <Spin spinning={isPending} />
            </div>
        );
    }

    if (!userId && !name) {
        return (
            <div className={clsx('flex items-center gap-2', className)}>
                {showAvatar && (
                    <Avatar
                        size={SIZES[size]}
                        style={{ minWidth: SIZES[size] }}
                        src={unallocatedPhotoUrl}
                    />
                )}
                {unallocatedLabel}
            </div>
        );
    }

    if (showPopover && userId && user) {
        return (
            <Popover
                className="flex items-center gap-2"
                trigger={['hover', 'focus']}
                placement="right"
                zIndex={2000}
                content={<Content user={user} />}
            >
                {showAvatar && (
                    <Avatar size={SIZES[size]} style={{ minWidth: SIZES[size] }} src={photoUrl} />
                )}
                {name}
            </Popover>
        );
    }

    return (
        <div className={clsx('flex items-center gap-2', className)}>
            {showAvatar && (
                <Avatar size={SIZES[size]} style={{ minWidth: SIZES[size] }} src={photoUrl} />
            )}
            {name}
        </div>
    );
};

type ContentProps = {
    user: RouterOutputs['user']['getUser'];
};

const Content = ({ user }: ContentProps) => (
    <>
        <FormField
            label="E-mail"
            value={
                <Button type="link" href={`mailto:${user.email}`} className="m-0 p-0">
                    {user.email}
                </Button>
            }
            className="pb-1"
            disableFormatting
        />
        <FormField label="Name" value={user.fullName} className="pb-1" disableFormatting />
        <FormField label="ID" value={String(user.userId)} className="pb-1" disableFormatting />
        <FormField
            label="Handling parties"
            value={
                user?.HandlingParties?.length ? (
                    <ul>
                        {user.HandlingParties.map(({ id, name }) => (
                            <li key={id}>{name}</li>
                        ))}
                    </ul>
                ) : (
                    '-'
                )
            }
            disableFormatting
        />
    </>
);
