import { useEffect } from 'react';
import { trpc } from '@/trpc';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@/hooks/useNavigate';

export const Route = createFileRoute('/public/new-complaint-by-key/$key')({
    component: NewComplaintByKey,
});

function NewComplaintByKey() {
    const { key } = Route.useParams();
    const searchParams = Route.useSearch();
    const { data } = trpc.org.getOrgIdByKey.useQuery({ key });
    const navigate = useNavigate();

    useEffect(() => {
        if (!data) {
            return;
        }

        navigate({
            to: '/public/$orgId/complaint/new',
            params: {
                orgId: String(data),
            },
            search: searchParams,
        });
    }, [data]);

    return (
        <div className="flex min-h-screen items-center justify-center">
            <div className="text-lg text-gray-600">Redirecting to a new complaint form...</div>
        </div>
    );
}
