import { observer } from 'mobx-react-lite';
import { Button, Checkbox, Table, Tooltip } from 'antd';
import {
    contactsToContactCollection,
    makeContactPrimaryForClaim,
    makeContactPrimaryForType,
} from 'shared/utils/contact';
import React, { useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Contact, ContactCollection, ContactType } from 'shared/types/contact';
import { DeleteOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons';
import { sortBy } from 'shared/utils/array';
import { capitalize } from '@/utils/string';
import { ContactEdit, ContactEditAction } from '@/components/Contact/ContactEdit';
import { cloneDeep } from '@/utils/general';
import { modal } from '@/components/Common/Modal';
import { useFormatter } from '@/hooks/useFormatter';

interface Props<T extends Contact = Contact> {
    value?: ContactCollection<T>;
    /** Informs parent about the changes in the contact list - use onRemove & onUpdate for more detailed info */
    onChange?: (contacts: ContactCollection<T>) => void;
    contactType: ContactType;
    readOnly?: boolean;
    title?: string;
    loading?: boolean;
    hideIsPrimaryColumn?: boolean;
    hideIsPrimaryForTypeColumn?: boolean;
    headerClassName?: string;
    /** Allows parent to remove contact from the DB */
    onRemove?: (contactId: string) => void;
    /** Allows parent to update contact in the DB */
    onUpdate?: (contact: T) => void;
    customColumns?: ColumnsType<T>;
    hideHeader?: boolean;
    /** Allows parent to show the add new contact form */
    showAddNewContactForm?: boolean;
    /** Informs parent that the add new contact form has been closed */
    addNewContactFormClosed?: () => void;
}

export const ContactList = observer(
    <T extends Contact = Contact>({
        value,
        onChange,
        contactType,
        readOnly = false,
        title,
        loading = false,
        hideIsPrimaryColumn = false,
        hideIsPrimaryForTypeColumn = false,
        headerClassName,
        onRemove,
        onUpdate,
        customColumns,
        hideHeader = false,
        showAddNewContactForm = false,
        addNewContactFormClosed,
    }: Props<T>) => {
        const [editData, setEditData] = useState<{ action: ContactEditAction; contact?: T }>({
            action: 'none',
            contact: undefined,
        });
        const [contacts, setContacts] = useState<ContactCollection<T>>(value || {});

        const { formatPhoneNumber } = useFormatter();

        useEffect(() => {
            setContacts(cloneDeep(value || {}));
        }, [value]);

        useEffect(() => {
            if (showAddNewContactForm) {
                setEditData({ action: 'add', contact: undefined });
            }
        }, [showAddNewContactForm]);

        const onContactChange = (contact: T) => {
            // Remove contact if it already exists and add it back
            contacts[contact.id] = contact;
            if ('isPrimary' in contact && contact.isPrimary) {
                makeContactPrimaryForClaim(contacts, contact.id);
            }
            if (contact.isPrimaryForType || Object.keys(contacts).length === 1) {
                makeContactPrimaryForType(contacts, contact.id);
            }
            setContacts(contacts);
            onChange?.(contacts);
            onUpdate?.(contact);
        };

        const tableDs = useMemo(() => {
            return sortBy(
                Object.values(contacts).filter((el) => el.type === contactType),
                (item) => item.lastName,
            );
        }, [contacts, contactType]);

        const columns: ColumnsType<T> = customColumns || [
            {
                dataIndex: 'firstName',
                title: 'First name',
            },
            {
                dataIndex: 'lastName',
                title: 'Last name',
            },
            {
                dataIndex: 'email',
                title: 'Email',
            },
            {
                dataIndex: 'phone',
                title: 'Phone',
                render: (value) => formatPhoneNumber(value),
            },
        ];
        if (!customColumns) {
            if (!hideIsPrimaryColumn) {
                columns.unshift({
                    dataIndex: 'isPrimary',
                    title: '',
                    render: (value) =>
                        value ? (
                            <Tooltip title="Main claim contact">
                                <div className="font-bold">P</div>
                            </Tooltip>
                        ) : (
                            ''
                        ),
                });
            }
            if (!hideIsPrimaryForTypeColumn) {
                columns.push({
                    dataIndex: 'isPrimaryForType',
                    title: 'Primary',
                    render: (value) => <Checkbox checked={value} onChange={() => {}} />,
                });
            }
        }

        if (!readOnly) {
            columns.push({
                title: 'Actions',
                fixed: 'right',
                align: 'center',

                render: (_value, row: T) => (
                    <div className="whitespace-nowrap">
                        <Button
                            type="link"
                            size="small"
                            icon={<EditOutlined />}
                            onClick={() => {
                                setEditData({ action: 'edit', contact: row });
                            }}
                        />
                        <Button
                            type="link"
                            size="small"
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                modal.confirm({
                                    title: 'Delete contact',
                                    content:
                                        'Are you sure you want to delete this contact? This action cannot be undone.',
                                    onOk: () => {
                                        const newCc = contactsToContactCollection(
                                            Object.values(contacts).filter(
                                                (item) => item.id !== row.id,
                                            ) || [],
                                        );
                                        if (row.isPrimaryForType && Object.keys(newCc).length > 0) {
                                            makeContactPrimaryForType(
                                                newCc,
                                                Object.values(newCc)[0].id,
                                            );
                                        }
                                        setContacts(newCc);
                                        onChange?.(newCc);
                                        onRemove?.(row.id);
                                    },
                                });
                            }}
                        />
                    </div>
                ),
            });
        }

        return (
            <>
                <ContactEdit
                    action={editData.action}
                    data={editData.contact}
                    contactType={contactType}
                    onChange={onContactChange}
                    onClose={() => {
                        setEditData({ action: 'none' });
                        addNewContactFormClosed?.();
                    }}
                    contactTypeTitle={capitalize(title || contactType.replace('_', ' '))}
                />
                <Table
                    title={
                        hideHeader
                            ? undefined
                            : () => (
                                  <div className="flex justify-between p-2 align-middle">
                                      <div className={headerClassName}>
                                          {title ||
                                              capitalize(contactType) + ' contacts' ||
                                              'Contacts'}
                                      </div>
                                      {!readOnly && (
                                          <Button
                                              type="primary"
                                              icon={<UserAddOutlined />}
                                              size="small"
                                              onClick={() => {
                                                  setEditData({
                                                      action: 'add',
                                                      contact: undefined,
                                                  });
                                              }}
                                          >
                                              Add contact
                                          </Button>
                                      )}
                                  </div>
                              )
                    }
                    rowKey="id"
                    scroll={{ x: 'max-content' }}
                    tableLayout="fixed"
                    columns={columns}
                    dataSource={tableDs}
                    size="small"
                    bordered
                    pagination={false}
                    loading={loading}
                />
            </>
        );
    },
);
