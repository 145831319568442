import { useFlags } from 'launchdarkly-react-client-sdk';
import { notification, Typography } from 'antd';
import { PropsWithChildren, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate } from '@/hooks/useNavigate';

export function MaintenanceModeOverlay({ children }: PropsWithChildren) {
    const { inMaintenanceMode, forceDisableMaintenance, redirectDestinationAfterMaintenance } =
        useFlags();
    const navigate = useNavigate();

    const [location, setLocation] = useState<string | null>(null);

    useEffect(() => {
        Sentry.setTag('in-maintenance-mode', inMaintenanceMode ? true : false);
        if (forceDisableMaintenance) {
            return;
        }

        if (inMaintenanceMode) {
            setLocation(window.location.href);
            navigate({ to: '/' });
            return;
        }

        if (location) {
            notification.success({
                message: 'Maintenance Complete',
                placement: 'top',
                description:
                    'The system maintenance is complete. Redirecting you to your previous location...',
                duration: 3,
                onClose: () => {
                    if (redirectDestinationAfterMaintenance === 'previous-location') {
                        // Redirect to the previous location but add timestamp to the url to avoid caching.
                        window.location.href = `${location}${location.includes('?') ? '&' : '?'}t=${Date.now()}`;
                    } else if (redirectDestinationAfterMaintenance === 'homepage') {
                        navigate({ to: '/' });
                    } else if (redirectDestinationAfterMaintenance === 'login') {
                        navigate({ to: '/login' });
                    }
                    setLocation(null);
                },
            });
        }
    }, [inMaintenanceMode, forceDisableMaintenance]);

    if (inMaintenanceMode && !forceDisableMaintenance) {
        return (
            <div className="flex h-screen w-full items-center justify-center bg-amber-300">
                <div className="text-lg">
                    <Typography.Title level={2}>Under Maintenance</Typography.Title>
                    <Typography.Text>
                        The site is undergoing upgrade. We'll be back soon.
                    </Typography.Text>
                </div>
            </div>
        );
    }

    return children;
}
