import {
    Contact,
    ContactBase,
    ContactCollection,
    ContactType,
    ContactTypeLiteralUnion,
} from '../types/contact';
import invariant from 'tiny-invariant';

/**
 * @param contacts Modifies the array in place
 * @param contactId Pass undefined or null to clear isPrimary flag
 */
export function makeContactPrimaryForClaim<T extends ContactBase = Contact>(
    contacts: ContactCollection<T>,
    contactId: string | undefined | null,
) {
    Object.values(contacts).forEach((el) => {
        if ('isPrimary' in el) {
            el.isPrimary = el.id === contactId;
        }
    });
}

/**
 * @param contacts Modifies the array in place
 * @param contactId Pass undefined or null to clear isPrimaryForType flag
 */
export function makeContactPrimaryForType<T extends ContactBase = Contact>(
    contacts: ContactCollection<T>,
    contactId: string | undefined | null,
) {
    const type = Object.values(contacts).find((el) => el.id === contactId)?.type;
    invariant(!!type, "contact doesn't exist");
    Object.values(contacts).forEach((el) => {
        if (el.type === type) {
            el.isPrimaryForType = el.id === contactId;
        }
    });
}

export function getPrimaryContact(contacts: ContactCollection | undefined) {
    if (!contacts) return undefined;
    return Object.values(contacts).find((item) => {
        return 'isPrimary' in item && item.isPrimary;
    });
}

export function getPrimaryContactForType<T extends ContactTypeLiteralUnion>(
    contacts: ContactCollection | undefined,
    type: T,
) {
    if (!contacts) return undefined;
    return Object.values(contacts).find(
        (item) => item.isPrimaryForType && item.type === type,
    ) as Extract<Contact, { type: T }>;
}

export function getFirstContactForType<T extends ContactType>(
    contacts: ContactCollection | undefined,
    type: T,
) {
    if (!contacts) return undefined;
    return Object.values(contacts).find((item) => item.type === type) as Extract<
        Contact,
        { type: T }
    >;
}

/*
 * @param contacts Modifies the object in place
 */
export function processPrimaryContacts(contacts: ContactCollection, updatedContactId: string) {
    const contact = contacts[updatedContactId];
    invariant(contact, 'contact must exist');
    if ('isPrimary' in contact && contact.isPrimary) {
        makeContactPrimaryForClaim(contacts, updatedContactId);
    }
    if (contact.isPrimaryForType) {
        makeContactPrimaryForType(contacts, updatedContactId);
    }
}

export function contactsToContactCollection<T extends ContactBase>(
    contacts: T[] | T,
): ContactCollection<T> {
    if (!contacts) return {};
    const contactCollection: ContactCollection<T> = {};
    const _contacts = Array.isArray(contacts) ? contacts : [contacts];
    _contacts
        .filter((c) => !!c)
        .forEach((contact) => {
            contactCollection[contact.id] = contact;
        });
    return contactCollection;
}

export function getContactFullName(contact?: Contact) {
    if (!contact) return '';
    return `${contact.firstName || ''} ${contact.lastName || ''}`.trim();
}

export function getContact(contacts: ContactCollection, contactId: string) {
    if (!contacts) return undefined;
    return contacts[contactId];
}
