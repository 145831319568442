import { stripHtml } from 'string-strip-html';
import dompurify from 'dompurify';

/**
 * Converts a string to HTML and sanitizes it
 * @param text
 */
export function textToHtml(text: string | null | undefined) {
    if (typeof text !== 'string') return text || '';
    if (!text) {
        return '';
    }
    return dompurify.sanitize(text).replaceAll('\n', '<br/>');
}

export function htmlToText(html: string | null | undefined) {
    if (!html) {
        return '';
    }
    return stripHtml(html).result;
}

export function sanitizeHtml(html: string | null | undefined) {
    if (typeof html !== 'string') return html || '';
    if (!html) {
        return '';
    }

    return dompurify.sanitize(html, { ALLOWED_ATTR: ['href', 'target'] });
}

export function htmlContainsText(html: string | null | undefined) {
    if (!html) {
        return false;
    }

    const parser = new DOMParser();
    const { body } = parser.parseFromString(sanitizeHtml(html), 'text/html');

    return !!body.textContent?.trim();
}

/**
 * Sanitizes and normalizes content by removing unnecessary whitespace
 * @param content - The content to sanitize
 * @returns Sanitized and normalized content
 */
export function sanitizeContent(content: string | null | undefined): string {
    if (typeof content !== 'string') return '';
    if (!content) return '';

    // Remove multiple spaces, tabs, and newlines
    return content
        .replace(/[\s\n\r\t]+/g, ' ') // Replace multiple whitespace with single space
        .trim(); // Remove leading and trailing whitespace
}
