import { useEffect, useState } from 'react';
import { useAppStateStore } from '@/stores/AppStateStore';
import { trpc } from '@/trpc';
import { App } from 'antd';
import { useIdleTimer } from 'react-idle-timer';

export function useAppBuildVersion() {
    const appState = useAppStateStore.getState();
    const { modal } = App.useApp();
    const { data: appBuildVersion, refetch } = trpc.appSetting.getAppBuildVersion.useQuery();

    const [isModalOpen, setIsModalOpen] = useState(false);

    useIdleTimer({
        timeout: 1000 * 60 * 5, // 5 minutes
        onPresenceChange: (presence) => {
            if (presence.type === 'active') {
                void refetch();
            }
        },
    });

    useEffect(() => {
        // Version has changed and that means we need to reload the app
        if (
            appBuildVersion &&
            appState.appBuildVersion &&
            appBuildVersion !== appState.appBuildVersion
        ) {
            if (!isModalOpen) {
                setIsModalOpen(true);

                modal.info({
                    title: 'New Version Available',
                    content:
                        'A new version of the application is available. The page will reload to update.',
                    okText: 'Reload Now',
                    onOk: () => {
                        window.location.href = `${window.location.href}${window.location.href.includes('?') ? '&' : '?'}t=${Date.now()}`;
                    },
                    centered: true,
                });
            }
        } else if (appBuildVersion) {
            appState.setAppBuildVersion(appBuildVersion);
        }
    }, [appBuildVersion]);
}
