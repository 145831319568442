import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { navRoutes } from 'shared/navigation/navRoutes';
import { PageHeader } from '@/components/Common/PageHeader';
import { Tabs } from 'antd';
import { RiskAppetiteStatementManageDetails } from './RiskAppetiteStatementManageDetails';
import { History } from '@/components/Common/History';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/risk/ras/details/$id')({
    staticData: {
        title: 'Risk appetite statement details',
    },
    params: {
        parse({ id }) {
            return {
                id: BigInt(id),
            };
        },
    },
    component: RiskAppetiteStatementDetails,
});

function RiskAppetiteStatementDetails() {
    const orgId = useOrgId();
    const { id } = Route.useParams();

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.risk),
                    toBreadCrumb(navRoutes.risk_ras_search, { orgId }),
                    { title: 'Details' },
                ]}
            />

            <PageHeader title="Risk Appetite Statement" />

            <Tabs
                type="line"
                items={[
                    {
                        key: 'risk_appetite_statement_details',
                        label: 'Risk Appetite Statement Details',
                        destroyInactiveTabPane: true,
                        children: <RiskAppetiteStatementManageDetails />,
                    },
                    {
                        key: 'risk_appetite_statement_history',
                        label: 'History',
                        destroyInactiveTabPane: true,
                        children: (
                            <History
                                idValue={Number(id)}
                                idPropertyName="riskAppetiteStatementId"
                            />
                        ),
                    },
                ]}
            />
        </>
    );
}
