import { mapObjectKeysToValues } from '../utils/object';

export const USER_FLAGS = {
    PAYMENT_ADMIN: 'payment_admin',
    CAN_VIEW_UNALLOCATED_CLAIMS: 'can_view_unallocated_claims',
} as const;

export type UserFlag = (typeof USER_FLAGS)[keyof typeof USER_FLAGS];

export const USER_ROLES = {
    USER: 'user',
    ORG_ADMIN: 'org_admin',
    SUPPLIER: 'supplier',
    //CLAIM_ADMIN: 'claim_admin',
} as const;

export type UserRole = (typeof USER_ROLES)[keyof typeof USER_ROLES];

export const SYSTEM_ACCOUNT_ID = {
    SYSTEM: 1,
    OTHER: -1,
    ANONYMOUS: -2,
    /*
        Unallocated user id is store in DB as null. However null is not a valid value for Select component. So we use -9999 as a placeholder for null.
    */
    UNALLOCATED: -9999n,
} as const;

export type SystemAccountId = keyof typeof SYSTEM_ACCOUNT_ID;

export const USER_SYSTEM_USAGE = {
    '1st_line': '1st Line',
    '2nd_line': '2nd Line',
    audit: 'Audit',
    oversight: 'Oversight',
    non_contributory: 'Non-contributory',
} as const;

export const USER_SYSTEM_USAGE_KEYS = mapObjectKeysToValues(USER_SYSTEM_USAGE);

export type UserSystemUsage = keyof typeof USER_SYSTEM_USAGE;

export const USER_STATISTIC_SETTINGS = {
    GREEN_STATUS_OF_NUMBER_OF_LOGINS_IN_12_WEEKS_PERCENTAGE_STEPS: [25, 74.99],
    FIRST_LINE_NUMBER_OF_LOGINS_IN_12_WEEKS_STEPS: [3, 11],
    SECOND_LINE_NUMBER_OF_LOGINS_IN_12_WEEKS_STEPS: [3, 11],
    OVERSIGHT_NUMBER_OF_LOGINS_IN_12_WEEKS_STEPS: [0, 2],
} as const;
