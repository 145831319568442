import { gql } from '@/__generated__';
import { TrainingRecordByPkQuery, TrainingRecordInsertInput, TrainingRecordSetInput } from '@/__generated__/graphql';
import { DatePickerOld } from '@/components/Common/DatePickerOld';
import { errorMessage } from '@/components/Common/errorMessage';
import { FileUpload, IFileUpload } from '@/components/Common/FileUpload';
import { UserSelectorMultiple } from '@/components/Common/UserSelectorMultiple';
import { TrainingRecordDefs } from '@/global/trainingRecord';
import { TrainingPeriodDs } from '@/graphql/queries/trainingPeriod';
import { useConfigServiceLoader } from '@/hooks/Configuration/useConfigServiceLoader';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useFormatter } from '@/hooks/useFormatter';
import { useLocalStore_depr } from '@/hooks/useLocalStore_depr';
import { useSafePath } from '@/hooks/useSafePath';
import { debounce } from '@/utils/general';
import { toJSON } from '@/utils/hasura';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Drawer, Form, Input, InputNumber, message, Select, Skeleton, Space } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocalStore } from '@/hooks/useLocalStore';
import { useParams, useMatches } from '@tanstack/react-router';
import { trpc } from '@/trpc';
import { useNavigate } from '@/hooks/useNavigate';

type Props = {
    recordId?: number | null;
};

type TrainingRecord = NonNullable<TrainingRecordByPkQuery['TrainingRecordByPk']>;

const { Option } = Select;

type State = {
    employeeIds: bigint[] | null;
};

export const TrainingRecordEdit = observer(({ recordId }: Props) => {
    const matches = useMatches();
    const trpcUtils = trpc.useUtils();
    const [trainingRecord, initStore] = useLocalStore<TrainingRecord>();
    const [getTrainingRecordByPk] = useLazyQuery(TrainingRecordByPk);
    const [getTrainingPeriod] = useLazyQuery(TrainingPeriodDs);
    const [insertTrainingRecordOne] = useMutation(InsertTrainingRecordOne);
    const [insertTrainingRecord] = useMutation(InsertTrainingRecord);
    const [updateTrainingRecord] = useMutation(UpdateTrainingRecord);
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const fileUploadRef = useRef<IFileUpload>(null);
    const safePath = useSafePath<TrainingRecord>();
    const safePathState = useSafePath<State>();
    const [open, setOpen] = useState(true);
    const config = useConfigServiceLoader((configService) => configService.fetchStaffTrainingRecord());
    const { fDateShortDayJs } = useFormatter();

    const [state, initState] = useLocalStore_depr<State>(() => ({
        employeeIds: [],
    }));

    const { userId } = useParams({
        strict: false,
    });

    const orgId = useOrgId();

    const action: 'edit' | 'add' = useMemo(() => (recordId ? 'edit' : 'add'), [recordId]);

    const initForm = async () => {
        try {
            setLoading(true);
            form.resetFields();

            if (action === 'add') {
                if (recordId) {
                    const { data } = await getTrainingRecordByPk({
                        variables: { id: recordId, orgId },
                        fetchPolicy: 'network-only',
                    });

                    if (data?.TrainingRecordByPk) {
                        initStore(data?.TrainingRecordByPk);
                    }
                } else {
                    initStore({} as TrainingRecord);
                }
            } else {
                const { data } = await getTrainingRecordByPk({
                    variables: { id: recordId, orgId },
                    fetchPolicy: 'network-only',
                });

                if (data?.TrainingRecordByPk) {
                    initStore(data?.TrainingRecordByPk);
                }
            }

            form.setFieldsValue(trainingRecord);
        } catch (e) {
            errorMessage.show(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        void initForm();
    }, [recordId]);

    const closeDrawer = () => {
        form.resetFields();
        initState({});
        fileUploadRef.current?.clearFileList();
        trpcUtils.staffTraining.invalidate();
        setOpen(false);
    };

    const save = debounce(async () => {
        try {
            setSaving(true);
            message.info('Saving...');

            const trainingPeriodRes = await getTrainingPeriod({
                variables: {
                    where: {
                        _and: [
                            { dateFrom: { _lte: trainingRecord.dateOfTraining } },
                            { dateTo: { _gte: trainingRecord.dateOfTraining } },
                        ],
                    },
                },
                fetchPolicy: 'network-only',
            });

            if (!trainingPeriodRes.data?.TrainingPeriod.length) {
                throw new Error(
                    'Unable to safe this staff training record.' +
                        'The training period does not exist that includes the date of training.' +
                        'Please contact your administrator and ask them to create a new training period for your organization.',
                );
            }

            if (action === 'add') {
                if (userId) {
                    const { data } = await insertTrainingRecordOne({
                        variables: {
                            trainingRecord: {
                                ...toJSON<TrainingRecordInsertInput>()(trainingRecord, 'id', 'User'),
                                userId,
                                trainingPeriodId: trainingPeriodRes.data?.TrainingPeriod[0].id,
                            },
                        },
                    });

                    const id = data?.insertTrainingRecordOne?.id;
                    await fileUploadRef.current?.insertFileList([id]);
                } else {
                    const trainingRecords = state.employeeIds?.map((id) => {
                        return {
                            ...toJSON<TrainingRecordInsertInput>()(trainingRecord, 'id', 'User'),
                            userId: id,
                            trainingPeriodId: trainingPeriodRes.data?.TrainingPeriod[0].id,
                        };
                    });

                    const { data } = await insertTrainingRecord({
                        variables: {
                            trainingRecords,
                        },
                    });

                    const ids =
                        data?.insertTrainingRecord?.returning.map((item) => {
                            return item.id as number;
                        }) || [];

                    if (ids.length > 0) {
                        await fileUploadRef.current?.insertFileList(ids);
                    }
                }
            } else {
                console.log('updateTrainingRecord', trainingRecord);
                await updateTrainingRecord({
                    variables: {
                        trainingRecord: toJSON<TrainingRecordSetInput>()(trainingRecord, 'orgId', 'id', 'User'),
                        id: recordId,
                        orgId,
                    },
                });

                await fileUploadRef.current?.insertFileList([recordId!]);
            }

            message.destroy();
            message.success('Saved.');
            setOpen(false);
        } catch (e) {
            errorMessage.show(e);
        } finally {
            setSaving(false);
        }
    });

    return (
        <Drawer
            open={open}
            onClose={() => {
                closeDrawer();
            }}
            afterOpenChange={(open) => {
                if (!open) {
                    const parentRoute = matches[matches.length - 2];
                    navigate({
                        to: parentRoute.pathname,
                    });
                }
            }}
            width={500}
            title={action === 'edit' ? 'Edit training record' : 'Add new training record'}
            footer={
                <Space className="flex justify-end">
                    <Button onClick={() => closeDrawer()}>Cancel</Button>
                    <Button type="primary" htmlType="submit" form="addTrainingRecordForm" loading={saving}>
                        Save
                    </Button>
                </Space>
            }
        >
            <Form
                id="addTrainingRecordForm"
                form={form}
                className="mt-5"
                layout="vertical"
                onFinish={save}
                onFinishFailed={(errorInfo) => {
                    form.scrollToField(errorInfo.errorFields[0].name);
                }}
            >
                {loading || !trainingRecord ? (
                    <Skeleton active />
                ) : (
                    <>
                        <Form.Item
                            label="Training record name"
                            name={safePath('trainingName')}
                            rules={[{ required: true, message: 'Please enter name.' }]}
                            required
                        >
                            <Input
                                placeholder="Enter training name"
                                value={trainingRecord.trainingName}
                                onChange={(e) => {
                                    trainingRecord.trainingName = e.target.value;
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Type" name={safePath('type')} required>
                            <Select
                                onChange={(value) => (trainingRecord.type = value)}
                                placeholder="Select type"
                                value={trainingRecord.type || ''}
                            >
                                {TrainingRecordDefs.aryTypes.map((type) => (
                                    <Option value={type} key={type}>
                                        {type}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {!userId && (
                            <Form.Item
                                label="Employee(s)"
                                name={safePathState('employeeIds')}
                                required
                                rules={[
                                    {
                                        validator: async () => {
                                            if (!state.employeeIds?.length)
                                                throw new Error('Please select name employee.');
                                        },
                                    },
                                ]}
                                validateTrigger={['onSelect']}
                            >
                                <UserSelectorMultiple
                                    value={null}
                                    placeholder="Select employee(s)"
                                    onChange={(value) => {
                                        state.employeeIds = value;
                                    }}
                                    onlyEmployee
                                />
                            </Form.Item>
                        )}
                        <Form.Item label="Provider" name={safePath('provider')}>
                            <Input
                                placeholder="Enter provider name"
                                value={trainingRecord.provider || ''}
                                onChange={(e) => {
                                    trainingRecord.provider = e.target.value;
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Date completed" name={safePath('dateOfTraining')}>
                            <DatePickerOld
                                onChange={(date) => {
                                    trainingRecord.dateOfTraining = date;
                                }}
                                value={trainingRecord.dateOfTraining}
                                className="w-full"
                                format={fDateShortDayJs}
                                maxDate={dayjs()}
                            />
                        </Form.Item>
                        <Form.Item
                            label="No of hours"
                            name={safePath('noOfHours')}
                            required
                            rules={[
                                {
                                    validator: async () => {
                                        if (!trainingRecord.noOfHours) throw new Error('Field is required');
                                        if (trainingRecord.noOfHours < 0)
                                            throw new Error('Only positive values are allowed');
                                    },
                                },
                            ]}
                        >
                            <InputNumber
                                className="w-full"
                                value={trainingRecord.noOfHours}
                                type="number"
                                onChange={(value) => (trainingRecord.noOfHours = value)}
                                min={0}
                            />
                        </Form.Item>
                        <Form.Item
                            label={config.data?.pointsName}
                            name={safePath('cipCredits')}
                            required
                            rules={[
                                {
                                    validator: async () => {
                                        if (!trainingRecord.cipCredits) throw new Error('Field is required');
                                        if (trainingRecord.cipCredits < 0)
                                            throw new Error('Only positive values are allowed');
                                    },
                                },
                            ]}
                        >
                            <InputNumber
                                className="w-full"
                                value={trainingRecord.cipCredits}
                                type="number"
                                onChange={(value) => (trainingRecord.cipCredits = value)}
                                min={0}
                            />
                        </Form.Item>
                        <Form.Item label="Comments" name={safePath('comments')}>
                            <Input.TextArea
                                rows={4}
                                placeholder="Add comments..."
                                value={trainingRecord.comments || ''}
                                onChange={(e) => {
                                    trainingRecord.comments = e.target.value;
                                }}
                            />
                        </Form.Item>
                        <FileUpload
                            idPropertyName="trainingRecordId"
                            idValues={recordId ? [recordId] : undefined}
                            ref={fileUploadRef}
                        />
                    </>
                )}
            </Form>
        </Drawer>
    );
});

const TrainingRecordByPk = gql(/* GraphQL */ `
    query TrainingRecordByPk($id: bigint = "", $orgId: bigint = "") {
        TrainingRecordByPk(id: $id, orgId: $orgId) {
            id
            cipCredits
            comments
            createdAt
            createdById
            dateOfTraining
            noOfHours
            orgId
            provider
            userId
            trainingName
            type
            User {
                ...UserInOrgBasicFields
            }
            CreatedBy {
                ...UserInOrgBasicFields
            }
        }
    }
`);

const InsertTrainingRecordOne = gql(/* GraphQL */ `
    mutation InsertTrainingRecordOne($trainingRecord: TrainingRecordInsertInput = {}) {
        insertTrainingRecordOne(object: $trainingRecord) {
            id
        }
    }
`);

const InsertTrainingRecord = gql(/* GraphQL */ `
    mutation InsertTrainingRecord($trainingRecords: [TrainingRecordInsertInput!] = {}) {
        insertTrainingRecord(objects: $trainingRecords) {
            returning {
                id
            }
        }
    }
`);

const UpdateTrainingRecord = gql(/* GraphQL */ `
    mutation UpdateTrainingRecord($id: bigint!, $orgId: bigint!, $trainingRecord: TrainingRecordSetInput = {}) {
        updateTrainingRecordByPk(pkColumns: { id: $id, orgId: $orgId }, _set: $trainingRecord) {
            id
        }
    }
`);
