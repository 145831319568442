import { errorMessage } from '@/components/Common/errorMessage';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useNavigate } from '@/hooks/useNavigate';
import { useTrpc } from '@/hooks/useTrpc';
import { RouterInputs, trpc } from '@/trpc';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Drawer, Form, Input, InputNumber, message, Select, Space, Switch } from 'antd';
import { useEffect, useState } from 'react';

export const Route = createFileRoute(
    '/console/$orgId/tools/ai-inbox/$id/review-email-classification',
)({
    params: {
        parse({ id }) {
            return {
                id: BigInt(id),
            };
        },
    },
    component: ReviewEmailClassification,
});

type FormValues = RouterInputs['emailClassification']['reviewEmailClassification'];

const FormItem = Form.Item<FormValues>;

function ReviewEmailClassification() {
    const { id } = Route.useParams();
    const orgId = useOrgId();
    const navigate = useNavigate();
    const [form] = Form.useForm<FormValues>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { trpcClient, trpcUtils } = useTrpc();
    const [open, setOpen] = useState(true);

    const { data } = trpc.emailClassification.getEmailClassification.useQuery(
        { id },
        {
            throwOnError(error) {
                message.error(error.message);
                return false;
            },
        },
    );

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                emailType: data?.emailType || 'UNKNOWN',
                score: data?.score || 0,
                isBreachOfRegulation: data?.isBreachOfRegulation || false,
                breachedRegulation: data?.breachedRegulation || '',
                justification: data?.justification || '',
            });
        }
    }, [data]);

    const handleSubmit = async (values: FormValues) => {
        if (!data) return;

        try {
            setIsSubmitting(true);
            message.loading('Reviewing email classification...');
            await trpcClient.emailClassification.reviewEmailClassification.mutate({
                id: Number(data.id),
                emailType: values.emailType,
                score: values.score,
                isBreachOfRegulation: values.isBreachOfRegulation,
                breachedRegulation: values.breachedRegulation,
                justification: values.justification,
            });
            message.destroy();
            message.success('Email classification reviewed successfully');
            trpcUtils.emailClassification.invalidate();
            setOpen(false);
        } catch (error) {
            message.destroy();
            errorMessage.show(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Drawer
            title="Review Email Classification"
            open={open}
            onClose={() => setOpen(false)}
            afterOpenChange={(open) => {
                if (!open) {
                    navigate({
                        to: '/console/$orgId/tools/ai-inbox',
                        params: { orgId: orgId },
                    });
                }
            }}
            width={700}
            footer={
                <Space className="flex justify-end">
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        type="primary"
                        loading={isSubmitting}
                        htmlType="submit"
                        form={`review-email-classification-${id}`}
                    >
                        Submit Review
                    </Button>
                </Space>
            }
        >
            {data && (
                <Form<FormValues>
                    form={form}
                    id={`review-email-classification-${id}`}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <FormItem
                        name="emailType"
                        label="Email Type"
                        rules={[{ required: true, message: 'Please select an email type' }]}
                    >
                        <Select>
                            <Select.Option value="UNKNOWN">Unknown</Select.Option>
                            <Select.Option value="INCIDENT">Incident</Select.Option>
                            <Select.Option value="COMPLAINT">Complaint</Select.Option>
                            <Select.Option value="BREACH">Breach</Select.Option>
                        </Select>
                    </FormItem>

                    <FormItem
                        name="score"
                        label="Confidence Score"
                        rules={[{ required: true, message: 'Please enter a score' }]}
                        extra="Score should be between 0 and 1"
                    >
                        <InputNumber min={0} max={1} step={0.01} style={{ width: '100%' }} />
                    </FormItem>

                    <FormItem
                        name="isBreachOfRegulation"
                        label="Is Breach of Regulation?"
                        valuePropName="checked"
                    >
                        <Switch />
                    </FormItem>

                    <FormItem name="breachedRegulation" label="Breached Regulation">
                        <Input.TextArea autoSize={{ minRows: 4, maxRows: 10 }} />
                    </FormItem>

                    <FormItem name="justification" label="Justification">
                        <Input.TextArea autoSize={{ minRows: 6, maxRows: 10 }} />
                    </FormItem>
                </Form>
            )}
        </Drawer>
    );
}
