import { FC } from 'react';
import {
    BarChartOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    ControlOutlined,
    // CreditCardOutlined,
    DashboardOutlined,
    DatabaseOutlined,
    ExclamationCircleOutlined,
    ExclamationOutlined,
    FormOutlined,
    FrownOutlined,
    GlobalOutlined,
    // HddOutlined,
    HomeOutlined,
    MailOutlined,
    MessageOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    RobotOutlined,
    SafetyOutlined,
    SearchOutlined,
    SettingOutlined,
    SolutionOutlined,
    SyncOutlined,
    ToolOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons';
import { UserFlag, UserRole } from 'constants/user';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type Feature =
    | 'claim'
    | 'compliance'
    | 'risk'
    | 'not-implemented'
    | '_test'
    | 'billing'
    | 'tools';

export interface Route {
    title?: string;
    homeTitle?: string;
    path: string;
    icon?: FC;
    roles?: readonly UserRole[];
    features?: readonly Feature[];
    userFlags?: readonly UserFlag[];
    featureFlag?: keyof ReturnType<typeof useFlags>;
    searchParams?: Record<string, string>;
    restrictToEmployee?: boolean;
    superAdminOnly?: boolean;
    tag?: {
        text?: string;
        featureFlag: keyof ReturnType<typeof useFlags>;
        color?: string;
    };
}

function asNavRoutes<T extends { [key: string]: Route | { [key: string]: Route } }>(arg: T): T {
    return arg;
}

function defineParams<T extends Route['searchParams']>() {
    return {} as T;
}

export function isRoute(value: any): value is Route {
    return value && typeof value.path === 'string';
}

export const navRoutes = asNavRoutes({
    home: {
        title: 'Home',
        path: '/console/$orgId',
        icon: HomeOutlined,
        roles: ['user', 'org_admin'],
        restrictToEmployee: false,
    },
    dashboard: {
        title: 'Dashboards',
        path: '/console/$orgId/dashboard',
        icon: DashboardOutlined,
        roles: ['user', 'org_admin'],
        features: ['claim', 'compliance'],
        tag: {
            featureFlag: 'dashboardsBetaTag',
        },
    },
    dashboard_section: {
        title: 'Dashboard',
        path: '/console/$orgId/dashboard/$section',
        icon: DashboardOutlined,
        roles: ['user', 'org_admin'],
        features: ['claim', 'compliance'],
    },

    //=== Tasks
    tasks: {
        title: 'Tasks',
        path: '/console/$orgId/tasks',
        roles: ['user', 'org_admin'],
    },
    tasks_search: {
        title: 'Task list',
        path: '/console/$orgId/tasks',
        icon: CheckOutlined,
        roles: ['user', 'org_admin'],
        tag: {
            featureFlag: 'tasksBetaTag',
        },
    },

    //=== User settings
    // userSettings: { title: 'User settings', path: '/console/$orgId/user-settings' },
    userSettings_profile: { title: 'User profile', path: '/console/$orgId/user-settings/profile' },
    userSettings_notifications: {
        title: 'Notifications',
        path: '/console/$orgId/user-settings/notifications',
    },

    //=== Claims
    claims: {
        title: 'Claims',
        path: '/console/$orgId/claims',
        icon: ExclamationOutlined,
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_lodge: {
        title: 'Lodge claim',
        path: '/console/$orgId/claims/lodge',
        icon: FormOutlined,
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_search: {
        title: 'View claims',
        path: '/console/$orgId/claims/search',
        icon: SearchOutlined,
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_edit: {
        title: 'Edit',
        path: '/console/$orgId/claims/edit/$id',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_view: {
        title: 'View',
        path: '/console/$orgId/claims/view/$id',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_manageClaim: {
        title: 'Manage claim',
        path: '/console/$orgId/claims/details/$id/manage',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_manageClaim_flag_add: {
        title: 'Add flag',
        path: '/console/$orgId/claims/details/$id/manage/flag/add',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_manageClaim_note_add: {
        title: 'Note',
        path: '/console/$orgId/claims/details/$id/manage/note/add',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_manageClaim_strategy: {
        title: 'Note',
        path: '/console/$orgId/claims/details/$id/manage-claim/strategies/add',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_comms: {
        title: 'Comms',
        path: '/console/$orgId/claims/details/$id/comms',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_expenses: {
        title: 'Expenses',
        path: '/console/$orgId/claims/details/$id/expenses',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_productTypeData: {
        title: 'Product type data',
        path: '/console/$orgId/claims/details/$id/product-type-data',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_expenses_add: {
        title: 'Expense manage',
        path: '/console/$orgId/claims/details/$id/expenses/add',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_expenses_edit: {
        title: 'Expense manage',
        path: '/console/$orgId/claims/details/$id/expenses/$expenseId/edit',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_comms_newMessage: {
        title: 'New message',
        path: '/console/$orgId/claims/details/$id/comms/add',
        roles: ['user', 'org_admin'],
        features: ['claim'],
        searchParams: defineParams<{
            email: string;
            phone: string;
            taskToCompleteId: string;
        }>(),
    },
    claims_details_comms_message: {
        title: 'New message',
        path: '/console/$orgId/claims/details/$id/comms/message/$messageId',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_financials: {
        title: 'Financials',
        path: '/console/$orgId/claims/details/$id/financials',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_workflow: {
        title: 'Workflow',
        path: '/console/$orgId/claims/details/$id/workflow',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_financials_reserve: {
        title: 'Financials',
        path: '/console/$orgId/claims/details/$id/financials/reserve/$action/$reserveId?',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_financials_payment: {
        title: 'Financials',
        path: '/console/$orgId/claims/details/$id/financials/payment/$reserveId/$action/$paymentId?',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_library: {
        title: 'Library',
        path: '/console/$orgId/claims/details/$id/library',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_policy_info: {
        title: 'Policy info',
        path: '/console/$orgId/claims/details/$id/policy-info',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_log: {
        title: 'Log',
        path: '/console/$orgId/claims/details/$id/log',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_summary: {
        title: 'Claim summary',
        path: '/console/$orgId/claims/details/$id/summary',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },
    claims_details_history: {
        title: 'navigate',
        path: '/console/$orgId/claims/details/$id/history',
        roles: ['user', 'org_admin'],
        features: ['claim'],
    },

    //== Reporting
    reporting: {
        title: 'Reporting',
        path: '/console/$orgId/reporting',
        icon: BarChartOutlined,
        roles: ['user', 'org_admin'],
        features: ['claim', 'compliance'],
        restrictToEmployee: false,
    },

    //== Not implemented
    // suppliers: {
    //     title: 'Suppliers',
    //     path: '/console/$orgId/suppliers',
    //     icon: ToolOutlined,
    //     roles: ['user', 'org_admin'],
    //     features: ['claim'],
    // },
    // payments: {
    //     title: 'Payments',
    //     path: '/console/$orgId/payments',
    //     icon: CreditCardOutlined,
    //     roles: ['user', 'org_admin'],
    //     features: ['not-implemented'],
    // },
    // fraudRegister: {
    //     title: 'Fraud register',
    //     path: '/console/$orgId/compliance-register',
    //     icon: HddOutlined,
    //     roles: ['user', 'org_admin'],
    //     features: ['not-implemented'],
    // },

    //=== Complaints
    compliance: {
        title: 'Compliance',
        path: '/console/$orgId/compliance',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
        icon: SafetyOutlined,
        restrictToEmployee: false,
    },
    compliance_complaints: {
        title: 'Complaints',
        path: '/console/$orgId/compliance/complaints',
        icon: FrownOutlined,
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    compliance_complaints_search: {
        title: 'Complaints',
        path: '/console/$orgId/compliance/complaints/search',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
        restrictToEmployee: false,
    },
    compliance_complaints_add: {
        title: 'New complaint',
        homeTitle: 'New complaint',
        path: '/console/$orgId/compliance/complaints/add',
        searchParams: defineParams<{
            emailClassificationId?: string;
            claimId?: string;
        }>(),
        icon: PlusOutlined,
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    compliance_complaints_edit: {
        title: 'Edit complaint',
        path: '/console/$orgId/compliance/complaints/edit/$complaintId',
        icon: FrownOutlined,
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    compliance_complaints_details_manage: {
        title: 'Complaint',
        path: '/console/$orgId/compliance/complaints/details/$id/manage',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    compliance_complaints_details_library: {
        title: 'Complaint',
        path: '/console/$orgId/compliance/complaints/details/$id/library',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    compliance_complaints_details_history: {
        title: 'Complaint',
        path: '/console/$orgId/compliance/complaints/details/$id/history',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    compliance_complaints_details_flag_add: {
        title: 'Complaint',
        path: '/console/$orgId/compliance/complaints/details/$id/manage/flag/add',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    // compliance_complaints_details_task_add: {
    //     title: 'Task',
    //     path: '/console/$orgId/compliance/complaints/details/$id/manage/task/add',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },
    // compliance_complaints_details_task_edit: {
    //     title: 'Task',
    //     path: '/console/$orgId/compliance/complaints/details/$id/manage/task/edit/$taskId',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },
    compliance_complaints_details_note: {
        title: 'Complaint',
        path: '/console/$orgId/compliance/complaints/details/$id/manage/note/add',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    // complaints_dashboard: {
    //     title: 'Complaints dashboard',
    //     path: '/console/$orgId/complaints/dashboard',
    //     icon: FrownOutlined,
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },

    //=== Compliance - Incidents
    // compliance_incidents: {
    //     title: 'Incidents',
    //     path: '/console/$orgId/compliance/incidents',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },
    compliance_incidents_add: {
        title: 'New incident',
        homeTitle: 'New incident',
        path: '/console/$orgId/compliance/incidents/add',
        searchParams: defineParams<{
            emailClassificationId: string;
        }>(),
        icon: PlusOutlined,
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    // compliance_incidents_edit: {
    //     title: 'Edit incident',
    //     path: '/console/$orgId/compliance/incidents/edit/$id',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },
    compliance_incidents: {
        title: 'Incidents',
        path: '/console/$orgId/compliance/incidents',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    compliance_incidents_details: {
        title: 'Incident',
        path: '/console/$orgId/compliance/incidents/details/$id/$section',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    compliance_incidents_details_manage: {
        title: 'Manage',
        path: '/console/$orgId/compliance/incidents/details/$id/manage',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    // compliance_incidents_details_library: {
    //     title: 'Library',
    //     path: '/console/$orgId/compliance/incidents/details/$id/library',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },
    // compliance_incidents_details_history: {
    //     title: 'navigate',
    //     path: '/console/$orgId/compliance/incidents/details/$id/history',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },

    //=== Compliance - Obligations
    // compliance_obligations: {
    //     title: 'Obligations',
    //     path: '/console/$orgId/compliance/obligations',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },
    compliance_obligations_add: {
        title: 'New obligation',
        path: '/console/$orgId/compliance/obligations/add',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    // compliance_obligations_edit: {
    //     title: 'Edit obligation',
    //     path: '/console/$orgId/compliance/obligations/edit/$id',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },
    compliance_obligations_search: {
        title: 'Obligations',
        path: '/console/$orgId/compliance/obligations/search',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    compliance_obligations_details: {
        title: 'Obligation',
        path: '/console/$orgId/compliance/obligations/details/$id/$section',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    // compliance_obligations_details_manage: {
    //     title: 'Manage',
    //     path: '/console/$orgId/compliance/obligations/details/$id/manage',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },
    // compliance_obligations_details_library: {
    //     title: 'Library',
    //     path: '/console/$orgId/compliance/obligations/details/$id/library',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },
    // compliance_obligations_details_history: {
    //     title: 'navigate',
    //     path: '/console/$orgId/compliance/obligations/details/$id/history',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },

    //=== Compliance - Conflict of interest
    // compliance_coi: {
    //     title: 'Conflict of interest',
    //     path: '/console/$orgId/compliance/coi',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },
    compliance_coi_add: {
        title: 'New item',
        homeTitle: 'New conflict of interest',
        path: '/console/$orgId/compliance/coi/add',
        icon: PlusOutlined,
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    // compliance_coi_edit: {
    //     title: 'Edit item',
    //     path: '/console/$orgId/compliance/coi/edit/$id',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },
    compliance_coi_search: {
        title: 'Conflict of interest',
        path: '/console/$orgId/compliance/coi/search',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    compliance_coi_details: {
        title: 'COI',
        path: '/console/$orgId/compliance/coi/details/$id/$section',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    // compliance_coi_details_manage: {
    //     title: 'Manage',
    //     path: '/console/$orgId/compliance/coi/details/$id/manage',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },
    // compliance_coi_details_library: {
    //     title: 'Manage',
    //     path: '/console/$orgId/compliance/coi/details/$id/library',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },

    //=== Compliance - Staff training
    // compliance_training: {
    //     title: 'Training record',
    //     path: '/console/$orgId/compliance/training',
    //     roles: ['user', 'org_admin'],
    //     features: ['compliance'],
    // },
    compliance_training_search: {
        title: 'Staff training record',
        path: '/console/$orgId/compliance/training/search',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    compliance_training_add: {
        title: 'New Training Record',
        homeTitle: 'New training record',
        path: '/console/$orgId/compliance/training/search/add-training',
        icon: PlusOutlined,
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },
    compliance_training_view_user: {
        title: 'View Training Record',
        path: '/console/$orgId/compliance/training/user/$userId',
        roles: ['user', 'org_admin'],
        features: ['compliance'],
    },

    //=== Tools
    tools: {
        title: 'Tools',
        path: '/console/$orgId/tools',
        roles: ['user', 'org_admin'],
        icon: ToolOutlined,
        features: ['tools'],
        featureFlags: 'complianceInbox',
    },
    tools_aiInbox: {
        title: 'Compliance AI Inbox',
        path: '/console/$orgId/tools/ai-inbox',
        icon: MessageOutlined,
        roles: ['user', 'org_admin'],
        features: ['tools'],
        featureFlags: 'complianceInbox',
    },
    tools_websiteChecker: {
        title: 'Website Checker',
        path: '/console/$orgId/tools/website-checker',
        icon: GlobalOutlined,
        roles: ['user', 'org_admin'],
        features: ['tools'],
        featureFlags: 'complianceWebsiteChecker',
    },

    //=== Risk
    risk: {
        title: 'Risk',
        path: '/console/$orgId/risk',
        roles: ['user', 'org_admin'],
        icon: ExclamationCircleOutlined,
        features: ['risk'],
    },
    risk_ras: {
        title: 'Risk Appetite Statement',
        path: '/console/$orgId/risk/ras',
        roles: ['user', 'org_admin'],
        features: ['risk'],
    },
    risk_ras_search: {
        title: 'Risk Appetite Statement',
        path: '/console/$orgId/risk/ras/search',
        roles: ['user', 'org_admin'],
        features: ['risk'],
    },
    risk_ras_details: {
        title: 'Risk Appetite Statement Details',
        path: '/console/$orgId/risk/ras/details/$id',
        roles: ['user', 'org_admin'],
        features: ['risk'],
    },
    // risk_register: {
    //     title: 'Risk Register',
    //     path: '/console/$orgId/risk/register',
    //     roles: ['user', 'org_admin'],
    //     features: ['risk'],
    // },
    risk_register_search: {
        title: 'Risk Register',
        path: '/console/$orgId/risk/register/search',
        roles: ['user', 'org_admin'],
        features: ['risk'],
        searchParams: defineParams<{
            assessmentRowIndex: string;
            assessmentColIndex: string;
            assessmentType: 'inherent' | 'residual';
        }>(),
    },
    risk_register_details: {
        title: 'Risk Details',
        path: '/console/$orgId/risk/register/details/$id/$section',
        roles: ['user', 'org_admin'],
        features: ['risk'],
    },
    // risk_register_details_assessment: {
    //     title: 'Risk assessment',
    //     path: '/console/$orgId/risk/register/details/$id/assessment',
    //     roles: ['user', 'org_admin'],
    //     features: ['risk'],
    // },
    // risk_register_details_actionPlan: {
    //     title: 'Risk action plan',
    //     path: '/console/$orgId/risk/register/details/$id/action-plan',
    //     roles: ['user', 'org_admin'],
    //     features: ['risk'],
    // },
    // risk_register_details_history: {
    //     title: 'History',
    //     path: '/console/$orgId/risk/register/details/$id/history',
    //     roles: ['user', 'org_admin'],
    //     features: ['risk'],
    // },

    //=== Controls
    controls: {
        title: 'Controls',
        path: '/console/$orgId/controls',
        roles: ['user', 'org_admin'],
        icon: ControlOutlined,
        features: ['risk', 'compliance'],
    },
    // controls_register: {
    //     title: 'Controls Register',
    //     path: '/console/$orgId/controls/register',
    //     roles: ['user', 'org_admin'],
    //     features: ['risk', 'compliance'],
    // },
    controls_register_search: {
        title: 'Controls Register',
        path: '/console/$orgId/controls/register/search',
        roles: ['user', 'org_admin'],
        features: ['risk', 'compliance'],
    },
    controls_register_add: {
        title: 'New Control',
        path: '/console/$orgId/controls/register/add',
        roles: ['user', 'org_admin'],
        features: ['risk', 'compliance'],
    },
    controls_register_edit: {
        title: 'Control Details',
        path: '/console/$orgId/controls/register/edit/$id',
        roles: ['user', 'org_admin'],
        features: ['risk', 'compliance'],
    },
    // controls_tests: {
    //     title: 'Control Tests',
    //     path: '/console/$orgId/controls/tests',
    //     roles: ['user', 'org_admin'],
    //     features: ['risk', 'compliance'],
    // },
    controls_tests_search: {
        title: 'Control Tests',
        path: '/console/$orgId/controls/tests/search',
        roles: ['user', 'org_admin'],
        features: ['risk', 'compliance'],
    },

    //=== FAQ
    faq: {
        title: 'FAQ',
        path: '/console/$orgId/faq',
        icon: QuestionCircleOutlined,
        roles: ['user', 'org_admin'],
    },

    //=== Configuration
    configuration: {
        title: 'Configuration',
        path: '/console/$orgId/configuration',
        icon: SettingOutlined,
        roles: ['org_admin'],
    },
    configuration_users: {
        title: 'Team management',
        path: '/console/$orgId/configuration/users',
        icon: UsergroupAddOutlined,
        roles: ['org_admin'],
    },
    configuration_assistance: {
        title: 'Assistance',
        path: '/console/$orgId/configuration/assistance',
        roles: ['org_admin'],
    },
    configuration_suppliers: {
        title: 'Suppliers',
        path: '/console/$orgId/configuration/suppliers',
        roles: ['org_admin'],
        features: ['claim'],
    },
    configuration_suppliers_add: {
        title: 'Add new Supplier',
        path: '/console/$orgId/configuration/suppliers/add',
        roles: ['org_admin'],
    },
    configuration_suppliers_edit: {
        title: 'Edit Supplier',
        path: '/console/$orgId/configuration/suppliers/edit/$supplierId',
        roles: ['org_admin'],
    },
    configuration_suppliers_details: {
        title: 'Supplier Details',
        path: '/console/$orgId/configuration/suppliers/details/$supplierId',
        roles: ['org_admin'],
    },
    configuration_claim: {
        title: 'Claim',
        path: '/console/$orgId/configuration/claim',
        roles: ['org_admin'],
        features: ['claim'],
    },
    configuration_task_manager: {
        title: 'Task Manager',
        path: '/console/$orgId/configuration/task-manager',
        roles: ['org_admin'],
        features: ['claim'],
    },
    configuration_documents: {
        title: 'Documents',
        path: '/console/$orgId/configuration/documents',
        roles: ['org_admin'],
    },
    configuration_tasks: {
        title: 'Tasks',
        path: '/console/$orgId/configuration/tasks',
        roles: ['org_admin'],
        features: ['claim'],
    },
    configuration_payments: {
        title: 'Payments',
        path: '/console/$orgId/configuration/payments',
        roles: ['org_admin'],
        features: ['claim'],
        userFlags: ['payment_admin'],
    },
    configuration_productTypes: {
        title: 'Product types & PDS',
        path: '/console/$orgId/configuration/product-types',
        roles: ['org_admin'],
        features: ['claim'],
    },
    configuration_productTypes_pds: {
        title: 'PDS configuration',
        path: '/console/$orgId/configuration/product-types/$productTypeId',
        roles: ['org_admin'],
        features: ['claim'],
    },
    configuration_productTypes_pds_pdsVersion: {
        title: 'PDS version configuration',
        path: '/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId',
        roles: ['org_admin'],
    },
    configuration_insurers: {
        title: 'Insurers',
        path: '/console/$orgId/configuration/insurers',
        roles: ['org_admin'],
        features: ['claim'],
    },
    // configuration_pds: {
    //     title: 'PDS',
    //     path: '/console/$orgId/configuration/pds',
    //     roles: ['org_admin'],
    //     features: ['claim'],
    // },
    configuration_expenses_and_billing: {
        title: 'Expenses & Billing',
        path: '/console/$orgId/configuration/expenses-and-billing',
        roles: ['org_admin'],
        features: ['billing'],
    },
    configuration_complaints: {
        title: 'Complaints',
        path: '/console/$orgId/configuration/complaints',
        roles: ['org_admin'],
        features: ['compliance'],
    },
    configuration_compliance: {
        title: 'Compliance',
        path: '/console/$orgId/configuration/compliance',
        roles: ['org_admin'],
        features: ['compliance'],
    },
    configuration_system: {
        title: 'System',
        path: '/console/$orgId/configuration/system',
        roles: ['org_admin'],
    },
    configuration_handling_parties: {
        title: 'Handling Parties',
        path: '/console/$orgId/configuration/handling-parties',
        roles: ['org_admin'],
    },
    configuration_comms_templates: {
        title: 'Comms templates',
        path: '/console/$orgId/configuration/comms-templates',
        roles: ['org_admin'],
        features: ['claim'],
    },
    configuration_questionnaire: {
        title: 'Questionnaire',
        path: '/console/$orgId/configuration/questionnaire',
        roles: ['org_admin'],
        features: ['claim'],
    },
    configuration_risk: {
        title: 'Risk',
        path: '/console/$orgId/configuration/risk/$section',
        roles: ['org_admin'],
        features: ['risk'],
    },
    configuration_risk_appetite: {
        title: 'Risk',
        path: '/console/$orgId/configuration/risk/appetite',
        roles: ['org_admin'],
        features: ['risk'],
    },
    // configuration_risk_categories: {
    //     title: 'Risk Appetite',
    //     path: '/console/$orgId/configuration/risk/categories',
    //     roles: ['org_admin'],
    //     features: ['risk'],
    // },
    // configuration_risk_matrix: {
    //     title: 'Risk Appetite',
    //     path: '/console/$orgId/configuration/risk/matrix',
    //     roles: ['org_admin'],
    //     features: ['risk'],
    //     searchParams: defineParams<{
    //         newCategoryId?: string;
    //     }>(),
    // },
    configuration_stats: {
        title: 'Statistics',
        path: '/console/$orgId/configuration/statistics',
        roles: ['org_admin'],
    },

    // ==========================
    // = Test
    //===========================
    test: {
        title: 'Test',
        path: '/console/$orgId/test',
        features: ['_test'],
    },

    // ==========================
    // = PUBLIC
    //===========================
    public_root: { title: 'Curium', path: '/' },
    public_lodgementSummary: {
        title: 'Lodgement Summary',
        path: '/public/lodgement-summary/$accessKey',
    },
    public_orgSection: {
        title: 'Organization',
        path: '/public/$orgId/*',
    },
    public_orgSection_claim_lodgement: {
        path: '/public/$orgId/claim/lodgement/$pdsVersionId/$questionnaireId/$productTypeId',
        searchParams: defineParams<{
            draftId: string;
            accessKey: string;
            data: string;
        }>(),
    },
    // public_orgSection_claim_viewInvoice: {
    //     path: '/public/$orgId/claim/view-invoice/$accessKey',
    // },
    public_orgSection_complaint_new: {
        path: '/public/$orgId/complaint/new',
    },
    public_newComplaint: {
        title: 'New Complaint',
        path: '/public/new-complaint/$orgId',
    },
    public_newComplaintByKey: {
        title: 'New Complaint',
        path: '/public/new-complaint-by-key/$key',
    },
    // TODO: Remove this route  once implemented the orgSection_claim_lodgement
    public_claimLodgement: {
        path: '/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId',
        searchParams: defineParams<{
            draftId: string;
            accessKey: string;
            data: string;
        }>(),
    },
    public_authCallback: { title: 'Auth callback', path: '/callback' },
    public_login: {
        title: 'Login',
        path: '/login',
        searchParams: defineParams<{ redirectTo: string }>(),
    },
    public_logOutIn: { title: 'Logout', path: '/logoutin' },
    public_adminLogin: { title: 'Login', path: '/admin-login' },
    public_adminAuthCallback: { title: 'Admin Auth callback', path: '/admin-callback' },
    public_logout: { title: 'Logout', path: '/logout' },

    // ==========================
    // = SPECIAL
    //===========================
    getFile: {
        title: 'File download',
        path: '/console/$orgId/get-file',
        searchParams: defineParams<{
            key: string;
            name: string;
        }>(),
    },

    // ==========================
    // = Admin Portal
    //===========================
    adminPortal: {
        // root: { title: 'Admin Dashboard', path: '/admin-console', superAdminOnly: true },
        home: {
            title: 'Home',
            path: '/admin-console',
            icon: HomeOutlined,
            superAdminOnly: true,
        },
        newOrganization: {
            title: 'Onboard new organization',
            path: '/admin-console/new-organization',
            icon: PlusCircleOutlined,
            superAdminOnly: true,
        },
        organizations: {
            title: 'Organizations',
            path: '/admin-console/organizations',
            icon: DatabaseOutlined,
            superAdminOnly: true,
        },
        organizations_users: {
            title: 'Team management',
            path: '/admin-console/organizations/$orgId/users',
            superAdminOnly: true,
        },
        users: {
            title: 'Users',
            path: '/admin-console/users',
            icon: UsergroupAddOutlined,
            superAdminOnly: true,
        },
        obligations: {
            title: 'Obligations',
            path: '/admin-console/obligations',
            icon: SolutionOutlined,
            superAdminOnly: true,
        },
        obligations_library: {
            title: 'Obligations Library',
            path: '/admin-console/obligations/library',
            superAdminOnly: true,
        },
        obligations_library_add: {
            title: 'New Obligation',
            path: '/admin-console/obligations/library/add',
            superAdminOnly: true,
        },
        obligations_library_details: {
            title: 'Obligation Details',
            path: '/admin-console/obligations/library/$id/$section',
            superAdminOnly: true,
        },
        controls: {
            title: 'Controls',
            path: '/admin-console/controls',
            icon: ControlOutlined,
            superAdminOnly: true,
        },
        controls_library: {
            title: 'Controls Library',
            path: '/admin-console/controls/library',
            superAdminOnly: true,
        },
        // controls_library_add: {
        //     title: 'New Control',
        //     path: '/admin-console/controls/library/add',
        //     superAdminOnly: true,
        // },
        controls_library_details: {
            title: 'Control Details',
            path: '/admin-console/controls/library/$id/$section',
            superAdminOnly: true,
        },
        configuration: {
            title: 'Configurations',
            path: '/admin-console/configuration',
            icon: SettingOutlined,
            superAdminOnly: true,
        },
        configuration_obligations: {
            title: 'Obligations',
            path: '/admin-console/configuration/obligations',
            superAdminOnly: true,
        },
        advanced: {
            title: 'Advanced',
            path: '/admin-console/advanced',
            icon: ToolOutlined,
            superAdminOnly: true,
        },
        appSettings: {
            title: 'App settings',
            path: '/admin-console/advanced/app-settings',
            icon: ToolOutlined,
            superAdminOnly: true,
        },
        faq: {
            title: 'FAQ',
            path: '/admin-console/faq',
            icon: QuestionCircleOutlined,
            superAdminOnly: true,
        },
        commTemplates: {
            title: 'Comms templates',
            path: '/admin-console/comm-templates',
            icon: MessageOutlined,
            superAdminOnly: true,
        },
        reports: {
            title: 'Reports',
            path: '/admin-console/reports',
            superAdminOnly: true,
            icon: BarChartOutlined,
        },
        dbUpgrades: {
            title: 'DB Upgrades',
            path: '/admin-console/advanced/db-upgrades',
            superAdminOnly: true,
            icon: SyncOutlined,
        },
        aiPrompts: {
            title: 'AI Prompts',
            path: '/admin-console/ai-prompts',
            superAdminOnly: true,
            icon: RobotOutlined,
        },
        jobs: {
            title: 'Jobs',
            path: '/admin-console/advanced/jobs',
            superAdminOnly: true,
            icon: ClockCircleOutlined,
        },
        jobs_recurring: {
            title: 'Recurring Jobs',
            path: '/admin-console/advanced/jobs/recurring',
            superAdminOnly: true,
        },
        jobs_onDemand: {
            title: 'On Demand Jobs',
            path: '/admin-console/advanced/jobs/on-demand',
            superAdminOnly: true,
        },
        emailDomains: {
            title: 'Email Domains',
            path: '/admin-console/advanced/email-domains',
            superAdminOnly: true,
            icon: MailOutlined,
        },
    },

    //DIVIDER_ORG_ADMIN_ONLY: new NavNode({ title: 'DIVIDER', type: 'divider', roles: ['org_admin'] }),

    // ==========================
    // = TEST
    //===========================
    // twilioTest: { title: 'Twilio Test', path: '/console/$orgId/twilio' },
    // emailTest: { title: 'E-mail Test', path: '/console/$orgId/email-test' },
    // flowchartTest: { title: 'Flowchart Test', path: '/console/$orgId/flowchart-test' },
    // flowchartTestAnt: { title: 'Flowchart Test Ant', path: '/console/$orgId/flowchart-test-ant' },
    testPage: { title: 'Test Page', path: '/console/$orgId/test' },
} as const);

export const CLAIM_DETAILS_SECTIONS = {
    MANAGE: 'manage',
    CONTACTS: 'contacts',
    EXPENSES: 'expenses',
    FINANCIALS: 'financials',
    COMMS: 'comms',
    LIBRARY: 'library',
    HISTORY: 'history',
    TASKS: 'tasks',
    POLICY_INFO: 'policy-info',
    PRODUCT_TYPE_DATA: 'product-type-data',
} as const;

export const COMPLAINT_DETAILS_SECTIONS = {
    MANAGE: 'manage',
    LIBRARY: 'library',
    HISTORY: 'history',
} as const;

export const INCIDENT_DETAILS_SECTIONS = {
    MANAGE: 'manage',
    LIBRARY: 'library',
    HISTORY: 'history',
} as const;

export type IncidentDetailsSection =
    (typeof INCIDENT_DETAILS_SECTIONS)[keyof typeof INCIDENT_DETAILS_SECTIONS];

export const OBLIGATION_DETAILS_SECTIONS = {
    MANAGE: 'manage',
} as const;

export type ObligationDetailsSection =
    (typeof OBLIGATION_DETAILS_SECTIONS)[keyof typeof OBLIGATION_DETAILS_SECTIONS];

export const COI_DETAILS_SECTIONS = {
    MANAGE: 'manage',
    LIBRARY: 'library',
} as const;

export type CoiDetailsSection = (typeof COI_DETAILS_SECTIONS)[keyof typeof COI_DETAILS_SECTIONS];

export const DASHBOARD_SECTIONS = {
    MANAGEMENT: 'management',
    CLAIMS: 'claims',
    COMPLAINTS: 'complaints',
    INCIDENTS: 'incidents',
    PEOPLE_AND_CULTURE: 'people-and-culture',
    RISKS: 'risks',
} as const;

export type DashboardSection = (typeof DASHBOARD_SECTIONS)[keyof typeof DASHBOARD_SECTIONS];

export const RISK_REGISTER_DETAILS_SECTIONS = {
    ASSESSMENT: 'assessment',
    ACTION_PLAN: 'action-plan',
    HISTORY: 'history',
} as const;

export type RiskRegisterDetailsSection =
    (typeof RISK_REGISTER_DETAILS_SECTIONS)[keyof typeof RISK_REGISTER_DETAILS_SECTIONS];

export const RISK_CONFIGURATION_SECTIONS = {
    APPETITE: 'appetite',
    CATEGORIES: 'categories',
    MATRIX: 'matrix',
} as const;

export type RiskConfigurationSection =
    (typeof RISK_CONFIGURATION_SECTIONS)[keyof typeof RISK_CONFIGURATION_SECTIONS];

export const CONTROL_LIBRARY_SECTIONS = {
    DETAILS: 'details',
    HISTORY: 'history',
} as const;

export type ControlLibrarySection =
    (typeof CONTROL_LIBRARY_SECTIONS)[keyof typeof CONTROL_LIBRARY_SECTIONS];

export const OBLIGATION_LIBRARY_SECTIONS = {
    DETAILS: 'details',
    HISTORY: 'history',
} as const;

export type ObligationLibrarySection =
    (typeof CONTROL_LIBRARY_SECTIONS)[keyof typeof CONTROL_LIBRARY_SECTIONS];
