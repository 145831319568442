import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { navRoutes } from 'shared/navigation/navRoutes';
import { Form, Input, InputNumber, Space, Tabs } from 'antd';
import { ConfigurationItemEditor } from '@/components/Configuration/ConfigurationItemEditor';
import { PageHeader } from '@/components/Common/PageHeader';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { ClaimIdConfig } from './ClaimIdConfig';
import { PolicyImport } from './PolicyImport';
import { ConfigurationItemArrayEditor } from '@/components/Configuration/ConfigurationItemArrayEditor';
import { ConfigurationItemListEditor } from '@/components/Configuration/ConfigurationItemListEditor';
import { Rule } from 'antd/es/form';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/claim')({
    staticData: {
        title: 'Claim',
        modules: ['claim'],
    },

    component: ConfigurationClaim,
});

function ConfigurationClaim() {
    const orgId = useOrgId();

    const tabItems = [
        {
            label: 'General',
            key: 'general',
            children: (
                <Space direction="vertical" size={[16, 16]} className="w-full">
                    <ConfigurationItemArrayEditor
                        title="Claim decisions"
                        settingToEdit={'CLAIM_DECISIONS'}
                        description="Rename, add or remove a claim decision."
                        itemValidator={[
                            {
                                validator: async (rule: Rule, value: any) => {
                                    if (!value) {
                                        throw new Error('Value is required');
                                    }
                                },
                            },
                        ]}
                        className="w-[500px]"
                    />
                    <ConfigurationItemListEditor
                        title="Claim Timeline Items"
                        settingToEdit={'CLAIM_TIMELINE_ITEMS'}
                        description="Rename, add or remove a claim timeline item."
                    >
                        <Form.Item
                            name="internalName"
                            label="Internal name"
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="label" label="Label" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="labelWhenCompleted"
                            label="Label when completed"
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="order" label="Order" rules={[{ required: true }]}>
                            <InputNumber />
                        </Form.Item>
                        <Form.Item name="prepopulate" label="Prepopulate" valuePropName="checked">
                            <Input type="checkbox" />
                        </Form.Item>
                        <Form.Item
                            name="createAsCompleted"
                            label="Create as completed"
                            valuePropName="checked"
                        >
                            <Input type="checkbox" />
                        </Form.Item>
                        <Form.Item name="hideFromUi" label="Hide from UI" valuePropName="checked">
                            <Input type="checkbox" />
                        </Form.Item>
                    </ConfigurationItemListEditor>
                    <ConfigurationItemEditor
                        title="Triage outcome"
                        settingToEdit={'CLAIM_TRIAGE_OUTCOME'}
                        description="Rename, add or remove a triage outcome item."
                        editor={{
                            height: '300px',
                        }}
                    />
                    <ConfigurationItemEditor
                        title="Claim Flag Types"
                        settingToEdit={'CLAIM_FLAG_TYPES'}
                        description="Rename, add or remove a claim flag type."
                        editor={{
                            height: '300px',
                        }}
                    />
                </Space>
            ),
        },
        {
            label: 'Claim ID configuration',
            key: 'claim-id',
            children: <ClaimIdConfig />,
        },
        {
            label: 'Policy import',
            key: 'policy-import',
            children: <PolicyImport />,
        },
    ];

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.configuration),
                    toBreadCrumb(navRoutes.configuration_claim, { orgId }),
                ]}
            />

            <PageHeader
                title="Claim configuration"
                subTitle="Various claim settings can be configured here."
            />

            <Tabs type="line" defaultActiveKey="general" items={tabItems} />
        </>
    );
}
