import { Space, Typography } from 'antd';
import { ConfigurationItemEditor } from '@/components/Configuration/ConfigurationItemEditor';
import { PageHeader } from '@/components/Common/PageHeader';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/configuration/obligations')({
    staticData: {
        title: 'Obligations',
    },
    component: ObligationsConfiguration,
});

function ObligationsConfiguration() {
    return (
        <>
            <PageHeader title="Obligation Library configuration" />

            <Space direction="vertical" size={[16, 16]} className="w-full">
                <ConfigurationItemEditor
                    title="Legislation items"
                    settingToEdit="OBLIGATION_LEGISLATION_ITEMS"
                    description="Rename, add, or remove an obligations legislation item."
                    editor={{
                        height: '400px',
                    }}
                />
                <ConfigurationItemEditor
                    title="Labels"
                    settingToEdit="OBLIGATION_LIBRARY_LABELS"
                    description={
                        <Typography.Text
                            className="flex items-center text-gray-400"
                            copyable={{
                                icon: [<QuestionCircleOutlined key="copy-icon" />],
                                tooltips: ['Click to Copy Example JSON', 'Copied!'],
                                text:
                                    '[\n' +
                                    '    {\n' +
                                    '        "id": "a3f558e8-02d2-4ca1-b565-1e599bd25ada",\n' +
                                    '        "name": "example_name",\n' +
                                    '        "type": "SINGLE_SELECT",\n' +
                                    '        "label": "Example Label",\n' +
                                    '        "showInTable": true,\n' +
                                    '        "options": [\n' +
                                    '            {\n' +
                                    '                "label": "Option 1",\n' +
                                    '                "internalName": "option_1"\n' +
                                    '            },\n' +
                                    '            {\n' +
                                    '                "label": "Option 2",\n' +
                                    '                "internalName": "option_2"\n' +
                                    '            }\n' +
                                    '        ]\n' +
                                    '    },\n' +
                                    '    {\n' +
                                    '        "id": "47ecac68-7772-415c-bf24-2be11be2862a",\n' +
                                    '        "name": "another_name",\n' +
                                    '        "type": "MULTI_SELECT",\n' +
                                    '        "label": "Another Label",\n' +
                                    '        "showInTable": false,\n' +
                                    '        "options": [\n' +
                                    '            {\n' +
                                    '                "label": "Option A",\n' +
                                    '                "internalName": "option_A"\n' +
                                    '            },\n' +
                                    '            {\n' +
                                    '                "label": "Option B",\n' +
                                    '                "internalName": "option_B"\n' +
                                    '            }\n' +
                                    '        ]\n' +
                                    '    }\n' +
                                    ']',
                            }}
                        >
                            Rename, add, or remove a label for obligations
                        </Typography.Text>
                    }
                    editor={{
                        height: '400px',
                    }}
                />
            </Space>
        </>
    );
}
