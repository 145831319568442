import { ProductDataEditForm } from '@/components/Claim/ProductType/ProductDataEditForm';
import invariant from 'tiny-invariant';
import { claimFormStore } from '../store';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/product-type-data')({
    component: ProductTypeData,
});

function ProductTypeData() {
    const claim = claimFormStore.use.formData?.();
    invariant(claim, 'Claim is required.');
    const save = claimFormStore.use.save();

    return (
        <ProductDataEditForm
            productTypeData={claim.productTypeData}
            productTypeId={claim.productTypeId}
            onSave={(productTypeData) => save({ productTypeData })}
        />
    );
}
