import { forwardRef, useState } from 'react';
import { Dayjs } from 'dayjs';
import { DatePicker as AntdDatePicker, Modal, theme } from 'antd';
import { isoToDayJs } from '@/utils/dateTime';
import { DateTime } from 'luxon';
import type { DatePickerProps as AntDatePickerProps } from 'antd/es/date-picker';
import { useMediaQuery } from '@/hooks/UI/useMediaQuery';
import { LocalizationProvider, StaticDatePicker, StaticDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider, createTheme } from '@mui/material';

export interface DatePickerOldProps extends Omit<AntDatePickerProps<Dayjs>, 'value' | 'onChange'> {
    value?: string | null | undefined;
    onChange?: (value: string | null | undefined) => void;
}

/**
 * @deprecated If you use trpc requests use VDatePicker instead. Use this hook only for graphql queries.
 * DatePicker use native JS Date object as value instead of ISO string for smooth interaction with trpc.
 */
export const DatePickerOld = forwardRef<any, DatePickerOldProps>(
    (props: DatePickerOldProps, ref) => {
        const { value, onChange, ...rest } = props;
        const _value = isoToDayJs(value);

        const isMobile = useMediaQuery('(max-width: 768px)');
        const [visible, setVisible] = useState(false);

        const { token } = theme.useToken();

        const onMobileDateConfirm = (value: Dayjs | null) => {
            const isoValue = value?.toISOString() ?? null;
            onChange?.(isoValue);
            setVisible(false);
        };

        const renderMobileDatePicker = () => (
            <>
                <AntdDatePicker
                    {...rest}
                    value={_value}
                    panelRender={() => null}
                    onClick={() => setVisible(true)}
                    placeholder="Select date"
                    ref={ref}
                    inputReadOnly
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Modal open={visible} centered footer={false} closable={false}>
                        <ThemeProvider
                            theme={createTheme({
                                palette: {
                                    primary: {
                                        main: token.colorPrimary,
                                    },
                                    error: {
                                        main: token.colorError,
                                    },
                                },
                                typography: {
                                    fontFamily: token.fontFamily,
                                    fontSize: token.fontSize,
                                },
                            })}
                        >
                            {props.showTime ? (
                                <StaticDateTimePicker
                                    onClose={() => {
                                        setVisible(false);
                                    }}
                                    value={_value}
                                    onAccept={onMobileDateConfirm}
                                    maxDate={props.maxDate}
                                />
                            ) : (
                                <StaticDatePicker
                                    onClose={() => {
                                        setVisible(false);
                                    }}
                                    value={_value}
                                    onAccept={onMobileDateConfirm}
                                    maxDate={props.maxDate}
                                />
                            )}
                        </ThemeProvider>
                    </Modal>
                </LocalizationProvider>
            </>
        );

        const renderDesktopDatePicker = () => (
            <AntdDatePicker
                {...rest}
                value={_value}
                onChange={(dtValue) => {
                    if (dtValue === null) {
                        onChange?.(null);
                    } else {
                        onChange?.(DateTime.fromJSDate(dtValue.toDate()).toISO());
                    }
                }}
                ref={ref}
            />
        );

        return isMobile ? renderMobileDatePicker() : renderDesktopDatePicker();
    },
);

DatePickerOld.displayName = 'DatePicker';
