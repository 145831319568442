import { createFileRoute } from '@tanstack/react-router';
import { ClaimForm } from '../ClaimLodgement';

export const Route = createFileRoute('/console/$orgId/claims/edit/$id')({
    staticData: {
        title: 'Edit claim',
    },
    component() {
        return <ClaimForm formAction="edit" />;
    },
});
