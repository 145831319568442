import invariant from 'tiny-invariant';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useNavigate } from '@/hooks/useNavigate';
import { useApolloClient } from '@apollo/client';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { claimFormStore } from '../../store';
import { ClaimExpenseEdit } from '../ClaimExpenseEdit';
import { ExpenseItemsByClaimId } from '../~route';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/expenses/add')({
    component: ExpenseAdd,
});

function ExpenseAdd() {
    const orgId = useOrgId();
    const claim = claimFormStore.use.formData?.();
    invariant(claim, 'Claim is required.');
    const isReadonly = claimFormStore.use.isReadonly();
    const apolloClient = useApolloClient();
    const navigate = useNavigate();

    const [open, setOpen] = useState(true);

    return (
        !isReadonly && (
            <ClaimExpenseEdit
                open={open}
                claimId={claim.id}
                claimInsurerId={claim.insurerId}
                expenseItemId={-1}
                onClose={() => {
                    setOpen(false);

                    void apolloClient.refetchQueries({
                        include: [ExpenseItemsByClaimId],
                    });

                    navigate({
                        to: '/console/$orgId/claims/details/$id/expenses',
                        params: {
                            orgId,
                            id: claim.id,
                        },
                        fromComponent: 'drawer',
                    });
                }}
                claimExternalOrgsIds={claim.ExternalOrgs.map((item) => +item.ExternalOrg?.id)}
            />
        )
    );
}
