import { OrgSettings } from '../types/org';
import { SYSTEM_ORG_ID } from './org';

export const DEFAULT_ORG_SETTINGS: OrgSettings = {
    orgId: SYSTEM_ORG_ID.NOT_APPLICABLE,
    name: '',
    defaultCountryCode: 'AU',
    allowedCountryCodes: ['AU'],
    allowAllCountryCodes: false,
    logoUrl: '',
} as const;
