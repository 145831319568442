import { createFileRoute, Outlet } from '@tanstack/react-router';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useOrgSettingsStore } from '@/stores/OrgSettingStore';
import * as Sentry from '@sentry/react';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { SYSTEM_ORG_ID } from 'shared/constants/org';

export const Route = createFileRoute('/public/$orgId')({
    component: PublicOrgSectionLayout,
});

function PublicOrgSectionLayout() {
    const orgSettingsStore = useOrgSettingsStore();
    const [isInitialized, setIsInitialized] = useState(false);
    const orgId = useOrgId();

    useEffect(() => {
        const asyncWrapper = async () => {
            if (orgId < SYSTEM_ORG_ID.FIRST_VALID_ORG_ID) {
                return;
            }

            try {
                await orgSettingsStore.init(BigInt(orgId));
                setIsInitialized(true);
            } catch (error) {
                Sentry.captureException(error, {
                    tags: {
                        location: 'PublicOrgSection',
                    },
                });
            }
        };

        void asyncWrapper();
    }, [orgId]);

    return isInitialized ? (
        <Outlet />
    ) : (
        <div className="flex h-full w-full items-center justify-center">
            <Spin spinning size="large" tip="Loading..." />
        </div>
    );
}
