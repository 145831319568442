import { useOrgId } from '@/hooks/Org/useOrgId';
import { useEffect, useState } from 'react';
import { errorMessage } from '@/components/Common/errorMessage';
import { useIsPublicSection } from '@/hooks/useIsPublicSection';
import * as Sentry from '@sentry/react';
import { useNavigate } from '@/hooks/useNavigate';

type Props = Parameters<Sentry.FallbackRender>[0];

export function ErrorFallback({ error, resetError }: Props) {
    const orgId = useOrgId();
    const isPublicSection = useIsPublicSection();
    const navigate = useNavigate();

    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

    useEffect(() => {
        // HMR will trigger this error fallback on every change, so disable it in development
        if (import.meta.env.VITE_ENV === 'development') {
            return;
        }

        const asyncWrapper = async () => {
            if (error && !isErrorModalVisible) {
                try {
                    setIsErrorModalVisible(true);

                    await errorMessage.showAsync(error, { okText: 'Try again' });

                    resetError();

                    if (isPublicSection) {
                        navigate({ to: '/' });
                    } else {
                        navigate({ to: '/console/$orgId', params: { orgId } });
                    }
                } finally {
                    setIsErrorModalVisible(false);
                }
            }
        };

        void asyncWrapper();
    }, [error]);

    return <></>;
}
