import { CollapsiblePanel } from '@/components/Common/CollapsiblePanel';
import { errorMessage } from '@/components/Common/errorMessage';
import { PageHeader } from '@/components/Common/PageHeader';
import { User } from '@/components/Common/User';
import { UserSelector } from '@/components/Common/UserSelector';
import { Indicator } from '@/components/Common/Indicator';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { getHighestPriorityStatus } from '@/utils/control';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { grey } from '@ant-design/colors';
import { DeleteOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row, Select, Space, Table, Tooltip } from 'antd';
import { RouterInputs, RouterOutputs, trpc } from '@/trpc';
import { useState } from 'react';
import {
    CONTROL_FREQUENCY,
    CONTROL_STATUSES,
    ControlFrequency,
    ControlStatus,
} from 'shared/constants/control';
import { navRoutes } from 'shared/navigation/navRoutes';
import { Link } from '@/components/Common/Link';
import { useNavigate } from '@/hooks/useNavigate';
import { useSafePath } from '@/hooks/useSafePath';
import { commonActionColumn } from '@/utils/table';
import { NewTabLink } from '@/components/Common/NewTabLink';
import { Falsy } from 'shared/utils/boolean';
import { isTruthy } from 'shared/utils/boolean';
import { ComponentPreview } from '@/components/Common/ComponentPreview';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/controls/register/search')({
    staticData: {
        title: 'Controls register',
    },
    component: ControlsRegisterSearch,
});

type Control = RouterOutputs['control']['listControls']['rows'][number];

type Filter = {
    ownerId: bigint;
    frequency: string;
};

function ControlsRegisterSearch() {
    const orgId = useOrgId();
    const navigate = useNavigate();

    const [showFilter, setShowFilter] = useState(false);

    const [variables, setVariables] = useState<RouterInputs['control']['listControls']>({
        orderBy: { id: 'desc' },
        limit: 10,
        offset: 0,
    });

    const { data, isPending } = trpc.control.listControls.useQuery(variables, {
        throwOnError: ({ message }) => {
            errorMessage.show(message);
            return false;
        },
    });

    const [form] = Form.useForm<Filter>();
    const safePath = useSafePath<Filter>();

    const { tableProps } = useAntdTable<Control>({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isPending,
            total: data?.total,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                width: 40,
                render: (_, { status, ControlTests }) => {
                    const { color, title } = getHighestPriorityStatus(status, ControlTests);

                    return (
                        <Indicator
                            tooltip={{
                                title,
                            }}
                            style={{
                                background: color,
                            }}
                        />
                    );
                },
                fixed: 'left',
            },
            {
                title: 'Control No.',
                dataIndex: 'id',
                render: String,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                className: 'max-w-xs align-top',
                render: (value) => <ComponentPreview>{value}</ComponentPreview>,
            },
            {
                title: 'Objective',
                dataIndex: 'objective',
                className: 'max-w-xs align-top',
                render: (value) => <ComponentPreview>{value}</ComponentPreview>,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (value) => CONTROL_STATUSES[value as ControlStatus] || value,
            },
            {
                title: 'Control Frequency',
                dataIndex: 'frequency',
                render: (value) => CONTROL_FREQUENCY[value as ControlFrequency] || value,
            },
            {
                title: 'Owner',
                dataIndex: 'ownerId',
                render: (value) => <User userId={value} />,
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Space>
                        <NewTabLink
                            to="/console/$orgId/controls/register/edit/$id"
                            params={{
                                orgId,
                                id: value,
                            }}
                        />
                        <Tooltip title="Delete">
                            <Button
                                type="text"
                                disabled
                                icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </Tooltip>
                    </Space>
                ),
            },
        ],
    });

    const onSearch = () => {
        const item = form.getFieldsValue();

        const whereClauses: (Falsy | typeof variables.where)[] = [
            item.ownerId && { ownerId: BigInt(item.ownerId) },
            item.frequency && { frequency: item.frequency },
        ];

        setVariables((prev) => ({
            ...prev,
            where: {
                AND: whereClauses.filter(isTruthy),
            },
        }));
    };

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.controls),
                    toBreadCrumbWithoutLink(navRoutes.controls_register_search),
                ]}
            />

            <PageHeader title="Control Register" />

            <CollapsiblePanel open={showFilter}>
                <Card title="Filters" size="small" className="mb-4">
                    <Form form={form} layout="vertical" onFinish={onSearch}>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label="Control Owner" name={safePath('ownerId')}>
                                    <UserSelector />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Frequency" name={safePath('frequency')}>
                                    <Select
                                        options={Object.keys(CONTROL_FREQUENCY).map((key) => ({
                                            label: CONTROL_FREQUENCY[key as ControlFrequency],
                                            value: key,
                                        }))}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Space className="flex justify-end">
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    onSearch();
                                }}
                            >
                                Reset
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Search
                            </Button>
                        </Space>
                    </Form>
                </Card>
            </CollapsiblePanel>

            <Space className="mb-4 flex justify-end">
                <Tooltip title="Filters">
                    <Button
                        type="text"
                        size="large"
                        title="Filter"
                        onClick={() => setShowFilter((prev) => !prev)}
                    >
                        <FilterOutlined />
                    </Button>
                </Tooltip>
                <Link
                    to="/console/$orgId/controls/register/add"
                    params={{ orgId }}
                    type="primary"
                    icon={<PlusOutlined />}
                >
                    Add control
                </Link>
            </Space>

            <Card>
                <Table
                    size="small"
                    scroll={{ x: 'max-content' }}
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: (e) =>
                            navigate(
                                {
                                    to: '/console/$orgId/controls/register/edit/$id',
                                    params: {
                                        orgId,
                                        id,
                                    },
                                },
                                e,
                            ),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
