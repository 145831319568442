import { trpc } from '@/trpc';
import { skipToken } from '@tanstack/react-query';
import { Button, Card, Descriptions, Drawer, Empty, Tabs, Typography, Tag } from 'antd';
import type { EmailAddress } from 'shared/types/email';
import { errorMessage } from '../Common/errorMessage';

export type EmailDetailsAction = ['view', id: bigint] | ['hide'];

interface EmailDetailsDrawerProps {
    action: EmailDetailsAction;
    onClose: () => void;
    title?: string;
}

export function EmailDetailsDrawer({ action, onClose, title }: EmailDetailsDrawerProps) {
    const id = action[0] === 'view' ? action[1] : null;

    const { data, isPending } = trpc.emailClassification.getEmailClassification.useQuery(
        id ? { id } : skipToken,
        {
            enabled: action[0] === 'view',
            throwOnError(error) {
                errorMessage.show(error);
                return true;
            },
        },
    );

    return (
        <Drawer
            title={title || 'Email Details'}
            open={action[0] === 'view'}
            onClose={onClose}
            width={1000}
            footer={
                <div className="flex justify-end">
                    <Button onClick={onClose}>Close</Button>
                </div>
            }
            loading={isPending || !data}
        >
            {data && (
                <div className="space-y-6">
                    <Card className="shadow-sm">
                        <div className="flex items-center gap-2">
                            <Typography.Text strong>Type:</Typography.Text>
                            <Tag color="blue">{data.emailType}</Tag>
                        </div>
                    </Card>

                    {data.summary && (
                        <Card title="Summary" className="shadow-sm">
                            <Typography.Paragraph className="mt-2 whitespace-pre-wrap rounded bg-gray-50 p-4">
                                {data.summary}
                            </Typography.Paragraph>
                        </Card>
                    )}

                    {data.justification && (
                        <Card title="Justification" className="shadow-sm">
                            <Typography.Paragraph className="mt-2 whitespace-pre-wrap rounded bg-gray-50 p-4">
                                {data.justification}
                            </Typography.Paragraph>
                        </Card>
                    )}

                    {data.breachedRegulation && (
                        <Card title="Breached Regulation" className="shadow-sm">
                            <Typography.Paragraph className="mt-2 whitespace-pre-wrap rounded bg-gray-50 p-4">
                                {data.breachedRegulation}
                            </Typography.Paragraph>
                        </Card>
                    )}

                    <Card title="Email Content" className="shadow-sm">
                        <Tabs
                            defaultActiveKey="header"
                            items={[
                                {
                                    key: 'header',
                                    label: 'Header',
                                    children: (
                                        <div className="mt-2 max-h-[300px] overflow-auto rounded bg-gray-50 p-4">
                                            <Card className="shadow-sm">
                                                <Descriptions column={1} className="email-header">
                                                    <Descriptions.Item label="Subject">
                                                        <Typography.Text strong>
                                                            {data.subject}
                                                        </Typography.Text>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Email ID">
                                                        <Typography.Text copyable>
                                                            {data.emailId}
                                                        </Typography.Text>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="From">
                                                        {displayEmailAddresses(data.from)}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="To">
                                                        {displayEmailAddresses(data.to)}
                                                    </Descriptions.Item>
                                                    {data.cc?.length > 0 && (
                                                        <Descriptions.Item label="CC">
                                                            {displayEmailAddresses(data.cc)}
                                                        </Descriptions.Item>
                                                    )}
                                                    {data.bcc?.length > 0 && (
                                                        <Descriptions.Item label="BCC">
                                                            {displayEmailAddresses(data.bcc)}
                                                        </Descriptions.Item>
                                                    )}
                                                </Descriptions>
                                            </Card>
                                        </div>
                                    ),
                                },
                                {
                                    key: 'html',
                                    label: 'HTML',
                                    children: data.bodyHtml ? (
                                        <div
                                            className="mt-2 max-h-[300px] overflow-auto rounded bg-gray-50 p-4"
                                            dangerouslySetInnerHTML={{
                                                __html: data.bodyHtml,
                                            }}
                                        />
                                    ) : (
                                        <Empty description="No HTML content available" />
                                    ),
                                },
                                {
                                    key: 'text',
                                    label: 'Plain Text',
                                    children: (
                                        <Typography.Paragraph
                                            className="mt-2 whitespace-pre-wrap rounded bg-gray-50 p-4"
                                            style={{ maxHeight: '300px', overflowY: 'auto' }}
                                        >
                                            {data.bodyText || 'No plain text content available'}
                                        </Typography.Paragraph>
                                    ),
                                },
                            ]}
                        />
                    </Card>

                    {(data.extractedData as any) && (
                        <Card title="Extracted Data" className="shadow-sm">
                            <pre className="m-0 mt-2 max-h-[200px] overflow-auto rounded bg-gray-50 p-4">
                                {JSON.stringify(data.extractedData, null, 2)}
                            </pre>
                        </Card>
                    )}
                </div>
            )}
        </Drawer>
    );
}

function displayEmailAddresses(emailAddresses: EmailAddress[] | undefined) {
    if (!emailAddresses) {
        return '-';
    }
    return emailAddresses
        .map((email) => {
            if (email.name) {
                return `${email.name} <${email.email}>`;
            }
            return email.email;
        })
        .join(', ');
}
