import { Breadcrumb } from 'antd';
import { ReactNode } from 'react';
import { useNavigate } from '@/hooks/useNavigate';

type Props = {
    breadCrumbs: {
        title: ReactNode;
        path?: string;
    }[];
};

export function BreadCrumbs({ breadCrumbs }: Props) {
    const navigate = useNavigate();

    return (
        <div className="mb-3">
            <Breadcrumb
                items={breadCrumbs.map(({ path, title }, i) => ({
                    key: i,
                    title: path ? (
                        <a href={path} key={i}>
                            {title}
                        </a>
                    ) : (
                        title
                    ),
                    onClick(e) {
                        e.preventDefault();

                        if (path) {
                            navigate({ href: path || '' });
                        }
                    },
                }))}
            />
            {/*<Breadcrumb>*/}
            {/*    {breadCrumbs.map((el, index) => (*/}
            {/*        <Breadcrumb.Item*/}
            {/*            key={index}*/}
            {/*            onClick={(e) => {*/}
            {/*                e.preventDefault();*/}
            {/*                if (el.path) {*/}
            {/*                   navigate(el.path || '');*/}
            {/*                }*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            {renderItem(el, index)}*/}
            {/*        </Breadcrumb.Item>*/}
            {/*    ))}*/}
            {/*</Breadcrumb>*/}
        </div>
    );
}
