import { useLocalObservable } from 'mobx-react-lite';

import { ProductTypeDef } from 'shared/types/productType';
import { QuestionnaireDef } from 'shared/types/questionnaire/definition';
import type { AliasToken } from 'antd/es/theme/interface';
import { claimCreateInputSchema } from 'server/dal/claim/schema';
import { z } from 'zod';

export type ClaimInput = z.infer<typeof claimCreateInputSchema>;

export interface ClaimFormState {
    claimId?: number;
    claimGeneratedId?: string;
    claimAccessKey?: string;
    note: string;
    claim: ClaimInput;
    newClaimId?: number;
    newClaimGeneratedId?: string;
    statuses: string[];
    decisions: string[];
    questionnaireDef: QuestionnaireDef;
    productTypeDef: ProductTypeDef;
    // Token for sending email & sms on public pages
    expToken: string;
    ui: {
        questionnaireLogoUrl: string;
        questionnaireFaviconUrl: string;
        questionnaireDeclarationTitle: string;
        questionnaireDeclarationBody: string;
        questionnaireConfirmationBody: string;
        backButtonLabel: string;
        backButtonUrl: string;
        activeStep: number;
        setActiveStep: (step: number) => void;
        activeForm: number;
        setActiveForm: (form: number) => void;
        goToNextForm: () => void;
        goToPrevForm: () => void;
        formAction: 'new' | 'edit' | 'view';
        steps: string[];
        initDone: boolean;
        readOnly: boolean;
        isSaved: boolean;
        displayAddNote: boolean;
        showAddNote: boolean;
        isSaving: boolean;
        direction: 'forward' | 'backward';
        theme: Partial<AliasToken>;
    };
    setAddNoteVisibility: (isVisible: boolean) => void;
}

export function useClaimFormState() {
    return useLocalObservable<ClaimFormState>(() => ({
        claimId: undefined,
        claimGeneratedId: undefined,
        note: '',
        decisions: [],
        statuses: [],
        questionnaireDef: [],
        productTypeDef: {} as ProductTypeDef,
        expToken: '',
        ui: {
            questionnaireLogoUrl: '',
            questionnaireFaviconUrl: '',
            questionnaireDeclarationTitle: '',
            questionnaireDeclarationBody: '',
            questionnaireConfirmationBody: '',
            backButtonLabel: '',
            backButtonUrl: '',
            initDone: false,
            isSaved: false,
            isSaving: false,
            displayAddNote: false,
            activeStep: 0,
            setActiveStep(step: number) {
                this.activeStep = step;
            },
            activeForm: 0,
            setActiveForm(form: number) {
                this.direction = form > this.activeForm ? 'forward' : 'backward';
                this.activeForm = form;
            },
            goToNextForm() {
                this.direction = 'forward';
                this.activeForm += 1;
            },
            goToPrevForm() {
                this.direction = 'backward';
                this.activeForm -= 1;
            },
            showAddNote: false,
            get steps() {
                const labels = [
                    'Policy details',
                    'Loss details',
                    'Loss cause',
                    'Damage description',
                    'Confirmation',
                ];

                if (this.formAction === 'new') {
                    return labels;
                } else {
                    return labels.slice(1);
                }
            },
            formAction: 'new',
            get readOnly() {
                return this.formAction === 'view';
            },
            direction: 'forward',
            theme: {},
        },

        claim: {
            contacts: {},
            jsonData: {},
            files: {},
        },
        setAddNoteVisibility(isVisible: boolean) {
            this.ui.showAddNote = isVisible;
        },
    }));
}
