import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import {
    navRoutes,
    ControlLibrarySection,
    CONTROL_LIBRARY_SECTIONS,
} from 'shared/navigation/navRoutes';
import { App, Skeleton, Tabs } from 'antd';
import { errorMessage } from '@/components/Common/errorMessage';
import { toBreadCrumb, toBreadCrumbWithoutLink } from '@/utils/navigation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { History } from '@/components/Common/History';
import { UpdateControlLibraryByPk } from '@/graphql/mutations/control.ts';
import { ControlLibraryByPk } from '@/graphql/queries/control.ts';
import { controlLibraryFormStore } from './store';
import { ControlLibraryItemDetailsEdit } from './ControlLibraryItemDetailsEdit';
import { useNavigate } from '@/hooks/useNavigate';
import { RecordNotFoundError } from '@/utils/error';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/controls/library/$id/$section')({
    staticData: {
        title: 'Control details',
    },
    params: {
        parse({ id, section }) {
            return {
                id: BigInt(id),
                section: section as ControlLibrarySection,
            };
        },
    },
    component: ControlLibraryItemDetails,
});

function ControlLibraryItemDetails() {
    const { message } = App.useApp();
    const { id } = Route.useParams();
    const navigate = useNavigate();
    const [getControlByPk] = useLazyQuery(ControlLibraryByPk);
    const [updateControlLibraryByPk] = useMutation(UpdateControlLibraryByPk);

    const { formData: control } = controlLibraryFormStore.useInit({
        loadDependencies: [id],

        async onSave(currentValue, newValue) {
            try {
                message.info('Saving...');

                await updateControlLibraryByPk({
                    variables: {
                        id: currentValue.id,
                        input: newValue,
                    },
                });

                message.destroy();
                message.success('Saved.');

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad() {
            try {
                const { data } = await getControlByPk({
                    variables: {
                        id,
                    },
                    fetchPolicy: 'network-only',
                });

                if (!data?.ControlLibraryByPk?.id) {
                    throw new RecordNotFoundError();
                }

                return {
                    success: true,
                    data: data?.ControlLibraryByPk,
                };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onError(error) {
            message.destroy();

            if (error instanceof RecordNotFoundError) {
                await errorMessage.showAsync(
                    "The control you are trying to access either doesn't exist.",
                );
                navigate({ to: '/admin-console/controls/library' });
            } else {
                errorMessage.show(error);
            }
        },
    });

    return (
        <>
            <BreadCrumbs
                breadCrumbs={[
                    toBreadCrumbWithoutLink(navRoutes.adminPortal.controls),
                    toBreadCrumb(navRoutes.adminPortal.controls_library),
                    {
                        title: 'Details',
                    },
                ]}
            />

            {control && BigInt(id) === BigInt(control.id) ? (
                <Tabs
                    type="line"
                    onTabClick={(key) => {
                        navigate({
                            to: '/admin-console/controls/library/$id/$section',
                            params: {
                                id,
                                section: key as ControlLibrarySection,
                            },
                        });
                    }}
                    items={[
                        {
                            key: CONTROL_LIBRARY_SECTIONS.DETAILS,
                            label: 'Manage control',
                            children: <ControlLibraryItemDetailsEdit />,
                        },
                        {
                            key: CONTROL_LIBRARY_SECTIONS.HISTORY,
                            label: 'History',
                            children: (
                                <History idValue={Number(id)} idPropertyName="controlLibraryId" />
                            ),
                        },
                    ]}
                />
            ) : (
                <Skeleton active />
            )}
        </>
    );
}
