import { Button, ButtonProps } from 'antd';
import { createLink, LinkComponent } from '@tanstack/react-router';
import { forwardRef } from 'react';
import clsx from 'clsx';

type LinkProps = Omit<ButtonProps, 'href'> & { innerType?: ButtonProps['type'] };

const AntdLinkComponent = forwardRef<HTMLAnchorElement, LinkProps>(
    ({ innerType, className, ...props }, ref) => (
        <Button
            ref={ref}
            type={innerType}
            className={clsx(innerType === 'link' && 'h-auto border-none p-0', className)}
            {...props}
        />
    ),
);

const CreatedLinkComponent = createLink(AntdLinkComponent);

export type LinkFC = LinkComponent<typeof AntdLinkComponent>;

export const Link: LinkFC = ({ preload, type, innerType, ...props }) => {
    return (
        <CreatedLinkComponent
            preload={preload || 'intent'}
            innerType={innerType || type}
            {...(props as any)}
        />
    );
};
