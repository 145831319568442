import { Card, Col, Row } from 'antd';
import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { navRoutes } from 'shared/navigation/navRoutes';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { TaskView } from '@/components/Task/TaskView';
import { TaskTable, TaskTableProps } from './TaskTable';
import { useSignal } from '@/hooks/useSignal';

const TaskSearchSearchSchema = z.object({
    id: z.coerce.bigint().optional(),
});

export const Route = createFileRoute('/console/$orgId/tasks/')({
    staticData: {
        title: 'Task list',
    },
    validateSearch: zodValidator(TaskSearchSearchSchema),
    component: TaskSearch,
});

function TaskSearch() {
    const { id } = Route.useSearch();
    const [refetchTableSignal, setRefetchTableSignal] =
        useSignal<TaskTableProps['refetchSignal']['data']>();

    return (
        <>
            <BreadCrumbs breadCrumbs={[toBreadCrumbWithoutLink(navRoutes.tasks_search)]} />

            <TaskTable refetchSignal={refetchTableSignal} />
            <Row gutter={16} className="mb-4">
                <Col
                    id="task-search-filter-area"
                    span={id ? 14 : 24}
                    className="flex justify-between"
                />
            </Row>
            <Row gutter={16}>
                <Col span={id ? 14 : 24}>
                    <Card>
                        <div id="task-search-table-area" />
                    </Card>
                </Col>
                {!!id && (
                    <Col span={10}>
                        <Card>
                            <TaskView
                                taskId={id}
                                onUpdate={({ id, wasCompleted }) => {
                                    setRefetchTableSignal(
                                        wasCompleted ? { completedTaskId: id } : {},
                                    );
                                }}
                                showUserStatistic
                            />
                        </Card>
                    </Col>
                )}
            </Row>
        </>
    );
}
