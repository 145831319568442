import { CollapsiblePanel } from '@/components/Common/CollapsiblePanel';
import { ComponentPreview } from '@/components/Common/ComponentPreview';
import { DatePicker } from '@/components/Common/DatePicker';
import { errorMessage } from '@/components/Common/errorMessage';
import { Indicator } from '@/components/Common/Indicator';
import { Link } from '@/components/Common/Link';
import { NewTabLink } from '@/components/Common/NewTabLink';
import { User } from '@/components/Common/User';
import { UserSelectorMultiple } from '@/components/Common/UserSelectorMultiple';
import { ProductSelector } from '@/components/Complaint/ProductSelector';
import { PageContent } from '@/components/Layout/PageContent';
import { BreadCrumbs } from '@/components/Navigation/BreadCrumbs';
import { IncidentDefs } from '@/global/incident';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { useFormatter } from '@/hooks/useFormatter';
import { useNavigate } from '@/hooks/useNavigate';
import { useSafePath } from '@/hooks/useSafePath';
import { RouterInputs, trpc } from '@/trpc';
import { toBreadCrumbWithoutLink } from '@/utils/navigation';
import { commonActionColumn } from '@/utils/table';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Space, Table, Tag, Tooltip } from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { navRoutes } from 'shared/navigation/navRoutes';
import { Falsy, isTruthy } from 'shared/utils/boolean';

export const Route = createFileRoute('/console/$orgId/compliance/incidents')({
    staticData: {
        title: 'Incidents',
    },
    component: IncidentSearch,
});

type Filters = {
    id: string | undefined;
    productImpacted: string[] | undefined;
    products: string[] | undefined;
    risk: string[] | undefined;
    typeId: bigint[] | undefined;
    potentialImpactSeverity: string[] | undefined;
    potentialImpactFrequency: string[] | undefined;
    dtIdentified: Date | null;
    status: string[] | undefined;
    ownerIds: bigint[] | undefined;
    externalClaimId: string | undefined;
    policyNo: string | undefined;
};

function IncidentSearch() {
    const orgId = useOrgId();
    const { formatDate, fDateShortDayJs } = useFormatter();
    const navigate = useNavigate();

    const [showFilter, setShowFilter] = useState(false);
    const [variables, setVariables] = useState<RouterInputs['incident']['listIncidents']>({
        orderBy: { id: 'desc' },
        limit: 10,
        offset: 0,
    });

    const { data, isPending } = trpc.incident.listIncidents.useQuery(variables, {
        throwOnError: (error) => {
            errorMessage.show(error.message);
            return false;
        },
    });

    const { data: { productsImpacted, risks, types } = {} } = trpc.incident.getIncidentColumnsForFiltering.useQuery(
        undefined,
        {
            throwOnError: (error) => {
                errorMessage.show(error.message);
                return false;
            },
        },
    );

    const [form] = Form.useForm<Filters>();
    const safePath = useSafePath<Filters>();
    const ownerIds = Form.useWatch<Filters['ownerIds']>(safePath('ownerIds'), form);

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isPending,
            total: data?.total,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                key: 'indicator',
                render: (_, item) => (
                    <Indicator
                        tooltip={{ title: item.Indicator?.title }}
                        style={{ backgroundColor: item.Indicator?.color || 'green' }}
                    />
                ),
            },
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                render: (_, item) => (
                    <>
                        {item.isBreachWithoutObligations && (
                            <Tooltip
                                title={
                                    <>
                                        This incident is a breach. Please specify which obligation(s) this incident has
                                        breached.
                                    </>
                                }
                            >
                                (Out of sync){' '}
                            </Tooltip>
                        )}
                        {String(item.id)}
                    </>
                ),
            },
            {
                title: 'Product or service',
                dataIndex: 'productImpacted',
                sorter: true,
            },
            {
                title: 'Type',
                dataIndex: 'IncidentType.label',
                sorter: true,
                render: (_, { IncidentType }) =>
                    IncidentType && (
                        <Tag className={clsx(IncidentType.isBreach && 'border-error bg-error text-red-50')}>
                            {IncidentType?.label}
                        </Tag>
                    ),
            },
            {
                title: 'Severity',
                dataIndex: 'potentialImpactSeverity',
                sorter: true,
                render: (value?: IncidentDefs.enumPotentialImpactSeverity) =>
                    value && <Tag color={IncidentDefs.severityToColor[value]}>{value}</Tag>,
            },
            {
                title: 'Incident description',
                dataIndex: 'description',
                sorter: true,
                className: 'max-w-xs align-top',
                render: (value) => <ComponentPreview>{value}</ComponentPreview>,
            },
            {
                title: 'Date identified',
                dataIndex: 'dtIdentified',
                sorter: true,
                render: (value) => formatDate(value),
            },
            {
                title: 'Date closed',
                dataIndex: 'dtClosed',
                sorter: true,
                render: (value) => formatDate(value),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: true,
                render: (value?: IncidentDefs.IncidentStatus) =>
                    value && (
                        <Tag color={IncidentDefs.statusToColor[value]}>
                            {IncidentDefs.INCIDENT_STATUSES[value] || value}
                        </Tag>
                    ),
            },
            {
                title: 'Owner',
                dataIndex: 'Owner.firstName',
                sorter: true,
                render: (_, { Owner }) => (
                    <User userId={Owner.userId} photoUrl={Owner.photoUrl} name={Owner.fullName} />
                ),
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <NewTabLink
                        to="/console/$orgId/compliance/incidents/details/$id/$section"
                        params={{
                            orgId,
                            id: value,
                            section: 'manage',
                        }}
                    />
                ),
            },
        ],
    });

    const onSearch = () => {
        const item = form.getFieldsValue();

        const filters: (Falsy | RouterInputs['incident']['listIncidents']['where'])[] = [
            item.id && { id: BigInt(item.id) },
            item.productImpacted?.length && {
                productImpacted: { in: item.productImpacted },
            },
            item.products?.length && {
                OR: item.products.map((product) => ({
                    products: {
                        string_contains: product,
                    },
                })),
            },
            item.risk?.length && { risk: { in: item.risk } },
            item.typeId?.length && { typeId: { in: item.typeId } },
            item.potentialImpactSeverity?.length && {
                potentialImpactSeverity: { in: item.potentialImpactSeverity },
            },
            item.potentialImpactFrequency?.length && {
                potentialImpactFrequency: { in: item.potentialImpactFrequency },
            },
            item.dtIdentified && {
                AND: [
                    {
                        dtIdentified: {
                            gte: DateTime.fromJSDate(item.dtIdentified).startOf('day').toJSDate(),
                        },
                    },
                    {
                        dtIdentified: {
                            lte: DateTime.fromJSDate(item.dtIdentified).endOf('day').toJSDate(),
                        },
                    },
                ],
            },
            item.status?.length && { status: { in: item.status } },
            item.ownerIds?.length && { Owner: { userId: { in: item.ownerIds } } },
            item.externalClaimId && {
                externalClaimId: { contains: item.externalClaimId },
            },
            item.policyNo && { policyNo: { contains: item.policyNo } },
        ];

        const where: RouterInputs['incident']['listIncidents']['where'] = {
            AND: filters.filter(isTruthy),
        };

        setVariables((prev) => ({ ...prev, where }));
    };

    return (
        <>
            <BreadCrumbs breadCrumbs={[toBreadCrumbWithoutLink(navRoutes.compliance_incidents)]} />

            <PageContent>
                <CollapsiblePanel open={showFilter}>
                    <Card title="Filters" size="small" className="mb-4">
                        <Form form={form} layout="vertical" onFinish={onSearch}>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item label="Incident ID" name={safePath('id')}>
                                        <InputNumber className="w-full" min="0" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Part of the business impacted" name={safePath('productImpacted')}>
                                        <Select
                                            options={productsImpacted?.map((item) => ({
                                                label: item || '-',
                                                value: item,
                                            }))}
                                            mode="multiple"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Products" name={safePath('products')}>
                                        <ProductSelector />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Risk" name={safePath('risk')}>
                                        <Select
                                            options={risks?.map((item) => ({
                                                label: item || '-',
                                                value: item,
                                            }))}
                                            mode="multiple"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label="Type" name={safePath('typeId')}>
                                        <Select
                                            options={types?.map(({ label, id }) => ({
                                                label: label || '-',
                                                value: id,
                                            }))}
                                            mode="multiple"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Impact severity" name={safePath('potentialImpactSeverity')}>
                                        <Select
                                            options={IncidentDefs.aryPotentialImpactSeverity.map((item) => ({
                                                value: item,
                                            }))}
                                            mode="multiple"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Impact frequency" name={safePath('potentialImpactFrequency')}>
                                        <Select
                                            options={IncidentDefs.aryPotentialImpactFrequency.map((item) => ({
                                                value: item,
                                            }))}
                                            mode="multiple"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Date identified" name={safePath('dtIdentified')}>
                                        <DatePicker
                                            placeholder=""
                                            className="w-full"
                                            format={fDateShortDayJs}
                                            maxDate={dayjs()}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label="Status" name={safePath('status')}>
                                        <Select
                                            options={Object.keys(IncidentDefs.INCIDENT_STATUSES).map((key) => ({
                                                label: IncidentDefs.INCIDENT_STATUSES[
                                                    key as IncidentDefs.IncidentStatus
                                                ],
                                                value: key,
                                            }))}
                                            mode="multiple"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Owner" name={safePath('ownerIds')}>
                                        <UserSelectorMultiple value={ownerIds || []} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Claim #" name={safePath('externalClaimId')}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Policy #" name={safePath('policyNo')}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Space className="flex justify-end">
                                <Button
                                    onClick={() => {
                                        form.resetFields();
                                        onSearch();
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Search
                                </Button>
                            </Space>
                        </Form>
                    </Card>
                </CollapsiblePanel>

                <Space className="mb-4 flex justify-end">
                    <Tooltip title="Filters">
                        <Button type="text" size="large" title="Filter" onClick={() => setShowFilter((prev) => !prev)}>
                            <FilterOutlined />
                        </Button>
                    </Tooltip>
                    <Link
                        to="/console/$orgId/compliance/incidents/add"
                        params={{
                            orgId,
                        }}
                        icon={<PlusOutlined />}
                    >
                        New incident
                    </Link>
                </Space>

                <Card>
                    <Table
                        scroll={{ x: 'max-content' }}
                        size="small"
                        onRow={({ id }) => ({
                            className: 'cursor-pointer',
                            onClick: (e) => {
                                navigate(
                                    {
                                        to: '/console/$orgId/compliance/incidents/details/$id/$section',
                                        params: {
                                            orgId,
                                            id,
                                            section: 'manage',
                                        },
                                    },
                                    e,
                                );
                            },
                        })}
                        {...tableProps}
                    />
                </Card>
            </PageContent>
        </>
    );
}
