import { ActionBar } from '@/components/Common/ActionBar';
import { CardTitle } from '@/components/Common/CardTitle';
import { DatePickerOld } from '@/components/Common/DatePickerOld';
import { PhoneNumberInput } from '@/components/Common/PhoneNumberInput';
import { ScrollToTopOnMount } from '@/components/Common/ScrollToTopOnMount';
import { useFormatter } from '@/hooks/useFormatter';
import { useAppStateStore } from '@/stores/AppStateStore';
import { validatePhoneNumber } from '@/utils/phone';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Divider, Form, Input, Radio, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { AU_STATES } from 'shared/constants/location';
import { Contact, ContactCollection, DriverContact as TDriverContact } from 'shared/types/contact';
import { ProductTypeCardTemplate } from 'shared/types/productType';
import { makeContactPrimaryForClaim } from 'shared/utils/contact';
import { uuid } from 'shared/utils/general';

type Props = {
    claimData: { contacts?: ContactCollection | null };
    onBack: () => void;
    onNext: () => void;
    onSaveDraftOpen?: () => void;
    card: ProductTypeCardTemplate;
};

const Item = Form.Item<TDriverContact>;

export const DriverContact = observer(
    ({ claimData, onBack, onNext, onSaveDraftOpen, card }: Props) => {
        const { claimLodgementSettings } = useAppStateStore();
        const { fDateShortDayJs } = useFormatter();

        const [form] = Form.useForm<TDriverContact>();

        const requiredMessage = claimLodgementSettings.fieldRequiredMessage;

        const aryClaimContact = Object.values(claimData.contacts || {});

        const onFinish = (contact: TDriverContact, goNext = true) => {
            claimData.contacts = claimData.contacts || {};
            claimData.contacts[contact.id] = contact;
            if (contact.isPrimary) {
                makeContactPrimaryForClaim(claimData.contacts, contact.id);
            }
            if (goNext) {
                onNext();
            }
        };

        const _onBack = () => {
            const contact = form.getFieldsValue() as TDriverContact;
            claimData.contacts = claimData.contacts || {};
            claimData.contacts[contact.id] = contact;
            if (contact.isPrimary) {
                makeContactPrimaryForClaim(claimData.contacts, contact.id);
            }
            onBack();
        };

        useEffect(() => {
            if (aryClaimContact.length) {
                const driverContact = aryClaimContact.find(isDriverContact);

                form.resetFields();

                if (isDriverContact(driverContact)) {
                    form.setFieldsValue(driverContact);
                }
            }
        }, [claimData]);

        return (
            <Form<TDriverContact>
                layout="vertical"
                form={form}
                name="_driverContact"
                initialValues={{
                    id: uuid(),
                    type: 'third_party',
                    typeName: 'driver',
                    isSystemType: true,
                }}
                onFinish={onFinish}
                size="large"
                scrollToFirstError
            >
                <ScrollToTopOnMount dep={card.id} />
                <Card
                    title={
                        <CardTitle
                            title={card.title || 'Driver details'}
                            subTitle={card.subTitle}
                        />
                    }
                    className="crm-ant-card-flexible-header"
                >
                    {aryClaimContact.find(({ type }) => type === 'policy_holder') && (
                        <Item label="Same as Policy holder" valuePropName="checked">
                            <Checkbox
                                onChange={(e) => {
                                    const initialId = form.getFieldValue('id');

                                    if (e.target.checked) {
                                        form.setFieldsValue({
                                            ...aryClaimContact.find(isPolicyHolderContact),
                                            id: initialId,
                                            type: 'third_party',
                                            typeName: 'driver',
                                            isSystemType: true,
                                        });

                                        return;
                                    }

                                    form.resetFields();
                                    form.setFieldsValue({
                                        id: initialId,
                                        type: 'third_party',
                                        typeName: 'driver',
                                        isSystemType: true,
                                    });
                                }}
                            />
                        </Item>
                    )}
                    <Item name="id" hidden />
                    <Item name="type" hidden />
                    <Item name="isSystemType" hidden />
                    <Item name="typeName" hidden />
                    <Item
                        label="First name"
                        name="firstName"
                        required
                        rules={[{ required: true, message: requiredMessage }]}
                    >
                        <Input />
                    </Item>
                    <Item
                        label="Last name"
                        name="lastName"
                        required
                        rules={[{ required: true, message: requiredMessage }]}
                    >
                        <Input />
                    </Item>
                    <Item label="E-mail" name="email" rules={[{ type: 'email' }]}>
                        <Input />
                    </Item>
                    <Item
                        label="Phone"
                        name="phone"
                        rules={[
                            {
                                validator: async (_, value) => {
                                    if (!validatePhoneNumber(value))
                                        throw new Error('Invalid phone number');
                                },
                            },
                        ]}
                    >
                        <PhoneNumberInput />
                    </Item>
                    <Divider plain>Address</Divider>
                    <Item label="Address 1" name="addressLine1">
                        <Input />
                    </Item>
                    <Item label="Address 2" name="addressLine2">
                        <Input />
                    </Item>
                    <Item label="Suburb" name="addressSuburb">
                        <Input />
                    </Item>
                    <Item label="State" name="addressState">
                        <Select
                            options={AU_STATES.map((s) => ({
                                value: s,
                            }))}
                        />
                    </Item>
                    <Item label="Postcode" name="addressPostcode">
                        <Input />
                    </Item>
                    <Item label="Driver's licence number" name="licenseNo">
                        <Input />
                    </Item>
                    <Item label="License expiry date" name="licenseExpiry">
                        <DatePickerOld format={fDateShortDayJs} />
                    </Item>
                    <Item
                        label="Is this person the main contact for all claim-related communication?"
                        name="isPrimary"
                    >
                        <Radio.Group size="large">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Item>
                </Card>
                <ActionBar
                    renderLeft={() => (
                        <Button
                            type="default"
                            onClick={_onBack}
                            icon={<ArrowLeftOutlined />}
                            size="large"
                        >
                            Back
                        </Button>
                    )}
                    renderRight={() => (
                        <>
                            {card.allowSkipping && (
                                <Button type="default" onClick={onNext} size="large">
                                    Skip
                                </Button>
                            )}
                            {onSaveDraftOpen && (
                                <Button
                                    icon={<SaveOutlined />}
                                    onClick={() => {
                                        const values = form.getFieldsValue();
                                        onFinish(values, false);
                                        onSaveDraftOpen();
                                    }}
                                    size="large"
                                >
                                    Save
                                </Button>
                            )}
                            <Button type="primary" htmlType="submit" size="large">
                                Next
                            </Button>
                        </>
                    )}
                />
            </Form>
        );
    },
);

const isDriverContact = (data?: Contact): data is TDriverContact => {
    if (!data) {
        return false;
    }
    return data.type === 'third_party' && data.typeName === 'driver';
};

const isPolicyHolderContact = (data?: Contact): data is TDriverContact => {
    if (!data) {
        return false;
    }

    return data.type === 'policy_holder';
};
