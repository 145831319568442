import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/dashboard')({
    staticData: {
        title: 'Dashboards',
        roles: ['user', 'org_admin'],
        modules: ['claim', 'compliance'],
    },
    component: Outlet,
});
